// services
import {
  createRecordInfo,
  findRecord,
  updateRecordFileVersionInfo,
  updateRecordInfo,
  updateRecordOperations
} from "@/services/record/recordService";

// utils
import { isArray } from "@/utils";

// model
import {
  findRecordState,
  recordMode,
  recordState,
  recordType
} from "@/model/record/recordModel";

// mutation Types
import { SET_RECORD_MODE } from "@/store/shared/mutationTypes/record/record-mutation-types";

/**
 * get all records, for provides state, by provided record id
 * @param state
 * @param {Number|number} id record id
 * @param {Boolean|boolean} includeCurrentRecord include current Record
 * @return {*[]} records
 */
const getRecords = (state, id, includeCurrentRecord = true) => {
  const records = [];

  if (includeCurrentRecord) {
    if ((state.record?.id ?? -100) === id) {
      records.push(state.record);
    }
  }

  const record = findRecord(state.records, id);
  if (record) {
    records.push(record);
  }

  const document = findRecord(state.documents, id);
  if (document) {
    records.push(document);
  }

  const breadcrumb = findRecord(state.breadcrumbs, id);
  if (breadcrumb) {
    records.push(breadcrumb);
  }

  return records;
};

/**
 * set the Current Record
 * @param state
 * @param {{record, category, version}} payload
 */
const setCurrentRecord = (state, payload) => {
  const record = payload?.record;
  const category = payload?.category;
  const version = payload?.version;

  /*const record = payload?.record ?? payload;
  const category =
    (await payload?.category) ??
    (await getCategory(record?.categoryId ?? -1))?.data;*/

  // Prevent commit to store if business logic failed
  if (record?.categoryId !== category?.id) {
    throw `Couldn't set current record, since provided category (id: ${category?.id}) does not match record's category (id:${record?.id})`;
  }

  // Set the current record
  state.record = record;

  // Set the current record's category
  state.recordCategory = category;

  state.recordFileVersion = version;

  console.log("recordFileVersion:", state.recordFileVersion);

  // Get all related records, by specified record id, from provided state
  const records = getRecords(state, record.id, false) ?? [];

  // update Record Info in each record of the current state
  try {
    records.forEach(record => {
      updateRecordInfo(record, state.record);
    });
  } catch (e) {
    console.error(
      `Error in: setCurrentRecord() while updateRecordInfo. ${e} (id=${record?.id})`
    );
  }

  // remarks: set current record always ensure recordMode.view mode
  setRecordMode(state, recordMode.view);
};

/**
 * set current Document
 * @param state
 * @param {{document, category}} payload
 */
const setDocument = (state, payload) => {
  const document = payload?.document;
  const category = payload?.category;

  //
  // e.g., how to impose business logic before committing any changes
  //

  //
  // Prevent commit to store if business logic failed
  //
  if (document?.categoryId !== category?.id) {
    throw `Couldn't set current document, since provided category (id: ${category?.id}) does not match document's category (id:${document?.id})`;
  }

  state.document = document;
  state.documentCategory = category;
};

/**
 * set Document Children
 * @param state
 * @param {{document, children}} payload
 */
const setDocumentChildren = (state, payload) => {
  const document = state.documents?.find(d => d.id === payload?.id ?? -1);
  const children = payload?.children ?? [];
  // Update document children
  if (document) {
    document.children = children?.length ?? 0;
    document.childRecords = children ?? [];

    console.log(`setDocumentChildren() document:`, document);
  }
};

/**
 * set current File Version
 * @param state
 * @param {{version, category}} payload
 */
const setFileVersion = (state, payload) => {
  const version = payload?.version;
  const category = payload?.category;

  //
  // e.g., how to impose business logic before committing any changes
  //

  //
  // Prevent commit to store if business logic failed
  //
  if (version?.categoryId !== category?.id) {
    throw `Couldn't set current FileVersion, since provided category (id: ${version?.id}) does not match File Version's category (id:${category?.id})`;
  }

  state.version = version;
  state.versionCategory = category;
};

/**
 * set Record Lock
 * @param state
 * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
 */
const setRecordLock = (state, payload) => {
  try {
    const id = payload?.id ?? -1;
    const isLocked = payload?.isLocked ?? false;
    const records = getRecords(state, id, true) ?? [];

    records.forEach(record => {
      record.isLocked = isLocked;
      //updateRecordInfo
      updateRecordInfo(record, payload);
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * update Record Status of current record and all related records
 * @param state
 * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
 */
const updateRecordStatus = (state, payload) => {
  try {
    const id = payload?.id ?? -1;
    const stateId = payload?.stateId ?? recordState.created;
    const stateName = findRecordState(stateId)?.name ?? "";

    // get all related records
    const records = getRecords(state, id, true) ?? [];

    records.forEach(record => {
      record.stateId = stateId;
      record.state = stateName;
      updateRecordOperations(record, payload);
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * update Record Infos
 * @param state
 * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload RecordItemModel (RecordInfo)
 */
const updateRecordInfos = (state, payload) => {
  try {
    const records = getRecords(state, payload?.id ?? -1, true) ?? [];

    records.forEach(record => {
      updateRecordInfo(record, payload);
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Record Read Only
 * @param state
 * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
 */
const setRecordReadOnly = (state, payload) => {
  try {
    const id = payload?.id ?? -1;
    const isReadOnly = payload?.isReadOnly ?? false;
    const records = getRecords(state, id, true) ?? [];

    records.forEach(record => {
      record.isReadOnly = isReadOnly;
      //updateRecordInfo
      updateRecordInfo(record, payload);
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Record Draft
 * @param state
 * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
 */
const setRecordDraft = (state, payload) => {
  try {
    const id = payload?.id ?? -1;
    const isDraft = payload?.isDraft ?? false;
    const records = getRecords(state, id, true) ?? [];

    records.forEach(record => {
      record.isDraft = isDraft;
      //updateRecordInfo
      updateRecordInfo(record, payload);
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Update File Version Info
 * reminder: RecordInfo - fields are not included
 * @param state
 * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
 */
const updateFileVersionInfo = (state, payload) => {
  try {
    const id = payload?.id ?? -1;
    const records = getRecords(state, id, true) ?? [];

    records.forEach(record => {
      updateRecordFileVersionInfo(record, payload);
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Upload File Progress
 * @param state
 * @param progress
 */
const setUploadFileProgress = (state, progress) => {
  state.uploadFileProgress = progress;
};

/**
 * set Record Mode
 * @param state
 * @param {Number|number} payload Record Mode
 */
const setRecordMode = (state, payload) => {
  state.recordMode = payload;

  if (state.recordMode !== recordMode.new) {
    state.recordNew = undefined;
    state.recordNewCategory = undefined;
    state.projectNew = undefined;
    state.projectNewTemplate = undefined;
  }

  console.log(SET_RECORD_MODE, state.recordMode);
};

/**
 * set the current Record as a New Record
 * @param state
 * @param {{record, category}} payload
 */
const setRecordNew = (state, payload) => {
  try {
    // set record Mode
    state.recordMode = recordMode.new;

    // set a new record and its category
    state.recordNew = payload?.record;
    state.recordNewCategory = payload?.category;

    // set a new workflow project and its template
    state.projectNew = payload?.project;
    state.projectNewTemplate = payload?.projectTemplate;
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Record New And its Category
 * @param state
 * @param {{record, category}} payload
 */
const setRecordNewAndCategory = (state, payload) => {
  state.recordNew = payload?.record;
  state.recordNewCategory = payload?.category;
};

const setRecordCreated = (state, payload) => {
  try {
    state.recordNew = payload;

    if (state.recordMode !== recordMode.new) {
      state.recordMode = recordMode.new;

      console.log(`setRecordCreated() state.recordMode:`, state.recordMode);
    }

    const recordNew = state.recordNew;

    // Update state.records
    if ((recordNew?.id ?? -1) > 0) {
      if (
        (state.record?.parentId ?? -1) === recordNew.parentId ||
        (state.record?.id ?? -1) === recordNew.parentId
      ) {
        // adds recordNew to the end of an array and returns the new length of the array
        state.records.push(recordNew);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * set current Record as edit Record
 * @param state
 * @param {{record, category}} payload
 */
const setRecordEdit = (state, payload) => {
  try {
    state.recordNew = payload?.record;
    state.recordNewCategory = payload?.category;
    state.recordMode = recordMode.edit;

    console.log(`setRecordEdit() state.recordMode:`, state.recordMode);
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Record Edited
 * @param state
 * @param {{record, category}} payload
 */
const setRecordEdited = (state, payload) => {
  try {
    const record = payload.record;
    const category = payload.category;

    const id = record?.id ?? -1;
    const records = getRecords(state, id, false) ?? [];

    // if current record, update record & its category
    if (id === state.record?.id) {
      state.record = record;
      state.recordCategory = category;
    }

    records.forEach(record => {
      updateRecordInfo(record, state.record);
    });

    // 4. Update a version of listed file versions
    if ((state.record?.recordTypeId ?? recordType.RECORD) === recordType.FILE) {
      (state.versions ?? []).forEach(fv => {
        updateRecordInfo(fv, state.record);
      });
    }

    setRecordMode(state, recordMode.view);
  } catch (e) {
    console.error(e);
  }
};

const setRecordDeleted = (state, payload) => {
  try {
    const id = payload ?? -1;

    if (id < 0) {
      return;
    }

    // 1. Update record state of current record
    if ((state.record?.id ?? -1) === id) {
      state.record.isDeleted = true;
    }

    // 2. Update record itm of listed records
    const record = findRecord(state.records, id);
    if (record) {
      record.isDeleted = true;

      // remove deleted record from the state.records
      const index = state.records.indexOf(record);
      if (index >= 0) {
        state.records.splice(index, 1);
      }
    }

    // 3. Update record item of listed documents/files/records
    const doc = findRecord(state.documents, id);
    if (doc) {
      doc.isDeleted = true;
    }

    // 4. Update a version of listed file versions
    if (state.record?.recordTypeId === recordType.FILE) {
      (state.versions ?? []).forEach(fv => {
        fv.isDeleted = true;
      });
    }

    // Set Delete Status to true
    state.recordDeleted = true;
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Record Text Field Value
 * @param state
 * @param {{id:number, value:string}} payload
 */
const setRecordTextFieldValue = (state, payload) => {
  try {
    const fieldId = payload?.id ?? -1;
    const record =
      state.recordMode === recordMode.view ? state?.record : state?.recordNew;

    if ((record?.id ?? -1) >= 0) {
      const fv = record?.fieldValues?.find(fv => fv.id === fieldId);
      if (fv) {
        fv.value = payload?.value ?? "";
        fv.textLoaded = true;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Record Lookup Data
 * @param state
 * @param {{id:number, value: any}[]} fieldValues
 */
const setRecordLookupData = (state, fieldValues) => {
  try {
    const record = state?.recordNew;
    if (!record || !fieldValues) return;

    fieldValues.forEach(fv => {
      if ((fv?.id ?? -1) >= 0) {
        const fieldValue = record?.fieldValues?.find(
          el => el.id === (fv.id ?? -1)
        );
        if (fieldValue) {
          fieldValue.value = fv.value;
        }
      } else if ((fv?.id ?? 0) === -1) {
        record.name = fv.value ?? "";
      }
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * set Compound Document New File Versions
 * @param state
 * @param payload TODO: list of new Compound Document records (files) instead of a single record
 */
const setCompoundDocumentNewFileVersions = (state, payload) => {
  const newRecords = payload;

  console.log(`setCompoundDocumentNewFileVersions() newRecords:`, newRecords);

  if (!isArray(newRecords)) {
    return;
  }

  // Update child records of SELECTED record
  newRecords.forEach(record => {
    if ((state?.record?.id ?? -1) === record.parentId) {
      if (!state.documents) {
        state.documents = [];
      }

      //
      // TODO: insert/append accordingly
      //
      state.documents.push(createRecordInfo(record));
    }
  });
};

/**
 * Append new Records to Record List
 * @param state
 * @param newRecords - Records to be Appended
 */
const appendRecords = (state, newRecords) => {
  try {
    console.log(`appendRecords() newRecords:`, newRecords);

    if (!isArray(newRecords)) {
      return;
    }

    // Update child records of SELECTED record
    newRecords.forEach(record => {
      if (
        !state.records.find(recordObj => {
          return recordObj.id === record.id; // Check to Avoid Duplications
        })
      ) {
        state.records.push(record);
      }
    });
  } catch (e) {
    console.log(e?.toString());
  }
};
export {
  setCurrentRecord,
  updateRecordStatus,
  setRecordLock,
  setRecordReadOnly,
  setRecordDraft,
  updateFileVersionInfo,
  updateRecordInfos,
  setUploadFileProgress,
  setRecordNew,
  setRecordNewAndCategory,
  setRecordCreated,
  setRecordEdit,
  setRecordEdited,
  setRecordMode,
  setRecordDeleted,
  setRecordTextFieldValue,
  setRecordLookupData,
  setCompoundDocumentNewFileVersions,
  setDocument,
  setDocumentChildren,
  setFileVersion,
  appendRecords
};

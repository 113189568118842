//import Vue from "vue";

// remarks: avoid import available icons,
// since it will bundle all available icons to final bundle, which has a big size.
// import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader

// fort awesome
// https://fontawesome.com/docs/apis/javascript/icon-library
// https://medium.com/front-end-weekly/how-to-use-fon-awesome-5-on-vuejs-project-ff0f28310821

// # Free icons styles
//
// 1. Install the base Font Awesome SVG library (fontawesome-svg-core)
// The @fortawesome/fontawesome-svg-core package provides the functionality,
// but not any of the icon content.
// npm i --save @fortawesome/fontawesome-svg-core
//
// 2. Install a Font Awesome icon sets
// npm i --save @fortawesome/free-solid-svg-icons
// npm i --save @fortawesome/free-regular-svg-icons // optional
// npm i --save @fortawesome/free-brands-svg-icons  // optional
//
// 3. Install the Font Awesome Vue component (vue-fontawesome).
// npm i --save @fortawesome/vue-fontawesome

//
// Adding icons to the library
//

// The "library" is the way we can subset or reduce file sizes and reference icons easily.
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Sub-setting - only import the icons that we need.
import {
  faCheck,
  faAsterisk,
  faClock,
  faGlobe,
  faLock,
  faPencil,
  faLink,
  faTrash,
  faXmark,
  faTriangleExclamation,
  faHand,
  faEye,
  faEyeSlash,
  faInfo,
  faSignOut
} from "@fortawesome/free-solid-svg-icons";

import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

// Add only needed icons to fontawesome-svg-core library
// The { library } import has an add() method which takes any number of arguments and registers icons for later use.
library.add(
  faCheck,
  faCheckCircle,
  faAsterisk,
  faClock,
  faGlobe,
  faLock,
  faPencil,
  faLink,
  faTrash,
  faXmark,
  faTriangleExclamation,
  faHand,
  faEye,
  faEyeSlash,
  faInfo,
  faSignOut
);

/**
 * free-solid-svg-icon names
 * @type {Readonly<{asterisk: string, link: string, triangleExclamation: string, eyeSlash: string, check: string, clock: string, xmark: string, pencil: string, trash: string, eye: string, globe: string, lock: string, hand: string}>}
 */
const fasIconName = Object.freeze({
  check: "check",
  asterisk: "asterisk",
  clock: "clock",
  globe: "globe",
  lock: "lock",
  pencil: "pencil",
  link: "link",
  trash: "trash",
  xmark: "xmark",
  triangleExclamation: "triangleExclamation",
  hand: "hand",
  eye: "eye",
  eyeSlash: "eye-slash",
  info: "info",
  signOut: "sign-out"
});

/**
 * free-regular-svg-icon names
 * @type {Readonly<{checkCircle: string}>}
 */
const farIconName = Object.freeze({
  checkCircle: "CheckCircle"
});

/**
 * Font Awesome Icon prefix
 * @type {Readonly<{faRegular: string, faSolid: string}>}
 */
const faPrefix = Object.freeze({
  faSolid: "fas",
  faRegular: "far"
});

const createFontAwesomeSolidIcon = iconName => {
  return createFontAwesomeIcon(iconName, faPrefix.faSolid);
};

const createFontAwesomeRegularIcon = iconName => {
  return createFontAwesomeIcon(iconName, faPrefix.faRegular);
};

/**
 * create Font Awesome Icon
 * @param {String|string} iconName
 * @param {String|string} prefix
 * @return {{iconName:string, prefix: string}}
 */
const createFontAwesomeIcon = (iconName, prefix = faPrefix.faSolid) => {
  return {
    prefix: prefix,
    iconName: iconName
  };
};

export {
  FontAwesomeIcon,
  fasIconName,
  farIconName,
  faPrefix,
  createFontAwesomeSolidIcon,
  createFontAwesomeRegularIcon
};

// services
import { handleError } from "@/services/error/errorService";
import {
  deleteWorkflowProject as apiDeleteWorkflowProject,
  cancelWorkflowProject as apiCancelWorkflowProject,
  suspendWorkflowProject as apiSuspendWorkflowProject,
  resumeWorkflowProject as apiResumeWorkflowProject
} from "@/services/api/apiWorkflow";

// store
import {
  SET_PROJECT_DELETED,
  SET_PROJECT_CANCELED,
  SET_PROJECT_SUSPENDED,
  SET_PROJECT_RESUMED
} from "@/store/shared/mutationTypes/project/project-mutation-types";

/**
 * delete Workflow Project
 * @param context
 * @param {number} id Workflow Project id
 * @return {Promise<void>}
 */
const deleteWorkflowProject = async (context, id) => {
  try {
    console.log(`deleteWorkflowProject() id:`, id);
    const response = await apiDeleteWorkflowProject(id);
    if (response.data) {
      context.commit(SET_PROJECT_DELETED, id);
    }
  } catch (e) {
    return await handleError(
      e,
      `Unable to delete project. (Project id: ${id})`
    );
  }
};

/**
 * suspend Workflow Project
 * @param context
 * @param {{ id: Number, message: String}} payload
 * @return {Promise<void>}
 */
const suspendWorkflowProject = async (context, payload) => {
  const id = payload?.id ?? -1;
  const message = payload?.message ?? undefined;
  try {
    console.log(`suspendWorkflowProject() id:`, id);
    const response = await apiSuspendWorkflowProject(id, message);
    if (response.data) {
      context.commit(SET_PROJECT_SUSPENDED, id);
    }
  } catch (e) {
    return await handleError(
      e,
      `Unable to suspend project. (Project id: ${id})`
    );
  }
};

/**
 * cancel Workflow Project
 * @param context
 * @param {number} id Workflow Project id
 * @return {Promise<void>}
 */
const cancelWorkflowProject = async (context, id) => {
  try {
    console.log(`cancelWorkflowProject() id:`, id);
    const response = await apiCancelWorkflowProject(id);
    if (response.data) {
      context.commit(SET_PROJECT_CANCELED, id);
    }
  } catch (e) {
    return await handleError(
      e,
      `Unable to cancel project. (Project id: ${id})`
    );
  }
};

/**
 * resume Workflow Project
 * @param context
 * @param {number} id Workflow Project id
 * @return {Promise<void>}
 */
const resumeWorkflowProject = async (context, id) => {
  try {
    console.log(`resumeWorkflowProject() id:`, id);
    const response = await apiResumeWorkflowProject(id);
    if (response.data) {
      context.commit(SET_PROJECT_RESUMED, id);
    }
  } catch (e) {
    return await handleError(
      e,
      `Unable to resume project. (Project id: ${id})`
    );
  }
};

export {
  deleteWorkflowProject,
  suspendWorkflowProject,
  cancelWorkflowProject,
  resumeWorkflowProject
};

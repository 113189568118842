// Vue Router
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

// routes
import { homeRoutes, notFoundRoutes } from "@/router/solution/solutionRoutes";
import { folderRoutes } from "@/router/folder/folderRoutes";
import { categoryRoutes } from "@/router/category/categoryRoutes";
import { searchRoutes } from "@/router/search/searchRoutes";
import { inboxRoutes } from "@/router/inbox/inboxRoutes";
import { projectRoutes } from "@/router/project/projectRoutes";
import { agendaRoutes } from "@/router/agenda/agendaRoutes";
import { loginRoutes } from "@/router/user/userRoutes";
import { documentRoutes } from "@/router/document/documentRoutes";
import { getStoredUser } from "@/services/user/userService";
import { isEmpty } from "@/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  // history mode: leverages the history.pushState API to achieve URL navigation without a page reload
  mode: "history",
  // please see: publicPath in vue.config.js
  base: process.env.BASE_URL,
  routes: [
    ...homeRoutes,
    ...folderRoutes,
    ...categoryRoutes,
    ...searchRoutes,
    ...inboxRoutes,
    ...projectRoutes,
    ...agendaRoutes,
    ...documentRoutes,
    ...loginRoutes,
    ...notFoundRoutes
  ]
});

//
// route meta data const
//
const metaRequiresAuth = "requiresAuth";

const autoRoute = (to, next) => {
  const isUserLoggedIn = store.getters["user/isUserLoggedIn"] ?? false;

  // if the route being navigated to matches one of our routes (record)
  // where the meta tag includes requiresAuth = true,
  // then we’ll see if we have a user loggedIn
  const requiresAuth = to.matched.some(route => route.meta[metaRequiresAuth]);

  if (requiresAuth && !isUserLoggedIn) {
    console.warn(
      `Re-Route to 'Login'; Requires Authentication: ${requiresAuth}; isUserLoggedIn: ${isUserLoggedIn}; Requested Route ${to.name}:`,
      to
    );
    next({ name: "Login", query: { redirectTo: to.fullPath } });
  } else {
    console.log(
      `Fulfill route request; Requires Authentication: ${requiresAuth}; Requested Route: ${to.name}:`,
      to
    );
    next();
  }
};

/**
 * get Auto Login User Info
 * @return {{userLoggedIn: boolean, hasToken:boolean, canAutoLogin: boolean, cannotAutoLoginReason: string}}
 */
const getAutoLoginUserInfo = () => {
  const userLoggedIn = store.getters["user/isUserLoggedIn"] ?? false;
  const storedUser = getStoredUser();
  const hasToken = !isEmpty(storedUser?.token);
  const canAutoLogin = storedUser && hasToken;

  let reason = undefined;

  if (!canAutoLogin) {
    if (!storedUser) {
      reason = "No stored user";
    }
    if (!hasToken) {
      const noToken = "No Token";
      reason = isEmpty(reason) ? `${noToken}` : `${reason}; ${noToken}`;
    }
  }

  return {
    userLoggedIn: userLoggedIn,
    hasToken: hasToken,
    canAutoLogin: canAutoLogin,
    cannotAutoLoginReason: reason
  };
};

//
// Using Global route Guards
//

/**
 * auto Login
 * @param user
 * @param to
 * @param next
 * @return {Promise<any>}
 */
function autoLogin(user, to, next) {
  console.log(
    `Going to auto-login user: ${user?.name} to repository:`,
    user?.repository
  );
  return store
    .dispatch("user/autoLogin")
    .then(() => {
      autoRoute(to, next);
    })
    .catch(e => {
      console.warn(`Couldn't Auto Login. ${e}`);
      autoRoute(to, next);
    });
}

/**
 * log Cannot Auto-Login Reason
 * @param {{name: string, repository: string }} user
 * @param {{userLoggedIn: boolean, hasToken:boolean, canAutoLogin: boolean, cannotAutoLoginReason: string}} loginInfo
 */
function logCannotAutoLoginReason(user, loginInfo) {
  console.log(
    `Cannot auto-login user: '${user?.name}' to repository: '${user?.repository}'. Reason:`,
    loginInfo?.cannotAutoLoginReason
  );
}

//
router.beforeEach((to, from, next) => {
  const storedUser = getStoredUser();
  const loginInfo = getAutoLoginUserInfo();

  console.log("router.beforeEach() loginInfo:", loginInfo);

  if (loginInfo.userLoggedIn) {
    console.log(
      `Make sure whether ${storedUser?.name}'s Repository Service is available.`
    );
    return store
      .dispatch("user/getUserRepositoryName")
      .then(response => {
        const repository = response;
        if (isEmpty(repository)) {
          console.warn(
            `User ${storedUser?.name}'s Repository Service is not available.`
          );
          if (loginInfo.canAutoLogin) {
            return autoLogin(storedUser, to, next);
          } else {
            logCannotAutoLoginReason(storedUser, loginInfo);
            autoRoute(to, next);
          }
        } else {
          console.log(
            `${storedUser?.name}'s Repository: ${repository} Service is available.`
          );
          autoRoute(to, next);
        }
      })
      .catch(e => {
        console.warn(
          `Try auto-Login, since Couldn't get User Repository. ${e}`
        );
        if (loginInfo.canAutoLogin) {
          return autoLogin(storedUser, to, next);
        } else {
          logCannotAutoLoginReason(storedUser, loginInfo);
          autoRoute(to, next);
        }
      });
  } else {
    if (loginInfo.canAutoLogin) {
      return autoLogin(storedUser, to, next);
    } else {
      logCannotAutoLoginReason(storedUser, loginInfo);
    }
  }

  autoRoute(to, next);
});

export default router;

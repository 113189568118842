// services
import { moduleNames } from "@/services/solution/solutionService";

// router
import {
  routeParams,
  expandedRoutePath,
  createAppModuleRouteName,
  createAppModuleRoutePath,
  createModuleContentRouteName,
  createModuleRoutePath,
  createModuleParamsId,
  createModuleChildRecordParamsId,
  createModuleExpandRecordContentRouteName,
  shortcutRoutePath,
  createModuleExpandRecordShortcutContentRouteName
} from "@/router/solution/solutionRoutes";

const moduleName = moduleNames.Folder;

const folderRoutes = Object.freeze([
  {
    path: createAppModuleRoutePath(moduleName),
    name: createAppModuleRouteName(moduleName),
    meta: { requiresAuth: true },
    component: () => import("@/views/folder/FolderHome"),
    children: [
      {
        path: createModuleRoutePath(routeParams.id),
        name: createModuleContentRouteName(moduleName),
        meta: { requiresAuth: true },
        props: createModuleParamsId,
        component: () => import("@/views/folder/FolderContent")
        // children: [
        //   {
        //     path: createModuleDetailsRoutePath(routeParams.id),
        //     name: createAppModuleDetailsRouteName(appModuleName),
        //     meta: { requiresAuth: true },
        //     props: true,
        //     component: () => import("@/views/meeting/FolderDetails")
        //   }
        // ]
      },
      {
        path: expandedRoutePath,
        name: createModuleExpandRecordContentRouteName(moduleName),
        meta: { requiresAuth: true },
        props: createModuleChildRecordParamsId,
        component: () => import("@/views/folder/FolderContent")
      },

      {
        path: shortcutRoutePath,
        name: createModuleExpandRecordShortcutContentRouteName(moduleName),
        meta: { requiresAuth: true },
        props: createModuleChildRecordParamsId,
        component: () => import("@/views/folder/FolderContent")
      }
    ]
  }
]);

export { folderRoutes };

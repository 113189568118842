// design
import {
  iconFile,
  iconFileImage,
  iconFilePdf,
  iconFileWord,
  iconExcel,
  iconFileJpg,
  iconXml,
  iconFilePng,
  iconFileGif,
  iconEmail
} from "@/design/icon/iconConst";

// utils
import { isEmpty, toLowerCaseSafe } from "@/utils";

/**
 * file Type enum which correspond to Questys.Common.FileType
 * @type {Readonly<{excelX: number, rtf: number, Svg: number, powerPointX: number, Html: number, Xhtml: number, unknown: number, wordX: number, excel: number, pdf: number, visio: number, Mhtml: number, Xml: number, ImageJpeg: number, Pcl: number, Pcl6: number, imageTiff: number, powerPoint: number, xps: number, text: number, word: number}>}
 */
const fileType = Object.freeze({
  unknown: 0,
  pdf: 1,
  word: 4,
  wordX: 5,
  excel: 6,
  excelX: 7,
  visio: 8,
  powerPoint: 9,
  powerPointX: 10,
  rtf: 11,
  xps: 12,
  text: 13,
  html: 14,
  xhtml: 15,
  mhtml: 16,
  xml: 17,
  svg: 18,
  pcl: 19,
  pcl6: 20,
  outlook: 21,
  imageTiff: 101,
  imageJpeg: 102,
  imageJpeg2000: 103,
  imagePng: 104,
  imageGif: 105,
  imageBmp: 106,
  imageEmf: 107,
  imagePcx: 108,
  imageTga: 109,
  imagePcd: 110
});

/**
 * File extensionName
 * @type {Readonly<{tif: string, pdf: string, tiff: string}>}
 */
const extensionName = Object.freeze({
  pdf: "pdf",
  word: "doc",
  wordX: "docx",
  excel: "xls",
  excelX: "xlsx",
  powerPoint: "ppt",
  powerPointX: "pptx",
  rtf: "rtf",
  xps: "xps",
  xml: "xml",
  svg: "svg",
  text: "txt",
  html: "html",
  tif: "tif",
  tiff: "tiff",
  jpg: "jpg",
  jpeg: "jpeg",
  png: "png",
  gif: "gif",
  bmp: "bmp",
  pcx: "pcx",
  msg: "msg"
  // add here more ...
});

const extensionAcronym = Object.freeze({
  pdf: "Portable Document Format (PDF)",
  tif: "Tag Image File Format (TIFF)",
  jpeg: "Joint Photographic Experts Group (JPG)",
  png: "Portable Network Graphic (PNG)",
  gif: "Graphics Interchange Format (GIF)",
  bmp: "Bitmap image file (BMP)",
  pcx: "Palette-indexed images (PCX)"
  // add here more ...
});

/**
 * File extensions
 * @type {Readonly<{extension: string, acronym: string, icon: string}[]>}
 */
const extensions = Object.freeze([
  {
    extension: extensionName.pdf,
    acronym: extensionAcronym.pdf,
    icon: iconFilePdf
  },
  {
    extension: extensionName.tif,
    acronym: extensionAcronym.tif,
    icon: iconFileImage
  },
  {
    extension: extensionName.tiff,
    acronym: extensionAcronym.tif,
    icon: iconFileImage
  },
  {
    extension: extensionName.jpg,
    acronym: extensionAcronym.jpeg,
    icon: iconFileJpg
  },
  {
    extension: extensionName.jpeg,
    acronym: extensionAcronym.jpeg,
    icon: iconFileJpg
  },
  {
    extension: extensionName.png,
    acronym: extensionAcronym.png,
    icon: iconFilePng
  },
  {
    extension: extensionName.gif,
    acronym: extensionAcronym.gif,
    icon: iconFileGif
  },
  {
    extension: extensionName.bmp,
    acronym: extensionAcronym.bmp,
    icon: iconFileImage
  },
  {
    extension: extensionName.pcx,
    acronym: extensionAcronym.pcx,
    icon: iconFileImage
  }
]);

/**
 * find Extension
 * @param {string} name Extension name
 * @return {{extension: string, acronym: string, icon: string}}
 */
const findExtension = name => {
  return extensions.find(el => el.extension === name);
};

/**
 * file Types which correspond to Questys.Common.FileType
 * @type {{isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean}[]}
 */
const fileTypes = Object.freeze([
  {
    type: fileType.pdf,
    extensions: [extensionName.pdf],
    icon: iconFilePdf,
    canAnnotate: true,
    multiFramed: true,
    isImageFormat: true
  },
  {
    type: fileType.word,
    extensions: [extensionName.word],
    icon: iconFileWord,
    canAnnotate: false,
    multiFramed: true,
    isImageFormat: false
  },
  {
    type: fileType.word,
    extensions: [extensionName.wordX],
    icon: iconFileWord,
    canAnnotate: false,
    multiFramed: true,
    isImageFormat: false
  },
  {
    type: fileType.excel,
    extensions: [extensionName.excel],
    icon: iconExcel,
    canAnnotate: false,
    multiFramed: true,
    isImageFormat: false
  },
  {
    type: fileType.excelX,
    extensions: [extensionName.excelX],
    icon: iconExcel,
    canAnnotate: false,
    multiFramed: true,
    isImageFormat: false
  },
  {
    type: fileType.xml,
    extensions: [extensionName.xml],
    icon: iconXml,
    canAnnotate: false,
    multiFramed: true,
    isImageFormat: false
  },
  {
    type: fileType.imageTiff,
    extensions: [extensionName.tif, extensionName.tiff],
    icon: iconFileImage,
    canAnnotate: true,
    multiFramed: true,
    isImageFormat: true
  },
  {
    type: fileType.ImageJpeg,
    extensions: [extensionName.jpg, extensionName.jpeg],
    icon: iconFileJpg,
    canAnnotate: true,
    multiFramed: false,
    isImageFormat: true
  },
  {
    type: fileType.imagePng,
    extensions: [extensionName.png],
    icon: iconFilePng,
    canAnnotate: true,
    multiFramed: false,
    isImageFormat: true
  },
  {
    type: fileType.imageGif,
    extensions: [extensionName.gif],
    icon: iconFileGif,
    canAnnotate: false,
    multiFramed: false,
    isImageFormat: true
  },
  {
    type: fileType.imageBmp,
    extensions: [extensionName.bmp],
    icon: iconFileImage,
    canAnnotate: false,
    multiFramed: false,
    isImageFormat: true
  },
  {
    type: fileType.imagePcx,
    extensions: [extensionName.pcx],
    icon: iconFileImage,
    canAnnotate: false,
    multiFramed: false,
    isImageFormat: true
  },
  {
    type: fileType.outlook,
    extensions: [extensionName.msg],
    icon: iconEmail,
    canAnnotate: false,
    multiFramed: true,
    isImageFormat: false
  }
  // add here more ...
]);

/**
 * find File Type
 * @param {Number} type File Type
 * @return {{extensions: ({name: string, icon: string}|{name: string, icon: string}|{name: string, icon: string}), type: string}}
 */
const findFileType = type => {
  return fileTypes.find(ft => ft.type === type);
};

/**
 * is Pdf file Extension
 * @param {String|string} extension file Extension
 * @return {boolean} true if Extension is Pdf file type Extension
 */
const isPdfExtension = extension => {
  return toLowerCaseSafe(extension) === extensionName.pdf;
};

/**
 * Image File Types
 * @return {{isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean}[]} Image File Types
 */
const imageFileTypes = () => {
  return fileTypes.filter(ft => ft.isImageFormat);
};

/**
 * find Image File Type by file extension
 * @param {string} ext
 * @return {{isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean}}
 */
const findImageFileType = ext =>
  imageFileTypes().find(ft =>
    ft.extensions.find(x => toLowerCaseSafe(x) === toLowerCaseSafe(ext))
  );

/**
 * annotate-able FileTypes
 * @return {({isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean}|{isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean}|{isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean}|{isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean}|{isImageFormat: boolean, extensions: string[], canAnnotate: boolean, icon: string, type: number, multiFramed: boolean})[]}
 */
const annotateFileTypes = () => {
  return fileTypes.filter(ft => ft.canAnnotate);
};

/**
 * is Annotate File Type Extension
 * @param {string} ext
 * @return {boolean} true if Annotate File Type Extension
 */
const isAnnotateFileTypeExtension = ext => {
  const ex = annotateFileTypes().find(el =>
    el.extensions.find(ex => toLowerCaseSafe(ex) === toLowerCaseSafe(ext))
  );
  return !isEmpty(ex);
};

/**
 * find File Type Icon
 * @param {string} extension
 * @return {string}
 */
const findFileTypeIcon = extension => {
  return (
    fileTypes.find(ft =>
      ft.extensions.find(ext => ext === toLowerCaseSafe(extension))
    )?.icon ?? iconFile
  );
};

/**
 * check In File Options
 * @type {Readonly<{insertAfterLastPage: number, overwrite: number, insertBeforeFirstPage: number}>}
 */
const checkInFileOption = Object.freeze({
  insertBeforeFirstPage: 0,
  insertAfterLastPage: 1,
  overwrite: 2
});

/**
 * check In File Options
 * @type {value: number, {text: string}[]}
 */
const checkInFileOptions = Object.freeze([
  {
    value: checkInFileOption.insertBeforeFirstPage,
    text: "Insert before first page"
  },
  {
    value: checkInFileOption.insertAfterLastPage,
    text: "Insert after last page"
  },
  {
    value: checkInFileOption.overwrite,
    text: "Overwrite"
  }
]);

/**
 * check In File Options for WIP File
 * @type {value: number, {text: string}[]}
 */
const checkInWIPFileOptions = Object.freeze([
  {
    value: checkInFileOption.insertBeforeFirstPage,
    text: "Insert before first page of Previous Version"
  },
  {
    value: checkInFileOption.insertAfterLastPage,
    text: "Insert after last page of Previous Version"
  },
  {
    value: checkInFileOption.overwrite,
    text: "Overwrite Previous Version"
  }
]);

/**
 * check In Compound Document File Options
 * @param pageNumber Insert before page number
 * @return {({text: string, value: number}|{text: string, value: number}|{text: string, value: number}|{text: string, value: number})[]}
 */
const checkInCompoundDocumentFileOptions = pageNumber =>
  Object.freeze([
    {
      value: checkInFileOption.insertBeforeFirstPage,
      text: "Insert before first page"
    },
    {
      value: checkInFileOption.insertAfterLastPage,
      text: `Insert before page ${pageNumber}`
    },
    {
      value: checkInFileOption.insertAfterLastPage,
      text: "Insert after last page"
    },
    {
      value: checkInFileOption.overwrite,
      text: "Overwrite"
    }
  ]);

/**
 * check In Single Image File Extensions
 * @type {{value: string, key: string, icon: string} []}
 */
const checkInSingleImageFileExtensions = Object.freeze([
  {
    key: extensionAcronym.tif,
    value: extensionName.tif,
    icon: iconFileImage
  },
  {
    key: extensionAcronym.pdf,
    value: extensionName.pdf,
    icon: iconFilePdf
  },
  {
    key: extensionAcronym.jpeg,
    value: extensionName.jpeg,
    icon: iconFileJpg
  }
]);

/**
 * check In Multiple Image File Extensions
 * @type {{value: string, key: string, icon: string} []}
 */
const checkInMultipleImageFileExtensions = Object.freeze([
  {
    key: extensionAcronym.tif,
    value: extensionName.tif,
    icon: iconFileImage
  },
  {
    key: extensionAcronym.pdf,
    value: extensionName.pdf,
    icon: iconFilePdf
  }
]);

/**
 * check In Compound File Extensions for Multiple Images
 * @type {{value: string, key: string, icon: string} []}
 */
const checkInCompoundMultipleImageFileExtensions = Object.freeze([
  {
    key: extensionAcronym.pdf,
    value: extensionName.pdf,
    icon: iconFilePdf
  }
]);

/**
 * download File Option
 * @type {Readonly<{asItIs: number, asPdf: number}>}
 */
const downloadFileOption = Object.freeze({
  asItIs: 0,
  asPdf: 1
});

/**
 * download File Options
 * @type {{description: string, option: number}[]}
 */
const downloadFileOptions = Object.freeze([
  {
    option: downloadFileOption.asItIs,
    description: "Download As It It"
  },
  {
    option: downloadFileOption.asPdf,
    description: "Download As PDF"
  }
]);

/**
 * find Download File Option
 * @param {Number} option Download File Option
 * @return {{description: string, option: number}}
 */
const findDownloadFileOption = option => {
  return downloadFileOptions.find(el => el.option === option);
};

/**
 * insert File Option
 * @type {Readonly<{afterLastPage: number, beforeFirstPage: number, overwrite: number}>}
 */
const insertFileOption = Object.freeze({
  beforeFirstPage: 0,
  afterLastPage: 1,
  overwrite: 2
});

/**
 * find Check In Multiple Image File Extension
 * @param {string} value Multiple Image File Extension
 * @return {{value: string, key: string, icon: string}} Check In Multiple Image File Extension
 */
const findCheckInMultipleImageFileExtension = value =>
  checkInMultipleImageFileExtensions.find(el => el?.value === value);

/**
 * find Check In Single Image File Extensions
 * @param {string} value Single Image File Extension
 * @return {{value: string, key: string, icon: string}} Check In Single Image File Extension
 */
const findCheckInSingleImageFileExtensions = value =>
  checkInSingleImageFileExtensions.find(el => el?.value === value);

/**
 * get File Size
 * @param {number} bytes
 * @param {number} decimals
 * @return {{unit: string, size: number}}
 */
const getFileSize = (bytes, decimals = 2) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (bytes === 0)
    return {
      size: 0,
      unit: sizes[0]
    };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: sizes[i]
  };
};

const findCheckinFileOption = value => {
  return checkInFileOptions.find(ft => ft?.value === value);
};

/**
 * Download File Mode
 * @type {Readonly<{default: number, view: number, viewerExport: number, wip: number}>}
 */
const downloadFileMode = Object.freeze({
  default: 0,
  wip: 1,
  view: 2,
  viewerExport: 3
});

export {
  fileType,
  fileTypes,
  findFileType,
  findFileTypeIcon,
  extensionName,
  checkInFileOption,
  checkInFileOptions,
  checkInSingleImageFileExtensions,
  checkInMultipleImageFileExtensions,
  checkInCompoundMultipleImageFileExtensions,
  extensionAcronym,
  checkInCompoundDocumentFileOptions,
  findCheckInMultipleImageFileExtension,
  findCheckInSingleImageFileExtensions,
  checkInWIPFileOptions,
  insertFileOption,
  extensions,
  findExtension,
  imageFileTypes,
  findImageFileType,
  downloadFileOptions,
  findDownloadFileOption,
  annotateFileTypes,
  isAnnotateFileTypeExtension,
  getFileSize,
  isPdfExtension,
  findCheckinFileOption,
  downloadFileMode
};

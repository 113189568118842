/**
 * Using Java Script ECMAScript 2021
 */
const getters = {
  /**
   * Get current selected Application module name
   * @param state
   * @returns {String|string}
   */
  appModuleName: state => state.appModule?.name ?? "",

  /**
   * Get current selected Application module icon
   * @param state
   * @returns {String|string}
   */
  appModuleIcon: state => state.appModule?.icon ?? "",

  /**
   * Get current selected Application module title
   * @param state
   * @returns {String|string}
   */
  appModuleTitle: state => state.appModule?.title ?? "",

  /**
   * Is visible Left Drawer
   * @param state
   * @returns {boolean}
   */
  visibleLeftDrawer: state => state.drawerLeft ?? true,

  /**
   * Is visible Right Drawer
   * @param state
   * @returns {boolean}
   */
  visibleRightDrawer: state => state.drawerRight ?? false,

  /**
   * Enable Mini Drawer
   * @param state
   * @return {boolean}
   */
  enableMiniDrawer: state => state.enableMiniDrawer ?? true,

  /**
   * Uses Tabs as a Application selector
   * @param state
   * @returns {boolean}
   */
  usesAppTabs: state => state.usesAppTabs ?? true,

  /**
   * App Bar collapse On Scroll
   * @param state
   * @return {boolean} true if App Bar collapse On Scroll
   */
  appBarCollapseOnScroll: state => state.appBarCollapseOnScroll ?? true,

  /**
   * Uses Sidebar Image
   * @param state
   * @returns {boolean}
   */
  usesSidebarImage: state => state.usesSidebarImage ?? false,

  /**
   * Get dialog Toolbar Style
   * @param state
   * @returns {boolean}
   */
  dialogToolbarStyle: state => state.dialogToolbarStyle ?? false,

  /**
   * Get Dialog Title Text Color
   * @param state
   * @returns {{name: string, variantType: string, variantNumber: number}}
   */
  dialogTitleColor: state => state.dialogTitleColor,

  /**
   * Application Bar Color
   * @param state
   * @returns {{name: String, variantType: String, variantNumber: Number}} Application Bar Color
   */
  appBarColor: state => state.appBarColor,

  /**
   * Application Bar Gradient
   * @param state
   * @return {string} Application Bar Gradient
   */
  appBarGradient: state => state.appBarGradient,

  /**
   * Data Table Toolbar Material Design Color
   * @param state
   * @returns {{variantType: string, variantNumber: number, name: string}}
   */
  dataTableToolbarColor: state => state.dataTableToolbarColor,

  /**
   * Get Menu Item colorName
   * @param state
   * @returns {{name:String, variantType:String, variantNumber:Number}}
   */
  menuItemColor: state => state.menuItemColor,

  /**
   * Get progress Color
   * @param state
   * @returns {{name:String, variantType:String, variantNumber:Number}}
   */
  progressColor: state => state.progressColor,

  /**
   * Get Button Color
   * @param state
   * @returns {{variantType: string, variantNumber: number, name: string}}
   */
  buttonColor: state => state.buttonColor,

  /**
   * Get Cancel Button Color
   * @param state
   * @returns {{name:String, variantType:String, variantNumber:Number}}
   */
  buttonCancelColor: state => state.buttonCancelColor,

  /**
   * Get command Buttons Text Style
   * @param state
   * @returns {boolean}
   */
  buttonTextStyle: state => state.buttonTextStyle ?? true,

  /**
   * Get whether button is Outlined
   * @param state
   * @returns {boolean}
   */
  buttonOutlined: state => state.buttonOutlined ?? false,

  /**
   * Get whether button is Rounded
   * @param state
   * @returns {boolean}
   */
  buttonRounded: state => state.buttonRounded ?? false,

  /**
   * Get button's Elevation
   * @param state
   * @returns {Number}
   */
  buttonElevation: state => state.buttonElevation ?? false,

  /**
   *  Details Toolbar colors
   *  Note: implements Material Design Color
   * @param state
   * @returns {{name:String, variantType:String, variantNumber:Number}}
   */
  detailsToolbarColor: state => state.detailsToolbarColor,

  /**
   * Delete Color
   * Note: implements Material Design Color
   * @param state
   * @returns {{name: String, variantType: String, variantNumber: Number}}
   */
  deleteColor: state => state.deleteColor,

  /**
   * records Fetch Count
   * @param state
   * @return {Number}
   */
  recordsFetchCount: state => state.recordsFetchCount
};

export default getters;

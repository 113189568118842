/**
 * Encapsulate Colors in the Material Design spec
 */

/**
 * Vuetify Color Theme Names
 * Vuetify has a standard theme applied for all components using these colorName type moduleNames
 * This can be easily changed. Simply pass a theme property to the Vuetify constructor
 * please see: plugins.vuetify
 * @type {Readonly<{secondary: string, success: string, warning: string, error: string, accent: string, primary: string, info: string}>}
 */
const colorTheme = Object.freeze({
  primary: "primary",
  secondary: "secondary",
  accent: "accent",
  error: "error",
  info: "info",
  success: "success",
  warning: "warning"
});

/**
 * Material design colorName palette moduleNames
 * @type {Readonly<{pink: string, deepPurple: string, green: string, lightBlue: string, lime: string, yellow: string, black: string, teal: string, amber: string, brown: string, cyan: string, transparent: string, grey: string, red: string, orange: string, lightGreen: string, blue: string, white: string, blueGrey: string, purple: string, deepOrange: string, indigo: string}>}
 */
const colorMD = Object.freeze({
  red: "red",
  pink: "pink",
  purple: "purple",
  deepPurple: "deep-purple",
  indigo: "indigo",
  blue: "blue",
  lightBlue: "light-blue",
  cyan: "cyan",
  teal: "teal",
  green: "green",
  lightGreen: "light-green",
  lime: "lime",
  yellow: "yellow",
  amber: "amber",
  orange: "orange",
  deepOrange: "deep-orange",
  brown: "brown",
  blueGrey: "blue-grey",
  grey: "grey",
  black: "black",
  white: "white",
  transparent: "transparent"
});

/**
 * Material design colorName Variant Type
 * @type {{lighten: string, darken: string}}
 */
const variantType = Object.freeze({
  lighten: "lighten",
  darken: "darken"
});

/**
 *  Material design colorName Variant Number
 * @type {Readonly<{n1: number, n2: number, n3: number, n4: number, n5: number}>}
 */
const variantNumber = Object.freeze({
  n1: 1,
  n2: 2,
  n3: 3,
  n4: 4,
  n5: 5
});

/**
 * Get Material design Color Class
 * @param {String|string} colorName
 * @param {String|string} variant
 * @param {Number} variantNumber
 * @returns {String|string} {string}
 */
const getMDColorClass = (colorName, variant, variantNumber) =>
  `${colorName} ${variant}-${variantNumber}`;

export { colorMD, colorTheme, variantNumber, variantType, getMDColorClass };

// services
import { storeSelectedModuleItemId } from "@/services/agenda/agendaModuleService";

// shared mutation types
import {
  SET_DRAWER_MINI,
  SET_RECORD,
  SET_RECORDS,
  SET_DOCUMENTS,
  SET_DOCUMENT,
  SET_RECORD_DRAFT,
  SET_RECORD_LOCK,
  SET_RECORD_READ_ONLY,
  SET_DOCUMENT_SEARCH_CATEGORY,
  UPDATE_RECORD_FILE_VERSION,
  SET_UPLOAD_FILE_PROGRESS,
  UPDATE_RECORD_STATUS,
  SET_FILE_VERSIONS,
  SET_FILE_VERSION,
  SET_RECORD_MODE,
  SET_RECORD_NEW,
  SET_RECORD_NEW_CATEGORY,
  SET_RECORD_EDIT,
  SET_RECORD_EDITED,
  SET_RECORD_CREATED,
  SET_RECORD_DELETED,
  SET_RECORD_TEXT_FIELD_VALUE,
  SET_RECORD_LOOKUP_DATA,
  SET_COMPOUND_DOCUMENT_NEW_FILE_VERSION,
  UPDATE_RECORD_INFO,
  SET_MOVED_RECORD,
  SET_LOCAL_UPLOADED_FILE,
  SET_DOCUMENT_CHILDREN,
  SET_RECORD_NEW_AND_CATEGORY,
  SET_MOVED_RECORDS,
  UPDATE_RECORD_LIST_BY_ID,
  UPDATE_DOCUMENT_LIST_BY_ID
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// hierarchy mutation types
import {
  SET_BREADCRUMBS,
  SET_PROJECT_NEW,
  SET_PROJECT_NEW_TEMPLATE,
  SET_RECORD_OPEN_PROJECTS,
  SET_RECORD_PROJECTS,
  SET_RECORD_NEW_PROJECT
} from "@/store/shared/mutationTypes/record/hierarchy-mutation-types";

// mutation Helper
import {
  setRecordDraft,
  setRecordLock,
  setRecordReadOnly,
  updateRecordStatus,
  setUploadFileProgress,
  updateFileVersionInfo,
  setRecordNew,
  setRecordEdit,
  setRecordMode,
  setRecordEdited,
  setRecordCreated,
  setRecordDeleted,
  setRecordTextFieldValue,
  setRecordLookupData,
  setCurrentRecord,
  setFileVersion,
  setDocument,
  updateRecordInfos,
  setDocumentChildren,
  setRecordNewAndCategory
} from "@/store/shared/mutations/record/record-mutations";

import {
  setCompoundDocumentNewFileVersions,
  setNewRecordProject,
  setProjectNew,
  setProjectNewTemplate,
  setRecordOpenProjects,
  setRecordProjects
} from "@/store/shared/mutations/record/hierarchy-mutations";
import { moduleNames } from "@/model/solution/moduleModel";
import {
  SET_PROJECT_CANCELED,
  SET_PROJECT_DELETED,
  SET_PROJECT_RESUMED,
  SET_PROJECT_SUSPENDED,
  UPDATE_PROJECT_MANAGER
} from "@/store/shared/mutationTypes/project/project-mutation-types";
import {
  setProjectCanceled,
  setProjectDeleted,
  setProjectResumed,
  setProjectSuspended
} from "@/store/shared/mutations/project/project-mutations";

const mutations = {
  /**
   * Set drawer to mini state
   * @param state
   * @param payload
   */
  [SET_DRAWER_MINI]: (state, payload) => {
    state.drawerMini = payload || false;
  },

  /**
   * Set current Agenda Meeting
   * @param state
   * @param {{record, category}} payload
   */
  [SET_RECORD]: (state, payload) => {
    setCurrentRecord(state, payload);

    console.log(`${moduleNames.Agenda}.${SET_RECORD} record:`, state.record);
    console.log(
      `${moduleNames.Agenda}.${SET_RECORD} recordCategory:`,
      state.recordCategory
    );

    state.projects = [];
    state.openProjects = [];

    storeSelectedModuleItemId(state.record?.id ?? -1);
  },

  /**
   * update Record Status
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [UPDATE_RECORD_STATUS](state, payload) {
    updateRecordStatus(state, payload);
  },

  /**
   * update Record Infos of current record and all related records
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload RecordItemModel
   */
  [UPDATE_RECORD_INFO](state, payload) {
    updateRecordInfos(state, payload);
  },

  [SET_RECORDS](state, payload) {
    state.records = payload;
    console.log(SET_RECORDS, state.records);
  },

  [SET_BREADCRUMBS](state, payload) {
    state.breadcrumbs = payload;
    console.log(SET_BREADCRUMBS, state.breadcrumbs);
  },

  [SET_DOCUMENTS]: (state, payload) => {
    state.documents = payload;
    console.log(SET_DOCUMENTS, state.documents);
  },

  /**
   * Set current selected 'project' document
   * @param state
   * @param {{document, category}} payload
   */
  [SET_DOCUMENT](state, payload) {
    setDocument(state, payload);
    console.log(
      `${moduleNames.Agenda}.${SET_DOCUMENT} document:`,
      state.document
    );
    console.log(
      `${moduleNames.Agenda}.${SET_DOCUMENT} documentCategory:`,
      state.documentCategory
    );
  },

  [SET_DOCUMENT_SEARCH_CATEGORY](state, payload) {
    state.searchCategory = payload;
    console.log(SET_DOCUMENT_SEARCH_CATEGORY, state.searchCategory);
  },

  /**
   * Set current 'agenda' record lock
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [SET_RECORD_LOCK](state, payload) {
    setRecordLock(state, payload);
  },

  /**
   * Set current 'agenda' record Read Only
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [SET_RECORD_READ_ONLY](state, payload) {
    setRecordReadOnly(state, payload);
  },

  /**
   * Set current 'agenda' record Read as Draft
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [SET_RECORD_DRAFT](state, payload) {
    setRecordDraft(state, payload);
  },

  /**
   * SET RECORD FILE VERSION
   * @param state
   * @param payload record
   */
  [UPDATE_RECORD_FILE_VERSION](state, payload) {
    updateFileVersionInfo(state, payload);
  },

  /**
   * SET UPLOAD FILE PROGRESS
   * @param state
   * @param {Number|number} progress
   */
  [SET_UPLOAD_FILE_PROGRESS](state, progress) {
    setUploadFileProgress(state, progress);
  },

  [SET_FILE_VERSIONS](state, payload) {
    state.versions = payload || [];
    console.log(SET_FILE_VERSIONS, state.versions);
  },

  /**
   * Set current 'folder' File Version
   * @param state
   * @param {{version, category}} payload
   */
  [SET_FILE_VERSION](state, payload) {
    setFileVersion(state, payload);
    console.log(
      `${moduleNames.Agenda}.${SET_FILE_VERSION} version:`,
      state.version
    );
    console.log(
      `${moduleNames.Agenda}.${SET_FILE_VERSION} versionCategory:`,
      state.versionCategory
    );
  },

  [SET_RECORD_MODE](state, payload) {
    setRecordMode(state, payload);
  },

  /**
   * set 'agenda' current Record as a New Record
   * @param state
   * @param {{record, category}} payload
   */
  [SET_RECORD_NEW](state, payload) {
    setRecordNew(state, payload);
  },

  [SET_RECORD_CREATED](state, payload) {
    setRecordCreated(state, payload);
  },

  [SET_RECORD_EDIT](state, payload) {
    setRecordEdit(state, payload);
  },

  [SET_RECORD_EDITED](state, payload) {
    setRecordEdited(state, payload);
  },

  [SET_RECORD_DELETED](state, payload) {
    setRecordDeleted(state, payload);
  },

  [SET_RECORD_NEW_AND_CATEGORY](state, payload) {
    setRecordNewAndCategory(state, payload);
  },

  [SET_RECORD_NEW_CATEGORY](state, payload) {
    state.recordNewCategory = payload;
  },

  [SET_RECORD_TEXT_FIELD_VALUE](state, payload) {
    setRecordTextFieldValue(state, payload);
  },

  [SET_RECORD_LOOKUP_DATA](state, payload) {
    setRecordLookupData(state, payload);
  },

  [SET_COMPOUND_DOCUMENT_NEW_FILE_VERSION](state, payload) {
    setCompoundDocumentNewFileVersions(state, payload);
  },

  [SET_MOVED_RECORD](state, payload) {
    state.movedRecord.moveType = payload?.type ?? undefined;
    state.movedRecord.record = payload?.record ?? undefined;
  },

  [SET_MOVED_RECORDS](state, payload) {
    state.batchMovedRecords.moveType = payload?.type ?? undefined;
    state.batchMovedRecords.records = payload?.records ?? [];
  },

  [SET_LOCAL_UPLOADED_FILE](state, payload) {
    state.localUploadedFile = payload ?? undefined;
  },

  /**
   * Set document children of specified document
   * @param state
   * @param {{document, children}} payload
   */
  [SET_DOCUMENT_CHILDREN](state, payload) {
    setDocumentChildren(state, payload);
  },

  /**
   * Set RECORD PROJECTS
   * @param state
   * @param payload
   */
  [SET_RECORD_PROJECTS](state, payload) {
    setRecordProjects(state, payload);
  },

  /**
   * Set RECORD OPEN PROJECTS
   * @param state
   * @param payload
   */
  [SET_RECORD_OPEN_PROJECTS](state, payload) {
    setRecordOpenProjects(state, payload);
  },

  /**
   * set PROJECT NEW
   * @param state
   * @param {{project, template}} payload
   */
  [SET_PROJECT_NEW](state, payload) {
    setProjectNew(state, payload);
  },

  /**
   * set PROJECT NEW TEMPLATE
   * @param state
   * @param {{id: number, name: string, assignAutoToOwner: boolean, calendar: string, canReassign: boolean, comment: string, created: string, definitionId: number, description: string, isDisabled: boolean, isHidden: boolean, notifyOnAborted: boolean, notifyOnAssignment: boolean, notifyOnAvailable: boolean, notifyOnCanceled: boolean, notifyOnCompletedFailure: boolean, notifyOnCompletedSuccess: boolean, projectManager: string, role: string, workflowName: string, workflowTypeId: number}} payload project template
   */
  [SET_PROJECT_NEW_TEMPLATE](state, payload) {
    setProjectNewTemplate(state, payload);
  },

  [SET_PROJECT_DELETED](state, payload) {
    setProjectDeleted(state, payload);
  },

  [SET_PROJECT_CANCELED](state, payload) {
    setProjectCanceled(state, payload);
  },

  [SET_PROJECT_SUSPENDED](state, payload) {
    setProjectSuspended(state, payload);
  },

  [SET_PROJECT_RESUMED](state, payload) {
    setProjectResumed(state, payload);
  },

  [UPDATE_PROJECT_MANAGER](state, payload) {
    const project = state.openProjects?.find(p => p.projectId === payload.id);
    project.projectManagerName = payload.managerName;
  },

  [SET_RECORD_NEW_PROJECT](state, payload) {
    setNewRecordProject(state, payload);
  },

  [UPDATE_RECORD_LIST_BY_ID](state, payload) {
    state.fetchRecordListById = payload;
  },

  [UPDATE_DOCUMENT_LIST_BY_ID](state, payload) {
    state.fetchDocumentListById = payload;
  }
};

export default mutations;

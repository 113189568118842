//
// Vuetify uses Google's Roboto font and Material Design Icons.
// The simplest way to install these are to include their CDN's in your main index.html.
//

import "@mdi/font/css/materialdesignicons.css"; // Ensure we are using css-loader
import "typeface-roboto/index.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { colorTheme } from "@/design/colors/Color";

Vue.use(Vuetify);

//
// We can also use the pre-defined material colors.
//
import colors from "vuetify/lib/util/colors";

//
// In order to change your font library, add the iconfont option
//
export default new Vuetify({
  icons: {
    iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  // Vuetify supports both light and dark variants of the Material Design spec.
  // theme: { dark: true },
  theme: {
    themes: {
      light: {
        [colorTheme.error]: colors.red.lighten1
      },
      dark: {
        [colorTheme.error]: colors.blue.lighten3
      }
    }
  }
});

// Customizing Questys themes here"
// By default, Vuetify has a color theme applied for all components.
// {
//     primary: '#1976D2',
//     secondary: '#424242',
//     accent: '#82B1FF',
//     error: '#FF5252',
//     info: '#2196F3',
//     success: '#4CAF50',
//     warning: '#FFC107',
// }

// services
import {
  storeUser,
  removeStoredUser,
  storeEnableCategoryModule,
  storeEnableTasksModule,
  storeEnableProjectsModule,
  storeEnableAgendaModule,
  createStoreUser,
  storeBackupUser,
  removeStoredBackupUser,
  getStoredBackupUser,
  storeEnableTextEditor,
  storeEnableAnnotationEditor,
  storeEnableSearchModule
} from "@/services/user/userService";

// mutation-types
import {
  SET_USER_MODULES,
  SET_USER_DATA,
  CLEAR_USER_DATA,
  SET_REPOSITORIES,
  SET_CHANGE_REPOSITORY,
  SET_CATEGORY_TYPES,
  SET_FIELDS,
  SET_CATEGORIES,
  SET_ENABLE_MODULE_CATEGORY,
  SET_ENABLE_MODULE_SEARCH,
  SET_ENABLE_MODULE_TASKS,
  SET_ENABLE_MODULE_PROJECTS,
  SET_ENABLE_MODULE_AGENDA,
  SET_FORM_FIELD,
  SET_TEXT_EDITOR,
  SET_ANNOTATION_EDITOR
} from "./mutation-types";

/**
 * Save User To Local lStore
 * @param {{name: string, token: string, tokenValidFrom: string, tokenValidTo: string, principal: {actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}}} user
 */
const saveUserToLocalStore = user => {
  try {
    const principal = user?.principal;

    const saveUser = createStoreUser(
      principal?.actorName ?? "",
      principal?.repository ?? "",
      user?.token ?? "",
      user?.tokenValidFrom ?? "",
      user?.tokenValidTo ?? ""
    );

    storeUser(saveUser);
  } catch (e) {
    console.error(e);
  }
};

const mutations = {
  /**
   * Set current user
   * @param state
   * @param user
   */
  [SET_USER_DATA]: (state, user) => {
    state.user = user;
    console.log(`${SET_USER_DATA} principal:`, state?.user?.principal);
    saveUserToLocalStore(state.user);
    removeStoredBackupUser();
  },

  /**
   * Clear current Questys' user
   * @param state
   */
  [CLEAR_USER_DATA]: state => {
    // 1. manually removing the user from local storage
    removeStoredUser();
    removeStoredBackupUser();

    // 2. store User's partial data to local storage
    const user = createStoreUser(
      state?.user?.principal?.actorName ?? "",
      state?.user?.principal?.repository ?? ""
    );

    storeUser(user);

    // Clearing out the Vuex State and axios header & reloads the current URL
    location.reload();
  },

  [SET_CHANGE_REPOSITORY]: (state, change) => {
    console.log(SET_CHANGE_REPOSITORY, change);

    if (change) {
      const backupUser = createStoreUser(
        state?.user?.principal?.actorName ?? "",
        state?.user?.principal?.repository ?? "",
        state?.user?.token ?? "",
        state?.user?.tokenValidFrom ?? "",
        state?.user?.tokenValidTo ?? ""
      );

      storeBackupUser(backupUser);
      removeStoredUser();

      const user = createStoreUser(
        state?.user?.principal?.actorName ?? "",
        state?.user?.principal?.repository ?? ""
      );

      storeUser(user);

      // Clearing out the Vuex State and axios header & reloads the current URL
      location.reload();
    } else {
      const user = getStoredBackupUser();
      storeUser(user);
      removeStoredBackupUser();
    }
  },

  /**
   * Set current Questys repositories
   * @param state
   * @param @type [{String}] repositories
   */
  [SET_REPOSITORIES]: (state, repositories) => {
    state.repositories = repositories || [];
  },

  [SET_CATEGORY_TYPES]: (state, payload) => {
    state.categoryTypes = payload;
    console.log(SET_CATEGORY_TYPES, state.categoryTypes);
  },

  [SET_FIELDS]: (state, payload) => {
    state.fields = payload;
    console.log(SET_FIELDS, state.fields);
  },

  [SET_FORM_FIELD]: (state, payload) => {
    let formField = state?.formFields?.find(
      ff => (ff?.id ?? -1) === (payload?.id ?? -1)
    );

    if (formField) {
      const index = state.formFields.indexOf(formField);
      if (index >= 0) {
        state.formFields.splice(index, 1, payload);
      }
    } else {
      if (!state.formFields) {
        state.formFields = [];
      }
      state.formFields.push(payload);
    }

    // console.log(`SET_FORM_FIELD state.formFields:`, state.formFields);

    formField = state.formFields?.find(
      ff => (ff?.id ?? -1) === (payload?.id ?? -1)
    );
    console.log(SET_FORM_FIELD, formField);
  },

  [SET_CATEGORIES]: (state, payload) => {
    state.categories = payload;
    console.log(SET_CATEGORIES, state.categories);
  },

  [SET_USER_MODULES]: (state, payload) => {
    state.modules = payload || [];
    console.log(SET_USER_MODULES, state.modules);
  },

  [SET_ENABLE_MODULE_CATEGORY]: (state, payload) => {
    state.enableCategoryModule = payload || false;
    storeEnableCategoryModule(state.enableCategoryModule);
  },

  [SET_ENABLE_MODULE_SEARCH]: (state, payload) => {
    state.enableSearchModule = payload || false;
    storeEnableSearchModule(state.enableSearchModule);
  },

  [SET_ENABLE_MODULE_TASKS]: (state, payload) => {
    state.enableTasksModule = payload || false;
    storeEnableTasksModule(state.enableTasksModule);
  },

  [SET_ENABLE_MODULE_PROJECTS]: (state, payload) => {
    state.enableProjectsModule = payload || false;
    storeEnableProjectsModule(state.enableProjectsModule);
  },

  [SET_ENABLE_MODULE_AGENDA]: (state, payload) => {
    state.enableAgendaModule = payload || false;
    storeEnableAgendaModule(state.enableAgendaModule);
  },

  [SET_TEXT_EDITOR]: (state, payload) => {
    state.enableTextEditor = payload || false;
    console.log(SET_TEXT_EDITOR, state.enableTextEditor);
    storeEnableTextEditor(state.enableTextEditor);
  },

  [SET_ANNOTATION_EDITOR]: (state, payload) => {
    state.enableAnnotationEditor = payload || false;
    console.log(SET_ANNOTATION_EDITOR, state.enableAnnotationEditor);
    storeEnableAnnotationEditor(state.enableAnnotationEditor);
  }
};

export default mutations;

// services
import {
  getCategories,
  getCategoryTypes,
  getField,
  getFields,
  getRepositories,
  getUserRepositoryName as apiGetUserRepositoryName
} from "@/services/api/apiContent";
import {
  login,
  loginWithToken,
  logout,
  updateAuthorization
} from "@/services/api/apiAuth";
import {
  getStoredUser,
  getEnableCategoryModule,
  getEnableSearchModule,
  getEnableTasksModule,
  getEnableProjectsModule,
  getEnableAgendaModule
} from "@/services/user/userService";
import { handleError } from "@/services/error/errorService";

// model
import { appModules, moduleNames } from "@/model/solution/moduleModel";
import { systemOperations } from "@/model/user/userModel";

// utils
import { isEmpty, isStringValueTrue } from "@/utils";

// mutation-types
import {
  CLEAR_USER_DATA,
  SET_CATEGORIES,
  SET_CATEGORY_TYPES,
  SET_CHANGE_REPOSITORY,
  SET_ENABLE_MODULE_AGENDA,
  SET_ENABLE_MODULE_CATEGORY,
  SET_ENABLE_MODULE_PROJECTS,
  SET_ENABLE_MODULE_SEARCH,
  SET_ENABLE_MODULE_TASKS,
  SET_FIELDS,
  SET_FORM_FIELD,
  SET_REPOSITORIES,
  SET_USER_DATA,
  SET_USER_MODULES
} from "./mutation-types";

/**
 * enabled Feature
 * @param {Boolean|boolean} supported is supported Feature
 * @param {String|string} settingValue local setting Value
 * @return {boolean|boolean}
 */
const enableFeature = (supported, settingValue) =>
  supported ? isStringValueTrue(settingValue) : false;

/**
 * Commit User
 * @param context
 * @param {{principal: ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|(function(*): ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|undefined))|*), tokenValidTo: (*|string), tokenValidFrom: (*|string), name: (string|string), token: (CancelToken|string|*|string)}} user
 */
const commitUser = (context, user) => {
  context.commit(SET_USER_DATA, user);

  updateAuthorization(user ? user.token || "" : "");

  //
  // Set available user's modules
  //
  const modules = appModules.filter(module =>
    context.getters["isSupportedModule"](module.name)
  );

  context.commit(SET_USER_MODULES, modules);

  appModules.forEach(module => {
    const supported = context.getters["isSupportedModule"](module.name);

    switch (module.name) {
      case moduleNames.Category: {
        const module = getEnableCategoryModule();
        if (!isEmpty(module)) {
          context.commit(
            SET_ENABLE_MODULE_CATEGORY,
            enableFeature(supported, module)
          );
        } else {
          if (!supported) {
            context.commit(SET_ENABLE_MODULE_CATEGORY, false);
          }
        }
        break;
      }
      case moduleNames.Search: {
        const module = getEnableSearchModule();
        if (!isEmpty(module)) {
          context.commit(
            SET_ENABLE_MODULE_SEARCH,
            enableFeature(supported, module)
          );
        } else {
          if (!supported) {
            context.commit(SET_ENABLE_MODULE_SEARCH, false);
          }
        }
        break;
      }
      case moduleNames.Tasks: {
        const enableTasks = getEnableTasksModule();
        if (!isEmpty(enableTasks)) {
          context.commit(
            SET_ENABLE_MODULE_TASKS,
            enableFeature(supported, enableTasks)
          );
        } else {
          if (!supported) {
            context.commit(SET_ENABLE_MODULE_TASKS, false);
          }
        }
        break;
      }
      case moduleNames.Projects: {
        const allowed = context.getters["isAllowedSystemOperation"](
          systemOperations.accessWFProjects
        );

        if (allowed) {
          const enableProjects = getEnableProjectsModule();
          if (!isEmpty(enableProjects)) {
            context.commit(
              SET_ENABLE_MODULE_PROJECTS,
              enableFeature(supported, enableProjects)
            );
          } else {
            if (!supported) {
              context.commit(SET_ENABLE_MODULE_PROJECTS, false);
            }
          }
        } else {
          context.commit(SET_ENABLE_MODULE_PROJECTS, false);
        }
        break;
      }
      case moduleNames.Agenda: {
        const enableAgendaModule = getEnableAgendaModule();
        if (!isEmpty(enableAgendaModule)) {
          context.commit(
            SET_ENABLE_MODULE_AGENDA,
            enableFeature(supported, enableAgendaModule)
          );
        } else {
          if (!supported) {
            context.commit(SET_ENABLE_MODULE_AGENDA, false);
          }
        }
        break;
      }
    }
  });
};

/**
 * Create User
 * @param data
 * @return {{principal: ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|(function(*): ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|undefined))|*), tokenValidTo: (*|string), tokenValidFrom: (*|string), name: (string|string), token: (CancelToken|string|*|string)}}
 */
const createUser = data => {
  return {
    name: data?.principal?.actorName ?? "",
    token: data?.token ?? "",
    tokenValidFrom: data?.validFrom ?? "",
    tokenValidTo: data?.validTo ?? "",
    principal: data?.principal
  };
};

/**
 * set User's Category And Fields
 * @param context
 * @return {Promise<never>}
 */
async function setUserCategoriesAndFields(context) {
  try {
    // set Category Types
    let response = await getCategoryTypes();
    context.commit(SET_CATEGORY_TYPES, response.data);

    // set current user's categories
    response = await getCategories();
    context.commit(SET_CATEGORIES, response.data);

    // set current user's fields
    response = await getFields();
    context.commit(SET_FIELDS, response.data);
  } catch (e) {
    return await handleError(e, `Unable to set User's Categories And Fields.`);
  }
}

/**
 * format Login Error
 * @param e
 * @param {String|string} message
 * @return {string|string}
 */
function formatLoginError(e, message) {
  const data = e?.response?.data;

  return data ? `${message}. ${data?.title}` : message ?? "";
}

const actions = {
  /**
   * Set Repositories
   * @param commit
   * @returns {Promise<String[]>}
   */
  async setRepositories({ commit }) {
    return getRepositories()
      .then(response => {
        // Checking response status
        if (response.status !== 200) {
          return Promise.reject(
            response.statusText
              ? new Error(
                  `Status Code: ${response.status}. ${response.statusText}.`
                )
              : new Error(`Status Code: ${response.status}`)
          );
        }

        /**
         * repositories
         * @type {String []}
         */
        const repositories = response.data || [];
        commit(SET_REPOSITORIES, repositories);

        return Promise.resolve(repositories);
      })
      .catch(error => handleError(error, `Unable to set Repositories`));
  },

  async getUserRepositoryName() {
    return apiGetUserRepositoryName()
      .then(response => {
        return Promise.resolve(response?.data);
      })
      .catch(e => {
        console.warn(`Couldn't get current User's Repository`, e?.toString());
        // handleError(error, `Unable to get User's Repository Name);
        return Promise.resolve(undefined);
      });
  },

  /**
   * Set Category Types
   * @param commit
   * @returns {Promise<{id: number, name: string}[]>}
   */
  async setCategoryTypes({ commit }) {
    return getCategoryTypes()
      .then(response => {
        const types = response.data;
        commit(SET_CATEGORY_TYPES, types);
        return Promise.resolve(types);
      })
      .catch(error => handleError(error, `Unable to set Category Types`));
  },

  /**
   * Login to Questys repository
   * @param context
   * @param {{ userName:string, password:string, repository:string }} payload
   * @return {Promise<{principal: ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|(function(*): ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|undefined))|*), tokenValidTo: (*|string), tokenValidFrom: (*|string), name: string, token: (CancelToken|string|*)}>}
   */
  async login(context, payload) {
    try {
      const response = await login(payload);
      const user = createUser(response.data);

      commitUser(context, user);

      await setUserCategoriesAndFields(context);

      return Promise.resolve(user);
    } catch (e) {
      return await handleError(e, formatLoginError(e, `Unable to Login user`));
    }
  },

  /**
   * Login With Token
   * @param context
   * @param {string} token
   * @return {Promise<{principal: ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|(function(*): ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|undefined))|*), tokenValidTo: (*|string), tokenValidFrom: (*|string), name: string, token: (CancelToken|string|*)}>}
   */
  async loginWithToken(context, token) {
    try {
      const response = await loginWithToken(token);
      const user = response.data;

      commitUser(context, createUser(user));

      await setUserCategoriesAndFields(context);

      return Promise.resolve(user);
    } catch (e) {
      return await handleError(
        e,
        formatLoginError(e, `Couldn't Login by user's token`)
      );
    }
  },

  /**
   * Logout
   * @param context
   */
  // eslint-disable-next-line no-unused-vars
  async logout(context) {
    return logout()
      .then(response => {
        updateAuthorization("");
        context.commit(CLEAR_USER_DATA);
        return Promise.resolve(response?.data);
      })
      .catch(error => {
        return handleError(error, "Couldn't logout");
      });
  },

  changeRepository(context) {
    context.commit(SET_CHANGE_REPOSITORY, true);
  },

  cancelChangeRepository(context) {
    context.commit(SET_CHANGE_REPOSITORY, false);
  },

  /**
   * Auto Login
   * @param context
   * @return {{principal: ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|(function(*): ({actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[]}|undefined))|*), tokenValidTo: (*|string), tokenValidFrom: (*|string), name: (string|string), token: (CancelToken|string|*|string)} | undefined}
   */
  async autoLogin(context) {
    const storedUser = getStoredUser();

    if (storedUser?.token) {
      return await context.dispatch("loginWithToken", storedUser?.token);
    }

    throw "Not found stored user";
  },

  /**
   * Set User's permitted fields
   * @param commit
   * @returns {Promise<[{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean}]>}
   */
  async setFields({ commit }) {
    return getFields()
      .then(response => {
        const data = response.data;

        commit(SET_FIELDS, data);

        return Promise.resolve(data);
      })
      .catch(error =>
        handleError(error, `Unable to set User's permitted fields.`)
      );
  },

  async setCategories({ commit }) {
    return getCategories()
      .then(response => {
        const data = response.data;

        commit(SET_CATEGORIES, data);

        return Promise.resolve(data);
      })
      .catch(error =>
        handleError(error, `Unable to set User's permitted categories.`)
      );
  },

  async setFormField(context, id) {
    return getField(id)
      .then(response => {
        const field = response.data;

        context.commit(SET_FORM_FIELD, field);

        return Promise.resolve(field);
      })
      .catch(e =>
        handleError(e, `Unable to set user's permitted Form Field by id: ${id}`)
      );
  }
};

export default actions;

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  /**
   * File Version
   * @type {{id:number, name:string, categoryId:number, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, isDeleted:boolean, isDraft:boolean, isComposite:boolean, isLink:boolean, isLocked:boolean, isReadOnly:boolean, recordTypeId:number, recordType:string, stateId:number, state:string, version:number, versionDate:string, versionOwner:string, operations: {name: string, allowed: boolean, valid: boolean}[] }[]}
   */
  document: null,
  searchHighlights: null,
  uploadFileProgress: 0
};

export default {
  actions,
  mutations,
  getters,
  state,
  namespaced: true
};

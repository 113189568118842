import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  repoUserLocalStorageKey
} from "@/utils/localStorageUtility";

/**
 * User Local Storage Keys
 * @type {Readonly<{buttonTextStyle: string, usesAppTabs: string, dialogToolbarStyle: string, buttonColor: string, usesSidebarImage: string, buttonOutlined: string, toolbarDetailsColor: string, menuItemColor: string, user: string}>}
 */
const storageKey = Object.freeze({
  user: "user",
  backupUser: "backupUser",
  usesAppTabs: "UsesAppTabs",
  collapseOnScroll: "collapseOnScroll",
  usesSidebarImage: "UsesSidebarImage",
  enableMiniDrawer: "enableMiniDrawer",
  dialogToolbarStyle: "dialogToolbarStyle",
  buttonTextStyle: "buttonTextStyle",
  buttonOutlined: "buttonOutlined",
  buttonRounded: "buttonRounded",
  buttonElevation: "buttonElevation",
  buttonColor: "buttonColor",
  menuItemColor: "menuItemColor",
  toolbarDetailsColor: "toolbarDetailsColor",
  enableCategoryModule: "enableCategoryModule",
  enableSearchModule: "enableSearchModule",
  enableTasksModule: "enableTasksModule",
  enableProjectsModule: "enableProjectsModule",
  enableAgendaModule: "enableAgendaModule",
  enableWebScan: "enableWebScan",
  enableTextEditor: "enableTextEditor",
  enableAnnotationEditor: "enableAnnotationEditor",
  recordsFetchCount: "recordsFetchCount"
});

/**
 * Create Store User
 * @param {String|string} name
 * @param {String|string} repository
 * @param {String|string} token
 * @param {String|string} tokenValidFrom
 * @param {String|string} tokenValidTo
 * @return {{tokenValidTo: string, tokenValidFrom: string, name: string, repository: string, token: string}}
 */
const createStoreUser = (
  name,
  repository,
  token = "",
  tokenValidFrom = "",
  tokenValidTo = ""
) => {
  return {
    name: name || "",
    repository: repository || "",
    token: token || "",
    tokenValidFrom: tokenValidFrom || "",
    tokenValidTo: tokenValidTo || ""
  };
};

/**
 * Store User to the local Storage
 * @param {{name: string, repository: string, token: string, tokenValidFrom: string, tokenValidTo: string}} user
 */
const storeUser = user => {
  try {
    if (user) {
      setLocalStorageItem(storageKey.user, JSON.stringify(user));
    } else {
      removeLocalStorageItem(storageKey.user);
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * Get Stored User from local Storage
 * @return {{name: string, repository: string, token: string, tokenValidFrom: string, tokenValidTo: string}|any|undefined}
 */
const getStoredUser = () => {
  try {
    const item = getLocalStorageItem(storageKey.user);
    if (!item) {
      return undefined;
    }
    return JSON.parse(item);
  } catch (e) {
    console.error(`Couldn't get stored user. ${e}`);
    return undefined;
  }
};

/**
 * Remove Stored User
 */
const removeStoredUser = () => removeLocalStorageItem(storageKey.user);

/**
 * store Backup User
 * @param {{name: string, repository: string, token: string, tokenValidFrom: string, tokenValidTo: string}} user
 */
const storeBackupUser = user => {
  try {
    if (user) {
      setLocalStorageItem(storageKey.backupUser, JSON.stringify(user));
    } else {
      removeLocalStorageItem(storageKey.backupUser);
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * get Stored Backup User
 * @return {{name: string, repository: string, token: string, tokenValidFrom: string, tokenValidTo: string}|any|undefined}
 */
const getStoredBackupUser = () => {
  try {
    const item = getLocalStorageItem(storageKey.backupUser);
    if (!item) {
      return undefined;
    }
    return JSON.parse(item);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * remove Stored Backup User
 */
const removeStoredBackupUser = () =>
  removeLocalStorageItem(storageKey.backupUser);

/**
 * User settings
 */

/**
 * Store UsesAppTabs to the local Storage
 * @param {String} usesAppTabs
 */
const storeUsesAppTabs = usesAppTabs =>
  setLocalStorageItem(storageKey.usesAppTabs, usesAppTabs);

/**
 * Get Stored UsesAppTabs from local Storage
 * @returns {String|string|null|undefined} UsesAppTabs
 */
const getStoredUsesAppTabs = () => getLocalStorageItem(storageKey.usesAppTabs);

/**
 * Store CollapseOnScroll to the local Storage
 * @param {String} value
 */
const storeAppBarCollapseOnScroll = value =>
  setLocalStorageItem(storageKey.collapseOnScroll, value);
/**
 * Get Collapse On Scroll
 * @return {String|string|null|undefined}
 */
const appBarCollapseOnScroll = getLocalStorageItem(storageKey.collapseOnScroll);

/**
 * store Uses Sidebar Image to local Storage
 * @param {String|string} usesSidebarImage
 */
const storeUsesSidebarImage = usesSidebarImage =>
  setLocalStorageItem(storageKey.usesSidebarImage, usesSidebarImage);

/**
 * Get Uses Sidebar Image from local Storage
 * @returns {string}
 */
const getStoredUsesSidebarImage = () =>
  getLocalStorageItem(storageKey.usesSidebarImage);

/**
 * store Enable Mini Drawer
 * @param {string} value
 */
const storeEnableMiniDrawer = value =>
  setLocalStorageItem(storageKey.enableMiniDrawer, value);

/**
 * Get Enable Mini Drawer
 * @type {string}
 */
const enableMiniDrawer = getLocalStorageItem(storageKey.enableMiniDrawer);

/**
 * Store Dialog Toolbar rStyle
 * @param {String|string} value
 */
const storeDialogToolbarStyle = value =>
  setLocalStorageItem(storageKey.dialogToolbarStyle, value);

/**
 * get Dialog Toolbar Style
 * @returns {String|string|null|undefined}
 */
const getDialogToolbarStyle = () =>
  getLocalStorageItem(storageKey.dialogToolbarStyle);

/**
 * Store Command Buttons Text Style
 * @param {String|string} value
 */
const storeCommandButtonTextStyle = value =>
  setLocalStorageItem(storageKey.buttonTextStyle, value);
/**
 * Get Command Buttons Text Style
 * @returns {String|string|null|undefined}
 */
const commandButtonTextStyle = getLocalStorageItem(storageKey.buttonTextStyle);

/**
 * Store Command Buttons Outlined
 * @param {String|string} value
 */
const storeCommandButtonOutlined = value =>
  setLocalStorageItem(storageKey.buttonOutlined, value);
/**
 * Get Command Buttons Outlined
 * @returns {String|string|null|undefined}
 */
const commandButtonOutlined = getLocalStorageItem(storageKey.buttonOutlined);

/**
 * Store Command Buttons Rounded
 * @param {String|string} value
 */
const storeCommandButtonRounded = value =>
  setLocalStorageItem(storageKey.buttonRounded, value);
/**
 * Get Command Buttons Rounded
 * @returns {String|string|null|undefined}
 */
const commandButtonRounded = getLocalStorageItem(storageKey.buttonRounded);

/**
 * Store Command Buttons Elevation
 * @param {String|string} value
 */
const storeCommandButtonElevation = value =>
  setLocalStorageItem(storageKey.buttonElevation, value);
/**
 * Get Command Buttons Rounded
 * @returns {String|string|null|undefined}
 */
const commandButtonElevation = getLocalStorageItem(storageKey.buttonElevation);

/**
 * Get Stored Data Table Configuration
 * @param {string} moduleName
 * @param {string} moduleItemId
 * @param {string} tableName
 * @returns {any|null}
 */
const getStoredDataTableConfiguration = (
  moduleName,
  moduleItemId,
  tableName
) => {
  try {
    const name = buildDataTableNameKey(moduleName, moduleItemId, tableName);
    const item = getLocalStorageItem(name);
    if (!item) {
      return null;
    }
    return JSON.parse(item);
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Get Data Table Record Properties Configuration
 * @param {string} moduleName
 * @param {string} moduleItemId
 * @param {string} tableName
 * @return {Array <{name: string, include: boolean, operator: string, value: string}>|any|null}
 */
const getDataTableRecordPropsConfiguration = (
  moduleName,
  moduleItemId,
  tableName
) => {
  try {
    const name = buildDataTableRecordPropsNameKey(
      moduleName,
      moduleItemId,
      tableName
    );
    const item = getLocalStorageItem(name);
    if (!item) {
      return null;
    }
    return JSON.parse(item);
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Get Data Table Fields Configuration
 * @param {string} moduleName
 * @param {string} moduleItemId
 * @param {string} tableName
 * @return {Array <{Id: number, Include: boolean, Value: string, Operator: string}>|any|undefined}
 */
const getDataTableFieldsConfiguration = (
  moduleName,
  moduleItemId,
  tableName
) => {
  try {
    const name = buildDataTableFieldsNameKey(
      moduleName,
      moduleItemId,
      tableName
    );
    const item = getLocalStorageItem(name);
    if (!item) {
      return undefined;
    }
    return JSON.parse(item);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * Store Data Table Configuration
 * @param {string} moduleName
 * @param {string} moduleItemId
 * @param {string} tableName
 * @param {any} configuration
 */
const storeDataTableConfiguration = (
  moduleName,
  moduleItemId,
  tableName,
  configuration
) => {
  try {
    const name = buildDataTableNameKey(moduleName, moduleItemId, tableName);
    if (configuration) {
      setLocalStorageItem(name, JSON.stringify(configuration));
    } else {
      removeLocalStorageItem(name);
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * Store Data Table Fields Configuration
 * @param {string} moduleName module Name
 * @param {string} moduleItemId module Item id
 * @param {string} tableName table Name
 * @param {any} configuration
 */
const storeDataTableFieldsConfiguration = (
  moduleName,
  moduleItemId,
  tableName,
  configuration
) => {
  try {
    const name = buildDataTableFieldsNameKey(
      moduleName,
      moduleItemId,
      tableName
    );
    if (configuration) {
      setLocalStorageItem(name, JSON.stringify(configuration));
    } else {
      removeLocalStorageItem(name);
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * store DataTable Record Props Configuration
 * @param {string} moduleName
 * @param {string} moduleItemId
 * @param {string} tableName
 * @param {any} configuration
 */
const storeDataTableRecordPropsConfiguration = (
  moduleName,
  moduleItemId,
  tableName,
  configuration
) => {
  try {
    const name = buildDataTableRecordPropsNameKey(
      moduleName,
      moduleItemId,
      tableName
    );
    if (configuration) {
      setLocalStorageItem(name, JSON.stringify(configuration));
    } else {
      removeLocalStorageItem(name);
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * Build Data Table local Storage Key
 * @param {string} moduleName
 * @param {string} moduleItemId
 * @param {string} tableName
 * @returns {string}
 */
const buildDataTableNameKey = (moduleName, moduleItemId, tableName) =>
  `${repoUserLocalStorageKey(moduleName)}_${moduleItemId}_${tableName}`;

/**
 * build Data Table Fields Name Key
 * @param {string} moduleName
 * @param {string} moduleItemId
 * @param {string} tableName
 * @return {string}
 */
const buildDataTableFieldsNameKey = (moduleName, moduleItemId, tableName) =>
  `${repoUserLocalStorageKey(moduleName)}_${moduleItemId}_${tableName}_fields`;

/**
 * build Data Table Record Properties Name Key
 * @param moduleName
 * @param moduleItemId
 * @param tableName
 * @return {`${string}_${string}_${string}_props`}
 */
const buildDataTableRecordPropsNameKey = (
  moduleName,
  moduleItemId,
  tableName
) =>
  `${repoUserLocalStorageKey(moduleName)}_${moduleItemId}_${tableName}_props`;

/**
 * Store Button Color
 * @param {String} color
 */
const storeButtonColor = color =>
  setLocalStorageItem(storageKey.buttonColor, color);
/**
 * get Stored Button Color
 * @returns {string}
 */
const getStoredButtonColor = () => getLocalStorageItem(storageKey.buttonColor);

/**
 * Store Menu Item Color
 * @param color
 */
const storeMenuItemColor = color =>
  setLocalStorageItem(storageKey.menuItemColor, color);
/**
 * get Stored Menu Item Color
 * @returns {string}
 */
const getStoredMenuItemColor = () =>
  getLocalStorageItem(storageKey.menuItemColor);

/**
 * Store Toolbar Details Color
 * @param color
 */
const storeToolbarDetailsColor = color =>
  setLocalStorageItem(storageKey.toolbarDetailsColor, color);
/**
 * get Toolbar Details Color
 * @returns {string}
 */
const getToolbarDetailsColor = () =>
  getLocalStorageItem(storageKey.toolbarDetailsColor);

/**
 * Store Enable Category Module indicator
 * @param {string} value
 */
const storeEnableCategoryModule = value =>
  setLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableCategoryModule),
    value
  );

/**
 * Get enable Category Module indicator
 * @return {string|null|undefined}
 */
const getEnableCategoryModule = () =>
  getLocalStorageItem(repoUserLocalStorageKey(storageKey.enableCategoryModule));

/**
 * Store Enable Search Module indicator
 * @param {string} value
 */
const storeEnableSearchModule = value =>
  setLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableSearchModule),
    value
  );

/**
 * Get enable Search Module indicator
 * @return {string|null|undefined}
 */
const getEnableSearchModule = () =>
  getLocalStorageItem(repoUserLocalStorageKey(storageKey.enableSearchModule));

/**
 * Store Enable Tasks Module indicator
 * @param {string} value
 */
const storeEnableTasksModule = value =>
  setLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableTasksModule),
    value
  );

/**
 * Get enable Tasks Module indicator
 * @return {string|null|undefined}
 */
const getEnableTasksModule = () =>
  getLocalStorageItem(repoUserLocalStorageKey(storageKey.enableTasksModule));

/**
 * Store Enable Projects Module indicator
 * @param {string} value
 */
const storeEnableProjectsModule = value =>
  setLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableProjectsModule),
    value
  );

/**
 * get Enable Projects Module
 * @return {string|null|undefined}
 */
const getEnableProjectsModule = () =>
  getLocalStorageItem(repoUserLocalStorageKey(storageKey.enableProjectsModule));

/**
 * Store Enable Agenda Module indicator
 * @param {string} value
 */
const storeEnableAgendaModule = value =>
  setLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableAgendaModule),
    value
  );

/**
 * Get enable Agenda Module indicator
 * @return {string|null|undefined}
 */
const getEnableAgendaModule = () =>
  getLocalStorageItem(repoUserLocalStorageKey(storageKey.enableAgendaModule));

/**
 * store Enable Text Editor
 * @param {string} value
 */
const storeEnableTextEditor = value =>
  setLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableTextEditor),
    value
  );

/**
 * store Enable Annotation Editor
 * @param {string} value
 */
const storeEnableAnnotationEditor = value =>
  setLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableAnnotationEditor),
    value
  );

/**
 * Get enable WebScan indicator
 * @return {string|null|undefined}
 */
const getEnableTextEditor = () =>
  getLocalStorageItem(repoUserLocalStorageKey(storageKey.enableTextEditor));

/**
 * Get enable WebScan indicator
 * @return {string|null|undefined}
 */
const getEnableAnnotationEditor = () =>
  getLocalStorageItem(
    repoUserLocalStorageKey(storageKey.enableAnnotationEditor)
  );

/**
 * store Records Fetch Count
 * @param {string|null|undefined} count
 */
const storeRecordsFetchCount = count =>
  setLocalStorageItem(storageKey.recordsFetchCount, count);

/**
 * get Records Fetch Count
 * @return {string|null}
 */
const getRecordsFetchCount = () =>
  getLocalStorageItem(storageKey.recordsFetchCount);

export {
  storeUser,
  getStoredUser,
  removeStoredUser,
  storeUsesAppTabs,
  getStoredUsesAppTabs,
  getStoredUsesSidebarImage,
  storeUsesSidebarImage,
  getStoredDataTableConfiguration,
  storeDataTableConfiguration,
  storeDialogToolbarStyle,
  getDialogToolbarStyle,
  storeCommandButtonTextStyle,
  storeCommandButtonOutlined,
  storeButtonColor,
  getStoredButtonColor,
  storeMenuItemColor,
  getStoredMenuItemColor,
  storeToolbarDetailsColor,
  getToolbarDetailsColor,
  storeDataTableFieldsConfiguration,
  getDataTableFieldsConfiguration,
  buildDataTableFieldsNameKey,
  storeDataTableRecordPropsConfiguration,
  getDataTableRecordPropsConfiguration,
  storeAppBarCollapseOnScroll,
  storeEnableMiniDrawer,
  storeEnableCategoryModule,
  getEnableCategoryModule,
  storeEnableTasksModule,
  getEnableTasksModule,
  storeEnableProjectsModule,
  storeEnableAgendaModule,
  getEnableAgendaModule,
  storeCommandButtonRounded,
  storeCommandButtonElevation,
  createStoreUser,
  storeBackupUser,
  getStoredBackupUser,
  removeStoredBackupUser,
  getEnableProjectsModule,
  commandButtonTextStyle,
  commandButtonOutlined,
  appBarCollapseOnScroll,
  enableMiniDrawer,
  commandButtonRounded,
  commandButtonElevation,
  storeEnableAnnotationEditor,
  storeEnableTextEditor,
  getEnableTextEditor,
  getEnableAnnotationEditor,
  storeRecordsFetchCount,
  getRecordsFetchCount,
  storeEnableSearchModule,
  getEnableSearchModule
};

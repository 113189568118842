const SET_PROJECTS = "SET_PROJECTS";
const SET_PROJECT = "SET_PROJECT";

const SET_PROJECT_TEMPLATES = "SET_PROJECT_TEMPLATES";
const SET_PROJECT_TEMPLATE = "SET_PROJECT_TEMPLATE";
const SET_WORKFLOW_ROLES = "SET_WORKFLOW_ROLES";
const SET_WORKFLOW_CALENDARS = "SET_WORKFLOW_CALENDARS";

const SET_PROJECT_CATEGORIES = "SET_PROJECT_CATEGORIES";
const SET_PROJECT_CATEGORY = "SET_PROJECT_CATEGORY";
const SET_PROJECT_CATEGORY_TEMPLATES = "SET_PROJECT_CATEGORY_TEMPLATES";

export {
  SET_PROJECTS,
  SET_PROJECT,
  SET_PROJECT_TEMPLATES,
  SET_PROJECT_TEMPLATE,
  SET_PROJECT_CATEGORIES,
  SET_PROJECT_CATEGORY,
  SET_PROJECT_CATEGORY_TEMPLATES,
  SET_WORKFLOW_ROLES,
  SET_WORKFLOW_CALENDARS
};

// design
import {
  iconFolderOutline,
  iconCategories,
  iconTasks,
  iconEvents,
  iconProjects,
  iconSearch
} from "@/design/icon/iconConst";

// model
import { featureName } from "@/model/features/featuresModel";

/**
 * Questys Application Module moduleNames
 * @type {Readonly<{Search: string, Category: string, Tasks: string, Projects: string, Agenda: string, Folder: string}>}
 */
const moduleNames = Object.freeze({
  Search: "Search",
  Folder: "Folder",
  Category: "Category",
  MyWork: "MyWork",
  Tasks: "Tasks",
  Projects: "Projects",
  Agenda: "Agenda"
});

/**
 * Questys Application Module moduleTitles
 * @type {Readonly<{Search: string, Category: string, Tasks: string, Projects: string, Agenda: string, Folder: string}>}
 */
const moduleTitles = Object.freeze({
  Search: "Search",
  Folder: "Folder",
  Category: "Category",
  MyWork: "My Work",
  Tasks: "My Tasks",
  Projects: "Projects",
  Agenda: "Meetings"
});

/**
 * Questys Application Module Icons
 * @type {Readonly<{Category: string, Search: string, Tasks: string, Projects: string, Agenda: string, Folder: string}>}
 */
const moduleIcons = Object.freeze({
  Search: iconSearch,
  Folder: iconFolderOutline,
  Category: iconCategories,
  MyWork: iconTasks,
  Tasks: iconTasks,
  Projects: iconProjects,
  Agenda: iconEvents
});

/**
 * Application Modules
 * @type {{name: string, icon: string, title: string, feature: string, public: boolean}[]}
 */
const appModules = Object.freeze([
  {
    name: moduleNames.Search,
    title: moduleTitles.Search,
    icon: moduleIcons.Search,
    feature: featureName.Execute,
    public: true
  },
  {
    name: moduleNames.Folder,
    title: moduleTitles.Folder,
    icon: moduleIcons.Folder,
    feature: featureName.Execute,
    public: true
  },
  {
    name: moduleNames.Category,
    title: moduleTitles.Category,
    icon: moduleIcons.Category,
    feature: featureName.Execute,
    public: true
  },
  {
    name: moduleNames.Tasks,
    title: moduleTitles.Tasks,
    icon: moduleIcons.Tasks,
    feature: featureName.WFx,
    public: false
  },
  {
    name: moduleNames.Projects,
    title: moduleTitles.Projects,
    icon: moduleIcons.Projects,
    feature: featureName.WFx,
    public: false
  },
  {
    name: moduleNames.Agenda,
    title: moduleTitles.Agenda,
    icon: moduleIcons.Agenda,
    feature: featureName.LSx,
    public: true
  }
]);

/**
 * find Application Module
 * @param name Application Module name
 * @return {{name: string, icon: string, title: string, feature: string, public:boolean}} returns Application Module
 */
const findModule = name => appModules.find(m => m.name === name);

export { moduleNames, moduleTitles, moduleIcons, appModules, findModule };

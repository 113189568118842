/**
 * agenda Document Type
 * @type {Readonly<{webHtmlAgenda: number, webEnabledAgenda: number, webEnabledAgendaMediaManager: number, agendaPacket: number, webHtmlAgendaMediaManager: number, agendaDocument: number, agendaOutline: number, agendaDraft: number}>}
 */
import {
  iconFilePdf,
  iconWord,
  iconHtml,
  iconEvent,
  iconAgendaItem,
  iconAgendaSection,
  iconRollCall,
  iconClerkModule,
  iconLegislatorsNotepad
} from "@/design/icon/iconConst";
import { categoryType } from "@/model/category/categoryModel";

/**
 * agenda Document Type
 * @type {Readonly<{webHtmlAgenda: number, webEnabledAgenda: number, webEnabledAgendaMediaManager: number, agendaPacket: number, webHtmlAgendaMediaManager: number, agendaDocument: number, agendaOutline: number, agendaDraft: number}>}
 */
const agendaDocumentType = Object.freeze({
  agendaDocument: 0,
  agendaDraft: 1,
  agendaPacket: 2,
  agendaOutline: 3,
  webEnabledAgenda: 4,
  webEnabledAgendaMediaManager: 5,
  webHtmlAgenda: 6,
  webHtmlAgendaMediaManager: 7
});

/**
 * meeting Minutes
 * @type {Readonly<{summary: number, webEnabledMediaManager: number, webEnabled: number, webHtmlMediaManager: number, webHtmlEnabled: number}>}
 */
const meetingMinutes = Object.freeze({
  summary: 0,
  webEnabled: 1,
  webEnabledMediaManager: 2,
  webHtmlEnabled: 3,
  webHtmlMediaManager: 4
});

/**
 * meeting Minute Types
 * @type {({icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number})[]}
 */
const meetingMinutesTypes = Object.freeze([
  {
    type: meetingMinutes.summary,
    label: "Meeting Minutes Summary",
    description: "Generate Meeting Minutes Summary",
    categoryType: categoryType.minutesSummary,
    icon: iconWord
  },
  {
    type: meetingMinutes.webEnabled,
    label: "Web Enabled Meeting Minutes",
    description:
      "Generate Web Enabled Meeting Minutes for external meeting publishing",
    categoryType: categoryType.minutesSummary,
    icon: iconHtml
  },
  {
    type: meetingMinutes.webEnabledMediaManager,
    label: "Web Enabled Meeting Minutes Media Manager",
    description:
      "Generate Web Enabled Meeting Minutes for Media Manager e.g. Granicus",
    categoryType: categoryType.minutesSummary,
    icon: iconHtml
  },
  {
    type: meetingMinutes.webHtmlEnabled,
    label: "Web HTML Meeting Minutes",
    description: "Generate Web HTML Meeting Minutes",
    categoryType: categoryType.minutesSummary,
    icon: iconHtml
  },
  {
    type: meetingMinutes.webHtmlMediaManager,
    label: "Web HTML Meeting Minutes Media Manager",
    description:
      "Generate HTML Meeting Minutes for Media Manager e.g. Granicus",
    categoryType: categoryType.minutesSummary,
    icon: iconHtml
  }
]);

/**
 * find Meeting Minutes Type
 * @param {number} type Meeting Minutes Type
 * @return {unknown}
 */
const findMeetingMinutesType = type => {
  return meetingMinutesTypes?.find(el => el?.type === type);
};

/**
 * agenda Document Types
 * @type {({icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number}|{icon: string, description: string, label: string, type: number})[]}
 */
const agendaDocumentTypes = Object.freeze([
  {
    type: agendaDocumentType.agendaDocument,
    label: "Agenda Document",
    description: "Generate Agenda Document",
    categoryType: categoryType.agendaSystemDocuments,
    icon: iconWord
  },
  {
    type: agendaDocumentType.agendaDraft,
    label: "Agenda Draft",
    description: "Generate Draft of Agenda Document",
    categoryType: categoryType.agendaSystemDocuments,
    icon: iconWord
  },
  {
    type: agendaDocumentType.agendaPacket,
    label: "Agenda Packet",
    description: "Generate Agenda (PDF) Packet",
    categoryType: categoryType.agendaPacket,
    icon: iconFilePdf
  },
  {
    type: agendaDocumentType.agendaOutline,
    label: "Agenda Outline",
    description: "Generate (PDF) Outline of Agenda",
    categoryType: categoryType.agendaSystemDocuments,
    icon: iconFilePdf
  },
  {
    type: agendaDocumentType.webEnabledAgenda,
    label: "Web Enabled Agenda",
    description: "Generate Web Enabled Agenda for external meeting publishing",
    icon: iconHtml
  },
  {
    type: agendaDocumentType.webEnabledAgendaMediaManager,
    label: "Web Enabled Agenda Media Manager",
    description: "Generate Web Enabled Agenda for Media Manager e.g. Granicus",
    categoryType: categoryType.agendaSystemDocuments,
    icon: iconHtml
  },
  {
    type: agendaDocumentType.webHtmlAgenda,
    label: "Web Html Agenda",
    description: "Generate Web Html Agenda for external meeting publishing",
    categoryType: categoryType.agendaSystemDocuments,
    icon: iconHtml
  }
]);

/**
 * find Agenda Document Type
 * @param {number} type Agenda Document Type
 * @return {{type: number, label: string, description: string, categoryType: number, icon: string}}
 */
const findAgendaDocumentType = type => {
  return agendaDocumentTypes?.find(el => el?.type === type);
};

/**
 * Agenda Packet Tabs Options
 * @type {Readonly<{itemNumbering: number, overallNumbering: number, noNumbering: number}>}
 */
const agendaPacketTabOptions = Object.freeze({
  noNumbering: 0,
  itemNumbering: 1,
  overallNumbering: 2
});

/**
 * Agenda Packet Tabs Option
 * @type {({name: string, type: number}|{name: string, type: number}|{name: string, type: number})[]}
 */
const agendaPacketTabOption = Object.freeze([
  {
    type: agendaPacketTabOptions.noNumbering,
    name: "No Numbering"
  },
  {
    type: agendaPacketTabOptions.itemNumbering,
    name: "Item Numbering"
  },
  {
    type: agendaPacketTabOptions.overallNumbering,
    name: "Overall Numbering"
  }
]);

/**
 * find Agenda Packet Tab Type
 * @param {number} type Agenda Packet Tab Type
 * @return {{type: number, name: string}}
 */
const findAgendaPacketTabType = type => {
  return agendaPacketTabOption?.find(el => el?.type === type);
};

/**
 * Create Agenda Packet Query Model
 * @param {Number} NumberingType
 * @param {Number} NumberPosition
 * @param {Array} NumberingOptions
 * @param {boolean} InsertPageSeparator
 * @param {boolean}  IncludeAgendaItemLink
 * @param {boolean} IncludeLinksBackToItem
 * @param {String} LinkStaticText
 * @param {String} LinkText
 * @param {String} LinkNote
 * @return {{InsertPageSeparator: boolean, NumberingType: Number, NumberPosition: Number, NumberingOptions: Array, LinkNote: String, LinkStaticText: String, IncludeLinksBackToItem: boolean, IncludeAgendaItemLink: boolean, LinkText: String}}
 */
const AgendaPacketQueryModel = (
  NumberingType = agendaPacketTabOptions.noNumbering,
  NumberPosition = 1,
  NumberingOptions = [],
  InsertPageSeparator = false,
  IncludeAgendaItemLink = false,
  IncludeLinksBackToItem = false,
  LinkStaticText = "",
  LinkText = "",
  LinkNote = ""
) => {
  return {
    NumberingType: NumberingType,
    NumberPosition: NumberPosition,
    NumberingOptions: NumberingOptions,
    InsertPageSeparator: InsertPageSeparator,
    IncludeAgendaItemLink: IncludeAgendaItemLink,
    IncludeLinksBackToItem: IncludeLinksBackToItem,
    LinkStaticText: LinkStaticText,
    LinkText: LinkText,
    LinkNote: LinkNote
  };
};

/**
 * create Agenda Packet Model
 * @return {{downOptions: [{disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}], item: boolean, includeLinkBackUp: boolean, pagesSuffixValue: string, itemTitleLengthValue: string, itemTitle: boolean, pagePrefixValue: string, linkNote: string, includeLinkAgenda: boolean, upOptions: [{disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}], itemTitlePrefixValue: string, currentTab: number, pagesPrefixValue: string, includePageSeparator: boolean, numberPosition: number, linkStaticText: string, itemSuffixValue: string, pages: boolean, pageSuffixValue: string, itemPrefixValue: string, page: boolean, agendaItemTitle: string}}
 */
const createAgendaPacketOption = () => {
  return {
    numberPosition: 0,
    currentTab: agendaPacketTabOptions.itemNumbering,
    upOptions: [
      {
        value: 0,
        text: "Upper",
        disabled: false
      },
      {
        value: 1,
        text: "Upper Middle",
        disabled: false
      },
      {
        value: 2,
        text: "Upper Right",
        disabled: false
      }
    ],
    downOptions: [
      {
        value: 3,
        text: "Lower",
        disabled: false
      },
      {
        value: 4,
        text: "Lower Middle",
        disabled: false
      },
      {
        value: 5,
        text: "Lower Right",
        disabled: false
      }
    ],
    item: true,
    itemTitle: true,
    page: true,
    pages: true,
    itemPrefixValue: "Item No.",
    itemSuffixValue: "",
    itemTitlePrefixValue: "",
    itemTitleLengthValue: "",
    pagePrefixValue: " - ",
    pageSuffixValue: "",
    pagesPrefixValue: " of ",
    pagesSuffixValue: "",
    includeLinkBackUp: true,
    includePageSeparator: true,
    includeLinkAgenda: true,
    linkStaticText:
      "The following page(s) contains the backup material for Agenda item",
    agendaItemTitle: "{ Agenda Item Title }",
    linkNote: "Please scroll down to view the backup material"
  };
};

/**
 * Agenda Packet Content
 * Expand on Demand
 * @type {Readonly<{pageSuffix: string, itemSuffix: string, pagePrefix: string, pageCountSuffix: string, itemTitlePrefix: string, itemPrefix: string, itemTitleLength: string, pageCountPrefix: string}>}
 */
const agendaPacketContent = Object.freeze({
  itemPrefix: "Item.Number.Prefix",
  itemSuffix: "Item.Number.Sufix",
  itemTitlePrefix: "Item.Title.Prefix",
  itemTitleLength: "Item.Title.Length",
  pagePrefix: "Item.Page.Prefix",
  pageSuffix: "Item.Page.Sufix",
  pageCountPrefix: "Item.PagesCount.Prefix",
  pageCountSuffix: "Item.PagesCount.Sufix"
});

/**
 * Agenda Meeting Tabs Option
 * @type {Readonly<{item: number, section: number, legislatorsNotepad: number, clerkModule: number, meeting: number, rollCall: number}>}
 */
const agendaMeetingTabOptions = Object.freeze({
  meeting: 0,
  section: 1,
  item: 2,
  clerkModule: 3,
  legislatorsNotepad: 4,
  rollCall: 5
});

/**
 * Agenda Meeting Tabs Option
 * @type {({name: string, icon: string, type: number}|{name: string, icon: string, type: number})[]}
 */
const agendaMeetingTabOption = Object.freeze([
  {
    type: agendaMeetingTabOptions.meeting,
    name: "Meeting",
    icon: iconEvent
  },
  {
    type: agendaMeetingTabOptions.rollCall,
    name: "Roll Call",
    icon: iconRollCall
  }
]);

/**
 * Agenda Section Tabs Option
 * @type {({name: string, icon: string, type: number}|{name: string, icon: string, type: number}|{name: string, icon: string, type: number})[]}
 */
const agendaSectionTabOption = Object.freeze([
  {
    type: agendaMeetingTabOptions.section,
    name: "Section",
    icon: iconAgendaSection
  },
  {
    type: agendaMeetingTabOptions.clerkModule,
    name: "Clerk Module",
    icon: iconClerkModule
  },
  {
    type: agendaMeetingTabOptions.legislatorsNotepad,
    name: "Legislators Notepad",
    icon: iconLegislatorsNotepad
  }
]);

/**
 * Agenda Item Tabs Option
 * @type {({name: string, icon: string, type: number}|{name: string, icon: string, type: number}|{name: string, icon: string, type: number})[]}
 */
const agendaItemTabOption = Object.freeze([
  {
    type: agendaMeetingTabOptions.item,
    name: "Item",
    icon: iconAgendaItem
  },
  {
    type: agendaMeetingTabOptions.clerkModule,
    name: "Clerk Module",
    icon: iconClerkModule
  },
  {
    type: agendaMeetingTabOptions.legislatorsNotepad,
    name: "Legislators Notepad",
    icon: iconLegislatorsNotepad
  }
]);

export {
  agendaDocumentType,
  meetingMinutes,
  agendaPacketTabOptions,
  agendaDocumentTypes,
  meetingMinutesTypes,
  findAgendaDocumentType,
  findMeetingMinutesType,
  AgendaPacketQueryModel,
  createAgendaPacketOption,
  agendaPacketTabOption,
  findAgendaPacketTabType,
  agendaPacketContent,
  agendaMeetingTabOption,
  agendaMeetingTabOptions,
  agendaSectionTabOption,
  agendaItemTabOption
};

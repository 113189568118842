// services
import { moduleNames } from "@/services/solution/solutionService";

// router
import {
  routeParams,
  createAppModuleRouteName,
  createAppModuleRoutePath,
  createModuleContentRouteName,
  createModuleRoutePath,
  createModuleParamsId,
  expandedRoutePath,
  createModuleExpandRecordContentRouteName,
  createModuleChildRecordParamsId
} from "@/router/solution/solutionRoutes";

const moduleName = moduleNames.Agenda;

const agendaRoutes = Object.freeze([
  {
    path: createAppModuleRoutePath(moduleName),
    name: createAppModuleRouteName(moduleName),
    component: () => import("@/views/agenda/AgendaHome"),
    children: [
      {
        path: createModuleRoutePath(routeParams.id),
        name: createModuleContentRouteName(moduleName),
        meta: { requiresAuth: true },
        props: createModuleParamsId,
        component: () => import("@/views/agenda/AgendaContent")
        // children: [
        //   {
        //     path: createModuleDetailsRoutePath(routeParams.id),
        //     name: createAppModuleDetailsRouteName(appModuleName),
        //     meta: { requiresAuth: true },
        //     props: true,
        //     component: () => import("@/views/agenda/AgendaDetails")
        //   }
        // ]
      },
      {
        path: expandedRoutePath,
        name: createModuleExpandRecordContentRouteName(moduleName),
        meta: { requiresAuth: true },
        props: createModuleChildRecordParamsId,
        component: () => import("@/views/agenda/AgendaContent")
      }
    ]
  }
]);

export { agendaRoutes };

/**
 * This utility encapsulate local Storage functionality
 */
import store from "@/store/index";

/**
 * get Storage Item by Storage Item's key
 * @param {String|string} key
 * @returns {string|null}
 */
const getLocalStorageItem = key => localStorage.getItem(key);

/**
 * Set Storage Item by Storage Item's key
 * @param {String|string} key
 * @param {String|string} value
 */
const setLocalStorageItem = (key, value) => localStorage.setItem(key, value);

/**
 * Remove Local Storage Item by its key
 * @param {String|string} key
 */
const removeLocalStorageItem = key => localStorage.removeItem(key);

/**
 * Get repository dependant Local Storage Key
 * @param {String|string} key
 * @return {String|string}
 */
const repoLocalStorageKey = key => {
  const repository = store?.getters["user/repository"] ?? "";
  return `${repository}_${key}`;
};

/**
 * repo User Local Storage Key
 * @param {String|string} key
 * @return {String|string} repo User Local Storage Key
 */
const repoUserLocalStorageKey = key => {
  const repository = store?.getters["user/repository"] ?? "";
  const actorId = store?.getters["user/actorId"] ?? -1;

  return `${repository}_${actorId}_${key}`;
};

export {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  repoLocalStorageKey,
  repoUserLocalStorageKey
};

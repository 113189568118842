// design
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import {
  Color,
  colorMD,
  colorTheme,
  variantNumber,
  variantType,
  getErrorColor,
  getErrorColorClass,
  getWarningColor,
  getWarningColorClass,
  getColorClass,
  getSuccessColorClass
} from "@/design/colors/Color";
import {
  iconAccountArrowRight,
  iconAccountCheck,
  iconCheck,
  iconClear,
  iconUndo
} from "@/design/icon/iconConst";

/**
 * user Task Name
 * @type {string} user Task Name
 */
const userTaskLabel = "User Task";

/**
 * workflow project task instance State
 * @type {Readonly<{canceled: number, suspendedWorkflow: number, faulted: number, queued: number, notStarted: number, invalidWorkflow: number, completed: number, abandoned: number, suspended: number}>}
 */
const taskState = Object.freeze({
  notStarted: 0, // The workflow task instance has not started
  queued: 1, // The workflow task instance is in an executing state
  suspended: 2, // The workflow task instance was put on hold
  completed: 10, // The workflow task instance completed successfully
  canceled: 11, // The workflow task instance was canceled to some reasons and wasn't completed
  faulted: 12, // The workflow task instance was aborted during execution

  invalidWorkflow: -1, // The workflow instance has not been initialized
  suspendedWorkflow: -2, // The workflow instance has been suspended
  abandoned: -3 // The workflow state is transitioned to complete/cancel, but the task has not been completed
});

/**
 * workflow project task instance states
 * @type {Readonly<{id: number, name: string}>[]}
 */
const taskStates = Object.freeze([
  {
    id: taskState.notStarted,
    name: "Not Started",
    color: {
      name: colorTheme.secondary,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n4
    }
  },
  {
    id: taskState.queued,
    name: "Queued",
    color: {
      name: colorMD.indigo,
      variantType: variantType.darken,
      variantNumber: variantNumber.n2
    }
  },
  {
    id: taskState.suspended,
    name: "Suspended",
    color: {
      name: colorTheme.warning,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: taskState.completed,
    name: "Completed",
    color: {
      name: colorTheme.success,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: taskState.canceled,
    name: "Canceled",
    color: {
      name: colorTheme.secondary,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n2
    }
  },
  {
    id: taskState.faulted,
    name: "Faulted",
    color: {
      name: colorTheme.error,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: taskState.invalidWorkflow,
    name: "Invalid Workflow",
    color: {
      name: colorTheme.warning,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: taskState.suspendedWorkflow,
    name: "Suspended Workflow",
    color: {
      name: colorTheme.warning,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: taskState.abandoned,
    name: "Abandoned",
    color: {
      name: colorTheme.warning,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  }
]);

const taskCreatedColorClass = () => getSuccessColorClass();

/**
 * due Date Text Class
 * @param {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}} task
 * @param {string} displayType display Type
 * @param {string} emphasis font Emphasis
 * @return {string|undefined}
 */
const dueDateTextClass = (
  task,
  displayType = undefined,
  emphasis = undefined
) => {
  if (!task?.dueDate) {
    return undefined;
  }

  const color = task.isDue ?? false ? getErrorColor() : getWarningColor();

  return new Text(
    color,
    displayType ?? displayTypes.subtitle1,
    emphasis ?? fontEmphasis.italic
  ).getClassText();
};

/**
 * due Date Color Class
 * @param {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}} task
 * @return {String|string|undefined}
 */
const dueDateColorClass = task => {
  return task?.isDue ?? false
    ? getErrorColorClass()
    : task?.dueDate
    ? getWarningColorClass()
    : undefined;
};

/**
 * overdue Text Class
 * @param task
 * @param {string} displayType
 * @param {string} emphasis
 * @return {string|undefined}
 */
const overdueTextClass = (
  task,
  displayType = undefined,
  emphasis = undefined
) => {
  if (!(task?.isOverdue ?? false)) {
    return undefined;
  }
  return new Text(
    getErrorColor(),
    displayType ?? displayTypes.subtitle1,
    emphasis ?? fontEmphasis.italic
  )?.getClassText();
};
/**
 * Task Validation color class
 * @param {Boolean} isValid
 * @return {String|string|undefined}
 */
const taskValidationColorClass = isValid => {
  return isValid ? getSuccessColorClass() : getErrorColorClass();
};

/**
 * due Date Message
 * @param {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}} task
 * @return {string|undefined}
 */
const dueDateMessage = task => {
  return task?.isDue ?? false
    ? "It's Due Date!"
    : task?.dueDate
    ? "Watch Due Date!"
    : undefined;
};

/**
 * task Priority
 * @type {Readonly<{normal: number, high: number, low: number}>}
 */
const taskPriority = Object.freeze({
  low: -1,
  normal: 0,
  high: 1
});

/**
 * task Priorities
 * @type {({color: {variantType: string, variantNumber: number, name: string}, text: string, value: number})[]}
 */
const taskPriorities = Object.freeze([
  {
    value: taskPriority.low,
    text: "Low",
    color: {
      name: colorTheme.secondary,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n4
    }
  },
  {
    value: taskPriority.normal,
    text: "Normal",
    color: {
      name: colorTheme.secondary,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n2
    }
  },
  {
    value: taskPriority.high,
    text: "High",
    color: {
      name: colorTheme.error,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  }
]);

/**
 * find Task Priority
 * @param value
 * @return {{color: {variantType: string, variantNumber: number, name: string}, text: string, value: number}}
 */
const findTaskPriority = value => {
  return taskPriorities.find(el => el?.value === value);
};

/**
 * find Task Priority Color
 * @param value Priority value
 * @return {*}
 */
const findTaskPriorityColor = value => {
  return taskPriorities?.find(el => el?.value === value)?.color;
};

/**
 * priority Text Class
 * @param {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}} task
 * @param {string} displayType
 * @param {string} emphasis
 * @return {string|undefined}
 */
const priorityTextClass = (
  task,
  displayType = undefined,
  emphasis = undefined
) => {
  if (!task) {
    return undefined;
  }

  /**
   * color
   * @type {Color|undefined}
   */
  const color = findPriorityColor(task.priority);

  return color
    ? new Text(
        color,
        displayType ?? displayTypes.subtitle1,
        emphasis ?? fontEmphasis.regular
      ).getClassText()
    : undefined;
};

/**
 * priority Color Class
 * @param task
 * @return {string|undefined}
 */
const priorityColorClass = task => {
  return findPriorityColor(task?.priority)?.getClassColor();
};

/**
 * find Priority Color
 * @param value Priority value
 * @return {Color|undefined}
 */
const findPriorityColor = value => {
  const color = findTaskPriorityColor(value);

  return color
    ? new Color(color.name, color.variantType, color.variantNumber)
    : undefined;
};

/**
 * find Task State
 * @param {Number|number} id Task State id
 * @return {{id: number, name: string, color: {name: string, variantType: string, variantNumber: number}}|undefined} Task State
 */
const findTaskState = id => {
  return taskStates?.find(el => el?.id === id);
};

/**
 * find Task State Color Class
 * @param {Number|number} id Task State id
 * @return {string|undefined}
 */
const findTaskStateColorClass = id => {
  const color = findTaskStateColor(id);

  return getColorClass(color);
};

/**
 * find Task State Color
 * @param {Number|number} id Task State id
 * @return {{name: string, variantType: string, variantNumber: number}|undefined}
 */
const findTaskStateColor = id => {
  return taskStates?.find(el => el?.id === id)?.color;
};

/**
 * task's Note Color Class
 * @return {String|string}
 */
const noteColorClass = () => {
  return new Color(
    colorMD.yellow,
    variantType.darken,
    variantNumber.n1
  ).getClassColor();
};

/**
 * user Task Type
 * @type {Readonly<{toDo: number, approval: number, review: number, unspecified: number}>}
 */
const userTaskType = Object.freeze({
  unspecified: -0,
  toDo: 1,
  approval: 2,
  review: 3
});

/**
 * user Task Command Type
 * @type {Readonly<{take: number, release: number, reassign: number}>}
 */
const userTaskCommandType = Object.freeze({
  reassign: 0,
  release: 1,
  take: 2
});

/**
 * user Task Commands
 * @type {Readonly<[{icon: string, description: string, label: string, type: number}]>}
 */
const userTaskCommands = Object.freeze([
  {
    type: userTaskCommandType.reassign,
    label: "Reassign",
    description: "Reassign task to different user",
    icon: iconAccountArrowRight
  },
  {
    type: userTaskCommandType.release,
    label: "Release",
    description: "Release 'Self' taken task",
    icon: iconUndo
  },
  {
    type: userTaskCommandType.take,
    label: "Take",
    description: "Assign 'Self' assigned task to current user",
    icon: iconAccountCheck
  }
]);

/**
 * find user Task Command
 * @param {number} type user Task Command type
 * @return {{icon: string, description: string, label: string, type: number}}
 */
const findUserTaskCommand = type => {
  return userTaskCommands?.find(el => el?.type === type);
};

/**
 * userTaskTypes
 * @type {({id: number, name: string, commandAccept: {label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}, commandReject: {label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}})[]}
 */
const userTaskTypes = Object.freeze([
  {
    id: userTaskType.unspecified,
    name: "Unspecified",
    commandAccept: {
      label: "Unspecified",
      icon: undefined,
      color: {
        name: colorTheme.secondary,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n4
      }
    },
    commandReject: {
      label: "Cancel",
      icon: iconClear,
      color: {
        name: colorTheme.error,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n1
      }
    }
  },
  {
    id: userTaskType.toDo,
    name: "ToDo",
    commandAccept: {
      label: "Done",
      icon: iconCheck,
      color: {
        name: colorTheme.success,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n1
      }
    },
    commandReject: {
      label: "Cancel",
      icon: iconClear,
      color: {
        name: colorTheme.error,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n1
      }
    }
  },
  {
    id: userTaskType.approval,
    name: "Approval",
    commandAccept: {
      label: "Approve",
      icon: iconCheck,
      color: {
        name: colorTheme.success,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n1
      }
    },
    commandReject: {
      label: "Reject",
      icon: iconClear,
      color: {
        name: colorTheme.error,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n1
      }
    }
  },
  {
    id: userTaskType.review,
    name: "Review",
    commandAccept: {
      label: "Review",
      icon: iconCheck,
      color: {
        name: colorTheme.success,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n1
      }
    },
    commandReject: {
      label: "Cancel",
      icon: iconClear,
      color: {
        name: colorTheme.error,
        variantType: variantType.lighten,
        variantNumber: variantNumber.n1
      }
    }
  }
]);

/**
 * find User Task Type
 * @param {Number|number} typeId user Task Type id
 * @return {{id: number, name: string, commandAccept: {label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}, commandReject: {label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}}}
 */
const findUserTaskType = typeId => {
  return userTaskTypes?.find(el => el?.id === typeId);
};

/**
 * find Command Accept Color Class
 * @param {Number|number} typeId User Task Type id
 * @return {String|string|undefined}
 */
const findCommandTaskAcceptColorClass = typeId => {
  const color = findUserTaskType(typeId)?.commandAccept?.color;

  return getColorClass(color);
};

/**
 * find Command Reject Color Class
 * @param {Number|number} typeId User Task Type id
 * @return {String|string|undefined}
 */
const findCommandTaskRejectColorClass = typeId => {
  const color = findUserTaskType(typeId)?.commandReject?.color;

  return getColorClass(color);
};

/**
 * Task Local Storage Keys
 * @type {{taskFilterOption: string}}
 */
const taskLocalStorageKeys = {
  taskFilterOption: "taskFilterOption"
};

/**
 * Task Headers for report
 * @type {({visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string})[]}
 */
const taskHeaders = Object.freeze([
  { text: "Id", value: "taskId", visible: true },
  { text: "Name", value: "name", visible: true },
  { text: "Due", value: "dueDate", visible: true },
  { text: "Project", value: "projectName", visible: true },
  { text: "Assignee", value: "assignedTo", visible: true },
  { text: "Priority", value: "priority", visible: true },
  { text: "Status", value: "status", visible: true },
  {
    align: "end",
    sortable: false,
    text: "Actions",
    value: "actions",
    visible: true
  }
]);

/**
 * user Task assignment Method
 * @type {Readonly<{auto: number, self: number, manual: number}>}
 */
const assignmentMethod = Object.freeze({
  auto: 0,
  manual: 1,
  self: 2
});

/**
 * user task assignment methods
 * @type {({method: number, name: string})[]}
 */
const assignmentMethods = Object.freeze([
  {
    method: assignmentMethod.auto,
    name: "Auto"
  },
  {
    method: assignmentMethod.manual,
    name: "Manual"
  },
  {
    method: assignmentMethod.self,
    name: "Self"
  }
]);

/**
 * find Assignment Method
 * @param method
 * @return {{method: number, name: string}}
 */
const findAssignmentMethod = method =>
  assignmentMethods.find(am => am.method === method);

/**
 * Is Valid Task entries
 * @param {{id: number, name: string, sequence: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}, duration: {calendar: string, duration: string, fixed: boolean, milestone: boolean}}} task
 * @return {boolean} true if Task Entries are valid
 */
const isValidTaskEntries = task => {
  return task
    ? !(!task.name || !task.assignment?.assignee || task.priority === undefined)
    : false;
};

export {
  taskState,
  taskStates,
  taskPriority,
  taskPriorities,
  userTaskType,
  userTaskTypes,
  noteColorClass,
  dueDateTextClass,
  dueDateColorClass,
  dueDateMessage,
  findTaskState,
  findTaskStateColor,
  findTaskStateColorClass,
  priorityTextClass,
  findTaskPriority,
  findTaskPriorityColor,
  findUserTaskType,
  findCommandTaskAcceptColorClass,
  findCommandTaskRejectColorClass,
  taskLocalStorageKeys,
  taskHeaders,
  userTaskLabel,
  assignmentMethod,
  assignmentMethods,
  findAssignmentMethod,
  taskValidationColorClass,
  isValidTaskEntries,
  priorityColorClass,
  overdueTextClass,
  userTaskCommandType,
  userTaskCommands,
  findUserTaskCommand,
  taskCreatedColorClass
};

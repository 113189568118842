import { toLowerCaseSafe } from "@/utils";

/**
 * Determine whether a record operation is valid (can operation be performed under current circumstances)
 * @param {{id: number, name: string, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @param {String|string} operation
 * @return {Boolean|boolean} true if record Operation can be performed under current circumstances
 */
const isValidRecordOperation = (record, operation) => {
  return findOperation(record?.operations, operation)?.valid ?? false;
};

/**
 * Find s record Operation
 * @param {{name: string, allowed: boolean, valid: boolean}[]} operations
 * @param {String|string} operation
 * @return {{name: string, allowed: boolean, valid: boolean}}
 */
const findOperation = (operations, operation) => {
  return operations?.find(
    op => toLowerCaseSafe(op.name) === toLowerCaseSafe(operation)
  );
};

export { isValidRecordOperation, findOperation };

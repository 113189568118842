import { getAxios } from "@/services/api/apiCommon";

/**
 * auth web api controller name
 * @type {string}
 */
const auth = "auth";

/**
 * Login to Questys repository
 * @param {{userName:String, password:String, repository:String}} credentials
 * @returns {Promise<{token:string, validFrom:string, validTo:string, principal:{actorId:number, actorName:string, repositoryUid:string, repository:string, isAdministrator:boolean, features:{name:string, used:number, total:number}[], systemOperations:{name:string, allowed:boolean, valid:boolean}[]}, features:{name:string, used:number, total:number}[]}>}
 */
const login = credentials => {
  const url = `/${auth}/login`;

  const data = new FormData();
  data.append("userName", credentials?.userName);
  data.append("password", credentials?.password);
  data.append("repository", credentials?.repository);

  return getAxios().post(url, data);
};

/**
 * login With Token
 * @param {string} token
 * @return {Promise<{token:string, validFrom:string, validTo:string, principal:{actorId:number, actorName:string, repositoryUid:string, repository:string, isAdministrator:boolean, features:{name:string, used:number, total:number}[], systemOperations:{name:string, allowed:boolean, valid:boolean}[]}, features:{name:string, used:number, total:number}[]}>}
 */
const loginWithToken = token => {
  const url = `/${auth}/loginWithToken`;

  const data = new FormData();
  data.append("token", token);

  return getAxios().post(url, data);
};

/**
 * Logout current user
 * @return {Promise<{Boolean}>}
 */
const logout = () => {
  return getAxios().post(`/${auth}/logout`);
};

/**
 * Update Authorization (Bearer authentication/token authentication)
 * @param {string} token
 */
const updateAuthorization = token => {
  try {
    //
    // Set the Authorization Header using:
    // _axiosInstance.interceptors.request instead of:
    //
    getAxios().defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (e) {
    console.error(e);
  }
};

export { login, loginWithToken, logout, updateAuthorization };

// model
import {
  assignmentMethod,
  dueDateColorClass,
  findTaskPriority,
  findTaskState,
  findTaskStateColorClass,
  taskState
} from "@/model/workflow/task/taskModel";

// filters
import {
  convertToYYYYMMDDHHMM,
  toLocalDateString,
  toLocalDateTimeString
} from "@/filters/dateFilter";

// utils
import { isValidDate } from "@/utils";

/**
 * format Status
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const formatStatus = task => {
  const status = task?.status ?? -1;
  const statusName = findTaskState(status)?.name ?? "";

  switch (status) {
    case taskState.queued:
    case taskState.completed: {
      return `${statusName} ${formatStatusDate(task)}`;
    }
    default: {
      return statusName;
    }
  }
};

/**
 * task Status Tooltip
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignedTo: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const taskStatusTooltip = task => {
  const status = task?.status ?? -1;
  const statusName = findTaskState(status)?.name ?? "";

  switch (status) {
    case taskState.queued:
      return `${statusName} on ${formatStatusDateAndTime(
        task
      )} and assigned to: ${task?.assignedTo}`;
    case taskState.completed: {
      return `${statusName} on ${formatStatusDateAndTime(task)} by ${
        task?.assignedTo
      }`;
    }
    case taskState.faulted:
    case taskState.abandoned:
    case taskState.canceled:
    case taskState.suspended: {
      return `${statusName} on ${formatStatusDateAndTime(task)}`;
    }
    default: {
      return `${statusName} ${task.name}`;
    }
  }
};

/**
 * format Status Date
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const formatStatusDate = task =>
  isValidDate(task?.statusChanged) ? toLocalDateString(task.statusChanged) : "";

/**
 * format Status Date and Time
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const formatStatusDateAndTime = task =>
  isValidDate(task?.statusChanged)
    ? toLocalDateTimeString(task.statusChanged)
    : "";

/**
 * format Due Date
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const formatDueDate = task =>
  isValidDate(task?.dueDate) ? toLocalDateString(task.dueDate) : "";

/**
 * format Due Date and time
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const taskDueDateLocalDateTime = task =>
  isValidDate(task?.dueDate) ? toLocalDateTimeString(task.dueDate) : "";

/**
 * format Overdue Text
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const formatOverdueText = task => (task?.isOverdue ?? false ? "Overdue" : "");

/**
 * format task Priority
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {string}
 */
const formatPriority = task =>
  `${findTaskPriority(task?.priority)?.text} Priority`;

/**
 * can Accept Task
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @param {number} actorId
 * @return {boolean} true if current user can Accept Task
 */
const canAcceptTask = (task, actorId) => {
  return isValidTask(task) && task?.actorId === actorId;
};

/**
 * can Reject Task
 * @param task
 * @param {number} actorId
 * @return {boolean} true if current user can Reject Task
 */
const canRejectTask = (task, actorId) => {
  return isValidTask(task) && task?.actorId === actorId;
};

/**
 * can Take Task
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {boolean}
 */
const canTakeTask = task => {
  return isValidTask(task) && isSelfAssignment(task);
};

/**
 * can Jump to  Task
 * @param task
 * @param {number} actorId
 * @return {boolean}
 */
const canJumpToTask = (task, actorId) => {
  return isValidTask(task) && task?.actorId === actorId;
};

/**
 * can Reassign Task
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {*|boolean}
 */
const canReassignTask = task => isValidTask(task) && !isSelfAssignment(task); //&& (task?.canReassign ?? false);

/**
 * can Release Task
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @param actorId
 * @return {false|*|boolean}
 */
const canReleaseTask = (task, actorId) => {
  return (
    isValidTask(task) &&
    (task?.assignmentMethodType ?? assignmentMethod.manual) ===
      assignmentMethod.self &&
    (task?.actorId ?? 2) === actorId &&
    (task?.canSelfRelease ?? false)
  );
};

/**
 * is a Valid Task (notStarted, queued)
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {boolean} true if a provided task is valid
 */
const isValidTask = task =>
  task
    ? task.status === taskState.notStarted || task.status === taskState.queued
    : false;

/**
 * is Task Assigned
 * @param task
 * @return {boolean} true if Task has been Assigned
 */
const isTaskAssigned = task => (task?.actorId ?? -1) !== -1;

/**
 * is Self Assignment
 * @param task
 * @return {boolean} true if Task has been Self Assignment
 */
const isSelfAssignment = task =>
  !!task &&
  task.assignmentMethodType === assignmentMethod.self &&
  !isTaskAssigned(task) &&
  !task.selfReleaseDisabled;

/**
 * GetTask Calendar Events
 * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusChanged: string, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, created: string, effective: string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}} task
 * @return {*[]}
 */
const getTaskCalendarEvents = task => {
  if (!task) return [];

  const events = [];

  events.push(
    // {
    //   name: "Created",
    //   start: convertToYYYYMMDDHHMM(task.created),
    //   color: colorMD.blue, // Customize color
    //   tooltip: `Task created at ${convertToYYYYMMDDHHMM(task.created)} `
    // },

    // {
    //   name: "Status",
    //   start: convertToYYYYMMDDHHMM(task.statusChanged),
    //   color: colorMD.green, // Customize color
    //   tooltip: `Task status changed at ${convertToYYYYMMDDHHMM(
    //     task.statusChanged
    //   )} `
    // },

    {
      id: task.taskId,
      name: task.name,
      start: convertToYYYYMMDDHHMM(task.statusChanged),
      end: task.dueDate ? convertToYYYYMMDDHHMM(task.dueDate) : undefined,
      // end: convertToYYYYMMDDHHMM(
      //   new Date(getDueDate(task.statusChanged, task.duration))
      // ),
      color: findTaskStateColorClass(task.status),
      tooltip: taskStatusTooltip(task)
    }

    // {
    //   name: "Duration",
    //   start: convertToYYYYMMDDHHMM(task.created),
    //   end: convertToYYYYMMDDHHMM(
    //     new Date(getDueDate(task.created, task.duration))
    //   ),
    //   color: colorMD.grey,
    //   tooltip: `Task total duration - ${task.duration} `
    // }
  );

  if (task.dueDate) {
    events.push({
      name: `Due`,
      start: convertToYYYYMMDDHHMM(new Date(task.dueDate)),
      color: dueDateColorClass(task),
      tooltip: `Due ${taskDueDateLocalDateTime(task)}`
    });
  }

  return events;
};

/**
 * Get Task Due Date
 * @param date
 * @param duration
 * @return {number}
 */
const getDueDate = (date, duration) => {
  const [daysPart, timePart] = duration.split(".");

  // Parse the time part into individual parts (hours, minutes, and seconds)
  const timeParts = timePart.split(":"); // Split into hours, minutes, and seconds

  // Convert parts into milliseconds
  const daysInMilliseconds = parseInt(daysPart, 10) * 24 * 60 * 60 * 1000;
  const hoursInMilliseconds = parseInt(timeParts[0], 10) * 60 * 60 * 1000;
  const minutesInMilliseconds = parseInt(timeParts[1], 10) * 60 * 1000;
  const secondsInMilliseconds = parseInt(timeParts[2], 10) * 1000;

  // Calculate the total duration in milliseconds
  const totalDurationInMilliseconds =
    daysInMilliseconds +
    hoursInMilliseconds +
    minutesInMilliseconds +
    secondsInMilliseconds;

  // Add the duration to the date and return the result
  return new Date(date).getTime() + totalDurationInMilliseconds;
};

export {
  formatStatus,
  formatStatusDate,
  formatDueDate,
  formatOverdueText,
  formatPriority,
  taskStatusTooltip,
  formatStatusDateAndTime,
  taskDueDateLocalDateTime,
  canAcceptTask,
  canRejectTask,
  canTakeTask,
  canReleaseTask,
  canReassignTask,
  isValidTask,
  isTaskAssigned,
  isSelfAssignment,
  getTaskCalendarEvents,
  getDueDate,
  canJumpToTask
};

const INITIALIZE_STATES = "INITIALIZE_STATES";
const SET_APPLICATION = "SET_APPLICATION";
const SET_DRAWER = "SET_DRAWER";
const SET_DRAWER_RIGHT = "SET_DRAWER_RIGHT";
const SET_DRAWER_ENABLE_MINI_DRAWER = "SET_DRAWER_ENABLE_MINI_DRAWER";
const SET_USES_APP_TABS = "SET_USES_APP_TABS";
const SET_USES_SIDEBAR_IMAGE = "SET_USES_SIDEBAR_IMAGE";
const SET_DIALOG_TOOLBAR_STYLE = "SET_DIALOG_TOOLBAR_STYLE";
const SET_COMMAND_COLOR = "SET_COMMAND_COLOR";
const SET_COMMAND_BUTTONS_TEXT_STYLE = "SET_COMMAND_BUTTONS_TEXT_STYLE";
const SET_COMMAND_BUTTONS_OUTLINED = "SET_COMMAND_BUTTONS_OUTLINED";
const SET_COMMAND_BUTTONS_ROUNDED = "SET_COMMAND_BUTTONS_ROUNDED";
const SET_COMMAND_BUTTONS_ELEVATION = "SET_COMMAND_BUTTONS_ELEVATION";
const SET_TOOLBAR_DETAILS_COLOR = "SET_TOOLBAR_DETAILS_COLOR";
const SET_APP_BAR_COLLAPSE_ON_SCROLL = "SET_APP_BAR_COLLAPSE_ON_SCROLL";
const SET_RECORDS_FETCH_COUNT = "SET_RECORDS_FETCH_COUNT";

export {
  INITIALIZE_STATES,
  SET_APPLICATION,
  SET_DRAWER,
  SET_DRAWER_RIGHT,
  SET_DRAWER_ENABLE_MINI_DRAWER,
  SET_USES_APP_TABS,
  SET_USES_SIDEBAR_IMAGE,
  SET_DIALOG_TOOLBAR_STYLE,
  SET_COMMAND_COLOR,
  SET_COMMAND_BUTTONS_TEXT_STYLE,
  SET_COMMAND_BUTTONS_OUTLINED,
  SET_COMMAND_BUTTONS_ROUNDED,
  SET_COMMAND_BUTTONS_ELEVATION,
  SET_TOOLBAR_DETAILS_COLOR,
  SET_APP_BAR_COLLAPSE_ON_SCROLL,
  SET_RECORDS_FETCH_COUNT
};

/**
 * set Project Deleted
 * @param state
 * @param {number} projectId workflow project id
 */
import { projectState } from "@/model/workflow/project/projectModel";

const setProjectDeleted = (state, projectId) => {
  console.log(`setProjectDeleted() projectId:`, projectId);

  // update projects
  let project = state.projects?.find(p => p.projectId === projectId);
  if (project) {
    // remove a deleted project from the state.projects
    const index = state.projects.indexOf(project);
    if (index >= 0) {
      state.projects.splice(index, 1);
    }

    //
    // do more updates here ...
    //
  }

  // update open projects e.g. ...
  project = state.openProjects?.find(p => p.projectId === projectId);
  if (project) {
    // remove a deleted project from the state.projects
    const index = state.openProjects.indexOf(project);
    if (index >= 0) {
      state.openProjects.splice(index, 1);
    }

    //
    // do more updates here ...
    //
  }
};

/**
 * set Project Canceled
 * @param state
 * @param {number} projectId workflow project id
 */
const setProjectCanceled = (state, projectId) => {
  console.log(`setProjectCanceled() projectId:`, projectId);

  if (state.project) {
    state.project.status = projectState.canceled;
  }

  // update projects
  let project = state.projects?.find(p => p.projectId === projectId);
  if (project) {
    project.status = projectState.canceled;
    // TODO: update project state/status ...
    // const index = state.projects.indexOf(project);
    //
    // do more updates here if/when...
    //
  }

  // update open projects e.g. ...
  project = state.openProjects?.find(p => p.projectId === projectId);
  if (project) {
    project.status = projectState.canceled;
    // TODO: update project state/status ...
    // const index = state.projects.indexOf(project);
    //
    // do more updates here if/when...
    //
  }
};

/**
 * set Project Suspended
 * @param state
 * @param {number} projectId workflow project id
 */
const setProjectSuspended = (state, projectId) => {
  console.log(`setProjectSuspended() projectId:`, projectId);

  if (state.project) {
    state.project.status = projectState.suspended;
  }

  // update projects
  let project = state.projects?.find(p => p.projectId === projectId);
  if (project) {
    project.status = projectState.suspended;
    // TODO: update project state
    // const index = state.projects.indexOf(project);
    //
    // do more updates here if/when...
    //
  }

  // update open projects e.g. ...
  project = state.openProjects?.find(p => p.projectId === projectId);
  if (project) {
    project.status = projectState.suspended;
    // TODO: update project state
    // const index = state.projects.indexOf(project);
    //
    // do more updates here if/when...
    //
  }
};

/**
 * set Project Resumed
 * @param state
 * @param {number} projectId workflow project id
 */
const setProjectResumed = (state, projectId) => {
  console.log(`setProjectResumed() projectId:`, projectId);

  if (state.project) {
    state.project.status = projectState.executing;
  }

  // update projects
  let project = state.projects?.find(p => p.projectId === projectId);
  if (project) {
    project.status = projectState.executing;
    // TODO: update project state
    // const index = state.projects.indexOf(project);
    //
    // do more updates here if/when...
    //
  }

  // update open projects e.g. ...
  project = state.openProjects?.find(p => p.projectId === projectId);
  if (project) {
    project.status = projectState.executing;
    // TODO: update project state
    // const index = state.projects.indexOf(project);
    //
    // do more updates here if/when...
    //
  }
};

/**
 * update Task Assignee
 * @param state
 * @param {string} userName - task assignee
 */
const updateTaskAssignee = (state, userName) => {
  console.log(`updateTaskAssignee() userName:`, userName);

  const actorId = state.task?.actorId ?? -1;

  // Update task properties based on conditions
  state.task = {
    ...state.task,
    actorName: actorId > 0 ? userName : state.task?.actorName,
    assignedTo: actorId <= 0 ? userName : state.task?.assignedTo
  };
};

export {
  setProjectDeleted,
  setProjectCanceled,
  setProjectSuspended,
  setProjectResumed,
  updateTaskAssignee
};

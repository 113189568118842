// utils
import {
  getLocalStorageItem,
  setLocalStorageItem,
  repoUserLocalStorageKey
} from "@/utils/localStorageUtility";

// model
import { moduleNames } from "@/model/solution/moduleModel";

/**
 * module Name
 * @type {string}
 */
const moduleName = moduleNames.Agenda;

/**
 * local Storage Key
 * @type {Readonly<{agendaTemplateId: string, expandedId: string, folderId: string}>}
 */
const localStorageKey = Object.freeze({
  folderId: `${moduleName}_folderId`,
  expandedId: `${moduleName}_expandedId`,
  agendaTemplateId: `${moduleName}_agendaTemplateId`
});

/**
 * Store Selected Module Item id (Selected meeting's record id) to the local Storage
 * @param {number}id Module Item id
 */
const storeSelectedModuleItemId = id =>
  setLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.folderId),
    id.toString()
  );

/**
 * store Selected Module expanded id
 * @param {Number|number} expandedId expanded id
 */
const storeSelectedModuleExpandedId = expandedId =>
  setLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.expandedId),
    expandedId.toString()
  );

/**
 * Get Selected Module Item id (Selected meeting's record id) from local Storage
 * @returns {Number|number}
 */
const getStoredSelectedModuleItemId = () => {
  const id = getLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.folderId)
  );
  return id ? (isNaN(id) ? 0 : Number(id)) : 0;
};

/**
 * store Agenda Template Id
 * @param {number} id Agenda Template Id
 */
const storeAgendaTemplateId = id =>
  setLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.agendaTemplateId),
    id.toString()
  );

/**
 * get Stored Agenda Template Id
 * @return {number|number} Stored Agenda Template Id
 */
const getStoredAgendaTemplateId = () => {
  const id = getLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.agendaTemplateId)
  );
  return id ? (isNaN(id) ? -1 : Number(id)) : -1;
};

/**
 * get Stored Selected Module expanded id
 * @return {Number|number}
 */
const getStoredSelectedModuleExpandedId = () => {
  const id = getLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.expandedId)
  );
  return id ? (isNaN(id) ? -1 : Number(id)) : -1;
};

export {
  storeSelectedModuleItemId,
  storeSelectedModuleExpandedId,
  getStoredSelectedModuleItemId,
  storeAgendaTemplateId,
  getStoredAgendaTemplateId,
  getStoredSelectedModuleExpandedId
};

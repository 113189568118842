// services
import { moduleNames } from "@/services/solution/solutionService";
import { isPublicAccess } from "@/services/config/configService";
import { isRecordExpandable } from "@/services/record/recordService";

// utils
import { isNumber } from "@/utils";

/**
 * route Params
 * @type {Readonly<{id: string, expandedId: string}>}
 */
const routeParams = Object.freeze({
  id: "id",
  expandedId: "expandedId"
});

/**
 * route root Name
 * @type {string}
 */
const rootName = isPublicAccess ? "Public" : "Questys";

/**
 * route Names
 * @type {Readonly<{notImplemented: string, error404: string, root: string, login: string, home: string}>}
 */
const routeNames = Object.freeze({
  home: "Home",
  login: "Login",
  root: rootName,
  notImplemented: "notImplemented",
  error404: "404 Error"
});

/**
 * create App Module Route Name
 * @param {String|string} moduleName
 */
const createAppModuleRouteName = moduleName => moduleName;

/**
 * create Application Module Route
 * @param {string} moduleName
 * @returns {{name: string}}
 */
const createAppModuleRoute = moduleName => {
  return { name: moduleName };
};

/**
 * create Application's Module route Path based on module name
 * @param {String} moduleName
 * @returns {string}
 */
const createAppModuleRoutePath = moduleName => {
  return process.env.BASE_URL === "/"
    ? `/${rootName}/${moduleName}`
    : `/${moduleName}`;
};

/**
 * create Application Module content route Name based on Module and content name
 * @param {String|string} moduleName
 * @returns {String|string}
 */
const createModuleContentRouteName = moduleName => `${moduleName}_content`;

/**
 * create Module expand Record Content Route Name
 * @param {String|string} moduleName module Name
 * @return {String|string} Module Child Record Content Route Name
 */
const createModuleExpandRecordContentRouteName = moduleName =>
  `${moduleName}_content_expand`;

/**
 * create Module expand Record Shortcut Content Route Name
 * @param {String|string} moduleName module Name
 * @return {String|string} Module Child Record Content Route Name
 */
const createModuleExpandRecordShortcutContentRouteName = moduleName =>
  `${moduleName}_shortcut_content_expand`;

/**
 * create Module Record Route Location
 * @param {String|string}  moduleName module Name
 * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @return {{name: (String|string), params: {id}}|{name: (String|string), params: {expandedId: (Number|number), id: (Number|number)}}}
 */
const createModuleRecordRouteLocation = (moduleName, record) => {
  return isRecordExpandable(record)
    ? createModuleItemContentRouteLocation(moduleName, record?.id ?? -1)
    : record?.isLink ?? false
    ? createModuleChildRecordShortcutContentRouteLocation(
        moduleName,
        record?.id ?? -1,
        record?.parentId ?? -1
      )
    : createModuleChildRecordContentRouteLocation(
        moduleName,
        record?.id ?? -1,
        record?.parentId ?? -1
      );
};

/**
 * create Module Child Record Content Route Location
 * @param {String|string} moduleName module Name
 * @param {Number|number} id Record id
 * @param {Number|number} parentId Record's parent id
 * @return {{name: String|string, params: {expandedId: Number|number, id: Number|number}}}
 */
const createModuleChildRecordContentRouteLocation = (
  moduleName,
  id,
  parentId
) => {
  return {
    name: createModuleExpandRecordContentRouteName(moduleName),
    params: { id: id, expandedId: parentId }
  };
};

/**
 * create Module Child Record Shortcut Content Route Location
 * @param {String|string} moduleName module Name
 * @param {Number|number} id Record id
 * @param {Number|number} parentId Record's parent id
 * @return {{name: String|string, params: {expandedId: Number|number, id: Number|number}}}
 */
const createModuleChildRecordShortcutContentRouteLocation = (
  moduleName,
  id,
  parentId
) => {
  return {
    name: createModuleExpandRecordShortcutContentRouteName(moduleName),
    params: { id: id, expandedId: parentId }
  };
};

/**
 * create Module Item Content Route Location
 * @param {String|string} moduleName module Name
 * @param {Number|number} id record id
 * @return {{name: (String|string), params: {id: Number|number}}}
 */
const createModuleItemContentRouteLocation = (moduleName, id) => {
  return {
    name: createModuleContentRouteName(moduleName),
    params: { id: id }
  };
};

/**
 * create Module Route Path using a route parameter name
 * @param {String} paramName
 * @returns {string}
 */
const createModuleRoutePath = (paramName = routeParams.id) => {
  return `:${paramName}`;
};

/**
 * expanded Route Path
 * @return {":expandedId/:id"}
 */
const expandedRoutePath = `:${routeParams.expandedId}/:${routeParams.id}`;

/**
 * shortcut Route Path
 * @return {":expandedId/shortcuts/:id"}
 */
const shortcutRoutePath = `:${routeParams.expandedId}/shortcuts/:${routeParams.id}`;

/**
 * create Module Details Route Path
 * @param paramName
 * @returns {string}
 */
const createModuleDetailsRoutePath = (paramName = routeParams.id) => {
  return `:${paramName}`;
};

/**
 * create Module Sub-Details Route Name
 * @param {String} moduleName
 * @param {String} moduleRouteName
 * @param {String} tabRouteName
 * @returns {string}
 */
const createModuleTabDetailsRouteName = (
  moduleName,
  moduleRouteName,
  tabRouteName
) => `${moduleName}_${moduleRouteName}_${tabRouteName}`;

/**
 * create Module Sub-Tab Details Route Name
 * @param {String} moduleName
 * @param {String} moduleRouteName
 * @param {String} tabRouteName
 * @param {String} subTabRouteName
 * @returns {string}
 */
const createModuleSubTabDetailsRouteName = (
  moduleName,
  moduleRouteName,
  tabRouteName,
  subTabRouteName
) => `${moduleName}_${moduleRouteName}_${tabRouteName}_${subTabRouteName}`;

/**
 * create Module Params Id
 * @param route
 * @return {{id: Number|number}}
 */
const createModuleParamsId = route => {
  const id = route?.params?.id?.toString();

  return {
    id: isNumber(id) ? Number(id) : -1
  };
};

/**
 * create Module Child Record Params id
 * @param route
 * @return {{ id: Number|number, expandedId: Number|number}}
 */
const createModuleChildRecordParamsId = route => {
  const id = route?.params?.id?.toString() ?? "";
  const expandedId = route?.params?.expandedId?.toString() ?? "";

  return {
    id: isNumber(id) ? Number(id) : -1,
    expandedId: isNumber(expandedId) ? Number(expandedId) : -1
  };
};

/**
 * Home Routes
 * @type {{redirect: {name: (String|string)}, path: string, name: string}[]}
 */
const homeRoutes = Object.freeze([
  {
    path: "/",
    name: routeNames.home,
    redirect: {
      name: isPublicAccess
        ? createAppModuleRouteName(moduleNames.Search)
        : createAppModuleRouteName(moduleNames.Folder)
    }
  },
  {
    path: `/${rootName}`,
    name: routeNames.root,
    redirect: {
      name: isPublicAccess
        ? createAppModuleRouteName(moduleNames.Search)
        : createAppModuleRouteName(moduleNames.Folder)
    }
  }
]);

const loginRoutes = Object.freeze([
  {
    path: `/${routeNames.login}`,
    name: routeNames.login,
    meta: {
      requiresAuth: false,
      isFullscreen: true
    },
    component: () => import("@/views/user/Login")
  }
]);

/**
 * Handle not found routes
 * @type {{path: string, component: (function(): Promise<*>), children: [{path: string, component: function(): Promise<*>, name: string}]}[]}
 */
const notFoundRoutes = Object.freeze([
  {
    path: "*",
    component: () => import("@/views/Home"),
    children: [
      {
        name: routeNames.error404,
        path: "",
        component: () => import("@/views/shared/errors/NotFound")
      }
    ]
  }
]);

export {
  homeRoutes,
  loginRoutes,
  notFoundRoutes,
  routeParams,
  routeNames,
  expandedRoutePath,
  shortcutRoutePath,
  createAppModuleRouteName,
  createAppModuleRoute,
  createAppModuleRoutePath,
  createModuleContentRouteName,
  createModuleExpandRecordContentRouteName,
  createModuleRoutePath,
  createModuleDetailsRoutePath,
  createModuleTabDetailsRouteName,
  createModuleSubTabDetailsRouteName,
  createModuleParamsId,
  createModuleChildRecordParamsId,
  createModuleChildRecordContentRouteLocation,
  createModuleItemContentRouteLocation,
  createModuleRecordRouteLocation,
  createModuleExpandRecordShortcutContentRouteName,
  createModuleChildRecordShortcutContentRouteLocation
};

// services
import { moduleNames } from "@/services/solution/solutionService";

// router
import {
  routeParams,
  createAppModuleRouteName,
  createAppModuleRoutePath,
  createModuleContentRouteName,
  createModuleRoutePath,
  createModuleParamsId
} from "@/router/solution/solutionRoutes";

const inboxRoutes = Object.freeze([
  {
    path: createAppModuleRoutePath(moduleNames.Tasks),
    name: createAppModuleRouteName(moduleNames.Tasks),
    meta: { requiresAuth: true },
    component: () => import("@/views/inbox/InboxHome"),
    children: [
      {
        path: createModuleRoutePath(routeParams.id),
        name: createModuleContentRouteName(moduleNames.Tasks),
        meta: { requiresAuth: true },
        props: createModuleParamsId,
        component: () => import("@/views/inbox/InboxContent")
        // children: [
        //   {
        //     path: createModuleDetailsRoutePath(routeParams.id),
        //     name: createAppModuleDetailsRouteName(appModuleName),
        //     meta: { requiresAuth: true },
        //     props: true,
        //     component: () => import("@/views/inbox/InboxDetails")
        //   }
        // ]
      }
    ]
  }
]);

export { inboxRoutes };

// router
import {
  createAppModuleRouteName,
  createAppModuleRoutePath
} from "@/router/solution/solutionRoutes";

/**
 * document module Name
 * @type {string}
 */
const moduleName = "Document";

/**
 * document Viewer Route Name
 * @type {string}
 */
const documentViewerRouteName = "DocumentViewer";

/**
 * create Document Viewer Route
 * @param {{id: number, version: number}} record Questys record (supported record type: compound document/file)
 * @param {string} fullTextSearch full text search criteria
 * @return {{name: string, params: {id: number}, query: {searchTerm: string, documentVersion: number}}}}
 */
const createDocumentViewerRoute = (record, fullTextSearch = undefined) => {
  return {
    name: documentViewerRouteName,
    params: { id: record?.id ?? -1 },
    query: {
      searchTerm: fullTextSearch,
      documentVersion: record?.version ?? -1
    }
  };
};

/**
 * create Document Viewer Route for file version history
 * @param {{id: number, version: number}} record Questys record (supported record type: compound document/file)
 * @param {string} fullTextSearch full text search criteria
 * @return {{name: string, params: {id: number}, query: {searchTerm: string, documentVersion: number}}}}
 */
const createDocumentViewerFileHistoryRoute = (
  record,
  fullTextSearch = undefined
) => {
  return {
    name: documentViewerRouteName,
    params: { id: record?.id ?? -1 },
    query: {
      searchTerm: fullTextSearch,
      documentVersion: record?.version ?? -1,
      isViewOnly: true
    }
  };
};

const documentRoutes = Object.freeze([
  {
    path: createAppModuleRoutePath("Image"),
    name: createAppModuleRouteName(moduleName),
    meta: { requiresAuth: true },
    component: () => import("@/views/document/DocumentHome"),
    children: [
      {
        path: `:id`,
        name: documentViewerRouteName,
        meta: { requiresAuth: true },
        props: true,
        component: () => import("@/views/document/DocumentViewer")
      }
    ]
  }
]);

export {
  documentRoutes,
  documentViewerRouteName,
  createDocumentViewerRoute,
  createDocumentViewerFileHistoryRoute
};

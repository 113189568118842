// model
import {
  commandStatus,
  findCommandStatus
} from "@/model/common/commands/commandModel";
import {
  findProjectState,
  projectState
} from "@/model/workflow/project/projectModel";
import {
  dueDateColorClass,
  findTaskState,
  findTaskStateColorClass,
  taskState
} from "@/model/workflow/task/taskModel";

// filters
import { convertToYYYYMMDDHHMM } from "@/filters/dateFilter";

// services
import {
  canJumpToTask,
  taskDueDateLocalDateTime,
  taskStatusTooltip
} from "@/services/inbox/taskService";
import {
  iconFileDetails,
  iconReport,
  iconRun,
  iconView
} from "@/design/icon/iconConst";

/**
 * prepare new workflow Project
 * @param {{id: number, name: string, categoryId: number, recordTypeId: number, recordType: string, parentId: number, children: number, createdBy: string, creationDate: string, extension: string, isComposite: boolean, isLink: boolean, isReadOnly: boolean, isDeleted: boolean, isDraft: boolean, isLocked: boolean, stateId: number, state: string, fieldValues: {id: number, name: string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id: number, sequence: number}[], operations: {name: string, allowed: boolean, valid: boolean}[]}|{id: number, name: string, categoryId: number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id: number, name: string}, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}}, recordTypeId: number, recordType: string, parentId: number, children: number, createdBy: string, creationDate: string, modificationDate: string, extension: string, isComposite: boolean, isLink: boolean, isReadOnly: boolean, isDeleted: boolean, isDraft: boolean, isLocked: boolean, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, stateId: number, state: string, fieldValues: {id: number, name: string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id: number, sequence: number}[], operations: {name: string, allowed: boolean, valid: boolean}[]}|*} record record that new workflow project will be attached to it
 * @param {{id: number, name: string, assignAutoToOwner: boolean, calendar: string, canReassign: boolean, comment: string, created: string, definitionId: number, description: string, isDisabled: boolean, isHidden: boolean, notifyOnAborted: boolean, notifyOnAssignment: boolean, notifyOnAvailable: boolean, notifyOnCanceled: boolean, notifyOnCompletedFailure: boolean, notifyOnCompletedSuccess: boolean, projectManager: string, role: string, workflowName: string, workflowTypeId: number, userTaskTemplates: {id: number, name: string, sequence: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}, duration: {calendar: string, duration: string, fixed: boolean, milestone: boolean}}} projectTemplate workflow project template
 * @param {boolean} runOnlyOneProject run Only One Project at current time
 * @param {number} startProjectOption start Project Option
 * @return {{recordId: number, projectManager: string, comment: string, runOnlyOneProject: boolean, startProjectOption: number, userTasks: ({id: number, name: string, duration: string, priority: number, reassignment: number, sequence: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}[]|[]), projectName: (*|string), templateId: (*|number)}}
 */
const prepareNewProject = (
  record,
  projectTemplate,
  runOnlyOneProject,
  startProjectOption
) => {
  return {
    recordId: record?.id ?? -1,
    projectName: record?.name ?? "",
    projectManager: projectTemplate?.projectManager ?? "",
    comment: projectTemplate?.comment ?? "",
    templateId: projectTemplate?.id ?? -1,
    runOnlyOneProject: runOnlyOneProject ?? false,
    startProjectOption: startProjectOption,
    userTasks: mapUserTasks(projectTemplate) ?? []
  };
};

/**
 * update New workflow Project
 * @param {{recordId: Number, projectName: string, comment: string, templateId: number, runOnlyOneProject: boolean, userTasks: {id: number, name: string, sequence: number, duration: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}}[]} project New workflow Project
 * @param {{id: number, name: string, assignAutoToOwner: boolean, calendar: string, canReassign: boolean, comment: string, created: string, definitionId: number, description: string, isDisabled: boolean, isHidden: boolean, notifyOnAborted: boolean, notifyOnAssignment: boolean, notifyOnAvailable: boolean, notifyOnCanceled: boolean, notifyOnCompletedFailure: boolean, notifyOnCompletedSuccess: boolean, projectManager: string, role: string, workflowName: string, workflowTypeId: number, userTaskTemplates: {id: number, name: string, sequence: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}, duration: {calendar: string, duration: string, fixed: boolean, milestone: boolean}}} template workflow Project template
 */
const updateNewProject = (project, template) => {
  project.templateId = template?.id ?? -1;
  project.projectManager = template?.projectManager ?? "";
  project.comment = template?.comment ?? "";

  if ((template?.userTaskTemplates?.length ?? 0) > 0) {
    if (!project.userTasks) {
      project.userTasks = [];
    }
    project.userTasks = mapUserTasks(template) ?? [];
  } else {
    project.userTasks = [];
  }
};

/**
 * map userTask Templates of project template to new project's userTasks
 * @param {{id: number, name: string, assignAutoToOwner: boolean, calendar: string, canReassign: boolean, comment: string, created: string, definitionId: number, description: string, isDisabled: boolean, isHidden: boolean, notifyOnAborted: boolean, notifyOnAssignment: boolean, notifyOnAvailable: boolean, notifyOnCanceled: boolean, notifyOnCompletedFailure: boolean, notifyOnCompletedSuccess: boolean, projectManager: string, role: string, workflowName: string, workflowTypeId: number, userTaskTemplates: {id: number, name: string, sequence: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}, duration: {calendar: string, duration: string, fixed: boolean, milestone: boolean}}} template workflow project template
 * @return {{id: number, name: string, duration: string, priority: number, reassignment: number, sequence: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}} []|[]}
 */
const mapUserTasks = template => {
  return (
    template?.userTaskTemplates?.map(t => {
      return {
        id: t.id,
        name: t.name,
        duration: {
          duration: t.duration.duration,
          calendar: t.duration.calendar,
          fixed: t.duration.fixed,
          milestone: t.duration.milestone
        },
        priority: t.priority,
        reassignment: t.reassignment,
        sequence: t.sequence,
        assignment: {
          assignee: t.assignment.assignee,
          fixed: t.assignment.fixed,
          method: t.assignment.method,
          roles: t.assignment.roles
        }
      };
    }) ?? []
  );
};

/**
 * get a New Project User Tasks
 * data transfer object (DTO) (which web API expects)
 * @param {{recordId: Number, projectName: string, projectManager: string, comment: string, templateId: number, runOnlyOneProject: boolean, userTasks: {id: number, name: string, sequence: number, duration: {duration: string, calendar: string, fixed: boolean, milestone: boolean}, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}[]}} project
 * @return {{id: number, duration: string, priority: number, assignment: {assignee: string, roles: string, method: number}}[]|*[]}
 */
const getNewProjectUserTasks = project =>
  project?.userTasks?.map(ut => {
    return {
      id: ut.id,
      duration: ut.duration?.duration,
      priority: ut.priority,
      assignment: {
        assignee: ut.assignment.assignee,
        roles: ut.assignment.roles,
        method: ut.assignment.method
      }
    };
  }) ?? [];

/**
 * delete Project Command Status
 * @param {{projectId: Number, projectName:string, status:number, isValid: boolean, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, projectStatus: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}} project
 * @return {{reason: string, status: number}}
 */
const deleteProjectCommandStatus = project => {
  // validate input arguments
  if (!project) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Not provided Project"
    };
  }
  // validate project permissions
  if (
    !(
      (project?.canAccess ?? false) &&
      (project?.canCancel ?? false) &&
      (project?.canSuspend ?? false)
    )
  ) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Permission denied"
    };
  }
  // validate project
  if (!(project?.isValid ?? false)) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Project not valid"
    };
  }
  // validate project status
  const status = project?.status ?? -1;
  const result =
    status === projectState.notStarted || status === projectState.faulted;
  return result
    ? {
        status: commandStatus.enabled,
        reason: ""
      }
    : {
        status: commandStatus.disabled,
        reason: `Cannot delete project due to project status. Project is ${
          findProjectState(status)?.name
        }`
      };
};

/**
 * cancel Project Command Status
 * @param {{projectId: Number, projectName:string, status:number, isValid: boolean, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, projectStatus: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}} project
 * @return {{reason: string, status: number}}
 */
const cancelProjectCommandStatus = project => {
  // validate input arguments
  if (!project) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Not provided Project"
    };
  }
  // validate project permissions
  if (!((project?.canAccess ?? false) && (project?.canCancel ?? false))) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Permission denied"
    };
  }
  // validate project
  if (!(project?.isValid ?? false)) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Project not valid"
    };
  }
  // validate project status
  const status = project?.status ?? -1;
  const result =
    status > projectState.notStarted && status < projectState.completed;
  return result
    ? {
        status: commandStatus.enabled,
        reason: ""
      }
    : {
        status: commandStatus.disabled,
        reason: `Cannot cancel project due to project status. Project is ${
          findProjectState(status)?.name
        }`
      };
};

/**
 * Suspend Project Command Status
 * @param {{projectId: Number, projectName:string, status:number, isValid: boolean, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, projectStatus: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}} project
 * @return {{reason: string, status: number}}
 */
const suspendProjectCommandStatus = project => {
  // validate input arguments
  if (!project) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Not provided Project"
    };
  }
  // validate project permissions
  if (!((project?.canAccess ?? false) && (project?.canSuspend ?? false))) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Permission denied"
    };
  }
  // validate project
  if (!(project?.isValid ?? false)) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Project not valid"
    };
  }
  // validate project status
  const status = project?.status ?? -1;
  const result = status === projectState.executing;
  return result
    ? {
        status: commandStatus.enabled,
        reason: ""
      }
    : {
        status: commandStatus.disabled,
        reason: `Cannot suspend project due to project status. Project is ${
          findProjectState(status)?.name
        }`
      };
};

/**
 * Resume Project Command Status
 * @param {{projectId: Number, projectName:string, status:number, isValid: boolean, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, projectStatus: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}} project
 * @return {{reason: string, status: number}}
 */
const resumeProjectCommandStatus = project => {
  // validate input arguments
  if (!project) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Not provided Project"
    };
  }
  // validate project permissions
  if (!(project?.canAccess ?? false)) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Permission denied"
    };
  }
  // validate project
  if (!(project?.isValid ?? false)) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Project not valid"
    };
  }
  // validate project status
  const status = project?.status ?? -1;
  const result = status === projectState.suspended;
  return result
    ? {
        status: commandStatus.enabled,
        reason: ""
      }
    : {
        status: commandStatus.disabled,
        reason: `Cannot resume project due to project status. Project is ${
          findProjectState(status)?.name
        }`
      };
};

/**
 * Change Ownership Project Command Status
 * @param {{projectId: Number, projectName:string, status:number, isValid: boolean, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, projectStatus: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}} project
 * @return {{reason: string, status: number}}
 */
const changeOwnershipProjectCommandStatus = project => {
  // validate input arguments
  if (!project) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Not provided Project"
    };
  }
  // validate project permissions
  if (
    !((project?.canAccess ?? false) && (project?.canChangeOwnership ?? false))
  ) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Permission denied"
    };
  }
  // validate project
  if (!(project?.isValid ?? false)) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Project not valid"
    };
  }
  // validate project status
  const status = project?.status ?? -1;
  const result = status < projectState.completed;

  return result
    ? {
        status: commandStatus.enabled,
        reason: ""
      }
    : {
        status: commandStatus.disabled,
        reason: `Cannot change ownership of the project due to project status. Project is ${
          findProjectState(status)?.name
        }`
      };
};

/**
 * reassign Project Task Command Status
 * @param project
 * @param task
 * @return {{reason: string, status: number}} Project Task Command Status
 */
const reassignProjectTaskCommandStatus = (project, task) => {
  // validate input arguments
  if (!project) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Not provided Project"
    };
  }
  // validate project permissions
  if (!(project.canAccess && project.canReassign)) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Permission denied"
    };
  }

  // validate project
  if (!project.isValid) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Project not valid"
    };
  }

  // validate project status
  const projectStatus = project.status;
  if (!(projectStatus < projectState.completed)) {
    return {
      status: commandStatus.disabled,
      reason: `Cannot reassign task due to project status. Project is ${
        findProjectState(projectStatus)?.name
      }`
    };
  }

  // validate task
  if (!task) {
    return {
      status: findCommandStatus(commandStatus.unavailable),
      reason: "Not provided task"
    };
  }

  // validate task status
  const taskStatus = task.status;
  const result = !(
    (taskStatus < taskState.notStarted || taskStatus >= taskState.completed) // Got from Client
  );

  return result
    ? {
        status: commandStatus.enabled,
        reason: ""
      }
    : {
        status: commandStatus.disabled,
        reason: `Cannot reassign task due to task status. Task is ${
          findTaskState(taskStatus)?.name
        }`
      };
};

/**
 * Get Project Tasks Calendar Events
 * @param {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}} project
 * @return {*[]}
 */
const getProjectTasksCalendarEvents = project => {
  if (!project) return [];

  const events = [];

  project.userTasks.forEach(task => {
    events.push(
      // {
      //   name: "Created",
      //   start: convertToYYYYMMDDHHMM(task.created),
      //   color: colorMD.blue, // Customize color
      //   tooltip: `Task "${task.name}" created at ${convertToYYYYMMDDHHMM(
      //     task?.created
      //   )} `
      // },

      {
        id: task.taskId,
        name: task.name,
        start: convertToYYYYMMDDHHMM(task.statusChanged),
        end: task.dueDate ? convertToYYYYMMDDHHMM(task.dueDate) : undefined,
        // end: convertToYYYYMMDDHHMM(
        //   new Date(getDueDate(task.statusChanged, task.duration))
        // ),
        color: findTaskStateColorClass(task.status),
        tooltip: taskStatusTooltip(task)
      }

      // {
      //   name: "Status",
      //   start: convertToYYYYMMDDHHMM(task.statusChanged),
      //   color: colorMD.green, // Customize color
      //   tooltip: `Task "${task.name}" status changed at ${convertToYYYYMMDDHHMM(
      //     task?.statusChanged
      //   )} `
      // },

      // {
      //   name: "Due",
      //   start: convertToYYYYMMDDHHMM(
      //     new Date(new Date(getDueDate(task.created, task.duration)))
      //   ),
      //   color: colorMD.red, // Customize color
      //   tooltip: `Task "${task.name}" due at ${convertToYYYYMMDDHHMM(
      //     new Date(new Date(getDueDate(task.created, task.duration)))
      //   )} `
      // },

      // {
      //   name: "Duration",
      //   start: convertToYYYYMMDDHHMM(task?.created),
      //   end: convertToYYYYMMDDHHMM(
      //     new Date(new Date(getDueDate(task.created, task.duration)))
      //   ),
      //   color: colorMD.grey,
      //   tooltip: `Task "${task.name}" total duration - ${task.duration} `
      // }
    );

    if (task.dueDate) {
      events.push({
        id: task.taskId,
        name: `Due`,
        start: convertToYYYYMMDDHHMM(new Date(task.dueDate)),
        color: dueDateColorClass(task),
        tooltip: `${task.name} Due ${taskDueDateLocalDateTime(task)}`
      });
    }
  });

  return events;
};

/**
 * Context Menu Items for Project Context Menu
 * @type {Readonly<{ShowDetails: string, DailyView: string, MonthlyView: string, WeeklyView: string, JumpToTask: string}>}
 */
const projectContextMenuItem = Object.freeze({
  ShowDetails: "Show Details",
  DailyView: "Daily View",
  MonthlyView: "Monthly View",
  WeeklyView: "Weekly View",
  JumpToTask: "Jump to Task",
  JumpToRecord: "Jump to Record",
  Export: "Export"
});

/**
 * Context Menu Items for Project Calendar
 * @type {function(*, *): [{visible: boolean, name: string, icon: string}, {visible: boolean, name: string, icon: string, type: string}, {visible: boolean, name: string, icon: string, type: string}, {visible: boolean, name: string, icon: string, type: string}, {visible: boolean, name: string, icon: string}, null, null]}
 */
const projectCalendarMenuItems = (task, actorId, calendarType) => [
  {
    name: projectContextMenuItem.ShowDetails,
    icon: iconFileDetails,
    visible: true,
    enabled: true
  },
  {
    name: projectContextMenuItem.DailyView,
    type: "day",
    icon: iconView,
    visible: true,
    enabled: calendarType !== "day"
  },
  {
    name: projectContextMenuItem.MonthlyView,
    type: "month",
    icon: iconView,
    visible: true,
    enabled: calendarType !== "month"
  },
  {
    name: projectContextMenuItem.WeeklyView,
    type: "week",
    icon: iconView,
    visible: true,
    enabled: calendarType !== "week"
  },
  {
    name: projectContextMenuItem.JumpToTask,
    icon: iconRun,
    visible: canJumpToTask(task, actorId),
    enabled: true
  },
  {
    name: projectContextMenuItem.JumpToRecord,
    icon: iconRun,
    visible: true,
    enabled: true
  },
  {
    name: projectContextMenuItem.Export,
    icon: iconReport,
    visible: true,
    enabled: true
  }
];

/**
 * Context Menu Items for Project Timeline
 * @type {function(*): [{name: string, icon: string}, {name: string, icon: string}, {name: string, icon: string}, {name: string, icon: string}]}
 */
const projectTimelineMenuItems = (task, actorId) => [
  {
    name: projectContextMenuItem.ShowDetails,
    icon: iconFileDetails,
    visible: true,
    enabled: true
  },
  {
    name: projectContextMenuItem.JumpToTask,
    icon: iconRun,
    visible: canJumpToTask(task, actorId),
    enabled: true
  },
  {
    name: projectContextMenuItem.JumpToRecord,
    icon: iconRun,
    visible: true,
    enabled: true
  },
  {
    name: projectContextMenuItem.Export,
    icon: iconReport,
    visible: true,
    enabled: true
  }
];

export {
  prepareNewProject,
  updateNewProject,
  getNewProjectUserTasks,
  deleteProjectCommandStatus,
  cancelProjectCommandStatus,
  suspendProjectCommandStatus,
  resumeProjectCommandStatus,
  changeOwnershipProjectCommandStatus,
  reassignProjectTaskCommandStatus,
  getProjectTasksCalendarEvents,
  projectCalendarMenuItems,
  projectContextMenuItem,
  projectTimelineMenuItems
};

// utils
import { trimSafe, trimRight, trimLeft, toLowerCaseSafe } from "@/utils";

// model
import { createPublicAccessSetting } from "@/model/public/publicAccessModel";

/**
 * Get BASE_URL
 * @type {string}
 */
const baseUrl = process.env.BASE_URL;

/**
 * Determines whether is public access enabled
 * @type boolean
 */
const isPublicAccess =
  toLowerCaseSafe(process.env?.VUE_APP_PUBLIC_ACCESS ?? "") === "true";

/**
 * Is Development environment
 * @type {boolean} true if app is running in development environment
 */
const isDevelopment =
  toLowerCaseSafe(process.env?.NODE_ENV ?? "") === "development";

/**
 * Initialize Questys Web API Url using .env
 */
let apiUrl = ensureValidUrl(process.env.VUE_APP_WEB_API_URL);

/**
 * Questys.WebApi Url
 * @type {string}
 */
let webApiUrl = `${apiUrl}/api`;

console.log(`configService - webApiUrl:`, webApiUrl);

/**
 * The URL of Questys.WebApi.WebDocumentViewer.WebThumbnailCallbacks request handler
 * @type {string}
 */
let wdvUrl = `${apiUrl}/wdv`;

/**
 * The URL of Questys.WebApi.WebCapture.WebCaptureHandler request handler
 * IMPORTANT: match Questys.WebApi AddWebCaptureMiddleware()
 * app.Map("/webCapture", wdvApp => ...
 * @type {string}
 */
let webCaptureUrl = `${apiUrl}/webCapture`;

/**
 * Questys Download Pdf url
 * @type {string}
 */
let downloadUrl = `${apiUrl}/Image/DownloadPdf`;

/**
 * Web Api call Time Out
 * @type {string}
 */
const webApiTimeOut = process.env.VUE_APP_AXIOS_TIMEOUT;

/**
 * Application version defined in vue.config.js
 * @type {String|string}
 */
const version = trimLeft(
  trimRight(process.env["VUE_APP_VERSION"] || "0", ['"']),
  ['"']
);

/**
 * App Name (Questys Access, Public Access, ...)
 * @type {String|string}
 */
const appName = process.env.VUE_APP_NAME || "Questys Access";

/**
 * Year of the Application
 * @type {string}
 */
const appYear =
  process.env["VUE_APP_YEAR"] || new Date().getFullYear().toString();

/**
 * public Access
 * @type {
 *    {searcher: {name: string, code: string, repository: string}},
 *    {appBar: {prominent: boolean, color: string, gradient: string, useTabs: boolean, logo: string, backgroundImageUrl: string, title: string, collapseOnScroll: boolean, fadeImgOnScroll: boolean, shrinkOnScroll: boolean}},
 *    {sideBar: {enableMiniSidebar: boolean, backgroundImageUrl: string}},
 *    {buttons: {color: string, elevation: number, outlined: boolean, rounded: boolean, textStyle: boolean}},
 *    {company: {disclaimers: string, name: string, logo: string, description: string, linkedIn: string, address: {zip: string, country: string, city: string, addressLine1: string, addressLine2: string, state: string}}},
 *    {contact: {name: string, description: string, phone: string, phoneDescription: string, email: string, emailDescription: string}},
 *    {modules: {visibleFolder: string, visibleCategory: string,  visibleMeetings: string}}
 *    }
 */
let publicAccess = createPublicAccessSetting();

/**
 * Ensure Valid Url
 * @param {String|string} urlName
 * @returns {String}
 */
function ensureValidUrl(urlName) {
  return urlName ? trimRight(trimSafe(urlName), ["/"]) : "";
}

/**
 * set Production Settings
 * @param {{ apiUrl: string}} settings
 */
function setProductionSettings(settings) {
  const url = settings?.apiUrl;
  if (url) {
    console.log(`setProductionSettings() settings.apiUrl:`, url);
    // Update apiUrl and it dependencies
    apiUrl = ensureValidUrl(url);
    webApiUrl = `${apiUrl}/api`;
    wdvUrl = `${apiUrl}/wdv`;
    webCaptureUrl = `${apiUrl}/webCapture`;
    downloadUrl = `${apiUrl}/Image/DownloadPdf`;

    console.log("Updated apiUrl:", apiUrl);
    console.log("Updated webApiUrl:", webApiUrl);
    console.log("Updated wdvUrl:", wdvUrl);
    console.log("Updated downloadUrl:", downloadUrl);
  } else {
    console.warn(`setProductionSettings() settings.apiUrl:`, url);
  }
}

/**
 * set Public Access Settings
 * @type {
 *    {searcher: {name: string, code: string, repository: string}},
 *    {appBar: {prominent: boolean, color: string, gradient: string, useTabs: boolean, logo: string, backgroundImageUrl: string, title: string, collapseOnScroll: boolean, fadeImgOnScroll: boolean, shrinkOnScroll: boolean}},
 *    {sideBar: {enableMiniSidebar: boolean, backgroundImageUrl: string}},
 *    {buttons: {color: string, elevation: number, outlined: boolean, rounded: boolean, textStyle: boolean}},
 *    {company: {disclaimers: string, name: string, logo: string, description: string, linkedIn: string, address: {zip: string, country: string, city: string, addressLine1: string, addressLine2: string, state: string}}},
 *    {contact: {name: string, description: string, phone: string, phoneDescription: string, email: string, emailDescription: string}},
 *    {modules: {visibleFolder: string, visibleCategory: string,  visibleMeetings: string}}
 *    }
 */
function setPublicAccessSettings(settings) {
  publicAccess = createPublicAccessSetting(settings);
}

/**
 * determines whether  Author is Allowed
 * @return {boolean} true if Author is Allowed
 */
const isAllowedAuthor = () => {
  /**
   * Enabled Author if not Public Access
   */
  return !isPublicAccess;
};

export {
  baseUrl,
  isDevelopment,
  apiUrl,
  webApiTimeOut,
  version,
  appName,
  appYear,
  webApiUrl,
  wdvUrl,
  webCaptureUrl,
  downloadUrl,
  isPublicAccess,
  publicAccess,
  isAllowedAuthor,
  setProductionSettings,
  setPublicAccessSettings
};

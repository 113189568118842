// services
import { createRecordInfo } from "@/services/record/recordService";
import { updateNewProject } from "@/services/project/projectService";

// utils
import { isArray } from "@/utils";

// model
import { recordMode } from "@/model/record/recordModel";

/**
 * set Compound Document New File Version
 * @param state
 * @param payload TODO: list of new Compound Document records (files) instead of a single record
 */
const setCompoundDocumentNewFileVersions = (state, payload) => {
  const newRecords = payload;
  if (!isArray(newRecords)) {
    return;
  }

  // Update child records of expanded record
  const breadcrumbsCount = state?.breadcrumbs?.length ?? 0;
  if (breadcrumbsCount > 0) {
    const expandedRecord = state?.breadcrumbs[breadcrumbsCount - 1];
    if (expandedRecord) {
      newRecords.forEach(record => {
        if (record.parentId === expandedRecord.id) {
          state.records.push(createRecordInfo(record));
        }
      });
    }
  }

  // Update child records of SELECTED record
  newRecords.forEach(record => {
    if ((state?.record?.id ?? -1) === record.parentId) {
      if (!state.documents) {
        state.documents = [];
      }

      //
      // TODO: insert/append accordingly
      //
      state.documents.push(createRecordInfo(record));
    }
  });
};

const setRecordProjects = (state, payload) => {
  state.projects = payload;
  state.openProjects = [];

  console.log(`setRecordProjects projects:`, state?.projects);
  console.log(`setRecordProjects openProjects:`, state?.openProjects);
};

const setRecordOpenProjects = (state, payload) => {
  const project = payload;

  if (!state.openProjects) {
    state.openProjects = [];
  }

  const foundProject = state.openProjects.find(
    p => p.projectId === project?.projectId ?? -1
  );

  if (foundProject) {
    const index = state.openProjects.indexOf(foundProject);
    if (index >= 0) {
      state.openProjects[index] = project;
    }
  } else {
    state.openProjects.push(project);
  }

  console.log(`setRecordOpenProjects projects:`, state?.openProjects);
};

/**
 * set new Project
 * @param state
 * @param {{project, template}} payload (projectNew, template)
 */
const setProjectNew = (state, payload) => {
  state.recordMode = recordMode.newProject;
  state.projectNew = payload?.project;
  state.projectNewTemplate = payload?.template;

  console.log(`setProjectNew() state.projectNew:`, state.projectNew);
  console.log(
    `setProjectNew() state.projectNewTemplate:`,
    state.projectNewTemplate
  );
};

/**
 * change the template of current new workflow project
 * @param state
 * @param {{id: number, name: string, assignAutoToOwner: boolean, calendar: string, canReassign: boolean, comment: string, created: string, definitionId: number, description: string, isDisabled: boolean, isHidden: boolean, notifyOnAborted: boolean, notifyOnAssignment: boolean, notifyOnAvailable: boolean, notifyOnCanceled: boolean, notifyOnCompletedFailure: boolean, notifyOnCompletedSuccess: boolean, projectManager: string, role: string, workflowName: string, workflowTypeId: number, userTaskTemplates: {id: number, name: string, sequence: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}, duration: {calendar: string, duration: string, fixed: boolean, milestone: boolean}}} payload template of current new workflow project
 */
const setProjectNewTemplate = (state, payload) => {
  state.projectNewTemplate = payload;

  if (state.projectNew) {
    updateNewProject(state.projectNew, state.projectNewTemplate);
  }

  console.log(`setProjectNewTemplate() state.projectNew:`, state.projectNew);
  console.log(
    `setProjectNewTemplate() state.projectNewTemplate:`,
    state.projectNewTemplate
  );
};

/**
 * set New Record Project
 * @param state
 * @param {{ project, projectTemplate }} payload
 */
const setNewRecordProject = (state, payload) => {
  state.projectNew = payload.project;
  state.projectNewTemplate = payload.projectTemplate;

  console.log(`setNewRecordProject() state.projectNew:`, state.projectNew);
  console.log(
    `setNewRecordProject() state.projectNewTemplate:`,
    state.projectNewTemplate
  );
};

export {
  setCompoundDocumentNewFileVersions,
  setRecordProjects,
  setRecordOpenProjects,
  setProjectNew,
  setProjectNewTemplate,
  setNewRecordProject
};

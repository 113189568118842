/**
 * User's system Operations
 * @type {Readonly<{ChangePassword: string, FolderCreateAsUser: string, FieldDelete: string, BarcodeCoversheet: string, FieldRead: string, TemplateCreate: string, TemplateRead: string, FormRead: string, UserCreate: string, AccessDeletedRecord: string, AccessWFProjects: string, RunAgendaDesigner: string, FieldCreate: string, TemplateUpdate: string, UserRead: string, Logout: string, FormCreate: string, TemplateDelete: string, MotionActionCreate: string, MotionActionRead: string, MotionActionDelete: string, MotionActionUpdate: string, CategoryRead: string, CategoryUpdate: string, VoteActionUpdate: string, AccessClerkModule: string, AccessRollCall: string, VoteActionRead: string, Login: string, ChangePermission: string, CategoryDelete: string, VoteActionCreate: string, UserResetPassword: string, RunExportConfig: string, RunWFProjectEditor: string, AccessUserList: string, AccessAuditTrail: string, FormDelete: string, UserUpdate: string, FieldUpdate: string, RunImport: string, RunBatchScan: string, None: string, FormUpdate: string, UserDelete: string, CategoryCreate: string, AccessLegislatorWorkpad: string}>}
 */
const systemOperations = Object.freeze({
  none: "None",
  categoryRead: "CategoryRead",
  categoryCreate: "CategoryCreate",
  categoryUpdate: "CategoryUpdate",
  categoryDelete: "CategoryDelete",
  fieldRead: "FieldRead",
  fieldCreate: "FieldCreate",
  fieldUpdate: "FieldUpdate",
  fieldDelete: "FieldDelete",
  formRead: "FormRead",
  formCreate: "FormCreate",
  formUpdate: "FormUpdate",
  formDelete: "FormDelete",
  templateRead: "TemplateRead",
  templateCreate: "TemplateCreate",
  templateUpdate: "TemplateUpdate",
  templateDelete: "TemplateDelete",
  motionActionRead: "MotionActionRead",
  motionActionCreate: "MotionActionCreate",
  motionActionUpdate: "MotionActionUpdate",
  motionActionDelete: "MotionActionDelete",
  voteActionRead: "VoteActionRead",
  voteActionCreate: "VoteActionCreate",
  voteActionUpdate: "VoteActionUpdate",
  userRead: "UserRead",
  userCreate: "UserCreate",
  userUpdate: "UserUpdate",
  userDelete: "UserDelete",
  userResetPassword: "UserResetPassword",
  changePassword: "ChangePassword",
  changePermission: "ChangePermission",
  accessDeletedRecord: "AccessDeletedRecord",
  runImport: "RunImport",
  runBatchScan: "RunBatchScan",
  runExportConfig: "RunExportConfig",
  login: "Login",
  logout: "Logout",
  runAgendaDesigner: "RunAgendaDesigner",
  barcodeCoversheet: "BarcodeCoversheet",
  accessWFProjects: "AccessWFProjects",
  runWFProjectEditor: "RunWFProjectEditor",
  accessAuditTrail: "AccessAuditTrail",
  folderCreateAsUser: "FolderCreateAsUser",
  accessUserList: "AccessUserList",
  accessRollCall: "AccessRollCall",
  accessClerkModule: "AccessClerkModule",
  accessLegislatorWorkPad: "AccessLegislatorWorkpad"
});

export { systemOperations };

// Requiring the lodash library
const _ = require("lodash");

/**
 * Checks if value is classified as a Number primitive or object
 * @param {any} value
 * @return {boolean}
 */
const isNumber = value => {
  const str = value?.toString() ?? "";

  return isEmpty(str) ? false : !isNaN(str);
};

/**
 * This method is like _.clone except that it recursively clones value.
 * Returns: Returns the deep cloned value.
 * @param {Array} array let objects = [{ 'a': 1 }, { 'b': 2 }];
 * @returns {*} returns deep cloned object
 */
const deepCopyArray = array => _.cloneDeep(array);

/**
 * recursively clones value
 * @param object
 * @return {any}
 */
const cloneDeep = object => _.cloneDeep(object);

/**
 * Checks if value is empty.
 * A value is considered empty unless it’s an arguments object,
 * array, string, or jQuery-like collection with a length greater
 * than 0 or an object with own enumerable properties.
 *
 * Returns true if value is empty, else false.
 * @param {any} value
 * @returns {boolean|boolean}
 */
const isEmpty = value => _.isEmpty(value);

/**
 * Trim Right using RegExp on charList array
 * @param {string} text
 * @param {string[]} charList
 * @returns {string}
 */
const trimRight = (text, charList) =>
  text ? text.replace(new RegExp(`[${charList}]+$`), "") : "";

/**
 * The trimEnd() method removes whitespace from the end of a string.
 * trimRight() is an alias of this method.
 * Returns:
 * A new string representing str stripped of whitespace from its end (right side).
 * If the end of str has no whitespace, a new string is still returned (essentially a copy of str),
 * with no exception being thrown.
 * @param  text
 * @param chars
 * @returns {*|string}
 */
const trimEnd = (text, chars) => (text ? text.trimEnd(text, chars) : "");

/**
 * The trimStart() method removes whitespace from the beginning of a string.
 * trimLeft() is an alias of this method.
 * A new string representing str stripped of whitespace from its beginning (left side).
 * If the beginning of str has no whitespace,
 * a new string is still returned (essentially a copy of str), with no exception being thrown.
 * @param text
 * @param chars
 * @returns {*|string}
 */
const trimStart = (text, chars) => (text ? text.trimStart(text, chars) : "");

/**
 * Trim Left using RegExp on charList array
 * @param {string} text
 * @param {string[]} charList
 * @returns {string}
 */
const trimLeft = (text, charList) =>
  text ? text.replace(new RegExp(`^[${charList}]+`), "") : "";

/**
 * slugify, remove invalid chars, collapse whitespace and replace by -, collapse dashes and replace by -
 * @param {string} value
 * @returns {string}
 */
const slugify = value =>
  value
    ? value
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes and replace by -
    : "";

/**
 * this method removes alphabets from a string
 * Returns a string containing only numbers
 * @param {string} text
 * @returns {string}
 */
const removeNonDigits = text => (text ? text.replace(/\D/g, "") : text);

/**
 * The split() method divides a String into an ordered list of substrings,
 * puts these substrings into an array, and returns the array.
 * The division is done by searching for a pattern; where the pattern is
 * provided as the first parameter in the method's call.
 * Returns: An Array of strings, split at each point where the
 * separator occurs in the given string. Quick and suitable for relatively short strings,
 * because it uses intermediate array
 * @param {string} text
 * @param {string} stringToFind
 * @returns {number}
 */
const substringCountQuick = (text, stringToFind) =>
  text ? text.split(stringToFind).length : 0;

/**
 *The match() method retrieves the result of matching a string against a regular expression
 * Returns:
 * An Array whose contents depend on the presence or absence of the global (g) flag,
 * or null if no matches are found. If the g flag is used, all results matching the
 * complete regular expression will be returned, but capturing groups will not.
 * if the g flag is not used, only the first complete match and its related
 * capturing groups are returned. In this case, the returned item will have
 * additional properties
 * groups
 * An object of named capturing groups whose keys are the moduleNames and values are the
 * capturing groups or undefined if no named capturing groups were defined.
 * index
 * The index of the search at which the result was found.
 * input
 * A copy of the search string
 * @param  {string} text
 * @param  {string} stringToFind
 * @returns {number}
 */
// slower but suitable for very long strings, because it uses RegEx
const substringCount = (text, stringToFind) =>
  text ? (text.match(new RegExp(stringToFind, "g")) || []).length : 0;

/**
 * The trim() method removes whitespace from both ends of a string.
 * Whitespace in this context is all the whitespace characters
 * (space, tab, no-break space, etc.) and all the line terminator
 * characters (LF, CR, etc.).
 * Returns: A new string representing str stripped of whitespace from
 * both its beginning and end.If neither the beginning or end of str
 * has any whitespace, a new string is still
 * returned (essentially a copy of str), with no exception being thrown.
 * To return a new string with whitespace trimmed from just one end,
 * use trimStart() or trimEnd().
 * @param {any} value
 * @returns {string|string}
 */
const trimSafe = value => (value ? _.trim(value) : "");

/**
 * The toLowerCase() method returns the calling string value converted to lower case.
 * Returns: A new string representing the calling string converted to lower case.
 * @param {string} value
 * @returns {string|string}
 */
const toLowerCaseSafe = value => (value ? _.toLower(value) : "");

/**
 * The toUpperCase() method returns the calling string value
 * converted to uppercase (the value will be converted to a string if it isn't one).
 * Returns: A new string representing the calling string converted to upper case.
 * @param value
 * @returns {string|string}
 */
const toUpperCaseSafe = value => (value ? _.toUpper(value) : "");

/**
 * is Valid Index
 * @param {number} index
 * @param {number} length
 * @returns {boolean}
 */
const isValidIndex = (index, length) => index >= 0 && index < length;

/**
 * checks it value passed in this method is true, if not then it returns false
 * @param {any} value
 * @returns {boolean|boolean}
 */
const getBooleanValue = value => (value ? Boolean(value) : false);

/**
 * get Numeric Value
 * @param value
 * @returns {number|number}
 */
const getNumericValue = value => (value ? Number(value) : 0);

/**
 * Checks if value is classified as an Array object.
 * Returns true if value is correctly classified, else false.
 * @param {any} value
 * @return {boolean}
 */
const isArray = value => value && _.isArray(value);

/**
 * This method returns the length of an array
 * @param array
 * @returns {*|number}
 */
const itemCount = array => (isArray(array) ? array.length : 0);

/**
 * This methods remove empty values: "", null, undefined, and 0 from array
 * @param {Array} array
 * @returns {*|*[]}
 */
const removeEmptyValues = array =>
  isArray(array)
    ? array.filter(function(el) {
        return el;
      })
    : [];

/**
 * The split() method divides a String into an ordered list of substrings,
 * puts these substrings into an array, and returns the array.
 * The division is done by searching for a pattern;
 * where the pattern is provided as the first parameter in the method's call.
 * Returns:An Array of strings, split at each point where the separator occurs
 * in the given string.
 * @param {string} value
 * @returns {string []}
 */
const splitSafe = value => (value ? removeEmptyValues(value.split(" ")) : []);

/**
 * Validate if object passed is of type object or not
 * @param {any} value
 * @returns {boolean}
 */
const isObjectValid = value => _.isObject(value);

/**
 * checks if environment is development or not
 * @type {boolean}
 */
const isDevelopment = toLowerCaseSafe(process.env.NODE_ENV) === "development";

/**
 * if environment is development then shows message and parameters
 * with date on console
 * @param {string} message
 * @param params
 */
const consoleLog = (message, params) => {
  if (isDevelopment) {
    console.log(`${new Date().toISOString()} ${message}`, params || "");
  }
};

/**
 * Checks if the date passed in is current or previous date
 * @param value
 * @returns {boolean}
 */
const isTodayOrBefore = value => {
  if (!isValidDate(value)) {
    return false;
  }

  const dateValue = new Date(value);
  const date = new Date();

  return dateValue <= date;
};

/**
 * is date1 earlier than date2?
 * @param {string} date1
 * @param {string} date2
 * @return {boolean} true if date1 is Earlier then date2
 */
const isEarlierThan = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) return false;

  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return d1 < d2;
};

/**
 * Checks if value is classified as a Date value
 * @param value The value to check
 * @return {boolean} true if value is correctly classified, else false
 */
const isValidDate = value => {
  // It will return NaN if it cannot parse the supplied date string
  const timestamp = Date.parse(value?.toString());

  return !isNaN(timestamp);
};

/**
 * Checks if value is classified as a Date object
 * @param value The value to check
 * @return {boolean} true if value is correctly classified, else false
 */
const isValidDateObject = value => {
  return _.isDate(value);
};

/**
 * Ensure Valid Date (In the case that date is null/undefined)
 * @param {Date} value
 * @returns {Date|*}
 */
const ensureValidDate = value =>
  isValidDate(value) ? new Date(value) : new Date();

/**
 * get ISO 8601 date strings (YYYY-MM-DD),
 * Intended for v-date-picker
 * @param {Date} value
 * @returns {string|undefined}
 */
const dateISOString = value => {
  try {
    const isoDate = new Date(value).toISOString();

    return (isoDate?.length ?? 0) >= 10 ? isoDate.substr(0, 10) : isoDate ?? "";
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

/**
 * Is Odd Number
 * Returns 1 if odd number and 0 if number is even
 * @param {number} number
 * @returns {number}
 */
const isOddNumber = number => number % 2;

/**
 * Get Name Initials
 * @param {String|string} names
 * @returns {string}
 */
const getNameInitials = names => {
  if (!names) return "";

  const words = names.split(" ");

  if (words.length >= 2) {
    return `${words[0].substring(0, 1)}${words[1].substring(0, 1)}`;
  }

  return names.length >= 2 ? names.substring(0, 2) : names;
};

/**
 * Determines whether a string value is equal to 'true'
 * @param {string} value
 * @return {boolean}
 */
const isStringValueTrue = value => toLowerCaseSafe(value) === "true";

/**
 * Create Blob
 * @param data
 * @return {Blob}
 */
function createBlob(data) {
  return new Blob([data], {
    // The MIME type  of the data that will be stored into the blob
    type: data?.type ?? ""
  });
}

/**
 * determines is an object Blob
 * @param {Object} object
 * @return {boolean} returns true if specified object is Blob
 */
const isBlob = object => object?.constructor?.name?.toLowerCase() === "blob";

/**
 * Find Max number
 * @param {number[]}  numbers
 * @return {number}
 */
const maxNumber = numbers => {
  if (!isArray(numbers) || numbers.length === 0) {
    return 0;
  }

  return Math.max.apply(null, numbers ?? []);
};

export {
  cloneDeep,
  deepCopyArray,
  trimRight,
  trimLeft,
  trimEnd,
  trimStart,
  slugify,
  removeNonDigits,
  substringCountQuick,
  substringCount,
  isValidIndex,
  isEmpty,
  trimSafe,
  toLowerCaseSafe,
  toUpperCaseSafe,
  getBooleanValue,
  getNumericValue,
  itemCount,
  removeEmptyValues,
  splitSafe,
  isObjectValid,
  consoleLog,
  isTodayOrBefore,
  isArray,
  ensureValidDate,
  dateISOString,
  isOddNumber,
  getNameInitials,
  isNumber,
  isStringValueTrue,
  createBlob,
  maxNumber,
  isValidDate,
  isValidDateObject,
  isBlob,
  isEarlierThan
};

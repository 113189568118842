// import { getPlatform, isMobile } from "@/services/dom/windowService";

/**
 * indicating whether Scanning is supported by current platform
 * @return {boolean} returns boolean true, if Scanning is supported by current platform
 */
const supportedScanning = () => {
  return true;

  //
  // Deprecated: This feature is no longer recommended.
  //
  //return !isMobile() && getPlatform()?.toLowerCase() === "windows";
};

export { supportedScanning };

import { toLowerCaseSafe } from "@/utils";

/**
 * Questys Field Name
 * @type {Readonly<{sequence: string, immutable: string, requiredForAutoFiling: string, name: string, unselectable: string, id: string, label: string}>}
 */
const fieldName = Object.freeze({
  id: "id",
  name: "name",
  label: "label",
  immutable: "immutable",
  requiredForAutoFiling: "requiredForAutoFiling",
  sequence: "sequence",
  unselectable: "unselectable"
});

/**
 * Questys Field Label
 * @type {Readonly<{sequence: string, immutable: string, requiredForAutoFiling: string, name: string, unselectable: string, id: string, label: string}>}
 */
const fieldLabel = Object.freeze({
  id: "Id",
  name: "Name",
  label: "Label",
  immutable: "Immutable",
  requiredForAutoFiling: "Required For Auto Filing",
  sequence: "Sequence",
  unselectable: "Unselectable"
});

/**
 * Search Operator Type
 * @type {Readonly<{equal: number, contains: number, equalOrGreaterThan: number, like: number, lessThan: number, equalOrLessThan: number, on: number, greaterThan: number}>}
 */
const searchOperator = Object.freeze({
  equal: 0,
  on: 1,
  like: 2,
  contains: 3,
  greaterThan: 4,
  equalOrGreaterThan: 5,
  lessThan: 6,
  equalOrLessThan: 7
});

/**
 * Search Operators
 * @type {{description: string, operator: string}[]}
 */
const searchOperators = Object.freeze([
  { id: searchOperator.equal, description: "Equal", operator: "=" },
  { id: searchOperator.on, description: "On", operator: "ON" },
  { id: searchOperator.like, description: "Like", operator: "*" },
  {
    id: searchOperator.contains,
    description: "Contains",
    operator: "CONTAINS"
  },
  {
    id: searchOperator.greaterThan,
    description: "Greater Than",
    operator: ">"
  },
  {
    id: searchOperator.equalOrGreaterThan,
    description: "Equal Or Greater Than",
    operator: ">="
  },
  { id: searchOperator.lessThan, description: "Less Than", operator: "<" },
  {
    id: searchOperator.equalOrLessThan,
    description: "Equal Or Less Than",
    operator: "<="
  }
]);

/**
 * find search Operator by searchOperator Id
 * @param {number} id searchOperator Id
 * @return {{id: number, name: string, flags: number, label: string, searchOperators: {operator: string, description: string}[]}}
 */
const findSearchOperator = id => {
  return searchOperators.find(el => el.id === id);
};

const rangeFromOperators = Object.freeze([
  searchOperators[searchOperator.greaterThan],
  searchOperators[searchOperator.equalOrGreaterThan]
]);

const isRangeFromOperator = operator =>
  rangeFromOperators.filter(el => el.operator === operator).length > 0;

const rangeToOperators = Object.freeze([
  searchOperators[searchOperator.lessThan],
  searchOperators[searchOperator.equalOrLessThan]
]);

const literalSearchOperators = Object.freeze([
  searchOperators[searchOperator.equal],
  searchOperators[searchOperator.like]
]);

const textSearchOperators = Object.freeze([
  searchOperators[searchOperator.contains]
]);

const numericSearchOperators = Object.freeze([
  searchOperators[searchOperator.equal],
  searchOperators[searchOperator.greaterThan],
  searchOperators[searchOperator.equalOrGreaterThan],
  searchOperators[searchOperator.lessThan],
  searchOperators[searchOperator.equalOrLessThan]
]);

const dateSearchOperators = Object.freeze([
  searchOperators[searchOperator.on],
  searchOperators[searchOperator.greaterThan],
  searchOperators[searchOperator.equalOrGreaterThan],
  searchOperators[searchOperator.lessThan],
  searchOperators[searchOperator.equalOrLessThan]
]);

const bitSearchOperators = Object.freeze([
  searchOperators[searchOperator.equal]
]);

/**
 * Get Questys Field Data Type
 * @type {Readonly<{CURRENCY: number, DATE: number, NUMBER: number, LITERAL: number, TEXT: number, BIT: number, INTEGER: number}>}
 */
const fieldType = Object.freeze({
  TEXT: 0,
  BIT: 1,
  CURRENCY: 2,
  DATE: 3,
  INTEGER: 4,
  LITERAL: 5,
  NUMBER: 6
});

/**
 * Determines whether Questys Field Data Type is numeric
 * @param {number} type
 * @return {boolean}
 */
const isNumericFieldType = type => {
  return (
    type === fieldType.NUMBER ||
    type === fieldType.INTEGER ||
    type === fieldType.CURRENCY
  );
};

/**
 * Questys Field Data Types
 * @type {({id:number, name:string, flags:number, label:string, searchOperators:[{operator:string, description:string}]})[]}
 */
const fieldTypes = Object.freeze([
  {
    id: fieldType.TEXT,
    name: "TEXT",
    label: "Text",
    flags: 0,
    searchOperators: textSearchOperators
  },
  {
    id: fieldType.BIT,
    name: "BIT",
    label: "Bit",
    flags: 0,
    searchOperators: bitSearchOperators
  },
  {
    id: fieldType.CURRENCY,
    name: "CURRENCY",
    label: "Currency",
    flags: 0,
    searchOperators: numericSearchOperators
  },
  {
    id: fieldType.DATE,
    name: "DATE",
    label: "Date",
    flags: 0,
    searchOperators: dateSearchOperators
  },
  {
    id: fieldType.INTEGER,
    name: "INTEGER",
    label: "Integer",
    flags: 0,
    searchOperators: numericSearchOperators
  },
  {
    id: fieldType.LITERAL,
    name: "LITERAL",
    label: "Literal",
    flags: 0,
    searchOperators: literalSearchOperators
  },
  {
    id: fieldType.NUMBER,
    name: "NUMBER",
    label: "Number",
    flags: 0,
    searchOperators: numericSearchOperators
  }
]);

/**
 * find Field Type
 * @param {Number|number} fieldTypeId
 * @return {{id: number, name: string, flags: number, label: string, searchOperators: {operator: string, description: string}[]}}
 */
const findFieldType = fieldTypeId => {
  return fieldTypes.find(el => el.id === fieldTypeId);
};

/**
 * Is Field Range Searchable
 * @param {fieldType: number} fieldDataType
 * @return {boolean}
 */
const isRangeSearchable = fieldDataType =>
  fieldDataType === fieldType.INTEGER ||
  fieldDataType === fieldType.NUMBER ||
  fieldDataType === fieldType.CURRENCY ||
  fieldDataType === fieldType.DATE;

const defaultSearchOperator = fieldDataType => {
  if (fieldDataType === fieldType.DATE) {
    return searchOperators[searchOperator.on].operator;
  } else if (fieldDataType === fieldType.TEXT) {
    return searchOperators[searchOperator.contains].operator;
  }

  return searchOperators[searchOperator.equal].operator;
};

const defaultSearchRangeToOperator =
  searchOperators[searchOperator.lessThan].operator;

/**
 * Get Maximum Text-Memo field character count that will be loaded by default when getRecord() is called
 * @type {number} Maximum Text-Memo field character count
 */
const maxMemoFieldLength = 4096;

const fieldOperationName = Object.freeze({
  none: "None",
  view: "View",
  update: "Update",
  add: "Add",
  delete: "Delete"
});

/**
 * is Allowed current user to Modify Field
 * @param {{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean, unselectable:boolean, operations: {name:string, allowed:boolean, valid:boolean}[]}} field
 * @return {boolean}
 */
const isAllowedModifyField = field =>
  (findFieldOperation(field?.operations, fieldOperationName.view)?.allowed ??
    false) &&
  (findFieldOperation(field?.operations, fieldOperationName.update)?.allowed ??
    false) &&
  (findFieldOperation(field?.operations, fieldOperationName.add)?.allowed ??
    false) &&
  (findFieldOperation(field?.operations, fieldOperationName.delete)?.allowed ??
    false);

/**
 * is Allowed current user to view/access Field
 * @param {{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean, unselectable:boolean, operations: {name:string, allowed:boolean, valid:boolean}[]}} field
 * @return {boolean} return true if current user is Allowed to view/access Field
 */
const isAllowedViewField = field =>
  findFieldOperation(field?.operations ?? [], fieldOperationName.view)
    ?.allowed ?? false;

/**
 * is Allowed Field Operation
 * @param {{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean, unselectable:boolean, operations: {name:string, allowed:boolean, valid:boolean}[]}} field
 * @param {string} operation Field Operation (View, Update, Add, Delete)
 * @return {boolean} returns true if Field Operation is Allowed
 */
const isAllowedFieldOperation = (field, operation) => {
  return (
    findFieldOperation(field?.operations ?? [], operation)?.allowed ?? false
  );
};

/**
 * is Valid Field Operation
 * @param {{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean, unselectable:boolean, operations: {name:string, allowed:boolean, valid:boolean}[]}} field
 * @param {string} operation Field Operation (View, Update, Add, Delete)
 * @return {boolean} returns true if Field Operation is Valid
 */
const isValidFieldOperation = (field, operation) => {
  return findFieldOperation(field?.operations ?? [], operation)?.valid ?? false;
};

/**
 * find Field Operation
 * @param {{name:string, allowed:boolean, valid:boolean}[]} operations field operations
 * @param {string} operation Field Operations
 * @return {{name:string, allowed:boolean, valid:boolean}} return found Field Operation
 */
const findFieldOperation = (operations, operation) => {
  return operations?.find(
    op => toLowerCaseSafe(op.name) === toLowerCaseSafe(operation)
  );
};

export {
  fieldName,
  fieldLabel,
  fieldTypes,
  fieldType,
  literalSearchOperators,
  textSearchOperators,
  numericSearchOperators,
  dateSearchOperators,
  bitSearchOperators,
  searchOperators,
  searchOperator,
  rangeFromOperators,
  rangeToOperators,
  defaultSearchRangeToOperator,
  maxMemoFieldLength,
  fieldOperationName,
  isRangeFromOperator,
  isRangeSearchable,
  defaultSearchOperator,
  isNumericFieldType,
  findFieldType,
  findSearchOperator,
  isAllowedFieldOperation,
  isValidFieldOperation,
  isAllowedModifyField,
  isAllowedViewField
};

// model
import {
  fieldType,
  findFieldType,
  rangeToOperators
} from "../field/fieldModel";

// design
import { Color, colorTheme } from "@/design/colors/Color";
import { colorMD, variantNumber, variantType } from "@/design/colors/mdColors";

import {
  iconAgendaItem,
  iconAgendaSection,
  iconCopy,
  iconCut,
  iconDownload,
  iconDraft,
  iconEvent,
  iconFile,
  iconFileCheckIn,
  iconFileCheckOut,
  iconFileDetails,
  iconFileRelease,
  iconFolder,
  iconJumpToFolder,
  iconJumpToOriginal,
  iconLock,
  iconMovePage,
  iconPaste,
  iconPasteShortcut,
  iconPrint,
  iconReadOnly,
  iconRecord,
  iconReindex,
  iconTrash,
  iconUnLock,
  iconEmail,
  iconEdit,
  iconFileCheckOutForSign,
  iconDocument,
  iconInfo,
  iconCheck,
  iconCancel,
  iconRestore,
  iconSubmit,
  iconRecall
} from "@/design/icon/iconConst";

/**
 * Field Name
 * @type {Readonly<{owner: string, isLink: string, recordType: string, stateId: string, flags: string, isDraft: string, creationDate: string, parentId: string, modificationDate: string, isComposite: string, isReadOnly: string, recordTypeId: string, isDeleted: string, createdBy: string, children: string, isLocked: string, name: string, id: string, state: string, categoryId: string}>}
 */
const fieldName = Object.freeze({
  id: "id",
  name: "name",
  categoryId: "categoryId",
  parentId: "parentId",
  recordTypeId: "recordTypeId",
  recordType: "recordType",
  stateId: "stateId",
  state: "state",
  createdBy: "createdBy",
  creationDate: "creationDate",
  owner: "owner",
  children: "children",
  fieldValues: "fieldValues",
  flags: "flags",
  isComposite: "isComposite",
  isDeleted: "isDeleted",
  isDraft: "isDraft",
  isLink: "isLink",
  isLocked: "isLocked",
  isReadOnly: "isReadOnly",
  modificationDate: "modificationDate",
  pageCount: "pageCount",
  updatedBy: "updatedBy",
  extension: "extension",
  version: "version"
});

/**
 * Field Label
 * @type {Readonly<{owner: string, createdBy: string, recordType: string, name: string, id: string, state: string, creationDate: string, categoryId: string}>}
 */
const fieldLabel = Object.freeze({
  id: "Id",
  name: "Name",
  categoryId: "Category Id",
  createdBy: "Created By",
  creationDate: "Creation Date",
  modificationDate: "Modification Date",
  modifiedBy: "Modified By",
  owner: "Owner",
  recordType: "Type",
  state: "State",
  pageCount: "Page Count",
  extension: "Extension",
  version: "Version"
});

/**
 * record Type which represents Questys.Entities.RecordTypes
 * @type {Readonly<{ITEM: number, RECORD: number, DOCUMENT: number, MEETING: number, FILE: number, FOLDER: number, SECTION: number}>}
 */
const recordType = Object.freeze({
  FOLDER: 0,
  FILE: 1,
  RECORD: 2,
  MEETING: 6,
  SECTION: 7,
  ITEM: 8,
  DOCUMENT: 12
});

/**
 * record Event Type
 * @type {Readonly<{AGENDA_MEETING: number, DOCUMENT_ASSEMBLY: number, NONE: number}>}
 */
const recordEventType = Object.freeze({
  NONE: 0,
  AGENDA_MEETING: 1,
  DOCUMENT_ASSEMBLY: 2
});

/**
 * Questys record Types
 * @type {({name: string, icon: string, id: number}|{name: string, icon: string, id: number}|{name: string, icon: string, id: number}|{name: string, icon: string, id: number}|{name: string, icon: string, id: number})[]}
 */
const recordTypes = Object.freeze([
  { id: recordType.FOLDER, name: "Folder", icon: iconFolder },
  { id: recordType.FILE, name: "File", icon: iconFile },
  { id: recordType.RECORD, name: "Record", icon: iconRecord },
  { id: recordType.MEETING, name: "Meeting", icon: iconEvent },
  { id: recordType.SECTION, name: "Section", icon: iconAgendaSection },
  { id: recordType.ITEM, name: "Item", icon: iconAgendaItem },
  //
  // Compound document - keep it as folder - the blue color will keep it backwards compatible
  // and the same folder icon will make a Compound document almost as Folder
  //
  { id: recordType.DOCUMENT, name: "Document", icon: iconDocument }
]);

/**
 * find Record Type Icon
 * @param {number} id Record Type id
 * @return {string} Record Type Icon name
 */
const findRecordTypeIcon = id => {
  return findRecordType(id)?.icon ?? iconRecord;
};

/**
 * find Record Type
 * @param {number} id record Type id
 * @return {{name: string, icon: string, id: number}}
 */
const findRecordType = id => {
  return recordTypes.find(rt => rt.id === id);
};

/**
 * record State
 * @type {Readonly<{checkedOut: number, new: number, inProgress: number, defeated: number, checkedIn: number, notLocked: number, created: number, dispositionPending: number, readOnly: number, completed: number, published: number, unassigned: number, dispositionApprovalPending: number, posted: number, archived: number, deleted: number, submitted: number, notReadOnly: number, modified: number, passed: number, locked: number}>}
 */
const recordState = Object.freeze({
  new: 0,
  created: 1,
  modified: 2,
  checkedOut: 3,
  checkedIn: 4,
  locked: 5,
  notLocked: -5,
  dispositionApprovalPending: 6,
  dispositionPending: 7,
  deleted: 8,
  inProgress: 9,
  submitted: 10,
  passed: 11,
  defeated: 12,
  completed: 13,
  archived: 14,
  posted: 15,
  published: 16,
  unassigned: 17,
  readOnly: 18,
  notReadOnly: 19
});

/**
 * Available Record States per Record Type
 * @param {Number} type
 * @return {({name: string, id: number}|{name: string, id: number}|{name: string, id: number}|{name: string, id: number}|{name: string, id: number})[]}
 */
const recordTypeStates = type => {
  switch (type) {
    case recordType.FOLDER:
    case recordType.SECTION:
    case recordType.DOCUMENT:
    case recordType.RECORD: {
      return recordStates.filter(
        el =>
          el.id === recordState.created ||
          el.id === recordState.modified ||
          el.id === recordState.locked ||
          el.id === recordState.notLocked ||
          el.id === recordState.readOnly ||
          el.id === recordState.notReadOnly ||
          el.id === recordState.deleted
      );
    }
    case recordType.MEETING: {
      return recordStates.filter(
        el =>
          el.id === recordState.created ||
          el.id === recordState.modified ||
          el.id === recordState.locked ||
          el.id === recordState.notLocked ||
          el.id === recordState.readOnly ||
          el.id === recordState.notReadOnly ||
          el.id === recordState.posted ||
          el.id === recordState.published ||
          el.id === recordState.deleted
      );
    }
    case recordType.ITEM: {
      return recordStates.filter(
        el =>
          el.id === recordState.created ||
          el.id === recordState.modified ||
          el.id === recordState.locked ||
          el.id === recordState.notLocked ||
          el.id === recordState.readOnly ||
          el.id === recordState.notReadOnly ||
          el.id === recordState.inProgress ||
          el.id === recordState.passed ||
          el.id === recordState.completed ||
          el.id === recordState.unassigned ||
          el.id === recordState.submitted ||
          el.id === recordState.deleted
      );
    }
    case recordType.FILE: {
      return recordStates.filter(
        el =>
          el.id === recordState.created ||
          el.id === recordState.modified ||
          el.id === recordState.locked ||
          el.id === recordState.notLocked ||
          el.id === recordState.readOnly ||
          el.id === recordState.notReadOnly ||
          el.id === recordState.checkedOut ||
          el.id === recordState.checkedIn ||
          el.id === recordState.archived ||
          el.id === recordState.deleted
      );
    }
  }
};

const recordStates = Object.freeze([
  { id: recordState.new, name: "New" },
  { id: recordState.created, name: "Created" },
  { id: recordState.modified, name: "Modified" },
  { id: recordState.checkedOut, name: "Checked Out" },
  { id: recordState.checkedIn, name: "Checked In" },
  { id: recordState.locked, name: "Locked" },
  { id: recordState.notLocked, name: "Not Locked" },
  {
    id: recordState.dispositionApprovalPending,
    name: "Disposition Approval Pending"
  },
  { id: recordState.dispositionPending, name: "Disposition Pending" },
  { id: recordState.deleted, name: "Deleted" },
  { id: recordState.inProgress, name: "In Progress" },
  { id: recordState.submitted, name: "Submitted" },
  { id: recordState.passed, name: "Passed" },
  { id: recordState.defeated, name: "Defeated" },
  { id: recordState.completed, name: "Completed" },
  { id: recordState.archived, name: "Archived" },
  { id: recordState.posted, name: "Posted" },
  { id: recordState.published, name: "Published" },
  { id: recordState.unassigned, name: "Unassigned" },
  { id: recordState.readOnly, name: "Read Only" },
  { id: recordState.notReadOnly, name: "Not Read Only" }
]);

/**
 * find Record State
 * @param {number} id record State id
 * @return {{id:number, name:string}|undefined}
 */
const findRecordState = id => {
  return recordStates.find(rt => rt.id === id);
};

/**
 * record Mode
 * @type {Readonly<{new: number, view: number, edit: number, newProject: number}>}
 */
const recordMode = Object.freeze({
  view: 0,
  new: 1,
  edit: 2,
  newProject: 3
});

/**
 * record Modes
 * @type {({mode: number, name: string}|{mode: number, name: string}|{mode: number, name: string})[]}
 */
const recordModes = Object.freeze([
  { mode: recordMode.view, name: "View" },
  { mode: recordMode.new, name: "New" },
  { mode: recordMode.edit, name: "Edit" },
  { mode: recordMode.newProject, name: "New Project" }
]);

/**
 * find Record Mode
 * @param mode Record Mode
 * @return {{mode: number, name: string}}
 */
const findRecordMode = mode => {
  return recordModes.find(m => m.mode === mode);
};

/**
 * searchable Property
 * @type {Readonly<{owner: string, creator: string, extension: string, createdDateFrom: string, createdDateTo: string, stateId: string, childrenOnly: string, version: string, modifiedDateTo: string, pages: string, name: string, modifiedDateFrom: string, typeId: string, id: string, categoryId: string}>}
 */
const searchableProperty = Object.freeze({
  id: "Id",
  name: "Name",
  owner: "Owner",
  creator: "Creator",
  createdDateFrom: "CreatedDateFrom",
  createdDateTo: "CreatedDateTo",
  modifiedDateFrom: "ModifiedDateFrom",
  modifiedDateTo: "ModifiedDateTo",
  categoryId: "CategoryId",
  stateId: "StateId",
  typeId: "TypeId",
  extension: "Extension",
  version: "Version",
  pages: "Pages",
  childrenOnly: "ChildrenOnly"
});

const recordOperation = Object.freeze({
  None: "None",
  Read: "Read",
  Update: "Update",
  Create: "Create",
  Delete: "Delete",
  Purge: "Purge",
  CheckIn: "CheckIn",
  CheckOut: "CheckOut",
  CheckOutForSign: "DigitalSigning",
  Lock: "Lock",
  Unlock: "Unlock",
  Release: "Release",
  Move: "Move",
  Export: "Export",
  Download: "Download",
  Email: "Email",
  Print: "Print",
  Notify: "Notify",
  AccessDraft: "AccessDraft",
  AccessHidden: "AccessHidden",
  AccessVersion: "AccessVersion",
  ShortcutCreate: "ShortcutCreate",
  RunImageRedaction: "RunImageRedaction",
  RunImageAnnotation: "RunImageAnnotation",
  SetDraft: "SetDraft",
  SetReadOnly: "SetReadOnly",
  LegalHoldManagement: "LegalHoldManagement",
  RetentionManagement: "RetentionManagement",
  Reindex: "Reindex",
  DigitalSigning: "DigitalSigning",
  NonOwnerAccess: "NonOwnerAccess",
  ViewDetails: "ViewDetails",
  Restore: "Undelete"
});

/**
 * record Operations
 * @type {{name: string, icon: string, label: string, tooltip: String}[]}
 */
const recordOperations = Object.freeze([
  {
    name: recordOperation.Lock,
    label: recordOperation.Lock,
    icon: iconLock,
    tooltip: recordOperation.Lock
  },
  {
    name: recordOperation.Unlock,
    label: `Unlock`,
    icon: iconUnLock,
    tooltip: `Unlock`
  },
  {
    name: recordOperation.SetReadOnly,
    label: "Read Only",
    icon: iconReadOnly,
    tooltip: "Read Only"
  },
  {
    name: recordOperation.SetDraft,
    label: "Draft",
    icon: iconDraft,
    tooltip: "Draft"
  },
  {
    name: recordOperation.Download,
    label: recordOperation.Download,
    icon: iconDownload,
    tooltip: recordOperation.Download
  },
  {
    name: recordOperation.Print,
    label: recordOperation.Print,
    icon: iconPrint,
    tooltip: recordOperation.Print
  },
  {
    name: recordOperation.Email,
    label: recordOperation.Email,
    icon: iconEmail,
    tooltip: recordOperation.Email
  },
  {
    name: recordOperation.Delete,
    label: recordOperation.Delete,
    icon: iconTrash,
    tooltip: recordOperation.Delete
  },
  {
    name: recordOperation.CheckOut,
    label: "Check Out",
    icon: iconFileCheckOut,
    tooltip: "Check Out"
  },
  {
    name: recordOperation.CheckOutForSign,
    label: "Check Out For Sign",
    icon: iconFileCheckOutForSign,
    tooltip: "Check Out For Sign"
  },
  {
    name: recordOperation.Release,
    label: recordOperation.Release,
    icon: iconFileRelease,
    tooltip: recordOperation.Release
  },
  {
    name: recordOperation.ViewDetails,
    label: "Details",
    icon: iconFileDetails,
    tooltip: "Details"
  },
  {
    name: recordOperation.CheckIn,
    label: recordOperation.CheckIn,
    icon: iconFileCheckIn,
    tooltip: recordOperation.CheckIn
  },
  {
    name: recordOperation.Update,
    label: "Edit",
    icon: iconEdit,
    tooltip: "Edit"
  },
  {
    name: recordOperation.Move,
    label: recordOperation.Move,
    icon: iconMovePage,
    tooltip: recordOperation.Move
  },
  {
    name: recordOperation.Reindex,
    label: recordOperation.Reindex,
    icon: iconReindex,
    tooltip: recordOperation.Reindex
  },
  {
    name: recordOperation.Purge,
    label: recordOperation.Purge,
    icon: iconTrash,
    tooltip: recordOperation.Purge
  },
  {
    name: recordOperation.Restore,
    label: "Restore",
    icon: iconRestore,
    tooltip: "Restore"
  }
]);

/**
 * find Record Operation
 * @param name record operation name
 * @return {{name: string, icon: string, label: string, tooltip: string}}
 */
const findRecordOperation = name => {
  return recordOperations?.find(el => el?.name === name);
};

const searchableProperties = Object.freeze([
  {
    name: searchableProperty.id,
    label: "Id",
    fieldDataType: fieldType.INTEGER,
    searchOperators: findFieldType(fieldType.INTEGER)?.searchOperators ?? []
  },
  {
    name: searchableProperty.name,
    label: "Record Name",
    fieldDataType: fieldType.LITERAL,
    searchOperators: findFieldType(fieldType.LITERAL)?.searchOperators ?? []
  },
  {
    name: searchableProperty.owner,
    label: "Owner",
    fieldDataType: fieldType.LITERAL,
    searchOperators:
      findFieldType(fieldType.LITERAL)?.searchOperators?.filter(
        el => el.operator === "="
      ) ?? []
  },
  {
    name: searchableProperty.creator,
    label: "Creator",
    fieldDataType: fieldType.LITERAL,
    searchOperators:
      findFieldType(fieldType.LITERAL)?.searchOperators?.filter(
        el => el.operator === "="
      ) ?? []
  },
  {
    name: searchableProperty.stateId,
    label: "State",
    fieldDataType: fieldType.INTEGER,
    searchOperators:
      findFieldType(fieldType.INTEGER)?.searchOperators?.filter(
        el => el.operator === "="
      ) ?? []
  },
  {
    name: searchableProperty.typeId,
    label: "Type",
    fieldDataType: fieldType.INTEGER,
    searchOperators:
      findFieldType(fieldType.INTEGER)?.searchOperators?.filter(
        el => el.operator === "="
      ) ?? []
  },
  {
    name: searchableProperty.createdDateFrom,
    label: "Created",
    fieldDataType: fieldType.DATE,
    searchOperators: findFieldType(fieldType.DATE)?.searchOperators ?? []
  },
  {
    name: searchableProperty.createdDateTo,
    label: "Created",
    fieldDataType: fieldType.DATE,
    searchOperators: rangeToOperators
  },
  {
    name: searchableProperty.modifiedDateFrom,
    label: "Modified",
    fieldDataType: fieldType.DATE,
    searchOperators: findFieldType(fieldType.DATE)?.searchOperators ?? []
  },
  {
    name: searchableProperty.modifiedDateTo,
    label: "Modified",
    fieldDataType: fieldType.DATE,
    searchOperators: rangeToOperators
  },
  {
    name: searchableProperty.extension,
    label: "Extension",
    fieldDataType: fieldType.LITERAL,
    searchOperators: findFieldType(fieldType.LITERAL)?.searchOperators ?? []
  },
  {
    name: searchableProperty.version,
    label: "Version",
    fieldDataType: fieldType.INTEGER,
    searchOperators: findFieldType(fieldType.INTEGER)?.searchOperators ?? []
  },
  {
    name: searchableProperty.pages,
    label: "Pages",
    fieldDataType: fieldType.INTEGER,
    searchOperators: findFieldType(fieldType.INTEGER)?.searchOperators ?? []
  },
  {
    name: searchableProperty.categoryId,
    label: "Category Id",
    fieldDataType: fieldType.INTEGER,
    searchOperators:
      findFieldType(fieldType.INTEGER)?.searchOperators?.filter(
        el => el.operator === "="
      ) ?? []
  },
  {
    name: searchableProperty.childrenOnly,
    label: "Child Records Only",
    fieldDataType: fieldType.BIT,
    searchOperators:
      findFieldType(fieldType.BIT)?.searchOperators?.filter(
        el => el.operator === "="
      ) ?? []
  }
]);

/**
 * find Searchable Property by Searchable Property name
 * @param {string} name Searchable Property name
 */
const findSearchableProperty = name => {
  return searchableProperties?.find(el => el?.name === name);
};

/**
 * color when Record is ReadOnly
 * @type {Color}
 */
const colorRecordReadOnly = new Color(
  colorMD.red,
  variantType.lighten,
  variantNumber.n2
);
const colorRecordReadOnlyClass = colorRecordReadOnly.getClassColor();

/**
 * color Record Inherited Read-Only
 * @type {Color}
 */
const colorRecordInheritedReadOnly = new Color(
  colorMD.grey,
  variantType.lighten,
  variantNumber.n1
);
const colorRecordInheritedReadOnlyClass = colorRecordInheritedReadOnly.getClassColor();

/**
 * color when Record is ReadOnly
 * @type {Color}
 */
const colorRecordDeleted = new Color(
  colorMD.red,
  variantType.lighten,
  variantNumber.n2
);
const colorRecordDeletedClass = colorRecordDeleted.getClassColor();

/**
 * color when Record is Locked
 * @type {Color}
 */
const colorRecordLocked = new Color(
  colorMD.red,
  variantType.lighten,
  variantNumber.n2
);
const colorRecordLockedClass = colorRecordLocked.getClassColor();

/**
 * Color when Record is New
 * @type {Color}
 */
const colorRecordNew = new Color(
  colorMD.yellow,
  variantType.darken,
  variantNumber.n2
);
const colorRecordNewClass = colorRecordNew.getClassColor();

/**
 * Color when Record is Created
 * @type {Color}
 */
const colorRecordCreated = new Color(
  colorMD.indigo,
  variantType.lighten,
  variantNumber.n2
);
const colorRecordCreatedClass = colorRecordCreated.getClassColor();

/**
 * color when Record is Checked Out
 * @type {Color}
 */
const colorRecordCheckedOut = new Color(
  colorMD.green,
  variantType.lighten,
  variantNumber.n2
);
const colorRecordCheckedOutClass = colorRecordCheckedOut.getClassColor();

/**
 * color Meeting Posted
 * @type {Color}
 */
const colorMeetingPosted = new Color(
  colorMD.blue,
  variantType.lighten,
  variantNumber.n2
);

const colorMeetingPostedClass = colorMeetingPosted.getClassColor();
const colorMeetingPublishedClass = colorRecordCheckedOut.getClassColor();
const colorAgendaItemSubmittedClass = colorRecordCheckedOut.getClassColor();

/**
 * color when Record is Checked In
 * @type {Color}
 */
const colorRecordCheckedIn = new Color(
  colorMD.blue,
  variantType.lighten,
  variantNumber.n3
);
const colorRecordCheckedInClass = colorRecordCheckedIn.getClassColor();

/**
 * Context Menu Record Operation
 * @type {Readonly<{Delete: string, Cut: string, Copy: string, Paste: string, Select: string}>}
 */
const contextMenuRecordOperation = Object.freeze({
  Select: "Select",
  Copy: "Copy",
  Cut: "Cut",
  JumpOriginal: "Go To Original",
  Paste: "Paste",
  Shortcut: "Paste Shortcut",
  Delete: "Delete",
  SubmitItems: "Submit Items",
  RecallItems: "Recall Items"
});

/**
 * Context Menu Record Operations
 * @type {({name: string, icon: string}|{name: string, icon: string}|{name: string, icon: string}|{name: string, icon: string}|{name: string, icon: string})[]}
 */
const contextMenuRecordOperations = Object.freeze([
  { name: contextMenuRecordOperation.Select, icon: iconJumpToFolder },
  { name: contextMenuRecordOperation.Copy, icon: iconCopy },
  { name: contextMenuRecordOperation.Cut, icon: iconCut },
  { name: contextMenuRecordOperation.JumpOriginal, icon: iconJumpToOriginal },
  { name: contextMenuRecordOperation.Paste, icon: iconPaste },
  { name: contextMenuRecordOperation.Shortcut, icon: iconPasteShortcut },
  { name: contextMenuRecordOperation.Delete, icon: iconTrash },
  { name: contextMenuRecordOperation.SubmitItems, icon: iconSubmit },
  { name: contextMenuRecordOperation.RecallItems, icon: iconRecall }
]);

/**
 * find Record Operation
 * @param name record operation name
 * @return {{name: string, icon: string}}
 */
const findContextMenuOperation = name => {
  return contextMenuRecordOperations?.find(el => el?.name === name);
};

/**
 * Batch Record Status
 * @type {Readonly<{Ready: string, Failed: string, Completed: string}>}
 */
const batchRecordStatus = Object.freeze({
  Ready: "Ready",
  Completed: "Completed",
  Failed: "Failed",
  Canceled: "Canceled"
});

/**
 * Batch Record Statuses
 * @type {({name: string, icon: string, iconColor: string}|{name: string, icon: string, iconColor: string}|{name: string, icon: string, iconColor: string}|{name: string, icon: string, iconColor: string})[]}
 */
const batchRecordStatuses = Object.freeze([
  {
    name: batchRecordStatus.Ready,
    icon: iconCheck,
    iconColor: colorTheme.primary
  },
  {
    name: batchRecordStatus.Completed,
    icon: iconCheck,
    iconColor: colorTheme.success
  },
  {
    name: batchRecordStatus.Failed,
    icon: iconInfo,
    iconColor: colorTheme.error
  },
  {
    name: batchRecordStatus.Canceled,
    icon: iconCancel,
    iconColor: colorTheme.warning
  }
]);

/**
 * find Record Operation
 * @param name record operation name
 * @return {{name: string, icon: string, iconColor:string}}
 */
const findBatchRecordStatus = name => {
  return batchRecordStatuses?.find(el => el?.name === name);
};

/**
 * Record Drag/Drop Insert Positions
 * @type {Readonly<{afterSibling: number, firstChild: number, lastChild: number, beforeSibling: number}>}
 */
const treeInsertOptions = Object.freeze({
  firstChild: 0,
  lastChild: 1,
  beforeSibling: 2,
  afterSibling: 3
});

export {
  fieldName,
  fieldLabel,
  recordType,
  recordEventType,
  recordTypes,
  recordState,
  recordStates,
  recordOperation,
  recordOperations,
  searchableProperties,
  searchableProperty,
  colorRecordReadOnlyClass,
  colorRecordDeletedClass,
  colorRecordLockedClass,
  colorRecordCheckedOutClass,
  colorRecordCheckedInClass,
  colorRecordNewClass,
  colorRecordCreatedClass,
  recordMode,
  recordModes,
  colorMeetingPostedClass,
  colorMeetingPublishedClass,
  colorAgendaItemSubmittedClass,
  contextMenuRecordOperation,
  contextMenuRecordOperations,
  colorRecordInheritedReadOnlyClass,
  recordTypeStates,
  findRecordType,
  findRecordTypeIcon,
  findRecordState,
  findRecordMode,
  findSearchableProperty,
  findRecordOperation,
  findContextMenuOperation,
  batchRecordStatus,
  batchRecordStatuses,
  findBatchRecordStatus,
  treeInsertOptions
};

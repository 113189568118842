const iconApplication = "mdi-appModule";
const iconBrowse = "mdi-dots-vertical";
const iconConfigure = "mdi-cog-outline";
const iconLogOut = "mdi-account-arrow-right-outline ";
const iconMenuDown = "mdi-menu-down";
const iconMenu = "mdi-menu";

// Chevron
const iconChevronDown = "mdi-chevron-down";
const iconChevronUp = "mdi-chevron-up";
const iconChevronLeft = "mdi-chevron-left";
const iconChevronRight = "mdi-chevron-right";

const iconDotsHorizontal = "mdi-dots-horizontal";
const iconDotsHorizontalCircleOutline = "mdi-dots-horizontal-circle-outline";

// Arrow
const iconArrowRightDrop = "mdi-arrow-right-drop-circle-outline";
const iconArrowLeftDrop = "mdi-arrow-left-drop-circle-outline";
const iconArrowDownDrop = "mdi-arrow-down-drop-circle-outline";
const iconArrowUpDrop = "mdi-arrow-up-drop-circle-outline ";
const iconArrowUpCircle = "mdi-arrow-up-thin-circle-outline";
const iconArrowUpThick = "mdi-arrow-up-thick";
const iconArrowUpThin = "mdi-arrow-up-thin";

const iconAccount = "mdi-account-outline";
const iconAccountCheck = "mdi-account-check-outline";
const iconManager = "mdi-account-tie";
const iconAccountArrowRight = "mdi-account-arrow-right-outline";
const iconSearch = "mdi-magnify";
const iconPrinter = "mdi-printer";
const iconNavigateBack = "mdi-chevron-left";
const iconNavigateToDetails = "mdi-chevron-right";
const iconView = "mdi-table-search ";
const iconAdd = "mdi-plus";
const iconMinus = "mdi-minus";
const iconEdit = "mdi-pencil-outline";
const iconSave = "mdi-content-save-outline";
const iconUndo = "mdi-undo";
const iconTrash = "mdi-trash-can-outline";
const iconClear = "mdi-close-outline ";
const iconRefresh = "mdi-cached ";
const iconPhone = "mdi-cellphone-basic";
const iconCalendar = "mdi-calendar";
const iconCalendarToday = "mdi-calendar-today";
const iconNotification = "mdi-bell-outline";
const iconNotificationAdd = "mdi-bell-plus-outline";
const iconNotificationRemove = "mdi-bell-remove-outline";
const iconNotificationCheck = "mdi-bell-check-outline";
const iconNote = "mdi-message-bulleted";
const iconAttachment = "mdi-paperclip";
const iconParts = "mdi-toolbox";
const iconAttribute = "mdi-tag-text-outline";
const iconCheck = "mdi-check";
const iconClose = "mdi-close";
const iconClock = "mdi-clock-outline";
const iconAnnouncement = "mdi-bullhorn-outline";
const iconTask = "mdi-calendar-check-outline";
const iconRun = "mdi-run-fast";
const iconDashboard = "mdi-view-dashboard-outline";
const iconFileEdit = "mdi-file-document-edit-outline";
const iconFileView = "mdi-file-search-outline";
const iconCaps = "mdi-keyboard-caps";
const iconReindex = "mdi-shuffle-disabled";
const iconReport = "mdi-package";
const iconSort = "mdi-sort";
const iconReload = "mdi-reload";
const iconHint = "mdi-alert-circle-outline";
const iconRestore = "mdi-backup-restore";

// Application Modules
const iconFolderOutline = "mdi-folder-outline";
const iconCategories = "mdi-bookshelf";
const iconEvents = "mdi-folder-clock-outline";
const iconTasks = "mdi-calendar-multiple-check";
const iconProjects = "mdi-calendar-clock-outline";

// Folder (record types)
const iconFolder = "mdi-folder-outline"; //"mdi-folder";
const iconFolderMultiple = "mdi-folder-multiple-outline";
const iconFolderPlus = "mdi-folder-plus";
const iconFolderPlusOutline = "mdi-folder-plus-outline";
const iconFolderUpload = "mdi-folder-upload";
const iconFolderUploadOutline = "mdi-folder-upload-outline";
const iconFile = "mdi-file-document-outline";
const iconFileDetails = "mdi-view-list-outline ";
const iconFileMultiple = "mdi-file-document-multiple-outline";
const iconFolderFileOutline = "mdi-folder-file-outline";
const iconUploadFile = "mdi-file-upload-outline";
const iconDocument = "mdi-folder-outline"; //"mdi-folder";
const iconDocumentOutline = "mdi-folder-file-outline";
const iconRecord = "mdi-file-outline";
const iconRecordMultiple = "mdi-file-multiple-outline";
const iconFolderArrowLeftOutline = "mdi-arrow-up-left"; // "mdi-folder-arrow-left-outline";
const iconFilePreview = "mdi-file-search-outline ";
const iconJumpToFolder = "mdi-folder-arrow-up-outline";
const iconFolderRefresh = "mdi-folder-refresh-outline";

const iconCopy = "mdi-content-copy";
const iconCut = "mdi-content-cut";
const iconPaste = "mdi-content-paste";
const iconPasteShortcut = "mdi-link-variant";
const iconJumpToOriginal = "mdi-origin";

const iconMenuRight = "mdi-menu-right";

// File type icons
const iconFilePdf = "mdi-file-pdf-box";
const iconFileWord = "mdi-file-word-box-outline";
const iconExcel = "mdi-microsoft-excel";
const iconFileJpg = "mdi-file-jpg-box";
const iconFileGif = "mdi-file-gif-box";
const iconFileImage = "mdi-file-image-outline";
const iconFilePng = "mdi-file-png-box";
const iconFilePowerpoint = "mdi-file-powerpoint-outline";
const iconFileVideo = "mdi-file-video-outline";
const iconXml = "mdi-xml";
const iconHtml = "mdi-file-code-outline";
const iconGlobe = "mdi-web";

// Agenda Management
const iconEvent = "mdi-folder-clock-outline";
const iconAgendaSection = "mdi-folder-file-outline";
const iconAgendaSectionPlus = "mdi-folder-multiple-plus";
const iconAgendaItem = "mdi-clipboard-text-clock-outline"; // "mdi-folder-text";
const iconSubmit = "mdi-earth-plus";
const iconRecall = "mdi-earth-minus";
const iconRollCall = "mdi-account-multiple";
const iconClerkModule = "mdi-account-star";
const iconLegislatorsNotepad = "mdi-book";

const iconPostMeeting = "mdi-clock-check-outline";
const iconUnPostMeeting = "mdi-clock-remove-outline ";

const iconPlay = "mdi-play";
const iconCancel = "mdi-close-circle-outline";
const iconSuspend = "mdi-cancel";

// Question/Warning/Info
const iconQuestion = "mdi-help-circle-outline";
const iconWarning = "mdi-alert-outline";
const iconInfo = "mdi-information-outline";
const iconError = "mdi-alert-octagon-outline";
const iconExclamation = "mdi-alert-circle-outline";

const iconCheckbox = "mdi-checkbox-outline";
const iconCheckboxBlank = "mdi-checkbox-blank-outline";

// Search
const iconFileSearch = "mdi-file-search-outline";
const iconFolderSearch = "mdi-folder-search-outline";
const iconFullTextSearch = "mdi-text-box-search-outline";

// Search operators
const iconDateOn = "mdi-approximately-equal ";
const iconEqual = "mdi-equal";
const iconGreaterThan = "mdi-greater-than";
const iconGreaterThanOrEqual = "mdi-greater-than-or-equal";
const iconLessThan = "mdi-less-than";
const iconLessThanOrEqual = "mdi-less-than-or-equal";
const iconAsterisk = "mdi-asterisk";

// Filter
const iconFilter = "mdi-filter-outline";
const iconFilterMenu = "mdi-filter-menu-outline";
const iconResetFilter = "mdi-filter-remove-outline";

const iconShowPassword = "mdi-eye-outline";
const iconHidePassword = "mdi-eye-off-outline";
//
//
// Questys specific
const iconDatabase = "mdi-database-outline";
const iconDatabaseLookup = "mdi-database-arrow-left-outline";
const iconFiles = "mdi-file-multiple";
const iconDownload = "mdi-file-download-outline";
const iconPrint = "mdi-printer";
const iconScanner = "mdi-scanner";
const iconSend = "mdi-file-send";

const iconLink = "mdi-link";
const iconAcquire = "mdi-tray-arrow-down";

// Record Attribute icons
const iconLock = "mdi-lock-outline";
const iconUnLock = "mdi-lock-open-variant-outline";
const iconReadOnly = "mdi-pencil-off-outline";
const iconDraft = "mdi-clipboard-edit-outline";

// Record source control icons
const iconFileCheckOut = "mdi-file-check-outline";

const iconFileCheckOutForSign = "mdi-file-sign";

const iconFileCheckIn = iconUploadFile;
const iconFileRelease = "mdi-file-restore-outline ";

// Import/Export
const iconExport = "mdi-export";
const iconImport = "mdi-import";

// Document Imaging Viewer
const iconZoomIn = "mdi-magnify-plus-outline";
const iconZoomOut = "mdi-magnify-minus-outline";
const iconFitToPage = "mdi-fit-to-page-outline";
const iconResize = "mdi-resize";
const iconFitToWidth = "mdi-file-swap-outline";
const iconRotateLeft = "mdi-rotate-left-variant";
const iconRotateRight = "mdi-rotate-right-variant";
const iconDropDownload = "mdi-arrow-down-bold-box-outline";
const iconFullBack = "mdi-skip-backward";
const iconFullForward = "mdi-skip-forward";
const iconSingleForward = "mdi-skip-next";
const iconSingleBackward = "mdi-skip-previous";
const iconPage = "mdi-book-open-page-variant";
const iconMovePage = "mdi-swap-vertical";

// Annotations
const iconAnnotation = "mdi-format-annotation-plus";
const iconRectangle = "mdi-rectangle-outline";
const iconEllipse = "mdi-ellipse-outline";
const iconSingleLine = "mdi-vector-line";
const iconMultipleLines = "mdi-vector-polyline";
const iconFreeHand = "mdi-signature-freehand";
const iconPolygon = "mdi-shape-polygon-plus";
const iconHighlight = "mdi-format-color-highlight";
const iconText = "mdi-card-text-outline";
const iconRedaction = "mdi-rectangle";
const iconStamp = "mdi-stamper";

// File Types
const iconPdf = "mdi-file-pdf-box";
const iconImage = "mdi-file-image-outline";
const iconWord = "mdi-file-word-outline";
const iconJpg = "mdi-file-jpg-box";

const iconHistory = "mdi-history";

const iconPhoneWork = "mdi-phone";
const iconEmail = "mdi-email";
const iconMapMarker = "mdi-map-marker";

// Message
const iconMessageText = "mdi-message-text-outline";
const iconMessageQuestion = "mdi-message-question-outline";
const iconMessageWarning = "mdi-Message-alert-outline";
const iconMessageCheck = "mdi-message-check-outline";

// record state
const iconOnHold = "mdi-timer-check-outline";
const iconAccessDenied = "mdi-close-octagon-outline";

export {
  iconApplication,
  iconBrowse,
  iconConfigure,
  iconLogOut,
  iconMenuDown,
  iconChevronDown,
  iconChevronUp,
  iconChevronLeft,
  iconChevronRight,
  iconArrowRightDrop,
  iconArrowLeftDrop,
  iconAccount,
  iconAccountArrowRight,
  iconAccountCheck,
  iconArrowDownDrop,
  iconArrowUpDrop,
  iconArrowUpCircle,
  iconSearch,
  iconPrinter,
  iconScanner,
  iconNavigateBack,
  iconNavigateToDetails,
  iconView,
  iconAdd,
  iconMinus,
  iconEdit,
  iconSave,
  iconTrash,
  iconPhone,
  iconClear,
  iconRefresh,
  iconCalendar,
  iconCalendarToday,
  iconWarning,
  iconInfo,
  iconError,
  iconQuestion,
  iconNotification,
  iconNotificationAdd,
  iconNotificationRemove,
  iconNotificationCheck,
  iconNote,
  iconAttachment,
  iconParts,
  iconAttribute,
  iconCheck,
  iconClose,
  iconClock,
  iconAnnouncement,
  iconEvents,
  iconEvent,
  iconAgendaSection,
  iconAgendaSectionPlus,
  iconAgendaItem,
  iconTask,
  iconTasks,
  iconRun,
  iconDashboard,
  iconFolder,
  iconFolderMultiple,
  iconFolderPlus,
  iconFolderPlusOutline,
  iconFolderUpload,
  iconFolderUploadOutline,
  iconCategories,
  iconProjects,
  iconLock,
  iconUnLock,
  iconReadOnly,
  iconDraft,
  iconLink,
  iconAcquire,
  iconShowPassword,
  iconHidePassword,
  iconDatabase,
  iconFiles,
  iconDownload,
  iconPdf,
  iconImage,
  iconJpg,
  iconWord,
  iconZoomIn,
  iconZoomOut,
  iconFitToPage,
  iconResize,
  iconFitToWidth,
  iconFileSearch,
  iconFullTextSearch,
  iconFolderSearch,
  iconFilter,
  iconFolderOutline,
  iconFilterMenu,
  iconDateOn,
  iconEqual,
  iconGreaterThan,
  iconGreaterThanOrEqual,
  iconLessThan,
  iconLessThanOrEqual,
  iconAsterisk,
  iconFile,
  iconUploadFile,
  iconDocument,
  iconDocumentOutline,
  iconRecord,
  iconRecordMultiple,
  iconCheckbox,
  iconCheckboxBlank,
  iconRotateLeft,
  iconRotateRight,
  iconDropDownload,
  iconPrint,
  iconFullBack,
  iconFullForward,
  iconSingleForward,
  iconSingleBackward,
  iconExport,
  iconImport,
  iconPlay,
  iconCancel,
  iconSuspend,
  iconRectangle,
  iconEllipse,
  iconAnnotation,
  iconFreeHand,
  iconHighlight,
  iconFileCheckOut,
  iconFileCheckIn,
  iconFileRelease,
  iconDotsHorizontal,
  iconDotsHorizontalCircleOutline,
  iconFolderArrowLeftOutline,
  iconHistory,
  iconFileMultiple,
  iconFileDetails,
  iconFolderFileOutline,
  iconFilePdf,
  iconFileWord,
  iconExcel,
  iconFileJpg,
  iconFilePng,
  iconFilePowerpoint,
  iconFileVideo,
  iconFileImage,
  iconFileGif,
  iconFileEdit,
  iconFileView,
  iconXml,
  iconHtml,
  iconSend,
  iconUndo,
  iconSingleLine,
  iconRedaction,
  iconStamp,
  iconText,
  iconPolygon,
  iconMultipleLines,
  iconMenu,
  iconPage,
  iconMovePage,
  iconDatabaseLookup,
  iconFilePreview,
  iconPhoneWork,
  iconMessageText,
  iconMessageQuestion,
  iconMessageWarning,
  iconMessageCheck,
  iconEmail,
  iconMapMarker,
  iconMenuRight,
  iconArrowUpThick,
  iconArrowUpThin,
  iconGlobe,
  iconSubmit,
  iconRecall,
  iconCopy,
  iconCut,
  iconPaste,
  iconPostMeeting,
  iconUnPostMeeting,
  iconJumpToFolder,
  iconManager,
  iconOnHold,
  iconAccessDenied,
  iconPasteShortcut,
  iconJumpToOriginal,
  iconCaps,
  iconReindex,
  iconReport,
  iconFolderRefresh,
  iconSort,
  iconExclamation,
  iconRollCall,
  iconLegislatorsNotepad,
  iconClerkModule,
  iconFileCheckOutForSign,
  iconReload,
  iconResetFilter,
  iconHint,
  iconRestore
};

import { getAxios } from "@/services/api/apiCommon";

/**
 * workflow User Tasks web api controller name
 * @type {string}
 */
const workflowUserTasks = "WorkflowUserTasks";

/**
 * workflow Projects web api controller name
 * @type {string}
 */
const workflowProjects = "WorkflowProjects";

/**
 * workflow Templates web api controller name
 * @type {string}
 */
const workflowTemplates = "WorkflowTemplates";

/**
 * workflow Project Categories web api controller name
 * @type {string}
 */
const workflowProjectCategories = "WorkflowProjectCategories";

/**
 * workflow Roles
 * @type {string}
 */
const workflowRoles = "WorkflowRoles";

/**
 * Workflow User Tasks
 */

/**
 * Get Workflow User Tasks
 * NOTE: Keep @returns arguments in sync
 * @returns {Promise<[{taskId:number, name:string, typeId:number, templateId:number, workflowInstanceId:number, workflowDefinitionId:number, recordId:number, recordName:string, status:number}]>}
 */
const getWorkflowUserTasks = () => getAxios().get(`/${workflowUserTasks}`);

/**
 * Get Workflow User Task by Task id
 * @param {Number|number} id  Task id
 * NOTE: Keep @returns arguments in sync
 * @returns {Promise<{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee:string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole:string, canReassign:boolean, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone:boolean, isOverdue:boolean, notifyOnEscalation:boolean, priority:number, selfReleaseDisabled:boolean, workflowDefinitionId:number, notes: {id:number, taskId:number, text:string, userId:number, userName:string, created:string}[]}>}
 */
const getWorkflowUserTask = id => getAxios().get(`/${workflowUserTasks}/${id}`);

/**
 * accept user task
 * @param {Number|number} id task id
 * @param {String|string} note task's note
 * @return {Promise<Boolean|boolean>}
 */
const accept = (id, note) => {
  const url = `/${workflowUserTasks}/${id}/accept`;

  return getAxios().post(url, note);
};

/**
 * reject task
 * @param {Number|number} id task id
 * @param {String|string} note task's note
 * @return {Promise<Boolean|boolean>}
 */
const reject = (id, note) => {
  const url = `/${workflowUserTasks}/${id}/reject`;

  return getAxios().post(url, note);
};

/**
 * take Task
 * @param {Number|number} id task id
 * @param {String|string} note note
 * @param {boolean} sendNotification send Notification
 * @return {Promise<Boolean|boolean>}
 */
const takeTask = (id, note, sendNotification = false) => {
  const url = `/${workflowUserTasks}/${id}/take?sendNotification=${sendNotification}`;

  return getAxios().post(url, note);
};

/**
 * release Task
 * @param {Number|number} id task id
 * @param {String|string} note task's note
 * @param {boolean} sendNotification
 * @return {Promise<Boolean|boolean>}
 */
const releaseTask = (id, note, sendNotification = false) => {
  const url = `/${workflowUserTasks}/${id}/release?sendNotification=${sendNotification}`;

  return getAxios().post(url, note);
};

/**
 * Workflow Projects
 *
 */

/**
 * get Workflow Projects
 * @param {Boolean|boolean} excludeCompleted exclude Completed workflow projects
 * @returns {Promise<[{projectId: number, definitionId: number, workflowInstanceId: number, workflowTypeId: number, projectName: String, recordId: number, subject: String, owner: String, projectManagerId: number, templateId: number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}]>}
 */
const getWorkflowProjects = (excludeCompleted = false) =>
  getAxios().get(`/${workflowProjects}?excludeCompleted=${excludeCompleted}`);

/**
 * Get Workflow Project by Project id
 * @param {Number|number} id Project id
 * NOTE: Keep @returns arguments in sync
 * @returns {Promise<{projectId: Number, projectName:string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string}>}
 */
const getWorkflowProject = id => getAxios().get(`/${workflowProjects}/${id}`);

/**
 * get Workflow Project Templates
 * @return {Promise<object[]>}
 */
const getWorkflowProjectTemplates = () =>
  getAxios().get(`/${workflowTemplates}`);

/**
 * get Workflow Calendars
 * @return {Promise<{string}[]>}
 */
const getWorkflowCalendars = () =>
  getAxios().get(`/${workflowTemplates}/calendars`);

/**
 * get Workflow Project Template by Template id
 * @param {number} id
 * @return  {Promise<object>}
 */
const getWorkflowProjectTemplate = id =>
  getAxios().get(`/${workflowTemplates}/${id}`);

/**
 * get Workflow Project Categories
 * @return {Promise<object[]>}
 */
const getWorkflowProjectCategories = () =>
  getAxios().get(`/${workflowProjectCategories}`);

/**
 * get Workflow Project Category
 * @param {number} id Workflow Project Category id
 * @return {Promise<object>}
 */
const getWorkflowProjectCategory = id =>
  getAxios().get(`/${workflowProjectCategories}/${id}`);

/**
 * get Workflow Project Category Templates
 * @param {number} id Workflow Project Category id
 * @return {Promise<object>[]}
 */
const getWorkflowProjectCategoryTemplates = id =>
  getAxios().get(`/${workflowProjectCategories}/${id}/templates`);

/**
 * start Workflow Project
 * @param {{recordId: (Number|number), comment: string, runOnlyOneProject: boolean, userTasks: ({id: number, name: string, sequence: number, duration: string, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}[]|*[]), projectName: string, templateId: (number|number)}} projectNew new workflow project
 * @return {Promise<{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}>}
 */
const startWorkflowProject = projectNew => {
  const url = `/${workflowProjects}/start`;
  const data = projectNew ? JSON.stringify(projectNew) : null;

  return getAxios().post(url, data);
};

/**
 * delete Workflow Project
 * @param {number} projectId Workflow Project id
 * @return {Promise<Boolean>}
 */
const deleteWorkflowProject = projectId => {
  const url = `/${workflowProjects}/${projectId}`;

  return getAxios().delete(url);
};

/**
 * suspend Workflow Project
 * @param {number} projectId Workflow Project id
 * @param {string} message Workflow Suspension Message
 * @return {Promise<Boolean>}
 */
const suspendWorkflowProject = (projectId, message) => {
  const url = `/${workflowProjects}/${projectId}/suspend?reason=${message}`;

  return getAxios().post(url);
};

/**
 * resume Workflow Project
 * @param {number} projectId resume Project id
 * @return {Promise<Boolean>}
 */
const resumeWorkflowProject = projectId => {
  const url = `/${workflowProjects}/${projectId}/resume`;

  return getAxios().post(url);
};

/**
 * cancel Workflow Project
 * @param {number} projectId resume Project id
 * @return {Promise<Boolean>}
 */
const cancelWorkflowProject = projectId => {
  const url = `/${workflowProjects}/${projectId}/cancel`;

  return getAxios().post(url);
};

/**
 * Get Workflow Roles
 * @return {Promise<Array>}
 */
const getWorkflowRoles = () => {
  const url = `/${workflowRoles}`;

  return getAxios().get(url);
};

/**
 * Get Workflow Users
 * @param {String} roles - Users per each Role
 * @return {Promise<Array>}
 */
const getWorkflowUsers = roles => {
  const url = `/${workflowRoles}/${roles}`;

  return getAxios().get(url);
};

/**
 * Get Workflow Project Manager Candidates
 * @param {Number} templateId Project Template Id
 * @return {Promise<{id: Number, name: string, displayName: string, firstName: string, lastName: string, title: String, description: String, email: String}>}
 */
const getManagerCandidates = templateId => {
  const url = `/${workflowTemplates}/${templateId}/manager-candidates`;

  return getAxios().get(url);
};

/**
 * Get Workflow Task User Candidates
 * @param {Number} taskId Project Task Id
 * @return {Promise<{id: Number, name: string, displayName: string, firstName: string, lastName: string, title: String, description: String, email: String}>}
 */
const getUserCandidates = taskId => {
  const url = `/${workflowUserTasks}/${taskId}/assignment-candidates`;

  return getAxios().get(url);
};

/**
 * Assign Project Manager
 * @param {{ projectId: Number, managerId: Number, comment: String, sendNotification: Boolean}} payload
 * @return {Promise<Boolean>}
 */
const assignManager = payload => {
  const projectId = payload?.projectId ?? -1;
  const managerId = payload?.managerId ?? -1;
  const comment = payload?.comment ?? "";
  const notify = payload?.sendNotification ?? false;

  const url = `/${workflowProjects}/${projectId}/assign-manager?userId=${managerId}&comment=${comment}&sendNotification=${notify}`;

  return getAxios().post(url);
};

/**
 * Assign Task User
 * @param {{ taskId: Number, userId: Number, comment: String, sendNotification: Boolean}} payload
 * @return {Promise<Boolean>}
 */
const assignUser = payload => {
  const taskId = payload?.taskId ?? -1;
  const userId = payload?.userId ?? -1;
  const comment = payload?.comment ?? "";
  const notify = payload?.sendNotification ?? false;

  const url = `/${workflowUserTasks}/${taskId}/assign?userId=${userId}&comment=${comment}&sendNotification=${notify}`;

  return getAxios().post(url);
};

export {
  getWorkflowProjects,
  getWorkflowProject,
  getWorkflowUserTask,
  getWorkflowUserTasks,
  accept,
  reject,
  getWorkflowProjectTemplates,
  getWorkflowProjectTemplate,
  startWorkflowProject,
  deleteWorkflowProject,
  suspendWorkflowProject,
  resumeWorkflowProject,
  cancelWorkflowProject,
  getWorkflowProjectCategories,
  getWorkflowProjectCategory,
  getWorkflowProjectCategoryTemplates,
  getWorkflowRoles,
  getWorkflowUsers,
  getWorkflowCalendars,
  getManagerCandidates,
  assignManager,
  getUserCandidates,
  assignUser,
  takeTask,
  releaseTask
};

// router
import {
  createAppModuleRouteName,
  createAppModuleRoutePath
} from "@/router/solution/solutionRoutes";

const moduleName = "Login";

const loginRoutes = Object.freeze([
  {
    path: createAppModuleRoutePath(moduleName),
    name: createAppModuleRouteName(moduleName),
    component: () => import("@/views/user/Login")
  }
]);

export { loginRoutes };

const SET_USER_MODULES = "SET_USER_MODULES";
const SET_USER_DATA = "SET_USER_DATA";
const CLEAR_USER_DATA = "CLEAR_USER_DATA";
const SET_REPOSITORIES = "SET_REPOSITORIES";
const SET_CATEGORY_TYPES = "SET_CATEGORY_TYPES";
const SET_FIELDS = "SET_FIELDS";
const SET_FORM_FIELD = "SET_FORM_FIELD";
const SET_CATEGORIES = "SET_CATEGORIES";
const SET_ENABLE_MODULE_CATEGORY = "SET_ENABLE_MODULE_CATEGORY";
const SET_ENABLE_MODULE_SEARCH = "SET_ENABLE_MODULE_SEARCH";
const SET_ENABLE_MODULE_TASKS = "SET_ENABLE_MODULE_TASKS";
const SET_ENABLE_MODULE_PROJECTS = "SET_ENABLE_MODULE_PROJECTS";
const SET_ENABLE_MODULE_AGENDA = "SET_ENABLE_MODULE_AGENDA";
const SET_CHANGE_REPOSITORY = "SET_CHANGE_REPOSITORY";
const SET_TEXT_EDITOR = "SET_TEXT_TAB";
const SET_ANNOTATION_EDITOR = "SET_ANNOTATION_TAB";

export {
  SET_USER_MODULES,
  SET_USER_DATA,
  CLEAR_USER_DATA,
  SET_REPOSITORIES,
  SET_CHANGE_REPOSITORY,
  SET_CATEGORY_TYPES,
  SET_FIELDS,
  SET_FORM_FIELD,
  SET_CATEGORIES,
  SET_ENABLE_MODULE_CATEGORY,
  SET_ENABLE_MODULE_SEARCH,
  SET_ENABLE_MODULE_TASKS,
  SET_ENABLE_MODULE_PROJECTS,
  SET_ENABLE_MODULE_AGENDA,
  SET_TEXT_EDITOR,
  SET_ANNOTATION_EDITOR
};

// model
import { moduleNames, findModule } from "@/model/solution/moduleModel";
import { recordOperation } from "@/model/record/recordModel";
import {
  isAllowedViewField,
  isAllowedModifyField
} from "@/model/field/fieldModel";
import {
  featureName,
  isAvailableFeature
} from "@/model/features/featuresModel";
import { isAllowedOperation } from "@/model/category/categoryModel";
import { systemOperations } from "@/model/user/userModel";

// services
import {
  isDevelopment,
  isPublicAccess,
  publicAccess
} from "@/services/config/configService";

const getters = {
  /**
   * Get current principal
   * @param state
   * @return {{actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[], systemOperations: {name: string, allowed: boolean, valid: Boolean}[]}|undefined} returns current principal
   */
  principal: state => state.user?.principal,

  /**
   * Ger Questys' available features for current user
   * @param state
   * @param getters
   * @return {{name: string, total: number, used: number}[]|*[]} returns available features for current user
   */
  features: (state, getters) => getters.principal?.features ?? [],

  /**
   * Get Get current principal's repository
   * @param state
   * @param getters
   * @return {String|string} returns current principal's repository
   */
  repository: (state, getters) => getters.principal?.repository ?? "",

  /**
   * Get current Actor id
   * @param state
   * @param getters
   * @return {number} returns Actor id
   */
  actorId: (state, getters) => getters.principal?.actorId ?? -1,

  /**
   * Get Get current principal Actor Name
   * @param state
   * @param getters
   * @return {String|string} returns current principal actor Name
   */
  actorName: (state, getters) => getters.principal?.actorName ?? "",

  /**
   * Get current principal's repository Uid
   * @param state
   * @param getters
   * @return {String|string} returns current principal's repository Uid
   */
  repositoryUid: (state, getters) => getters.principal?.repositoryUid ?? "",

  /**
   * Is the current principal Administrator
   * @param state
   * @param getters
   * @return {Boolean} returns whether current principal is in Administrator roll
   */
  isAdministrator: (state, getters) =>
    getters.principal?.isAdministrator ?? false,

  /**
   * Get User's available modules by Questys' Licensed features
   * @return {{name: string, icon: string, title: string}[]}
   */
  modules: state => state.modules || [],

  /**
   * Enable Category Module
   * @param state
   * @return {boolean}
   */
  enableCategoryModule: state => state.enableCategoryModule,

  /**
   * Enable earch Module
   * @param state
   * @return {boolean}
   */
  enableSearchModule: state => state.enableSearchModule,

  /**
   * enable Workflow Task Module
   * @param state
   * @return {boolean}
   */
  enableTasksModule: state => state.enableTasksModule,

  /**
   * enable Workflow Project Module
   * @param state
   * @return {boolean}
   */
  enableProjectsModule: state => state.enableProjectsModule,

  /**
   * enable Agenda Module
   * @param state
   * @return {boolean}
   */
  enableAgendaModule: state => state.enableAgendaModule,

  /**
   * enable Web Scan
   * @param state
   * @return {boolean} true if Web Scan is enabled
   */
  enableWebScan: state => state.enableWebScan ?? false,

  /**
   * is a licensed Module
   * @param state
   * @param getters
   * @return {function(*): boolean|boolean}
   */
  isLicensedModule: (state, getters) => name =>
    (getters.modules ?? []).filter(
      el => el.name.toLowerCase() === name?.toLowerCase()
    ).length > 0,

  /**
   * find feature by feature name
   * @param state
   * @param getters
   * @return {function(string): ({name: string, used: number, total: number})} returns found feature
   */
  findFeature: (state, getters) => name =>
    (getters.features ?? []).find(
      f => f?.name?.toLowerCase() === name?.toLowerCase()
    ),

  /**
   * is a Supported Module
   * @param state
   * @param getters
   * @return {(function(string): (boolean))}
   */
  isSupportedModule: (state, getters) => name => {
    // validate module existence
    const module = findModule(name);
    if (!module) {
      console.warn(`Not found Application Module: '${name}'.`);
      return false;
    }

    /**
     * Handle Public Access
     */
    if (isPublicAccess) {
      if (!(module?.public ?? false)) {
        return false;
      }
    }

    /**
     * In development - all modules are supported
     */
    if (isDevelopment) {
      return (
        module.name === moduleNames.Search ||
        module.name === moduleNames.Folder ||
        module.name === moduleNames.Category ||
        module.name === moduleNames.Tasks ||
        module.name === moduleNames.Projects ||
        module.name === moduleNames.Agenda
      );
    }

    // get module's feature
    const feature = getters.findFeature(module.feature);
    if (!feature) {
      console.warn(`Feature ${name} not found.`);
      return false;
    }

    // determines whether the provided feature is Available
    const available = isAvailableFeature(feature);
    if (!available) {
      console.warn(`Feature ${name} is not available.`);
    }

    return available;
  },

  /**
   * determines whether an Application Module is available (licensed, supported, enabled)
   * @param state
   * @param getters
   * @return {function(string): boolean}
   */
  isAvailableApplicationModule: (state, getters) => name => {
    return (
      !!getters.isLicensedModule(name) &&
      !!getters.isSupportedModule(name) &&
      !!getters.isEnabledModule(name)
    );
  },

  /**
   * is Enabled Application Module
   * @param state
   * @param getters
   * @return {function(string): boolean} return true if Application Module is Enabled
   */
  isEnabledModule: (state, getters) => name => {
    switch (name) {
      case moduleNames.Category: {
        return isPublicAccess
          ? publicAccess.modules.visibleCategory
          : !!state.enableCategoryModule;
      }
      case moduleNames.Search: {
        return !!state.enableSearchModule;
      }
      case moduleNames.Tasks: {
        return !!state.enableTasksModule;
      }
      case moduleNames.Projects: {
        return (
          !!getters.isAllowedSystemOperation(
            systemOperations.accessWFProjects
          ) && !!state.enableProjectsModule
        );
      }
      case moduleNames.Agenda: {
        return isPublicAccess
          ? publicAccess.modules.visibleMeetings
          : !!state.enableAgendaModule;
      }
      case moduleNames.Folder: {
        return isPublicAccess ? publicAccess.modules.visibleFolder : true;
      }
      default: {
        return false;
      }
    }
  },

  /**
   * is a Supported Feature
   * @param state
   * @param getters
   * @return {(function(*): (boolean|boolean))|*}
   */
  isSupportedFeature: (state, getters) => name => {
    let feature = getters.findFeature(name);

    if (!feature) {
      //
      // WebScan Feature has been added by Tyler using Software Potential web page
      //
      if (name === featureName.WebScan) {
        if (isDevelopment) {
          feature = {
            name: name,
            used: 1,
            total: 1
          };
        } else {
          console.warn(`isSupportedFeature() Feature ${name} not found.`);
          console.warn(`isSupportedFeature() features:`, getters.features);
          return false;
        }
      } else {
        console.warn(`isSupportedFeature() Feature ${name} not found.`);
        console.warn(`isSupportedFeature() features:`, getters.features);
        return false;
      }
    }

    return isAvailableFeature(feature);
  },

  /**
   * Get Questys' repositories
   * @param state
   * @return {{String}[]|*[]}
   */
  repositories: state => state.repositories || [],

  /**
   * Get user's allowed fields
   * @param state
   * @return {{id: number, name: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, unselectable: boolean}[]|*[]}
   */
  fields: state => state.fields || [],

  /**
   * form Fields (containing field definition)
   * @param state
   * @return {{id: number, name: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, unselectable: boolean, isPersistentField: boolean, isSystemCategoryType: boolean, isSystemField: boolean}[]|*[]}
   */
  formFields: state => state.formFields || [],

  /**
   * find Form Field
   * @param state
   * @param getters
   * @return {function(*): {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[], operations: {name:string, allowed:boolean, valid:boolean}[], lookup: {databaseLookup:boolean, enforce:boolean, items: string[]}}} Form Field
   */
  findFormField: (state, getters) => id =>
    getters.formFields?.find(el => (el?.id ?? -1) === id),

  /**
   * Get user's allowed categories
   * @param state
   * @return {{id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string}[]|*[]} returns user's allowed categories
   */
  categories: state => state.categories || [],

  /**
   * get a list of record creatable categories for current user
   * @param state
   * @param getters
   * @return {{id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string}[]|*[]}
   */
  createRecordCategories: (state, getters) =>
    (getters.categories || [])?.filter(category => {
      return isAllowedOperation(category, recordOperation.Create);
    }) ?? [],

  /**
   * create Record Categories By Category Type
   * @param state
   * @param getters
   * @return {function(*)}
   */
  createRecordCategoriesByCategoryType: (state, getters) => categoryTypeId => {
    return (
      (getters.createRecordCategories ?? []).filter(
        c => c.categoryType === categoryTypeId
      ) ?? []
    );
  },

  /**
   * get a list of record-updatable categories for current user
   * @param state
   * @param getters
   * @return {{id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string}[]|*[]}
   */
  updateRecordCategories: (state, getters) =>
    (getters.categories || [])?.filter(category => {
      return isAllowedOperation(category, recordOperation.Update);
    }) ?? [],

  /**
   * update Record Categories By Category Type
   * @param state
   * @param getters
   * @return {function(*)}
   */
  updateRecordCategoriesByCategoryType: (state, getters) => categoryTypeId => {
    return (
      (getters.updateRecordCategories ?? []).filter(
        c => c.categoryType === categoryTypeId
      ) ?? []
    );
  },

  /**
   * find Record Category by Category id
   * @param state
   * @param getters
   * @return {function(number): T | {id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string}}
   */
  findCategory: (state, getters) => id =>
    (getters.categories || [])?.find(c => c?.id === (id ?? -1)),

  /**
   * Find Field by Field id
   * @param state
   * @param getters
   * @return {function(number): {id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean, unselectable:boolean, operations: {name:string, allowed:boolean, valid:boolean}[]}} returns field
   */
  findField: (state, getters) => id =>
    getters.fields?.find(el => el?.id === id),

  /**
   * Determines whether a Field is a Persistent field
   * @param state
   * @param getters
   * @return {function(number) : boolean} returns a function with one parameter id, which represents field id and returns true if field is Persistent
   */
  isPersistentField: (state, getters) => id =>
    getters.findField(id)?.isPersistentField ?? false,

  /**
   * Determines whether a Field is Questys' System field
   * @param state
   * @param getters
   * @return {function(number) : T | boolean} returns a function with one parameter id, which represents field id
   */
  isSystemField: (state, getters) => id =>
    getters.findField(id)?.isSystemField ?? false,

  /**
   * is Allowed View Field
   * @param state
   * @param getters
   * @return {function(number): boolean|boolean} returns is Allowed View Field
   */
  isAllowedViewField: (state, getters) => id => {
    const field = getters.findField(id);
    return field ? isAllowedViewField(field) : false;
  },

  /**
   * is Allowed Modify Field
   * @param state
   * @param getters
   * @return {function(number): boolean|boolean} returns is Allowed Modify Field
   */
  isAllowedModifyField: (state, getters) => id => {
    const field = getters.findField(id);
    return field ? isAllowedModifyField(field) : false;
  },

  /**
   * is Allowed System Operation
   * @param state
   * @param getters
   * @return {function(*)}
   */
  isAllowedSystemOperation: (state, getters) => operation => {
    return (
      getters?.principal?.systemOperations?.find(so => so?.name === operation)
        ?.allowed ?? false
    );
  },

  /**
   * Get User's Fields count
   * @param state
   * @param getters
   * @return {Number|number} returns User's Fields count
   */
  countFields: (state, getters) => getters.fields?.length ?? 0,

  /**
   * Get User's Categories count
   * @param state
   * @param getters
   * @return {number|number} returns User's Categories count
   */
  countCategories: (state, getters) => getters.categories?.length ?? 0,

  /**
   * determines whether the current user is logged in
   * @param state
   * @param getters
   * @return {boolean} returns whether current user is logged in
   */
  isUserLoggedIn: (state, getters) => (getters.principal?.actorId ?? -1) >= 0
};

export default getters;

// filters
import {
  Color,
  colorMD,
  colorTheme,
  getErrorColorClass,
  getSuccessColorClass,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { toShortLocalString } from "@/filters/dateFilter";

// design
import {
  iconCancel,
  iconProjects,
  iconTrash,
  iconPlay,
  iconSuspend,
  iconAccountArrowRight,
  iconManager,
  iconExport
} from "@/design/icon/iconConst";
import { ruleNames } from "@/model/rules/ruleModel";

/**
 * Workflow project Label
 * @type {string} user friend Workflow project Label
 */
const projectLabel = "Project";

/**
 * workflow project instance State
 * @type {Readonly<{canceled: number, faulted: number, notStarted: number, executing: number, completed: number, suspended: number}>}
 */
const projectState = Object.freeze({
  notStarted: 0, // The workflow instance has not started
  executing: 1, // The workflow instance is in an executing state
  suspended: 2, // The workflow instance was put on hold
  completed: 10, // The workflow instance completed successfully
  canceled: 11, // The workflow instance was canceled to some reasons and wasn't completed
  faulted: 12 // The workflow instance was aborted during execution
});

/**
 * workflow project instance states
 * @type {Readonly<{id: number, name: string, color: {name: string, variantType: string, variantNumber: number } }>[]}
 */
const projectStates = Object.freeze([
  {
    id: projectState.notStarted,
    name: "Not Started",
    color: {
      name: colorTheme.secondary,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n4
    }
  },
  {
    id: projectState.executing,
    name: "Executing",
    color: {
      name: colorMD.indigo,
      variantType: variantType.darken,
      variantNumber: variantNumber.n2
    }
  },
  {
    id: projectState.suspended,
    name: "Suspended",
    color: {
      name: colorTheme.warning,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: projectState.completed,
    name: "Completed",
    color: {
      name: colorTheme.success,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: projectState.canceled,
    name: "Canceled",
    color: {
      name: colorTheme.secondary,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n2
    }
  },
  {
    id: projectState.faulted,
    name: "Faulted",
    color: {
      name: colorTheme.error,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  }
]);

/**
 * project Overdue Color
 * @type {Color}
 */
const projectOverdueColor = new Color(
  colorTheme.error,
  variantType.lighten,
  variantNumber.n1
);

/**
 * project Overdue Color Class
 * @type {String|string}
 */
const projectOverdueColorClass = projectOverdueColor.getClassColor();

/**
 * find Project State
 * @param {Number|number} id Project State id
 * @return {{id: number, name: string, color: {name: string, variantType: string, variantNumber: number}}|undefined} Project State
 */
const findProjectState = id => {
  return projectStates?.find(el => el?.id === id);
};

/**
 * find Project State Color Class
 * @param {Number|number} id Project State id
 * @return {String|string|undefined}
 */
const findProjectStateColorClass = id => {
  const color = findProjectStateColor(id);

  return color
    ? new Color(
        color.name,
        color.variantType,
        color.variantNumber
      ).getClassColor()
    : undefined;
};

/**
 * find Project State Color
 * @param {Number|number} id Project State id
 * @return {{name: string, variantType: string, variantNumber: number}|undefined} Project State Color
 */
const findProjectStateColor = id => {
  return projectStates?.find(el => el?.id === id)?.color;
};

/**
 * format project Date To Short Local String
 * @param {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}} project
 * @return {string|string}
 */
const projectDateToShortLocalString = project => {
  return project?.creationDate ? toShortLocalString(project.creationDate) : "";
};

/**
 * project Operation
 * @type {Readonly<{CancelProject: string, AccessProject: string, ReassignTask: string, SuspendProject: string, RunProject: string, ReassignManager: string}>}
 */
const projectOperation = Object.freeze({
  AccessProject: "AccessProject",
  SuspendProject: "SuspendProject",
  RunProject: "RunProject",
  ResumeProject: "ResumeProject",
  CancelProject: "CancelProject",
  DeleteProject: "DeleteProject",
  ReassignManager: "ReassignManager",
  ReassignTask: "ReassignTask",
  ExportProject: "ExportProject",
  RepairProject: "RepairProject"
});

/**
 * project Operations
 * @type {({name: string, icon: string, label: string})[]}
 */
const projectOperations = Object.freeze([
  {
    name: projectOperation.AccessProject,
    label: "Access Project",
    icon: iconProjects,
    hint: "Access Project"
  },
  {
    name: projectOperation.SuspendProject,
    label: "Suspend",
    icon: iconSuspend,
    hint: "Suspend Project, so it can be latter resumed"
  },
  {
    name: projectOperation.RunProject,
    label: "Run",
    icon: iconProjects,
    hint: "Run Project"
  },
  {
    name: projectOperation.CancelProject,
    label: "Cancel",
    icon: iconCancel,
    hint: "Cancel executing project, which cannot be resume latter"
  },
  {
    name: projectOperation.ResumeProject,
    label: "Resume",
    icon: iconPlay,
    hint: "Resume suspended project"
  },
  {
    name: projectOperation.DeleteProject,
    label: "Delete",
    icon: iconTrash,
    hint: "Permanency delete project"
  },
  {
    name: projectOperation.ReassignManager,
    label: "Assign Manager",
    icon: iconManager,
    hint: "Assign project manager"
  },
  {
    name: projectOperation.ReassignTask,
    label: "Reassign Task",
    icon: iconAccountArrowRight,
    hint: "Reassign project's task"
  },
  {
    name: projectOperation.ExportProject,
    label: "Export",
    icon: iconExport,
    hint: "Export project detailed report"
  },
  {
    name: projectOperation.RepairProject,
    label: "Repair",
    icon: iconExport,
    hint: "Try to Repair project"
  }
]);

/**
 * find Project Operation
 * @param name Project operation name
 * @return {{name: string, icon: string, label: string}}
 */
const findProjectOperation = name => {
  return projectOperations?.find(el => el?.name === name);
};

/**
 *  Project Local Storage Keys
 * @type {{projectFilterOption: string}}
 */
const projectLocalStorageKeys = {
  projectFilterOption: "projectFilterOption"
};

/**
 * Project Headers for a report
 * @type {({visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string})[]}
 */
const projectHeaders = Object.freeze([
  { text: "Id", value: "projectId", visible: true },
  { text: "Name", value: "projectName", visible: true },
  { text: "Created-On", value: "creationDate", visible: true },
  { text: "Record-Name", value: "projectName", visible: true },
  { text: "User", value: "userName", visible: true },
  { text: "Status", value: "status", visible: true },
  {
    align: "end",
    sortable: false,
    text: "Actions",
    value: "actions",
    visible: true
  }
]);

/**
 * project Name Rules
 * @return {[(function(*): *)]}
 */
const projectNameRules = () => [value => !!value || ruleNames.required];

/**
 * project Template Rules
 * @param {number} templateId project Template id
 * @return {(function(*): *)[]|*[]}
 */
const projectTemplateRules = templateId =>
  templateId <= 0 ? [value => !!value || ruleNames.required] : [];

/**
 * start Project Option
 * @type {Readonly<{showPredefinedProjectTemplate: number, none: number, autoStart: number}>}
 */
const startProjectOption = Object.freeze({
  none: 0,
  autoStart: 1,
  showPredefinedProjectTemplate: 2
});

/**
 * start Project Options
 * @type {Readonly<[{text: string, value: number}]>}
 */
const startProjectOptions = Object.freeze([
  {
    value: startProjectOption.none,
    text: "None"
  },
  {
    value: startProjectOption.autoStart,
    text: "Auto Start Project"
  },
  {
    value: startProjectOption.showPredefinedProjectTemplate,
    text: "Show Predefined Project Template"
  }
]);

/**
 * find Start Project Option
 * @param {number} option
 * @return {unknown}
 */
const findStartProjectOption = option => {
  return startProjectOptions.find(el => el.value === option);
};

/**
 * project Validation Color Class
 * @param {boolean} valid is valid project
 * @return {String|string}
 */
const projectValidationColorClass = valid => {
  return valid ? getSuccessColorClass() : getErrorColorClass();
};

export {
  projectState,
  projectStates,
  projectOverdueColor,
  projectOverdueColorClass,
  projectOperations,
  projectOperation,
  findProjectState,
  findProjectStateColor,
  findProjectStateColorClass,
  projectDateToShortLocalString,
  findProjectOperation,
  projectLocalStorageKeys,
  projectHeaders,
  projectLabel,
  projectNameRules,
  projectTemplateRules,
  startProjectOption,
  startProjectOptions,
  findStartProjectOption,
  projectValidationColorClass
};

import { moduleNames, moduleTitles } from "@/services/solution/solutionService";

// router
import {
  createAppModuleRouteName,
  createAppModuleRoutePath
} from "@/router/solution/solutionRoutes";

const searchRoutes = Object.freeze([
  {
    path: createAppModuleRoutePath(moduleNames.Search),
    name: createAppModuleRouteName(moduleNames.Search),
    meta: { requiresAuth: true, title: moduleTitles.Search },
    component: () => import("@/views/search/SearchHome")
  }
]);

export { searchRoutes };

const SET_PROJECT_DELETED = "SET_PROJECT_DELETED";
const SET_PROJECT_CANCELED = "SET_PROJECT_CANCELED";
const SET_PROJECT_SUSPENDED = "SET_PROJECT_SUSPENDED";
const SET_PROJECT_RESUMED = "SET_PROJECT_RESUMED";
const UPDATE_PROJECT_MANAGER = "UPDATE_PROJECT_MANAGER";

export {
  SET_PROJECT_DELETED,
  SET_PROJECT_CANCELED,
  SET_PROJECT_SUSPENDED,
  SET_PROJECT_RESUMED,
  UPDATE_PROJECT_MANAGER
};

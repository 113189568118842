// utils
import {
  getLocalStorageItem,
  repoUserLocalStorageKey,
  setLocalStorageItem
} from "@/utils/localStorageUtility";

// model
import { moduleNames } from "@/model/solution/moduleModel";

/**
 * module Name
 * @type {string}
 */
const moduleName = moduleNames.Projects;

/**
 * Local Storage Keys
 * @type {Readonly<{projectId: string}>}
 */
const localStorageKey = Object.freeze({
  projectId: `${moduleName}_projectId`
});

/**
 * Store Selected Module Item id (project id) to the local Storage
 * @param {Number|number}id
 */
const storeSelectedModuleItemId = id =>
  setLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.projectId),
    id.toString()
  );

/**
 * Get Stored Selected Module Item id (project id) from local Storage
 * @returns {Number|number}
 */
const getStoredSelectedModuleItemId = () => {
  const id = getLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.projectId)
  );
  return id ? (isNaN(id) ? -1 : Number(id)) : -1;
};

export { storeSelectedModuleItemId, getStoredSelectedModuleItemId };

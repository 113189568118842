// design
import {
  colorMD,
  colorTheme,
  variantNumber,
  variantType,
  getMDColorClass
} from "@/design/colors/mdColors";

/**
 * Variant Types
 * @type {(string)[]}
 */
const variantTypes = Object.freeze([variantType.lighten, variantType.darken]);

/**
 * Variant Numbers
 * @type {number[]}
 */
const variantNumbers = Object.freeze([
  variantNumber.n1,
  variantNumber.n2,
  variantNumber.n3,
  variantNumber.n4,
  variantNumber.n5
]);

/**
 * get Success Color
 * @return {Color} Success Color
 */
const getSuccessColor = () => {
  return new Color(colorTheme.success, variantType.lighten, variantNumber.n1);
};

/**
 * Get Success Color Class
 * @returns {String|string} Success Color Class
 */
const getSuccessColorClass = () => getSuccessColor().getClassColor();

/**
 * get Info Color
 * @return {Color} Info Color
 */
const getInfoColor = () => {
  return new Color(colorTheme.info, variantType.lighten, variantNumber.n1);
};

/**
 * Get Info Color Class
 * @returns {String|string} Info Color Class
 */
const geInfoColorClass = () => getInfoColor().getClassColor();

/**
 * get error Color
 * @return {Color} Error Color
 */
const getErrorColor = () => {
  return new Color(colorTheme.error, variantType.lighten, variantNumber.n1);
};

/**
 * Get Error Color Class
 * @returns {String|string} Error Color Class
 */
const getErrorColorClass = () => getErrorColor().getClassColor();

/**
 * get Warning Color
 * @return {Color} Warning Color
 */
const getWarningColor = () => {
  return new Color(colorTheme.warning, variantType.lighten, variantNumber.n1);
};

/**
 * Get Warning Color Class
 * @returns {String|string} Warning Color Class
 */
const getWarningColorClass = () => getWarningColor().getClassColor();

/**
 * get Primary Color
 * @return {Color} Primary Color
 */
const getPrimaryColor = () => {
  return new Color(colorTheme.primary, variantType.lighten, variantNumber.n1);
};

/**
 * Get Primary Color Class
 * @returns {String|string} Primary Color Class
 */
const getPrimaryColorClass = () => getPrimaryColor().getClassColor();

/**
 * get Secondary Color
 * @return {Color} Secondary Color
 */
const getSecondaryColor = () => {
  return new Color(colorTheme.secondary, variantType.lighten, variantNumber.n1);
};

const getSecondaryLightColor = () => {
  return new Color(colorTheme.secondary, variantType.lighten, variantNumber.n4);
};

const getSecondaryLightColorClass = () =>
  getSecondaryLightColor().getClassColor();

/**
 * get Secondary Color Class
 * @returns {String|string} Secondary Color Class
 */
const getSecondaryColorClass = () => getSecondaryColor().getClassColor();

/**
 * Get Random color (HexDecimal)
 * @return {string}
 */
const getRandomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

/**
 * get Color Class
 * @param {{name: String, variantType: string, variantNumber: number} | undefined} color
 * @return {String|string|undefined}
 */
const getColorClass = color => {
  return color
    ? new Color(
        color.name,
        color.variantType,
        color.variantNumber
      ).getClassColor()
    : undefined;
};

/**
 * Abstract Material Design Colors specification
 * https://vuetifyjs.com/en/styles/colors/
 */
class Color {
  name = undefined;
  variant = "";
  variantNumber = 0;

  constructor(name = undefined, variant = "", variantNumber = 0) {
    this.name = name;
    this.variant = variant;
    this.variantNumber = variantNumber;
  }

  /**
   * Get Class Color
   * @returns {string|*}
   */
  getClassColor() {
    if (
      !this.name ||
      !this.variant ||
      !this.isValidVariantNumber(this.variantNumber) ||
      !this.isValidVariantType(this.variant)
    ) {
      return this.name;
    }

    return getMDColorClass(this.name, this.variant, this.variantNumber);
  }

  /**
   * Determines is Valid Variant Number
   * @param {number} number
   * @returns {boolean}
   */
  isValidVariantNumber(number) {
    return number
      ? variantNumbers.filter(el => el === number).length > 0
      : false;
  }

  /**
   * Determines is Valid Variant Type
   * @param {string} variant
   * @returns {boolean}
   */
  isValidVariantType(variant) {
    return variant
      ? variantTypes.filter(el => el === variant).length > 0
      : false;
  }
}

export {
  variantType,
  variantNumber,
  colorTheme,
  colorMD,
  Color,
  getSuccessColorClass,
  getErrorColorClass,
  geInfoColorClass,
  getWarningColorClass,
  getPrimaryColorClass,
  getSecondaryColorClass,
  getSecondaryLightColor,
  getSecondaryLightColorClass,
  getMDColorClass,
  getErrorColor,
  getWarningColor,
  getInfoColor,
  getSuccessColor,
  getPrimaryColor,
  getSecondaryColor,
  getColorClass,
  getRandomColor
};

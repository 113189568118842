import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

// models
import {
  moduleNames as moduleName,
  appModules
} from "@/model/solution/moduleModel";
import { supportedScanning } from "@/model/acquire/scan/scanningModel";

const state = {
  /**
   * Current Questys user
   * @type {{name: string, token: string, tokenValidFrom: string, tokenValidTo: string, principal: {actorId: number, actorName: string, isAdministrator: boolean, repository: string, repositoryUid: string, features: {name: string, total: number, used: number}[], systemOperations: {name: string, allowed: boolean, valid: Boolean}[]}}}
   */
  user: undefined,

  /**
   * Questys repositories
   * @type [{String}]
   */
  repositories: [],

  /**
   * Questys Category Types
   * @type {{id:number, name:string}}[]
   */
  categoryTypes: [],

  /**
   * User's permitted fields
   * @type {{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean}}[]
   */
  fields: [],

  /**
   * Record Categories
   * @type {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, operations: {name:string, allowed:boolean, valid:boolean}[]}[]}
   */
  categories: [],

  /**
   * User's permitted form fields (with field definition)
   * @type {{id:number, name:string, fieldDataType:number, fieldDataTypeName:string, flags:number, immutable:boolean, unselectable:boolean, isPersistentField:boolean, isSystemCategoryType:boolean, isSystemField:boolean}}[]
   */
  formFields: [],

  /**
   * User's available modules
   * @type {{name: string, icon: string, title: string}[]}
   */
  modules: appModules.filter(
    app =>
      app.name === moduleName.Folder ||
      app.name === moduleName.Category ||
      app.name === moduleName.Agenda
  ),

  /**
   * Enable Category Module
   */
  enableCategoryModule: true,

  /**
   * Enable Search Module
   */
  enableSearchModule: true,

  /**
   * Enable Workflow Task Module
   */
  enableTasksModule: true,

  /**
   * Enable Workflow Project Module
   */
  enableProjectsModule: true,

  /**
   * Enable Workflow Project Module
   */
  enableAgendaModule: true,

  /**
   * Enable enableWebScan
   * @type boolean
   */
  enableWebScan: supportedScanning(),

  /**
   * Enable TextTab
   */
  enableTextEditor: false,

  /**
   * Enable AnnotationTab
   */
  enableAnnotationEditor: false
};

export default {
  actions,
  mutations,
  getters,
  state,
  namespaced: true
};

// axios lib
import axios from "axios";

// services
import { webApiTimeOut, webApiUrl } from "@/services/config/configService";

/**
 * Current Axios instance
 * @type {Object} Axios instance
 * @private
 */
let _axios = undefined;

/**
 * Get Axios Instance
 * (if current Axios is undefined, then new Axios Instance will be created, else current Axios will be returned)
 * @return {Object} returns current Axios Instance
 */
function getAxios() {
  if (_axios) {
    return _axios;
  }

  /**
   * Create Axios Instance on demand (when is needed)
   */
  _axios = createAxios();

  /**
   * request interceptors
   */
  _axios.interceptors.request.use(request => {
    /**
     * Remarks: Axios calls request interceptors before sending the request,
     * so we can use request interceptors to modify the request here when/if needed
     */
    // console.log(
    //   `intercepted request:`,
    //   `method:${request.method} url:${request.url}`
    // );

    /**
     * One of the most common use cases for interceptors is handling authorization.
     * Interceptors let us set the authorization header automatically on all requests as shown below.
     * REMARKS: tested but still does not work as expected when changing repositories ...
     */
    // request.headers.authorization = _token;

    // Important: request interceptors **must** return the request.
    return request;
  });

  /**
   * response interceptors
   */
  _axios.interceptors.response.use(response => {
    /**
     * Axios calls response interceptors after it sends the request and receives a response.
     * The response parameter to interceptors is the Axios response object,
     * the same object you get when you do await axios.get()
     */
    // console.log(`intercepted response data:`, response.data);

    /**
     * we can debug and analyze response.data here
     */

    // Important: response interceptors **must** return the response.
    return response;
  });

  return _axios;
}

/**
 * creates a new Axios instance
 * @return {Object} return a new Axios instance
 */
function createAxios() {
  // console.log(`Create axios instance using webApiUrl:`, webApiUrl);
  // creates a new Axios instance to set custom defaults for our application
  return axios.create({
    baseURL: webApiUrl,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    timeout: webApiTimeOut // Throw an error if API call takes longer then specified in webApiTimeOut
  });
}

export { getAxios };

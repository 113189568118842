// Web API service calls
import {
  countChildren,
  getCategory,
  getChildren,
  getDocument,
  getRecord,
  lockRecord as apiLockRecord,
  setDraftRecord as apiSetDraftRecord,
  setReadOnlyRecord as apiSetReadOnlyRecord,
  unLockRecord as apiUnLockRecord,
  uploadFile as apiUploadFile,
  uploadCompoundDocumentFile as apiUploadCompoundDocumentFile,
  checkInFile as apiCheckInFile,
  checkOutFile as apiCheckOutFile,
  checkOutFileForSign as apiCheckOutFileForSign,
  releaseFile as apiReleaseFile,
  getFileVersions,
  getFileVersion,
  downloadImageFileVersion as apiDownloadImageFileVersion,
  deleteRecord as apiDeleteRecord,
  updateRecord as apiUpdateRecord,
  getRecordTextFieldValue,
  lookup,
  checkInScannedFileToRecord as apiCheckInScannedFileToRecord,
  scanToCheckedInFile as apiCheckInScannedFileToFile,
  checkInEditedDocument as apiCheckInEditedDocument,
  getRecordInfo as apiGetRecordInfo,
  moveRecord as apiMoveRecord,
  copyRecord as apiCopyRecord,
  createShortcut as apiCreateShortcut,
  deleteRecordShortcut as apiDeleteRecordShortcut,
  moveRecordShortcut as apiMoveRecordShortcut,
  copyRecordShortcut as apiCopyRecordShortcut,
  getRecordShortcut,
  getRecordChildren,
  restoreDeletedRecord
} from "@/services/api/apiContent";

// services
import { handleError } from "@/services/error/errorService";

// shared record mutation types
import {
  SET_DOCUMENT,
  SET_DOCUMENT_SEARCH_CATEGORY,
  SET_DOCUMENTS,
  SET_RECORD,
  SET_RECORD_DRAFT,
  SET_RECORD_LOCK,
  SET_RECORD_READ_ONLY,
  SET_UPLOAD_FILE_PROGRESS,
  SET_FILE_VERSIONS,
  SET_FILE_VERSION,
  SET_RECORD_DELETED,
  SET_RECORD_EDITED,
  SET_RECORD_NEW_CATEGORY,
  SET_RECORD_TEXT_FIELD_VALUE,
  SET_RECORD_LOOKUP_DATA,
  UPDATE_RECORD_FILE_VERSION,
  UPDATE_RECORD_INFO,
  SET_RECORDS,
  SET_RECORD_NEW_AND_CATEGORY
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// services
import {
  changeRecordCategory,
  createRecordFileVersion,
  ensureValidRecordFiledValues,
  filterFilesAndDocuments,
  getInputMappings,
  updatableRecordFieldValues
} from "@/services/record/recordService";

// model
import { categoryAll } from "@/model/category/categoryModel";
import { findRecordType, recordType } from "@/model/record/recordModel";

const scriptName = "record-actions";

/**
 * Set current record
 * @param context
 * @param {Number|number} id
 * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
 */
async function setRecord(context, id) {
  try {
    let record = (await getRecord(id))?.data;
    const category = (await getCategory(record?.categoryId ?? -1))?.data;

    record = ensureValidRecordFiledValues(record, category);

    const version =
      (record?.recordTypeId ?? -1) === recordType.FILE
        ? (await getFileVersion(record.id, record.version))?.data
        : undefined;

    const payload = {
      record: record,
      category: category,
      version: version
    };

    context.commit(SET_RECORD, payload);

    return record;
  } catch (e) {
    return await handleError(e, `Unable to set current Record. (id: ${id})`);
  }
}

/**
 * Set Record Shortcut
 * @param context
 * @param payload {{id:Number, parentId: Number}}
 * @return {Promise<*>}
 */
async function setRecordShortcut(context, payload) {
  try {
    let record = (await getRecordShortcut(payload))?.data;
    const category = (await getCategory(record?.categoryId ?? -1))?.data;

    record = ensureValidRecordFiledValues(record, category);

    const recordPayload = {
      record: record,
      category: category
    };

    //Set Record with all fields
    context.commit(SET_RECORD, recordPayload);

    return record;
  } catch (e) {
    return await handleError(
      e,
      `Unable to set current Record. (id: ${payload.id})`
    );
  }
}

/**
 * Set current Document
 * @param context
 * @param {Number|number} id
 * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
 */
async function setDocument(context, id) {
  try {
    const document = (await getDocument(id))?.data;
    const category = (await getCategory(document?.categoryId ?? -1))?.data;

    const payload = {
      document: document,
      category: category
    };

    context.commit(SET_DOCUMENT, payload);

    return document;
  } catch (e) {
    return await handleError(e, `Unable to set Document. (id: ${id})`);
  }
}

/**
 * Set current documents
 * @param context
 * @param {Number|number}  id
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}[]>}
 */
async function setDocuments(context, id) {
  if (id < 0) {
    context.commit(SET_DOCUMENTS, []);
    return Promise.resolve([]);
  }
  return countChildren(id)
    .then(response => {
      return response.data;
    })
    .then(count => {
      return getChildren(id, count);
    })
    .then(response => {
      const data = response.data;
      const docs = filterFilesAndDocuments(data);

      context.commit(SET_DOCUMENTS, docs);
      return response.data;
    })
    .catch(error => handleError(error));
}

/**
 * Set current documents
 * @param context
 * @param {{id:Number, recordIndex:Number, count:Number}} payload
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}[]>}
 */
async function setChildrenDocuments(context, payload) {
  const id = payload?.id ?? -1;
  if (id < 0) {
    context.commit(SET_DOCUMENTS, []);
    return Promise.resolve([]);
  }
  return getRecordChildren(payload)
    .then(response => {
      const data = response.data;
      const docs = filterFilesAndDocuments(data);

      context.commit(SET_DOCUMENTS, docs);
      return response.data;
    })
    .catch(error => handleError(error));
}

/**
 *  Clear current list of documents
 * @param context
 */
function clearDocuments(context) {
  context.commit(SET_DOCUMENTS, []);
}

/**
 * Set File(s) of current selected file-record
 * @param context
 * @param {Number|number} id
 * @return {Promise<never>}
 */
async function setFileDocuments(context, id) {
  try {
    const response = await getRecord(id);
    const record = response?.data;

    const files = [];
    if ((record?.recordTypeId ?? recordType.RECORD) === recordType.FILE) {
      files.push(record);
    }

    const docs = filterFilesAndDocuments(files);

    context.commit(SET_DOCUMENTS, docs);
  } catch (e) {
    return await handleError(e, `Unable to set Files. (id: ${id})`);
  }
}

/**
 * Set File Versions of current selected file
 * @param context
 * @param {Number|number} id
 * @return {Promise<never>}
 */
async function setFileVersions(context, id) {
  try {
    const response = await getFileVersions(id);
    const versions = response?.data ?? [];

    console.log(`setFileVersions() versions:`, versions);

    const fileVersions = [];

    versions.forEach(v => {
      fileVersions.push(createRecordFileVersion(v));
    });

    context.commit(SET_FILE_VERSIONS, fileVersions);
  } catch (e) {
    return await handleError(e, `Unable to set File Versions. (id: ${id})`);
  }
}

/**
 * Set File Version of current selected file
 * @param context
 * @param payload
 * @return {Promise<{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}>}
 */
async function setFileVersion(context, payload) {
  const id = payload?.id ?? -1;
  const version = payload?.version ?? -1;

  try {
    const fileVersion = (await getFileVersion(id, version))?.data;
    const category = (await getCategory(fileVersion?.categoryId ?? -1))?.data;

    const payload = {
      version: fileVersion,
      category: category
    };

    context.commit(SET_FILE_VERSION, payload);

    return fileVersion;
  } catch (e) {
    return await handleError(
      e,
      `Unable to set File Version. (id: ${id}), (version: ${version})`
    );
  }
}

/**
 * save Record
 * @param context
 * @param {{ record, category }} payload
 * @return {Promise<{record}>}
 */
async function saveRecord(context, payload) {
  const recordType = payload?.record?.recordType ?? "record";
  const name = payload?.record?.name ?? "";
  const id = payload?.record?.id ?? -1;

  try {
    // Record Category
    const category = payload?.category;
    const categoryId = category?.id ?? -1;
    const fieldValues = updatableRecordFieldValues(payload?.record);

    const model = {
      name: name,
      categoryId: categoryId,
      fieldValues: fieldValues ?? [],
      flyingFields: payload?.record?.flyingFields ?? [],
      isLink: payload?.record?.isLink ?? false,
      parentId: payload?.record?.parentId ?? -1
    };

    const response = await apiUpdateRecord(model, id);
    const record = ensureValidRecordFiledValues(response?.data, category);

    const updatePayload = {
      record: record,
      category: category
    };

    context.commit(SET_RECORD_EDITED, updatePayload);

    return record;
  } catch (e) {
    return await handleError(
      e,
      `Unable to set save ${recordType} (${id}) ${name}`
    );
  }
}

/**
 * Delete Record
 * @param context
 * @param payload
 * @return {Promise<Boolean>}
 */
async function deleteRecord(context, payload) {
  const id = payload?.id ?? -1;

  try {
    const response = await apiDeleteRecord(id);
    const record = response?.data;

    context.commit(SET_RECORD_DELETED, id);

    return record;
  } catch (e) {
    return await handleError(e, `Unable to delete record. (id: ${id})`);
  }
}

/**
 * set Search Category
 * @param context
 * @param {Number|number} id
 * @return {Promise<any>}
 */
async function setSearchCategory(context, id) {
  if (id < 0) {
    return new Promise(resolutionFunc => {
      context.commit(SET_DOCUMENT_SEARCH_CATEGORY, categoryAll);
      resolutionFunc(categoryAll);
    });
  }
  return getCategory(id)
    .then(response => {
      context.commit(SET_DOCUMENT_SEARCH_CATEGORY, response.data);
      return response.data;
    })
    .catch(e => handleError(e, `Unable to set Search Category. (id: ${id})`));
}

/**
 * Lock Record
 * @param context
 * @param {Number|number} id
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function lockRecord(context, id) {
  return apiLockRecord(id)
    .then(response => {
      context.commit(SET_RECORD_LOCK, response?.data ?? false);
      return context.state?.record;
    })
    .catch(e => handleError(e, `Unable to Lock Record (id: ${id})`));
}

/**
 * Un Lock Record
 * @param context
 * @param {Number|number} id
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function unLockRecord(context, id) {
  return apiUnLockRecord(id)
    .then(response => {
      context.commit(SET_RECORD_LOCK, response?.data ?? false);
      return context.state?.record;
    })
    .catch(e => handleError(e, `Unable to Un Lock Record (id: ${id})`));
}

/**
 * set Draft Record
 * @param context
 * @param {Number|number} id
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function setDraftRecord(context, id) {
  return apiSetDraftRecord(id)
    .then(response => {
      context.commit(SET_RECORD_DRAFT, response?.data ?? false);
      return context.state?.record;
    })
    .catch(e => handleError(e, `Unable to set Draft Record (id: ${id})`));
}

/**
 * set Read Only Record
 * @param context
 * @param {Number|number} id
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function setReadOnlyRecord(context, id) {
  return apiSetReadOnlyRecord(id)
    .then(response => {
      context.commit(SET_RECORD_READ_ONLY, response?.data ?? false);
      return context.state?.record;
    })
    .catch(e => handleError(e, `Unable to set Read Only Record (id: ${id})`));
}

/**
 * Upload File to an empty record
 * @param context
 * @param {{id:Number, file:any, uploadFileModel:{Extension: undefined, Draft: boolean, InsertFileOption: number, Comments: string, Enqueue: boolean}}} payload
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function uploadFile(context, payload) {
  const id = payload?.id ?? -1;

  try {
    context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

    const file = payload?.file;
    const response = await apiUploadFile(
      id,
      file,
      payload.uploadFileModel,
      event => {
        const total = event?.total ?? 0;
        if (total > 0) {
          const progress = Math.round((100 * event.loaded) / total);
          context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
        }
      }
    );

    context.commit(UPDATE_RECORD_FILE_VERSION, response?.data);
    await setFileVersions(context, id);

    return response?.data;
  } catch (e) {
    return await handleError(e, `Unable to upload File (id: ${id}`);
  }
}

/**
 * upload Compound Document File
 * @param context
 * @param {{id: Number, file: any, insertBeforePage: Number, enqueue: Boolean, comments: string}} payload
 * @return {Promise<Array<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>>} File Model
 */
async function uploadCompoundDocumentFile(context, payload) {
  const id = payload?.id ?? -1;

  try {
    context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

    const file = payload?.file;
    const response = await apiUploadCompoundDocumentFile(
      id,
      file,
      payload?.insertBeforePage ?? false,
      payload?.enqueue ?? false,
      payload?.comments ?? "",
      event => {
        const total = event?.total ?? 0;
        if (total > 0) {
          const progress = Math.round((100 * event.loaded) / total);
          context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
        }
      }
    );

    //context.commit(SET_COMPOUND_DOCUMENT_NEW_FILE_VERSION, response?.data);
    console.log(
      `${scriptName} uploadCompoundDocumentFile() response?.data`,
      response?.data
    );

    // Update Records List after file insertion in Compound Document
    // response.data is child files of compound document
    const compoundPayload = {
      id: id,
      recordList: response?.data
    };
    await updateCompoundFileList(context, compoundPayload);

    return response?.data;
  } catch (e) {
    console.error(e?.toString());
    return await handleError(
      e,
      `Unable to upload File of Compound Document (id: ${id})`
    );
  }
}

/**
 * Update Compound File List
 * @param context
 * @param {{recordList: Array<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>, id: (Number|number)}} payload
 */
async function updateCompoundFileList(context, payload) {
  try {
    context.commit(SET_RECORDS, payload.recordList);

    //Necessary to update record Info when upload is done
    //TODO: Find better Approach
    await getRecordInfo(context, payload.id);
  } catch (e) {
    return await handleError(e, `Unable to Get Record Info (id: ${payload.id}`);
  }
}

/**
 * Get Record Info
 * @param context
 * @param {Number|number} id record id
 * @return {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
 */
async function getRecordInfo(context, id) {
  try {
    const recordInfo = (await apiGetRecordInfo(id)).data;

    context.commit(UPDATE_RECORD_INFO, recordInfo);

    return recordInfo;
  } catch (e) {
    return await handleError(e, `Unable to Get Record Info (id: ${id}`);
  }
}

/**
 * check Out File
 * @param context
 * @param {Number|number} id record id
 * @return {Promise<{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}>} RecordItemModel
 */
async function checkOutFile(context, id) {
  try {
    const recordInfo = (await apiCheckOutFile(id)).data;

    context.commit(UPDATE_RECORD_INFO, recordInfo);

    // Update file history
    await setFileVersions(context, recordInfo.id);

    return recordInfo;
  } catch (e) {
    return await handleError(e, `Unable to check Out File (id: ${id}`);
  }
}

/**
 * Check Out the File as a PDF document, so that e-signature can be applied locally at the client station
 * @param context
 * @param {Number|number} id record id
 * @return {Promise<{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}>} RecordItemModel
 */
async function checkOutFileForSign(context, id) {
  try {
    const recordInfo = (await apiCheckOutFileForSign(id)).data;

    console.log("checked Out File For Sign recordInfo:", recordInfo);

    context.commit(UPDATE_RECORD_INFO, recordInfo);

    // Update file history
    await setFileVersions(context, recordInfo.id);

    return recordInfo;
  } catch (e) {
    return await handleError(e, `Unable to check Out File (id: ${id}`);
  }
}

/**
 * release File
 * @param context
 * @param {Number|number} id record id
 * @return {Promise<{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}>} RecordItemModel
 */
async function releaseFile(context, id) {
  try {
    const recordInfo = (await apiReleaseFile(id)).data;

    context.commit(UPDATE_RECORD_INFO, recordInfo);

    // Update file history
    if (recordInfo.recordTypeId === recordType.FILE) {
      await setFileVersions(context, recordInfo.id);
    }

    return recordInfo;
  } catch (e) {
    return await handleError(e, `Unable to check Out File (id: ${id}`);
  }
}

/**
 * check In File
 * @param context
 * @param {{id: Number, file: any, uploadFileModel:{Extension: undefined, Draft: boolean, InsertFileOption: number, Comments: string, Enqueue: boolean}}} payload
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function checkInFile(context, payload) {
  const id = payload?.id ?? -1;

  try {
    context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

    // Check In File
    const file = payload?.file;
    const response = await apiCheckInFile(
      id,
      file,
      payload.uploadFileModel,
      event => {
        const total = event?.total ?? 0;
        if (total > 0) {
          const progress = Math.round((100 * event.loaded) / total);
          context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
        }
      }
    );

    context.commit(UPDATE_RECORD_FILE_VERSION, response?.data);

    // Update file history
    await setFileVersions(context, id);

    return response?.data;
  } catch (e) {
    return await handleError(e, `Unable to upload File (id: ${id}`);
  }
}

/**
 * check In EditedRecord
 * @param context
 * @param  payload
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function checkInEditedDocument(context, payload) {
  try {
    console.log(payload);
    const response =
      payload.version === 0
        ? await apiCheckInScannedFileToRecord(payload)
        : await apiCheckInEditedDocument(payload);

    context.commit(UPDATE_RECORD_FILE_VERSION, response?.data);

    // Update file history
    await setFileVersions(context, payload.id);

    return response;
  } catch (e) {
    return await handleError(
      e,
      `Unable to checkin Edited File by id: ${payload?.id ?? -1}`
    );
  }
}

/**
 * download Image File Version
 * @param context
 * @param payload
 * @return {Promise<*>}
 */
async function downloadImageFileVersion(context, payload) {
  try {
    return await apiDownloadImageFileVersion(payload);
  } catch (e) {
    return await handleError(
      e,
      `Unable to download File Version by (id: ${payload?.id ??
        -1} version: ${payload?.version ?? -1})`
    );
  }
}

/**
 * set new/edit Record Category
 * @param context
 * @param {number} payload
 * @return {Promise<void>}
 */
async function setNewRecordCategory(context, payload) {
  const id = payload ?? -1;

  try {
    const response = await getCategory(id);
    const categoryNew = response?.data;

    if (context.state.recordNew) {
      const recordNew = context.state.recordNew;
      const categoryOld = context.state.recordNewCategory;

      console.log(
        `setNewRecordCategory()  recordNew.categoryId:`,
        recordNew?.categoryId
      );
      console.log(`setNewRecordCategory()  categoryNew:`, categoryNew);
      console.log(`setNewRecordCategory()  categoryOld:`, categoryOld);

      await changeRecordCategory(recordNew, categoryOld, categoryNew);

      context.commit(SET_RECORD_NEW_AND_CATEGORY, {
        record: recordNew,
        category: categoryNew
      });
    } else {
      context.commit(SET_RECORD_NEW_CATEGORY, categoryNew);
    }
  } catch (e) {
    return await handleError(
      e,
      `Unable set New Record Category by (category id: ${id})`
    );
  }
}

/**
 * set current record Text field value
 * @param context
 * @param {number} payload
 * @return {Promise<void>}
 */
async function setRecordTextFieldValue(context, payload) {
  const fieldId = payload ?? -1;
  const recordId = context?.state?.record?.id ?? -1;

  try {
    const response = await getRecordTextFieldValue(recordId, fieldId);

    context.commit(SET_RECORD_TEXT_FIELD_VALUE, {
      id: fieldId,
      value: response?.data ?? ""
    });
  } catch (e) {
    return await handleError(
      e,
      `Unable set current record Text Field value by (Record id: ${recordId} (Field id: ${fieldId})`
    );
  }
}

/**
 * set Database Lookup Data for current inserting/editing record
 * @param context
 * @return {Promise<{id:number, value:any}[]>}
 */
async function setDatabaseLookupData(context) {
  // current inserting/editing record category
  const category = context?.state?.recordNewCategory;
  const categoryId = category?.id ?? -1;

  try {
    // current inserting/editing record
    const record = context?.state?.recordNew;
    const inputMappings = getInputMappings(record, category);
    const response = await lookup(categoryId, inputMappings);

    context.commit(SET_RECORD_LOOKUP_DATA, response?.data);

    return Promise.resolve(response?.data);
  } catch (e) {
    return await handleError(
      e,
      `Unable set set Database Lookup Data by (category id: ${categoryId})`
    );
  }
}

/**
 * check In Scanned File of current selected record
 * TODO: Determine if still Used
 * @param context
 * @param {{insert: boolean, insertAtBeginning: boolean}|undefined} payload
 * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
 */
async function checkInScannedFile(context, payload = undefined) {
  const record = context?.state?.record;
  const id = context?.state?.record?.id ?? -1;
  const recordTypeId = record?.recordTypeId ?? -1;

  try {
    let response = undefined;

    switch (recordTypeId) {
      case recordType.RECORD: {
        response = await apiCheckInScannedFileToRecord(id);
        context.commit(UPDATE_RECORD_FILE_VERSION, response?.data);
        break;
      }
      case recordType.FILE: {
        const insert = payload?.insert ?? false;
        const insertAtBeginning = payload?.insertAtBeginning ?? false;
        response = await apiCheckInScannedFileToFile(
          id,
          insert,
          insertAtBeginning
        );
        context.commit(UPDATE_RECORD_FILE_VERSION, response?.data);

        // Update file history
        await setFileVersions(context, id);
        break;
      }
      case recordType.DOCUMENT: {
        throw `Check In Scanned image file to: ${findRecordType(recordTypeId)
          ?.name ?? "unknown"} not implemented yet.`;
      }
      default: {
        throw `Check In Scanned image file to: ${findRecordType(recordTypeId)
          ?.name ?? "unknown"} not supported.`;
      }
    }

    return response?.data;
  } catch (e) {
    return await handleError(
      e,
      `Unable to Check In uploaded scanned image file (record id: ${id})`
    );
  }
}

/**
 * move Record
 * @param context
 * @param {{recordId:Number,destinationId:Number}} payload
 * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
 */
const moveRecord = async (context, payload) => {
  return await apiMoveRecord(payload);
  //context.commit(SET_RECORD, response?.data);
};

/**
 * copy Record
 * @param context
 * @param {{recordId:Number,destinationId:Number}} payload
 * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
 */
const copyRecord = async (context, payload) => {
  return await apiCopyRecord(payload);
  //context.commit(SET_RECORD, response?.data);
};

/**
 * move Record Shortcut
 * @param context
 * @param {{recordId:Number,recordParentId:Number,destinationId:Number}} payload
 * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
 */
const moveRecordShortcut = async (context, payload) => {
  return await apiMoveRecordShortcut(payload);
};

/**
 * copy Record Shortcut
 * @param context
 * @param {{recordId:Number,recordParentId:Number,destinationId:Number}} payload
 * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
 */
const copyRecordShortcut = async (context, payload) => {
  return await apiCopyRecordShortcut(payload);
};

/**
 * create Record Shortcut
 * @param context
 * @param {{recordId:Number,destinationId:Number}} payload
 * @return {Promise<{Boolean}>}
 */
const createShortcut = async (context, payload) => {
  return await apiCreateShortcut(payload);
};

/**
 * DeleteRecordShortcut
 * @param context
 * @param {{id:Number, parentId: Number}} payload
 * @return {Promise<{Boolean}>}
 */
const deleteRecordShortcut = async (context, payload) => {
  const response = await apiDeleteRecordShortcut(payload);
  if (response) context.commit(SET_RECORD_DELETED, payload.id);
  return response?.data;
};

/**
 * Restore Record
 * @param context
 * @param id
 * @return {Promise<{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}>}
 */
const restoreRecord = async (context, id) => {
  try {
    const response = await restoreDeletedRecord(id);
    const record = response?.data;
    if (record) context.commit(UPDATE_RECORD_INFO, record);
    return record;
  } catch (e) {
    return await handleError(e, `Unable to Restore Record`);
  }
};

export {
  setRecord,
  setDocument,
  setDocuments,
  clearDocuments,
  setFileDocuments,
  setSearchCategory,
  lockRecord,
  unLockRecord,
  setDraftRecord,
  setReadOnlyRecord,
  uploadFile,
  uploadCompoundDocumentFile,
  checkOutFile,
  checkOutFileForSign,
  checkInFile,
  releaseFile,
  setFileVersions,
  setFileVersion,
  downloadImageFileVersion,
  saveRecord,
  deleteRecord,
  setNewRecordCategory,
  setRecordTextFieldValue,
  setDatabaseLookupData,
  checkInScannedFile,
  checkInEditedDocument,
  updateCompoundFileList,
  moveRecord,
  copyRecord,
  createShortcut,
  deleteRecordShortcut,
  copyRecordShortcut,
  moveRecordShortcut,
  setRecordShortcut,
  setChildrenDocuments,
  restoreRecord
};

// import moment from "moment";
//
// export const FORMAT_INPUT_DATE = "YYYY-MM-DD";
// export const dateInput = value =>
//   value ? moment(value).format(FORMAT_INPUT_DATE) : value;

import { isValidDate } from "@/utils";

/**
 * To Short Local String
 * @param {String|string} value
 * @returns {string}
 */
export const toShortLocalString = value => {
  try {
    if (!isValidDate(value)) {
      return "";
    }

    const date = new Date(value);

    return date.toLocaleString(["en-US"], {
      month: "short",
      day: "2-digit",
      year: "numeric"
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * to Local Date Time String
 * @param {String|string} value
 * @return {string} Local Date Time String
 * format e.g.: `12/11/2012, 7:00:00 PM` in en-US locale with time zone
 */
export const toLocalDateTimeString = value => {
  try {
    if (!isValidDate(value)) {
      return "";
    }

    const date = new Date(value);

    return date.toLocaleString(["en-US"]);
  } catch (e) {
    console.error(e);
  }
};

export const toLocalDateString = value => {
  try {
    if (!isValidDate(value)) {
      return "";
    }

    const date = new Date(value);

    return date.toLocaleString(["en-US"], {
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Convert Date to YYYY-MM-DD HH:MM format
 * Currently to Match Vuetify Calendar type
 * Expand on Demand
 * @param {String | Date} date - eg 2023-07-12T09:54:06.697+05:00
 * @return {string}
 */
export const convertToYYYYMMDDHHMM = date => {
  if (!date) return "";
  try {
    const inputDateTime = toLocalDateTimeString(date);
    const [datePart, timePart] = inputDateTime.split(", ");
    const [month, day, year] = datePart.split("/");
    const [time, period] = timePart.split(" ");
    const [hour, minute] = time.split(":");
    const isPM = period === "PM";

    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
    const formattedHour = isPM
      ? Number(hour) === 12
        ? 12
        : Number(hour) + 12
      : Number(hour);
    const formattedTime = `${formattedHour
      .toString()
      .padStart(2, "0")}:${minute}`;

    return `${formattedDate} ${formattedTime}`;
  } catch (e) {
    console.error(e);
  }
};

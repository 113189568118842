// design
import { iconProjects, iconTrash } from "@/design/icon/iconConst";

// model
import { projectLabel } from "@/model/workflow/project/projectModel";

/**
 * project Template Label
 * @type {string} user friendly project Template Label
 */
const projectTemplateLabel = `${projectLabel} Template`;

/**
 * project template Operation
 * @type {Readonly<{select: string, delete: string, open: string}>}
 */
const projectTemplateOperation = Object.freeze({
  select: "select",
  open: "open",
  delete: "delete"
});

/**
 * project template Operations
 * @type {({modelName: string, icon: string, label: string})[]}
 */
const projectTemplateOperations = Object.freeze([
  {
    name: projectTemplateOperation.select,
    label: `Select`,
    icon: iconProjects
  },
  {
    name: projectTemplateOperation.open,
    label: `Open`,
    icon: iconProjects
  },
  {
    name: projectTemplateOperation.delete,
    label: `Delete`,
    icon: iconTrash
  }
]);

/**
 * find Template Operation
 * @param {string} name Template Operation modelName
 * @return {{name: string, icon: string, label: string}} roject template Operation
 */
const findProjectTemplateOperation = name => {
  return projectTemplateOperations.find(el => el.name === name);
};

/**
 * format Project Template Action Name
 * @param {string} name Project Template Operation name
 * @return {string}}
 */
const formatProjectTemplateActionName = name => {
  return `${findProjectTemplateOperation(name)?.label} ${projectTemplateLabel}`;
};

/**
 * project Template Entity
 * @type {Readonly<{template: number, category: number}>}
 */
const projectTemplateEntity = Object.freeze({
  category: 0,
  template: 1
});

/**
 * project Template Entities
 * @type {({type: number, name: string})[]}
 */
const projectTemplateEntities = Object.freeze([
  {
    type: projectTemplateEntity.category,
    name: `Category`
  },
  {
    type: projectTemplateEntity.template,
    name: `Template`
  }
]);

/**
 * find Project Template Entity
 * @param {number} type project Template Entity type
 * @return {{type: number, name: string}} Project Template Entity
 */
const findProjectTemplateEntity = type => {
  return projectTemplateEntities.find(el => el.type === type);
};

export {
  projectTemplateOperation,
  projectTemplateOperations,
  findProjectTemplateOperation,
  formatProjectTemplateActionName,
  projectTemplateLabel,
  projectTemplateEntity,
  projectTemplateEntities,
  findProjectTemplateEntity
};

// model
import {
  moduleNames,
  moduleTitles,
  moduleIcons,
  appModules
} from "@/model/solution/moduleModel";

// utilities
import {
  getLocalStorageItem,
  setLocalStorageItem,
  repoUserLocalStorageKey
} from "@/utils/localStorageUtility";

/**
 * Get Application module name
 * @param {String|string} name Application module name
 * @returns {{name: string, icon: string, title: string, feature: string, public: boolean}}
 */
const findAppModule = name => appModules.find(el => el.name === name);

/**
 * Solution local Store Keys
 * @type {Readonly<{moduleName: string}>}
 */
const localStorageKey = Object.freeze({
  moduleName: "module"
});

/**
 * Get Application module name from local Storage
 * @returns {String|string|null|undefined} Application module name
 */
const getStoredAppModuleName = () =>
  getLocalStorageItem(repoUserLocalStorageKey(localStorageKey.moduleName));

/**
 * Store Application module name to the local Storage
 * @param {String|string} name Application module name
 */
const storeAppModuleName = name =>
  setLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.moduleName),
    name
  );

export {
  moduleNames,
  moduleIcons,
  moduleTitles,
  findAppModule,
  getStoredAppModuleName,
  storeAppModuleName
};

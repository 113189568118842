// services
import { storeSelectedModuleItemId } from "@/services/inbox/inboxModuleService";

// mutation-types
import {
  SET_DRAWER_MINI,
  SET_RECORD,
  SET_DOCUMENTS,
  SET_DOCUMENT,
  SET_RECORD_DRAFT,
  SET_RECORD_LOCK,
  SET_RECORD_READ_ONLY,
  UPDATE_RECORD_FILE_VERSION,
  SET_UPLOAD_FILE_PROGRESS,
  UPDATE_RECORD_STATUS,
  SET_FILE_VERSIONS,
  SET_FILE_VERSION,
  SET_RECORD_MODE,
  SET_RECORD_EDIT,
  SET_RECORD_EDITED,
  SET_RECORD_NEW_CATEGORY,
  SET_RECORD_TEXT_FIELD_VALUE,
  SET_RECORD_LOOKUP_DATA,
  SET_COMPOUND_DOCUMENT_NEW_FILE_VERSION,
  UPDATE_RECORD_INFO,
  SET_DOCUMENT_CHILDREN,
  SET_RECORD_NEW_AND_CATEGORY
} from "@/store/shared/mutationTypes/record/record-mutation-types";

import {
  SET_TASKS,
  SET_TASK,
  UPDATE_TASK_ASSIGNEE
} from "@/store/inbox/mutation-types";

import {
  setCompoundDocumentNewFileVersions,
  setCurrentRecord,
  setDocument,
  setFileVersion,
  setRecordDraft,
  setRecordEdit,
  setRecordEdited,
  setRecordLock,
  setRecordLookupData,
  setRecordMode,
  setRecordReadOnly,
  updateRecordStatus,
  setRecordTextFieldValue,
  setUploadFileProgress,
  updateFileVersionInfo,
  updateRecordInfos,
  setDocumentChildren,
  setRecordNewAndCategory
} from "@/store/shared/mutations/record/record-mutations";
import { moduleNames } from "@/model/solution/moduleModel";
import {
  SET_PROJECT_CANCELED,
  SET_PROJECT_DELETED,
  SET_PROJECT_RESUMED,
  SET_PROJECT_SUSPENDED
} from "@/store/shared/mutationTypes/project/project-mutation-types";
import {
  setProjectCanceled,
  setProjectDeleted,
  setProjectResumed,
  setProjectSuspended,
  updateTaskAssignee
} from "@/store/shared/mutations/project/project-mutations";

const mutations = {
  /**
   * Set drawer to mini state
   * @param state
   * @param payload
   */
  [SET_DRAWER_MINI]: (state, payload) => {
    state.drawerMini = payload || false;
  },
  /**
   * Set Workflow user tasks state
   * @param state
   * @param payload
   */
  [SET_TASKS](state, payload) {
    state.tasks = payload;
    console.log(SET_TASKS, state.tasks);
  },
  /**
   * Set Workflow user task state
   * @param state
   * @param payload
   */
  [SET_TASK](state, payload) {
    state.task = payload;
    console.log(SET_TASK, state.task);
    storeSelectedModuleItemId(state.task?.taskId ?? -1);
  },

  /**
   * Set current 'inbox' record
   * @param state
   * @param {{record, category}} payload
   */
  [SET_RECORD](state, payload) {
    setCurrentRecord(state, payload);
    console.log(`${moduleNames.MyWork}.${SET_RECORD} record:`, state.record);
    console.log(
      `${moduleNames.MyWork}.${SET_RECORD} recordCategory:`,
      state.recordCategory
    );
  },

  /**
   * update Record Status
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [UPDATE_RECORD_STATUS](state, payload) {
    updateRecordStatus(state, payload);
  },

  /**
   * update Record Infos of current record and all related records
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload RecordItemModel
   */
  [UPDATE_RECORD_INFO](state, payload) {
    updateRecordInfos(state, payload);
  },

  [SET_DOCUMENTS]: (state, payload) => {
    state.documents = payload;
    console.log(SET_DOCUMENTS, state.documents);
  },

  /**
   * Set current selected 'inbox' document
   * @param state
   * @param {{document, category}} payload
   */
  [SET_DOCUMENT](state, payload) {
    setDocument(state, payload);
    console.log(
      `${moduleNames.MyWork}.${SET_DOCUMENT} document:`,
      state.document
    );
    console.log(
      `${moduleNames.MyWork}.${SET_DOCUMENT} documentCategory:`,
      state.documentCategory
    );
  },

  /**
   * Set current 'inbox' record lock
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [SET_RECORD_LOCK](state, payload) {
    setRecordLock(state, payload);
  },

  /**
   * Set current 'folder' record Read Only
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [SET_RECORD_READ_ONLY](state, payload) {
    setRecordReadOnly(state, payload);
  },

  /**
   * Set current 'folder' record as Draft
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [SET_RECORD_DRAFT](state, payload) {
    setRecordDraft(state, payload);
  },

  /**
   * SET RECORD FILE VERSION
   * @param state
   * @param payload
   */
  [UPDATE_RECORD_FILE_VERSION](state, payload) {
    updateFileVersionInfo(state, payload);
  },

  /**
   * SET UPLOAD FILE PROGRESS
   * @param state
   * @param {Number|number} progress
   */
  [SET_UPLOAD_FILE_PROGRESS](state, progress) {
    setUploadFileProgress(state, progress);
  },

  [SET_FILE_VERSIONS](state, payload) {
    state.versions = payload || [];
    console.log(SET_FILE_VERSIONS, state.versions);
  },

  /**
   * Set current 'inbox' File Version
   * @param state
   * @param {{version, category}} payload
   */
  [SET_FILE_VERSION](state, payload) {
    setFileVersion(state, payload);
    console.log(
      `${moduleNames.MyWork}.${SET_FILE_VERSION} version:`,
      state.version
    );
    console.log(
      `${moduleNames.MyWork}.${SET_FILE_VERSION} versionCategory:`,
      state.versionCategory
    );
  },

  [SET_RECORD_MODE](state, payload) {
    setRecordMode(state, payload);
  },

  [SET_RECORD_EDIT](state, payload) {
    setRecordEdit(state, payload);
  },

  [SET_RECORD_EDITED](state, payload) {
    setRecordEdited(state, payload);
  },

  [SET_RECORD_NEW_AND_CATEGORY](state, payload) {
    setRecordNewAndCategory(state, payload);
  },

  [SET_RECORD_NEW_CATEGORY](state, payload) {
    state.recordNewCategory = payload;
  },

  [SET_RECORD_TEXT_FIELD_VALUE](state, payload) {
    setRecordTextFieldValue(state, payload);
  },

  [SET_RECORD_LOOKUP_DATA](state, payload) {
    setRecordLookupData(state, payload);
  },

  [SET_COMPOUND_DOCUMENT_NEW_FILE_VERSION](state, payload) {
    setCompoundDocumentNewFileVersions(state, payload);
  },

  /**
   * Set document children of specified document
   * @param state
   * @param {{document, children}} payload
   */
  [SET_DOCUMENT_CHILDREN](state, payload) {
    setDocumentChildren(state, payload);
  },

  [SET_PROJECT_DELETED](state, payload) {
    setProjectDeleted(state, payload);
  },

  [SET_PROJECT_CANCELED](state, payload) {
    setProjectCanceled(state, payload);
  },

  [SET_PROJECT_SUSPENDED](state, payload) {
    setProjectSuspended(state, payload);
  },

  [SET_PROJECT_RESUMED](state, payload) {
    setProjectResumed(state, payload);
  },

  [UPDATE_TASK_ASSIGNEE](state, payload) {
    if (state.task) updateTaskAssignee(state, payload);
  }
};

export default mutations;

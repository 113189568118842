import { isBlob, trimEnd } from "@/utils";

/**
 * Handle Service Error
 * @param error
 * @param {string|undefined} message
 * @return {Promise<never>}
 */
const handleError = async (error, message = undefined) => {
  let errorMsg;

  console.error("error:", error);
  // console.warn("error?.response:", error?.response);

  if (error?.response) {
    //
    // Handle Microsoft.AspNetCore.Mvc.ProblemDetails gotten from error.response.data
    //
    const status = error?.response?.status;
    console.warn("response status:", status);

    const statusText = error?.response?.statusText;
    console.warn("response status Text:", statusText);

    const data = error.response?.data;

    let title = data?.title ?? "";
    let detail = data?.detail ?? "";

    if (isBlob(data)) {
      // The text() method in the Blob interface returns a Promise
      // that resolves with a string containing the contents of the blob,
      // interpreted as UTF-8.
      const error = await data.text();
      const problem = error ? JSON.parse(error) : undefined;
      // Set problem data
      title = problem?.title;
      detail = problem?.detail;
    }
    if (detail) {
      console.warn("problem detail:", detail);
    }
    if (!title) {
      title = error;
    }
    errorMsg = message ? `${trimEnd(message, ".")}. ${title}` : title;
  } else {
    errorMsg = message
      ? `${trimEnd(message, ".")}. ${error?.toString()}`
      : error?.toString();
  }

  console.error(errorMsg);

  return Promise.reject(errorMsg);
};

const br = " <br> ";

/**
 * format user friendly Error Message
 * @param {string} problem
 * @param {string} msg
 * @param {string} details
 * @param {string} hint
 * @return {string} returns user friendly formatted Error Message
 */
const errorMessage = (problem, msg, details, hint = undefined) => {
  return hint
    ? `${problem}.${br}${msg}${br}${details}.${br}${hint}`
    : `${problem}.${br}${msg}${br}${details}.`;
};

export { handleError, errorMessage };

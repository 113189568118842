import {
  iconAdd,
  iconEdit,
  iconTrash,
  iconClear,
  iconRefresh,
  iconSave,
  iconCheck,
  iconClose,
  iconPrinter,
  iconLogOut,
  iconConfigure,
  iconAccount,
  iconDatabase
} from "@/design/icon/iconConst";

/**
 * Command Names
 * @type {{cancel: string, add: string, new: string, edit: string, save: string, clear: string, refresh: string, reset: string, ok: string, delete: string}}
 */
const commandNames = Object.freeze({
  ok: "OK",
  cancel: "Cancel",
  close: "Close",
  save: "Save",
  add: "Add",
  new: "New",
  edit: "Edit",
  details: "Details",
  delete: "Delete",
  refresh: "Refresh",
  reset: "Reset",
  clear: "Clear",
  jump: "Jump",
  jumpTo: "Jump To",
  print: "Print",
  enter: "Enter",
  logOut: "Log Out",
  configure: "Configure",
  changeRepository: "Change Repository"
});

/**
 * Command Icons
 * @type {{cancel: string, add: string, new: string, edit: string, save: string, clear: string, refresh: string, reset: string, ok: string, delete: string}}
 */
const commandIcons = Object.freeze({
  ok: iconCheck,
  cancel: iconClose,
  save: iconSave,
  add: iconAdd,
  new: iconAdd,
  edit: iconEdit,
  delete: iconTrash,
  refresh: iconRefresh,
  reset: iconRefresh,
  clear: iconClear,
  print: iconPrinter,
  logOut: iconLogOut,
  configure: iconConfigure,
  account: iconAccount,
  iconDatabase: iconDatabase
});

/**
 * command Status
 * @type {Readonly<{unavailable: number, disabled: number, enabled: number}>}
 */
const commandStatus = Object.freeze({
  unavailable: 0,
  enabled: 1,
  disabled: 2
});

/**
 * command Statuses
 * @type {Readonly<[{text: string, status: number}]>}
 */
const commandStatuses = Object.freeze([
  {
    status: commandStatus.unavailable,
    text: "Unavailable"
  },
  {
    status: commandStatus.enabled,
    text: "Enabled"
  },
  {
    status: commandStatus.disabled,
    text: "disabled"
  }
]);

/**
 * find Command Status
 * @param {number} status Command Status
 * @return {{text: string, status: number}} Command Status
 */
const findCommandStatus = status => {
  return commandStatuses.find(el => el.status === status)?.status;
};

export {
  commandNames,
  commandIcons,
  commandStatus,
  commandStatuses,
  findCommandStatus
};

// services
import {
  checkInEditedDocument,
  checkOutFile,
  downloadFile,
  downloadFileAsPdf,
  downloadFileVersionAsPdf,
  downloadImageFileVersion as apiDownloadImageFileVersion,
  getFileVersion,
  getSearchHighlights,
  releaseFile,
  checkInScannedFileToRecord,
  getRecordInfo,
  uploadFileToEmptyRecordWip,
  uploadFileToCheckedOutFile,
  releaseCheckedOutFile,
  releaseWIPFile,
  releaseWIPRecord,
  scanToCheckedInFile,
  scanToCompoundDocument,
  checkInFile,
  downloadCompoundDocumentAsPdf,
  downloadFileVersion,
  importAnnotations,
  exportAnnotations,
  getOcrText,
  saveOcrText,
  uploadFileToFileWip,
  downloadEmptyRecordWipAsPDFDocument,
  downloadEmptyRecordWip,
  downloadFileWip,
  downloadFileWipAsPDFDocument,
  downloadPdfFileVersion,
  downloadEditFile,
  downloadEditFileAsPdf,
  downloadExportFileVersion,
  downloadExportFileVersionAsPdf
} from "@/services/api/apiContent";

import { handleError } from "@/services/error/errorService";

// shared mutation types
import {
  SET_DOCUMENT,
  SET_UPLOAD_FILE_PROGRESS
} from "../shared/mutationTypes/record/record-mutation-types";

// document mutation types
import { SET_SEARCH_HIGHLIGHTS } from "./mutation-types";

/**
 * Current Script Name
 * @type {string}
 */
const scriptName = "actions";

const actions = {
  /**
   * Get File Version
   * @param context
   * @param {{id:number, version:number}} payload
   * @return {Promise<{outFileType:string, isCheckedOutByOwner:boolean, canCheckOutForSigning:boolean, agendaItemOperations:Array<{name: string, allowed: boolean, valid: boolean}>, extension:string, checkOutExtension:string, version:number, versionDate:string,versionOwner:string, pageCount:number, fileSize:number, comments:string, id:number, name:string, parentId:number, ancestor:{id:number, name:string, categoryId:number, recordType:{id:number, name:string}, code:number, flags:number, createdBy:{id:number, name:string}, updatedBy:{id:number, name:string}, isComposite:boolean, isDeleted:boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold:boolean, isReadOnly:boolean, isRetained:boolean}, categoryId:number, stateId:number, stateOwnerId:number, state:string, recordTypeId:number, recordType:string, createdBy:string, updatedBy:string, owner:string, creationDate:string, modificationDate:string, flags:number, children:number, isDraft:boolean, isReadOnly:boolean, isLocked:boolean, isDeleted:boolean, isComposite:boolean, isLink:boolean, localFile:{hasFile:boolean, isModified:boolean, pageCount:number, extension:{type:number, extensions: Array<string>, description:string}}, searchFields:Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Version Model
   */
  async getFileVersion(context, payload) {
    try {
      const result = await getFileVersion(payload.id, payload.version);
      context.commit(SET_DOCUMENT, result?.data);
      return result ? result?.data : { id: payload.id };
    } catch (e) {
      await handleError(
        e,
        `Unable to fetch File Version. (id: ${payload?.id})`
      );
    }
  },

  /**
   * Get Record Info
   * @param context
   * @param {Number|number} id
   * @return {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async getRecordInfo(context, id) {
    try {
      const result = await getRecordInfo(id);
      context.commit(SET_DOCUMENT, result?.data);
      return result ? result.data : { id: id };
    } catch (e) {
      await handleError(e, `Unable to fetch Record Info. (id: ${id})`);
    }
  },

  /**
   * check In File
   * @param context
   * @param {{id:Number, file:any, uploadFileModel:{Extension: undefined, Draft: boolean, InsertFileOption: number, Comments: string, Enqueue: boolean}}} payload
   * @return {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} File Model
   */
  async checkInFile(context, payload) {
    try {
      return await checkInFile(
        payload.id,
        payload.file,
        payload.uploadFileModel
      );
    } catch (e) {
      return await handleError(
        e,
        `Unable to check In File. (id: ${payload?.id})`
      );
    }
  },

  /**
   * upload File To Empty Record Wip
   * @param context
   * @param {{id:number, file:any, insertFileOption:number}} payload
   * @return {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async uploadFileToEmptyRecordWip(context, payload) {
    try {
      context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

      return await uploadFileToEmptyRecordWip(
        payload.id,
        payload.file,
        payload.insertFileOption,
        event => {
          const total = event?.total ?? 0;
          if (total > 0) {
            const progress = Math.round((100 * event.loaded) / total);
            context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
          }
        }
      );
    } catch (e) {
      return await handleError(
        e,
        `Unable to upload File To Empty Record Wip. (id: ${payload?.id})`
      );
    }
  },

  /**
   * upload File To File Wip
   * @param context
   * @param {{id:number, file:any, insertFileOption:number}} payload
   * @return {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async uploadFileToFileWip(context, payload) {
    try {
      context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

      return await uploadFileToFileWip(
        payload.id,
        payload.file,
        payload.insertFileOption,
        event => {
          const total = event?.total ?? 0;
          if (total > 0) {
            const progress = Math.round((100 * event.loaded) / total);
            context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
          }
        }
      );
    } catch (e) {
      return await handleError(
        e,
        `Unable to upload File To File Wip. (id: ${payload?.id})`
      );
    }
  },

  /**
   * upload File To Document Record Wip
   * @param context
   * @param {{id:number, file:any, insertFileOption:number}} payload
   * @return {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async uploadFileToCompoundWip(context, payload) {
    try {
      context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

      return await uploadFileToEmptyRecordWip(
        //Todo: Replace With Document Wip, once Api Created
        payload.id,
        payload.file,
        payload.insertFileOption,
        event => {
          const total = event?.total ?? 0;
          if (total > 0) {
            const progress = Math.round((100 * event.loaded) / total);
            context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
          }
        }
      );
    } catch (e) {
      return await handleError(
        e,
        `Unable to upload File To Document Wip. (id: ${payload?.id})`
      );
    }
  },

  /**
   * upload File To Checked Out File
   * @param context
   * @param {{id:number, file:any, insertFileOption:number}} payload
   * @return {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} File Model
   */
  async uploadFileToCheckedOutFile(context, payload) {
    try {
      context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

      return await uploadFileToCheckedOutFile(
        payload.id,
        payload.file,
        payload.insertFileOption,
        event => {
          const total = event?.total ?? 0;
          if (total > 0) {
            const progress = Math.round((100 * event.loaded) / total);
            context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
          }
        }
      );
    } catch (e) {
      return await handleError(
        e,
        `Unable to upload File To Checked Out File. (id: ${payload?.id})`
      );
    }
  },

  /**
   * Download File
   * @param context
   * @param  {{id:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<string>}
   */
  async downloadFile(context, payload) {
    try {
      return await downloadFile(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download File. (id: ${payload?.id})`
      );
    }
  },

  /**
   * Download File as PDF document
   * @param context
   * @param {Number|number} id record id
   * @returns {Promise<any>}
   */
  async downloadFileAsPdf(context, id) {
    try {
      return await downloadFileAsPdf(id);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download File as PDF document. (id: ${id})`
      );
    }
  },

  /**
   * download Empty Record Wip File with annotation model
   * @param context
   * @param {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @return {Promise<*>}
   */
  async downloadEmptyRecordWip(context, payload) {
    try {
      return await downloadEmptyRecordWip(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download work-in progress file. (id: ${payload.id})`
      );
    }
  },

  /**
   * download Empty Record Wip File as PDF document with annotation model
   * @param context
   * @param {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @return {Promise<*>}
   */
  async downloadEmptyRecordWipAsPdf(context, payload) {
    try {
      return await downloadEmptyRecordWipAsPDFDocument(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download work-in progress file. (id: ${payload.id})`
      );
    }
  },

  /**
   * download File Wip with annotation model
   * @param context
   * @param {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @return {Promise<*>}
   */
  async downloadFileWip(context, payload) {
    try {
      return await downloadFileWip(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download work-in progress file. (id: ${payload.id})`
      );
    }
  },

  /**
   * download File Wip as PDF document with annotation model
   * @param context
   * @param {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @return {Promise<*>}
   */
  async downloadFileWipAsPdf(context, payload) {
    try {
      return await downloadFileWipAsPDFDocument(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download work-in progress file. (id: ${payload.id})`
      );
    }
  },

  /**
   * Download Compound Document as PDF document
   * @param context
   * @param  {{id:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<any>}
   */
  async downloadCompoundAsPdf(context, payload) {
    try {
      return await downloadCompoundDocumentAsPdf(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download Compound as PDF document. (id: ${payload.id})`
      );
    }
  },

  /**
   * download Image File Version
   * @param context
   * @param  {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<string>}
   */
  async downloadImageFileVersion(context, payload) {
    try {
      return await apiDownloadImageFileVersion(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download image File Version. (id: ${payload?.id ??
          -1} version: ${payload?.version ?? -1})`
      );
    }
  },

  /**
   * download File Version without any annotation option
   * @param context
   * @param {{id:number, version:number}} payload
   * @return {Promise<string>}
   */
  async downloadFileVersion(context, payload) {
    try {
      return await downloadFileVersion(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download File Version. (id: ${payload?.id ??
          -1} version: ${payload?.version ?? -1})`
      );
    }
  },

  /**
   * download Pdf File Version without any annotation option
   * @param context
   * @param {{id:number, version:number}} payload
   * @return {Promise<string>}
   */
  async downloadPdfFileVersion(context, payload) {
    try {
      return await downloadPdfFileVersion(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download Pdf File Version. (id: ${payload?.id ??
          -1} version: ${payload?.version ?? -1})`
      );
    }
  },

  /**
   * download File Version As Pdf
   * @param context
   * @param  {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<string>}
   */
  async downloadFileVersionAsPdf(context, payload) {
    try {
      return await downloadFileVersionAsPdf(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download File Version as Pdf by id: ${payload?.id ??
          -1} version: ${payload?.version ?? -1})`
      );
    }
  },

  /**
   * download Edit File
   * @param context
   * @param  {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<string>}
   */
  async downloadFileEdit(context, payload) {
    try {
      return await downloadEditFile(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download Edit File by id: ${payload?.id ?? -1}`
      );
    }
  },

  /**
   * download Edit File As Pdf
   * @param context
   * @param  {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<string>}
   */
  async downloadFileEditAsPdf(context, payload) {
    try {
      return await downloadEditFileAsPdf(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download Edit File as Pdf by id: ${payload?.id ?? -1}`
      );
    }
  },

  /**
   * download Export File Version
   * @param context
   * @param  {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<string>}
   */
  async downloadExportFileVersion(context, payload) {
    try {
      return await downloadExportFileVersion(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download Export File by id: ${payload?.id ??
          -1} version: ${payload?.version ?? -1})`
      );
    }
  },

  /**
   * download Export File Version As Pdf
   * @param context
   * @param  {{id:Number, version:Number, downloadOptions:{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}}} payload
   * @returns {Promise<string>}
   */
  async downloadExportFileVersionAsPdf(context, payload) {
    try {
      return await downloadExportFileVersionAsPdf(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to download Export File as Pdf by id: ${payload?.id ??
          -1} version: ${payload?.version ?? -1})`
      );
    }
  },
  /**
   * Check Out File
   * @param context
   * @param {Number|number} id
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async checkOutFile(context, id) {
    try {
      console.log(`${scriptName} checkOutFile Id:`, id);
      return await checkOutFile(id);
    } catch (e) {
      return await handleError(e, `Unable to checkout file. (id: ${id}`);
    }
  },

  /**
   * Release File
   * @param context
   * @param {Number|number} id
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async releaseFile(context, id) {
    try {
      console.log(`${scriptName} releaseFile() Id:`, id);
      return await releaseFile(id);
    } catch (e) {
      return await handleError(e, `Unable to release File. (id: ${id})`);
    }
  },

  /**
   * Release Checked out File
   * @param context
   * @param {Number|number} id
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async releaseCheckedOutFile(context, id) {
    try {
      console.log(`${scriptName} releaseCheckedOutFile() Id:`, id);
      return await releaseCheckedOutFile(id);
    } catch (e) {
      return await handleError(
        e,
        `Unable to release Checked Out File. (id: ${id})`
      );
    }
  },

  /**
   * Release Wip File for Scanned/Acquired File
   * @param context
   * @param {Number|number} id
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async releaseWIPFile(context, id) {
    try {
      console.log(`${scriptName} releaseWIPFile() Id:`, id);
      return await releaseWIPFile(id);
    } catch (e) {
      return await handleError(e, `Unable to release WIP File. (id: ${id})`);
    }
  },

  /**
   * Release Wip File for Empty Record
   * @param context
   * @param {Number|number} id
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Record Item Model
   */
  async releaseWIPRecord(context, id) {
    try {
      console.log(`${scriptName} releaseWIPRecord() Id:`, id);
      return await releaseWIPRecord(id);
    } catch (e) {
      return await handleError(e, `Unable to release WIP Record. (id: ${id})`);
    }
  },

  /**
   * Get Search Highlights in a document
   * @param context
   * @param {{id:number, searchTerm:string}} props
   * @returns {Promise<Array<{page: Number, locations: Array<{left: Number, top: Number, width: Number, height: Number}>}>>} SearchHitsModel
   */
  async getSearchHighlights(context, props) {
    const id = props ? props.id : -1;
    const searchTerm = props ? props.searchTerm || "" : "";

    try {
      console.log(`${scriptName} getSearchHighlights() Id:`, id);
      const result = await getSearchHighlights(id, searchTerm);
      context.commit(SET_SEARCH_HIGHLIGHTS, result.data);
      return result.data;
    } catch (e) {
      await handleError(e, `Unable to get Search Highlights by id: ${id}`);
    }
  },

  /**
   * Check in import/scanned file to empty record
   * @param context
   * @param {{id:number, checkInModel:{Extension: string, Draft: boolean, Comments: string, Enqueue: boolean}}} payload
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} File Model
   */
  async checkInScannedFileToRecord(context, payload) {
    try {
      console.log(`${scriptName} checkInScannedFileToRecord() Id:`, payload.id);
      return await checkInScannedFileToRecord(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to checkIn Scanned File To Record by id: ${payload?.id ?? -1}`
      );
    }
  },

  /**
   * Check in edited file (checked-out file)
   * @param context
   * @param {{id:number, checkInModel:{Extension: string, Draft: boolean, Comments: string, Enqueue: boolean}}} payload
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} File Model
   */
  async checkInEditedDocument(context, payload) {
    try {
      console.log(`${scriptName} checkInEditedDocument() Id:`, payload.id);
      return await checkInEditedDocument(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to checkIn Edited Document by id: ${payload?.id ?? -1}`
      );
    }
  },

  /**
   * Import/Scan File to Compound Document
   * @param context
   * @param {{id:number, compoundInsertOption:number, checkInModel:{Extension: string, Draft: boolean, InsertFileOption: number, Comments: string, Enqueue: boolean}}} payload
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} Document Model
   */
  async scanToCompoundDocument(context, payload) {
    try {
      console.log(`${scriptName} scanToCompoundDocument() Id:`, payload.id);
      return await scanToCompoundDocument(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to scan To Compound Document by id: ${payload?.id ?? -1}`
      );
    }
  },

  /**
   * Import/Scan to Checked in File
   * @param context
   * @param {{id:number, checkInModel:{Extension: string, Draft: boolean, InsertFileOption: number, Comments: string, Enqueue: boolean}}} payload
   * @returns {Promise<{agendaItemOperations: Array<{name: string, allowed: boolean, valid: boolean}>, extension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: Array<string>, description: string}}, searchFields: Array<{name: string, fieldDataType: number, fieldDataTypeName: string, operations: Array<{name: string, allowed: boolean, valid: boolean}>, id: number, value: string}>, operations: Array<{name: string, allowed: boolean, valid: boolean}>, meetingOperations: Array<{name: string, allowed: boolean, valid: boolean}>, fields: Array<string>}>} File Model
   */
  async scanToCheckedInFile(context, payload) {
    try {
      console.log(`${scriptName} scanToCheckedInFile() Id:`, payload.id);
      return await scanToCheckedInFile(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to scan To CheckedIn File by id: ${payload?.id ?? -1}`
      );
    }
  },

  /**
   * Import Annotation File to a document
   * @param context
   * @param {{id:number, version:number, pageOption:number, file:File}} payload
   * @return {Promise<VoidFunction>}
   */
  async importAnnotations(context, payload) {
    try {
      context.commit(SET_UPLOAD_FILE_PROGRESS, 0);

      return await importAnnotations(
        payload.id,
        payload.version,
        payload.pageOption,
        payload.file,

        event => {
          const total = event?.total ?? 0;
          if (total > 0) {
            const progress = Math.round((100 * event.loaded) / total);
            context.commit(SET_UPLOAD_FILE_PROGRESS, progress);
          }
        }
      );
    } catch (e) {
      return await handleError(
        e,
        `Unable to import Annotation File to a document. (id: ${payload?.id})`
      );
    }
  },

  /**
   * Export Annotations from a document
   * @param context
   * @param  {{id:Number, version:Number,pageOption:number}} payload
   * @returns {Promise<any>}
   */
  async exportAnnotations(context, payload) {
    try {
      return await exportAnnotations(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to Export Annotations. (id: ${payload.id})`
      );
    }
  },

  /**
   * Get Ocr Text
   * @param context
   * @param  {{id:Number,page:number}} payload
   * @returns {Promise<any>}
   */
  async getOcrText(context, payload) {
    try {
      return await getOcrText(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to Get Ocr Text. (id: ${payload.id})`
      );
    }
  },

  /**
   * Save Ocr Text
   * @param context
   * @param  {{id:Number,text:String,page:number}} payload
   * @returns {Promise<any>}
   */
  async saveOcrText(context, payload) {
    try {
      return await saveOcrText(payload);
    } catch (e) {
      return await handleError(
        e,
        `Unable to Save Ocr Text. (id: ${payload.id})`
      );
    }
  }
};

export default actions;

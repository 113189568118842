/**
 * Public Access settings Names/fields
 * @type {Readonly<{searcher: string, buttons: string, sideBar: string, appBar: string, contact: string, company: string, modules: string}>}
 */
const settingNames = Object.freeze({
  searcher: "searcher",
  company: "company",
  contact: "contact",
  appBar: "appBar",
  sideBar: "sideBar",
  buttons: "buttons",
  modules: "modules"
});

/**
 * searcher Settings
 * @type {Readonly<{code: string, name: string, repository: string}>}
 */
const searcherSettings = Object.freeze({
  name: "name",
  code: "code",
  repository: "repository"
});

/**
 * app-bar settings
 * @type {Readonly<{prominent: string, color: string, shrinkOnScroll: string, gradient: string, fadeImgOnScroll: string, useTabs: string, logo: string, logoSize: string, title: string, collapseOnScroll: string, backgroundImageUrl: string}>}
 */
const appBarSettings = Object.freeze({
  title: "title",
  logo: "logo",
  logoSize: "logoSize",
  backgroundImageUrl: "backgroundImageUrl",
  color: "color",
  gradient: "gradient",
  prominent: "prominent",
  useTabs: "useTabs",
  collapseOnScroll: "collapseOnScroll",
  fadeImgOnScroll: "fadeImgOnScroll",
  shrinkOnScroll: "shrinkOnScroll"
});

/**
 * sideBar Settings
 * @type {Readonly<{enableMiniSidebar: string, backgroundImageUrl: string}>}
 */
const sideBarSettings = Object.freeze({
  backgroundImageUrl: "backgroundImageUrl",
  enableMiniSidebar: "enableMiniSidebar"
});

/**
 * button Settings
 * @type {Readonly<{color: string, elevation: string, outlined: string, rounded: string, textStyle: string}>}
 */
const buttonSettings = Object.freeze({
  color: "color",
  textStyle: "textStyle",
  outlined: "outlined",
  rounded: "rounded",
  elevation: "elevation"
});

/**
 * company Settings
 * @type {Readonly<{disclaimers: string, name: string, logo: string, description: string, linkedIn: string}>}
 */
const companySettings = Object.freeze({
  name: "name",
  logo: "logo",
  description: "description",
  disclaimers: "disclaimers",
  linkedIn: "linkedIn",
  address: "address"
});

/**
 * address Settings
 * @type {Readonly<{zip: string, country: string, city: string, addressLine1: string, addressLine2: string, state: string}>}
 */
const addressSettings = Object.freeze({
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  city: "city",
  state: "state",
  zip: "zip",
  country: "country"
});

/**
 * contact Settings
 * @type {Readonly<{name: string, description: string, phone: string, phoneDescription: string, email: string, emailDescription: string}>}
 */
const contactSettings = Object.freeze({
  name: "name",
  description: "description",
  phone: "phone",
  phoneDescription: "phoneDescription",
  email: "email",
  emailDescription: "emailDescription"
});

/**
 * module Settings
 * @type {Readonly<{visibleFolder: string, visibleCategory: string,  visibleMeetings: string}>}
 */
const moduleSettings = Object.freeze({
  visibleFolder: "visibleFolder",
  visibleCategory: "visibleCategory",
  visibleMeetings: "visibleMeetings"
});

/**
 * create Public Access Setting
 * @param {
 *    {searcher: {name: string, code: string, repository: string}},
 *    {appBar: {prominent: boolean, color: string, gradient: string, useTabs: boolean, logo: string, logoSize: number, backgroundImageUrl: string, title: string, collapseOnScroll: boolean, fadeImgOnScroll: boolean, shrinkOnScroll: boolean}}, {sideBar: {enableMiniSidebar: boolean, backgroundImageUrl: string}},
 *    {buttons: {color: string, elevation: number, outlined: boolean, rounded: boolean, textStyle: boolean}},
 *    {company: {disclaimers: string, name: string, logo: string, description: string, linkedIn: string, address: {zip: string, country: string, city: string, addressLine1: string, addressLine2: string, state: string}}},
 *    {contact: {name: string, description: string, phone: string, phoneDescription: string, email: string, emailDescription: string}},
 *    {modules: {visibleFolder: string, visibleCategory: string, visibleMeetings: string}}
 *    } settings Public Access Settings
 * @return {
 *    {searcher: {name: string, code: string, repository: string}},
 *    {appBar: {prominent: boolean, color: string, gradient: string, useTabs: boolean, logo: string, logoSize: number, backgroundImageUrl: string, title: string, collapseOnScroll: boolean, fadeImgOnScroll: boolean, shrinkOnScroll: boolean}}, {sideBar: {enableMiniSidebar: boolean, backgroundImageUrl: string}},
 *    {buttons: {color: string, elevation: number, outlined: boolean, rounded: boolean, textStyle: boolean}},
 *    {company: {disclaimers: string, name: string, logo: string, description: string, linkedIn: string, address: {zip: string, country: string, city: string, addressLine1: string, addressLine2: string, state: string}}},
 *    {contact: {name: string, description: string, phone: string, phoneDescription: string, email: string, emailDescription: string}},
 *    {modules: {visibleFolder: string, visibleCategory: string, visibleMeetings: string}}
 *    }
 */
const createPublicAccessSetting = (settings = undefined) => {
  return {
    [settingNames.searcher]: {
      [searcherSettings.name]: settings?.searcher?.name ?? "",
      [searcherSettings.code]: settings?.searcher?.code ?? "",
      [searcherSettings.repository]: settings?.searcher?.repository ?? ""
    },
    [settingNames.appBar]: {
      [appBarSettings.title]: settings?.appBar?.title ?? "",
      [appBarSettings.logo]: settings?.appBar?.logo,
      [appBarSettings.logoSize]: settings?.appBar?.logoSize ?? 48,
      [appBarSettings.backgroundImageUrl]: settings?.appBar?.backgroundImageUrl,
      [appBarSettings.color]: settings?.appBar?.color,
      [appBarSettings.gradient]: settings?.appBar?.gradient,
      [appBarSettings.prominent]: settings?.appBar?.prominent ?? true,
      [appBarSettings.useTabs]: settings?.appBar?.useTabs ?? true,
      [appBarSettings.collapseOnScroll]:
        settings?.appBar?.collapseOnScroll ?? false,
      [appBarSettings.fadeImgOnScroll]:
        settings?.appBar?.fadeImgOnScroll ?? false,
      [appBarSettings.shrinkOnScroll]: settings?.appBar?.shrinkOnScroll ?? false
    },
    [settingNames.sideBar]: {
      [sideBarSettings.backgroundImageUrl]:
        settings?.sideBar?.backgroundImageUrl,
      [sideBarSettings.enableMiniSidebar]:
        settings?.sideBar?.enableMiniSidebar ?? true
    },
    [settingNames.buttons]: {
      [buttonSettings.color]: settings?.buttons?.color,
      [buttonSettings.textStyle]: settings?.buttons?.textStyle ?? true,
      [buttonSettings.outlined]: settings?.buttons?.outlined ?? false,
      [buttonSettings.rounded]: settings?.buttons?.rounded ?? false,
      [buttonSettings.elevation]: settings?.buttons?.elevation ?? 0
    },
    [settingNames.company]: {
      [companySettings.logo]: settings?.company?.logo ?? undefined,
      [companySettings.name]: settings?.company?.name ?? "",
      [companySettings.description]: settings?.company?.description ?? "",
      [companySettings.disclaimers]: settings?.company?.disclaimers ?? "",
      [companySettings.linkedIn]: settings?.company?.linkedIn ?? undefined,
      [companySettings.address]: {
        [addressSettings.addressLine1]:
          settings?.company?.address?.addressLine1 ?? "",
        [addressSettings.addressLine2]:
          settings?.company?.address?.addressLine2 ?? "",
        [addressSettings.city]: settings?.company?.address?.city ?? "",
        [addressSettings.state]: settings?.company?.address?.state ?? "",
        [addressSettings.zip]: settings?.company?.address?.zip ?? "",
        [addressSettings.country]: settings?.company?.address?.country ?? ""
      }
    },
    [settingNames.contact]: {
      [contactSettings.name]: settings?.contact?.name ?? "",
      [contactSettings.description]: settings?.contact?.description ?? "",
      [contactSettings.phone]: settings?.contact?.phone ?? "",
      [contactSettings.phoneDescription]:
        settings?.contact?.phoneDescription ?? "",
      [contactSettings.email]: settings?.contact?.email ?? "",
      [contactSettings.emailDescription]:
        settings?.contact?.emailDescription ?? ""
    },
    [settingNames.modules]: {
      [moduleSettings.visibleFolder]: settings?.modules?.visibleFolder ?? false,
      [moduleSettings.visibleCategory]:
        settings?.modules?.visibleCategory ?? false,
      [moduleSettings.visibleMeetings]:
        settings?.modules?.visibleMeetings ?? false
    }
  };
};

export { settingNames, createPublicAccessSetting };

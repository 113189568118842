// shared mutation types
import {
  SET_DOCUMENT,
  SET_UPLOAD_FILE_PROGRESS
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// document mutation types
import { SET_SEARCH_HIGHLIGHTS } from "@/store/document/mutation-types";

// mutation Helper
import { setUploadFileProgress } from "@/store/shared/mutations/record/record-mutations";

const mutations = {
  /**
   * Set current document
   * @param state
   * @param document
   */
  [SET_DOCUMENT]: (state, document) => {
    state.document = document;
    console.log(SET_DOCUMENT, state.document);
  },

  /**
   * Set current document's search highlights
   * @param state
   * @param searchHighlights
   */
  [SET_SEARCH_HIGHLIGHTS]: (state, searchHighlights) => {
    state.searchHighlights = searchHighlights;
    console.log(SET_SEARCH_HIGHLIGHTS, state.searchHighlights);
  },

  /**
   * SET UPLOAD FILE PROGRESS
   * @param state
   * @param {Number|number} progress
   */
  [SET_UPLOAD_FILE_PROGRESS](state, progress) {
    console.log(state, progress);
    setUploadFileProgress(state, progress);
  }
};

export default mutations;

/**
 * Questys licenced feature Names
 * @type {Readonly<{Execute: string, CmxFastForms: string, QuestysCMx: string, OcrNuance: string, OcrTesseract: string, WFx: string, WebClient: string, LSx: string, WebPortal: string, ScanIsis: string, CmxBarcodeCoverSheets: string, OcrTesseract06: string, CmxMSOfficeAddIn: string, PDFViewer: string, OcrTesseract08: string, OcrTesseract02: string, OcrTesseract04: string}>}
 */
// services
import { apiUrl, isDevelopment } from "@/services/config/configService";

// model
import { supportedScanning } from "@/model/acquire/scan/scanningModel";

// utils
import { isEmpty } from "@/utils";

/**
 * feature Name
 * @type {Readonly<{Execute: string, CmxFastForms: string, QuestysCMx: string, OcrNuance: string, OcrTesseract: string, WFx: string, WebClient: string, LSx: string, WebPortal: string, ScanIsis: string, WebScan: string, CmxBarcodeCoverSheets: string, OcrTesseract06: string, CmxMSOfficeAddIn: string, PDFViewer: string, OcrTesseract08: string, OcrTesseract02: string, OcrTesseract04: string}>}
 */
const featureName = Object.freeze({
  Execute: "Execute",
  QuestysCMx: "Questys CMx",
  LSx: "LSx",
  WFx: "WFx",
  CmxBarcodeCoverSheets: "cmxBarcodeCoversheets",
  CmxFastForms: "cmxFastForms",
  CmxMSOfficeAddIn: "cmxMSOfficeAddin",
  OcrNuance: "ocrNuance",
  OcrTesseract: "ocrTesseract",
  OcrTesseract02: "ocrTesseract02",
  OcrTesseract04: "ocrTesseract04",
  OcrTesseract06: "ocrTesseract06",
  OcrTesseract08: "ocrTesseract08",
  PDFViewer: "PDFViewer",
  WebClient: "WebClient",
  WebPortal: "WebPortal",
  ScanIsis: "ISIS",
  WebScan: "WebScan"
});

/**
 * feature Status
 * @param {{name: string, used: number, total: number}} feature
 * @return {{licenced: boolean, released: boolean, statusText: string, supported: boolean, notSupportedReason: string}}
 */
const featureStatus = feature => {
  const licenced = isLicencedFeature(feature);
  const released = isReleasedFeature(feature);

  const total = feature?.total ?? 0;
  const used = feature?.used ?? 0;
  const supported =
    feature.name === featureName.WebScan ? supportedScanning : true;

  let notSupportedReason = "";
  if (!supported) {
    if (feature.name === featureName.WebScan) {
      notSupportedReason = `${feature.name} is available on a Windows Desktop station only`;
    }
  }

  return createFeatureStatus(
    licenced,
    used,
    total,
    released,
    supported,
    notSupportedReason
  );
};

/**
 * create Feature Status
 * @param {boolean} licenced is Feature licenced
 * @param {number} licenceUsed number of used licences
 * @param {number} licenceTotal total licences count
 * @param {boolean} released is Feature released
 * @param {boolean} supported is Feature supported
 * @param {string} notSupportedReason not Supported Reason
 * @return {{licenced: boolean, released: boolean, statusText: string}} Feature Status
 */
const createFeatureStatus = (
  licenced,
  licenceUsed = 0,
  licenceTotal = 0,
  released = false,
  supported = true,
  notSupportedReason = ""
) => {
  const licenceUsage = `${licenceUsed}/${licenceTotal}`;
  const statusLicenced = licenced
    ? `Licenced (${licenceUsage})`
    : `Not Licenced (${licenceUsage})`;
  // const statusReleased = released ? "Released" : "Not Released"; // all released
  return {
    licenced: licenced,
    licenceUsed: licenceUsed,
    licenceTotal: licenceTotal,
    released: released,
    supported: supported,
    statusText: supported
      ? `${statusLicenced}` // and ${statusReleased} all released
      : notSupportedReason
  };
};

/**
 * determines whether the provided feature is Available (released, supported & licenced)
 * @param {{name: string, used: number, total: number}} feature
 * @return {boolean} returns whether provided feature is Available (released & licenced)
 */
const isAvailableFeature = feature => {
  const status = featureStatus(feature);

  if (!status.supported || !status.released || !status.licenced) {
    console.warn(`${feature?.name} ${status}`);
  }

  return status.supported && status.released && status.licenced;
};

/**
 * determines whether feature is released (customer ready)
 * @param {{name: string, used: number, total: number}} feature Questys' feature
 * @return {boolean} true if specified Feature has been Released
 */
const isReleasedFeature = feature => {
  switch (feature?.name) {
    case featureName.WFx: {
      return true;
      //return isDevOps();
    }
    case featureName.LSx: {
      return true;
      //return isDevOps();
    }
    case featureName.WebScan: {
      return true;
    }
    case featureName.CmxBarcodeCoverSheets: {
      return false;
    }
    case featureName.CmxFastForms: {
      return false;
    }
    case featureName.CmxMSOfficeAddIn: {
      return false;
    }
    case featureName.ScanIsis: {
      return false;
    }
    default: {
      return true;
    }
  }
};

/**
 * is Development or Operations/Demo/ ...
 * @return {string|boolean}
 */

/**
 * is Development or Questys' Demo Url
 * @return {boolean} true if is Development or Questys' Demo Url
 */
const isDevOps = () => {
  if (isDevelopment) {
    return true;
  }
  //
  // A Back-door for questys cloud Demo Purposes ...
  //
  const urls = [
    "https://demo06.questyscloud.com",
    "https://demo05.questyscloud.com",
    "https://localhost:44377"
  ];

  const url = urls.find(url => apiUrl?.startsWith(url) ?? false);

  return !isEmpty(url);
};

/**
 * determines whether feature is licensed
 * @param {{name: string, used: number, total: number}} feature Questys' feature
 * @return {boolean} returns whether Questys' feature is licensed
 */
const isLicencedFeature = feature => {
  switch (feature?.name) {
    case featureName.QuestysCMx:
    case featureName.Execute: {
      return true;
    }
    case featureName.WebScan: {
      /**
       * ensure Web Scan is available,
       * since the Web Scan feature is built into the cost of Access to the customer
       */
      return true;
    }
    default: {
      //
      // Remarks: eventually feature.used will be compared with feature.total
      // at this moment, just make sure that total license count is > 0,
      // since we do not have the means to determine used/released license count
      //
      return (feature?.total ?? 0) > 0;
    }
  }
};

export {
  featureName,
  isAvailableFeature,
  isReleasedFeature,
  isLicencedFeature,
  featureStatus,
  isDevOps
};

// utils
import {
  getLocalStorageItem,
  setLocalStorageItem,
  repoUserLocalStorageKey
} from "@/utils/localStorageUtility";

// model
import { moduleNames } from "@/model/solution/moduleModel";

/**
 * module Name
 * @type {string}
 */
const moduleName = moduleNames.Category;

/**
 * Local Storage Keys
 * @type {Readonly<{categoryId: string}>}
 */
const localStorageKey = Object.freeze({
  categoryId: `${moduleName}_categoryId`
});

/**
 * Store Selected Module Item id (categoryId) to the local Storage
 * @param {number} id Module Item id
 */
const storeSelectedModuleItemId = id =>
  setLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.categoryId),
    id.toString()
  );

/**
 * Get Stored Selected Module Item id (categoryId) from local Storage
 * @returns {Number|number}
 */
const getStoredSelectedModuleItemId = () => {
  const id = getLocalStorageItem(
    repoUserLocalStorageKey(localStorageKey.categoryId)
  );
  return id ? (isNaN(id) ? -1 : Number(id)) : -1;
};

export { storeSelectedModuleItemId, getStoredSelectedModuleItemId };

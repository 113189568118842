// services
import { storeSelectedModuleItemId } from "@/services/category/categoryModuleService";

// shared mutation types
import {
  SET_DRAWER_MINI,
  SET_DOCUMENTS,
  SET_DOCUMENT,
  SET_DOCUMENT_SEARCH_CATEGORY,
  SET_FILE_VERSION,
  SET_RECORD,
  UPDATE_RECORD_STATUS,
  UPDATE_RECORD_FILE_VERSION,
  UPDATE_RECORD_INFO,
  SET_DOCUMENT_CHILDREN
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// category mutation-types
import { SET_CATEGORIES, SET_CATEGORY } from "@/store/category/mutation-types";
import {
  setCurrentRecord,
  setDocument,
  setFileVersion,
  updateRecordStatus,
  updateFileVersionInfo,
  updateRecordInfos,
  setDocumentChildren
} from "@/store/shared/mutations/record/record-mutations";
import { moduleNames } from "@/model/solution/moduleModel";

const mutations = {
  /**
   * Set drawer to mini state
   * @param state
   * @param payload
   */
  [SET_DRAWER_MINI]: (state, payload) => {
    state.drawerMini = payload || false;
  },

  /**
   * SET_CATEGORIES
   * @param state
   * @param {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string}} categories
   */
  [SET_CATEGORIES](state, categories) {
    state.categories = categories;
    console.log(SET_CATEGORIES, state.categories);
  },

  [SET_CATEGORY](state, category) {
    state.category = category;
    console.log(SET_CATEGORY, state.category);
    storeSelectedModuleItemId(state.category?.id ?? -1);
  },

  /**
   * Set current 'category' record
   * @param state
   * @param {{record, category}} payload
   */
  [SET_RECORD](state, payload) {
    setCurrentRecord(state, payload);
    console.log(`${moduleNames.Category}.${SET_RECORD} record:`, state.record);
    console.log(
      `${moduleNames.Category}.${SET_RECORD} recordCategory:`,
      state.recordCategory
    );
    storeSelectedModuleItemId(state.record?.id ?? -1);
  },

  /**
   * SET RECORD FILE VERSION
   * @param state
   * @param payload
   */
  [UPDATE_RECORD_FILE_VERSION](state, payload) {
    updateFileVersionInfo(state, payload);
  },

  /**
   * update Record Status
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload
   */
  [UPDATE_RECORD_STATUS](state, payload) {
    updateRecordStatus(state, payload);
  },

  /**
   * update Record Infos of current record and all related records
   * @param state
   * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} payload RecordItemModel
   */
  [UPDATE_RECORD_INFO](state, payload) {
    updateRecordInfos(state, payload);
  },

  [SET_DOCUMENTS](state, documents) {
    state.documents = documents;
    console.log(SET_DOCUMENTS, state.documents);
  },

  /**
   * Set current selected 'category' document
   * @param state
   * @param {{document, category}} payload
   */
  [SET_DOCUMENT](state, payload) {
    setDocument(state, payload);
    console.log(
      `${moduleNames.Category}.${SET_DOCUMENT} document:`,
      state.document
    );
    console.log(
      `${moduleNames.Category}.${SET_DOCUMENT} documentCategory:`,
      state.documentCategory
    );
  },

  [SET_DOCUMENT_SEARCH_CATEGORY](state, payload) {
    state.searchCategory = payload;
    console.log(SET_DOCUMENT_SEARCH_CATEGORY, state.searchCategory);
  },

  /**
   * Set current 'Category' File Version
   * @param state
   * @param {{version, category}} payload
   */
  [SET_FILE_VERSION](state, payload) {
    setFileVersion(state, payload);
    console.log(
      `${moduleNames.Category}.${SET_FILE_VERSION} version:`,
      state.version
    );
    console.log(
      `${moduleNames.Category}.${SET_FILE_VERSION} versionCategory:`,
      state.versionCategory
    );
  },

  /**
   * Set document children of specified document
   * @param state
   * @param {{document, children}} payload
   */
  [SET_DOCUMENT_CHILDREN](state, payload) {
    setDocumentChildren(state, payload);
  }
};

export default mutations;

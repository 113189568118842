import Vue from "vue";
import Vuex from "vuex";

// modules
import solution from "./solution/index";
import category from "./category/index";
import search from "./search/index";
import folder from "./folder/index";
import inbox from "./inbox/index";
import project from "./project/index";
import agenda from "./agenda/index";
import document from "./document/index";
import user from "./user/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    solution,
    user,
    search,
    category,
    folder,
    inbox,
    project,
    agenda,
    document
  },

  state: {},
  mutations: {},
  recordAttributesOperations: {}
});

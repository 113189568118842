import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

// design
import {
  colorMD,
  colorTheme,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { indigoGradient } from "@/design/gradients/gradientConst";

const state = {
  /**
   * Current selected Application's module
   * @type {{name: String, title: String, icon: String}}
   */
  appModule: undefined,

  /**
   * Left drawer visibility
   * @type boolean
   */
  drawerLeft: true,

  /**
   * Right drawer visibility
   * @type boolean
   */
  drawerRight: false,

  /**
   *  Uses Tabs as a Application selector
   *  @type boolean
   */
  usesAppTabs: true,

  /**
   * Puts the app-bar into a collapsed state when scrolling
   *  @type boolean
   */
  appBarCollapseOnScroll: false,

  /**
   * Uses Sidebar Image
   * @type {boolean}
   */
  usesSidebarImage: false,

  /**
   * enable Mini (shrink) Drawer
   */
  enableMiniDrawer: true,

  /**
   * Dialog with/without Toolbar
   * @type {boolean}
   */
  dialogToolbarStyle: false,

  /**
   * Application Bar Material Design Color
   * @type {{name:String, variantType:String, variantNumber:Number}}
   */
  appBarColor: {
    name: colorMD.indigo,
    variantType: variantType.darken,
    variantNumber: variantNumber.n2
  },

  /**
   * Application Bar Gradient used to apply a simple gradient overlay to the appBar background image
   */
  appBarGradient: indigoGradient,

  /**
   * data Table Toolbar Material Design Color
   */
  dataTableToolbarColor: {
    name: colorMD.indigo,
    variantType: variantType.darken,
    variantNumber: variantNumber.n2
  },

  /**
   *  Dialog Title Text Material Design Color
   *  @type {{name:String, variantType:String, variantNumber:Number}}
   */
  dialogTitleColor: {
    name: colorMD.indigo,
    variantType: variantType.darken,
    variantNumber: variantNumber.n2
  },

  /**
   * Details Toolbar Material Material Design Color
   * @type {{name:String, variantType:String, variantNumber:Number}}
   */
  detailsToolbarColor: {
    name: colorMD.green,
    variantType: variantType.darken,
    variantNumber: variantNumber.n1
  },

  /**
   * Menu Item Material Design Color
   * @type {{name:String, variantType:String, variantNumber:Number}}
   */
  menuItemColor: {
    name: colorMD.indigo,
    variantType: variantType.darken,
    variantNumber: variantNumber.n2
  },

  /**
   * Progress Material Design Color
   * @type {{name:String, variantType:String, variantNumber:Number}}
   */
  progressColor: {
    name: colorTheme.primary,
    variantType: undefined,
    variantNumber: undefined
  },

  /**
   * Command Buttons Material Design Color
   * @type {{name:String, variantType:String, variantNumber:Number}}
   */
  buttonColor: {
    name: colorMD.indigo,
    variantType: variantType.darken,
    variantNumber: variantNumber.n2
  },

  /**
   * Command Button Text Style
   * @type {Boolean}
   */
  buttonTextStyle: true,

  /**
   * Command Button Outlined
   * @type {Boolean}
   */
  buttonOutlined: false,

  /**
   * Command Button Rounded
   * @type {Boolean}
   */
  buttonRounded: false,

  /**
   * Command Button Elevation
   * @type {Number}
   */
  buttonElevation: 0,

  /**
   * Dialog Cancel Button color (implements Material Design Color)
   * @type {{name:String, variantType:String, variantNumber:Number}}
   */
  buttonCancelColor: {
    name: colorTheme.secondary,
    variantType: undefined,
    variantNumber: undefined
  },

  /**
   * Delete Color (implements Material Design Color)
   * @type {{name:String, variantType:String, variantNumber:Number}}
   */
  deleteColor: {
    name: colorTheme.error,
    variantType: variantType.lighten,
    variantNumber: variantNumber.n1
  },

  /**
   * records Fetch Count
   * @type {Number}
   */
  recordsFetchCount: 50
};

export default {
  actions,
  mutations,
  getters,
  state,
  namespaced: true
};

// services
import { storeAppModuleName } from "@/services/solution/solutionService";
import {
  storeUsesAppTabs,
  getStoredUsesAppTabs,
  getStoredUsesSidebarImage,
  storeUsesSidebarImage,
  storeDialogToolbarStyle,
  storeCommandButtonTextStyle,
  getDialogToolbarStyle,
  commandButtonTextStyle,
  storeCommandButtonOutlined,
  commandButtonOutlined,
  storeCommandButtonRounded,
  commandButtonRounded,
  storeCommandButtonElevation,
  commandButtonElevation,
  storeButtonColor,
  storeToolbarDetailsColor,
  getStoredButtonColor,
  getToolbarDetailsColor,
  storeAppBarCollapseOnScroll,
  appBarCollapseOnScroll,
  storeEnableMiniDrawer,
  enableMiniDrawer,
  storeRecordsFetchCount,
  getRecordsFetchCount
} from "@/services/user/userService";

// Axillary
import { isStringValueTrue } from "@/utils";

// store
import {
  INITIALIZE_STATES,
  SET_APPLICATION,
  SET_DRAWER,
  SET_DRAWER_RIGHT,
  SET_DRAWER_ENABLE_MINI_DRAWER,
  SET_USES_APP_TABS,
  SET_USES_SIDEBAR_IMAGE,
  SET_DIALOG_TOOLBAR_STYLE,
  SET_COMMAND_COLOR,
  SET_COMMAND_BUTTONS_TEXT_STYLE,
  SET_COMMAND_BUTTONS_OUTLINED,
  SET_COMMAND_BUTTONS_ROUNDED,
  SET_COMMAND_BUTTONS_ELEVATION,
  SET_TOOLBAR_DETAILS_COLOR,
  SET_APP_BAR_COLLAPSE_ON_SCROLL,
  SET_RECORDS_FETCH_COUNT
} from "./mutation-types";

const mutations = {
  /**
   * Initialize states by using saved local storage data
   * @param state
   */
  [INITIALIZE_STATES]: state => {
    try {
      let value = getStoredUsesAppTabs();
      if (value) {
        state.usesAppTabs = isStringValueTrue(value);
      }
      value = getStoredUsesSidebarImage();
      if (value) {
        state.usesSidebarImage = isStringValueTrue(value);
      }
      value = getDialogToolbarStyle();
      if (value) {
        state.dialogToolbarStyle = isStringValueTrue(value);
      }
      value = commandButtonTextStyle;
      if (value) {
        state.buttonTextStyle = isStringValueTrue(value);
      }
      value = commandButtonOutlined;
      if (value) {
        state.buttonOutlined = isStringValueTrue(value);
      }
      value = commandButtonRounded;
      if (value) {
        state.buttonRounded = isStringValueTrue(value);
      }
      value = commandButtonElevation;
      if (value) {
        state.buttonElevation = value;
      }
      value = getStoredButtonColor();
      if (value) {
        state.buttonColor = isStringValueTrue(value);
      }
      value = getToolbarDetailsColor();
      if (value) {
        state.toolbarDetailsColor = isStringValueTrue(value);
      }
      value = appBarCollapseOnScroll;
      if (value) {
        state.appBarCollapseOnScroll = isStringValueTrue(value);
      }
      value = enableMiniDrawer;
      if (value) {
        state.enableMiniDrawer = isStringValueTrue(value);
      }
      value = getRecordsFetchCount();
      console.log(`INITIALIZE_STATES.getRecordsFetchCount() value:`, value);
      if (value && !isNaN(value)) {
        state.recordsFetchCount = Number(value);
        console.log(
          `INITIALIZE_STATES.Set state.recordsFetchCount:`,
          state.recordsFetchCount
        );
      }
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Set current appModule
   * @param state
   * @param  {{title: String, icon: String, name: String}} appModule
   * @constructor
   */
  [SET_APPLICATION]: (state, appModule) => {
    state.appModule = appModule;
    storeAppModuleName(state.appModule.name);
  },
  /**
   * Show/Hide drawerLeft
   * @param state
   * @param {Boolean} payload
   * @constructor
   */
  [SET_DRAWER]: (state, payload) => {
    state.drawerLeft = payload;
  },
  /**
   * Show/Hide right drawerLeft
   * @param state
   * @param {boolean} payload
   * @constructor
   */
  [SET_DRAWER_RIGHT]: (state, payload) => {
    state.drawerRight = payload;
  },
  /**
   * Enable Mini Drawer
   * @param state
   * @param {boolean} payload
   */
  [SET_DRAWER_ENABLE_MINI_DRAWER]: (state, payload) => {
    state.enableMiniDrawer = payload;
    storeEnableMiniDrawer(state.enableMiniDrawer);
  },

  /**
   *  Uses Tabs as a Application selector
   * @param state
   * @param {boolean} payload
   * @constructor
   */
  [SET_USES_APP_TABS]: (state, payload) => {
    state.usesAppTabs = payload;
    storeUsesAppTabs(state.usesAppTabs);
  },

  /**
   * puts the app-bar into a collapsed state when scrolling
   * @param state
   * @param {boolean} payload
   */
  [SET_APP_BAR_COLLAPSE_ON_SCROLL]: (state, payload) => {
    state.appBarCollapseOnScroll = payload;
    storeAppBarCollapseOnScroll(state.appBarCollapseOnScroll);
  },

  /**
   * Uses Sidebar Image
   * @param state
   * @param {Boolean} payload
   */
  [SET_USES_SIDEBAR_IMAGE]: (state, payload) => {
    state.usesSidebarImage = payload;
    storeUsesSidebarImage(state.usesSidebarImage);
  },
  [SET_DIALOG_TOOLBAR_STYLE]: (state, payload) => {
    state.dialogToolbarStyle = payload;
    storeDialogToolbarStyle(state.dialogToolbarStyle);
  },
  [SET_COMMAND_COLOR]: (state, payload) => {
    state.buttonColor = payload;
    storeButtonColor(state.commandColor);
  },
  [SET_COMMAND_BUTTONS_TEXT_STYLE]: (state, payload) => {
    state.buttonTextStyle = payload;
    storeCommandButtonTextStyle(state.buttonTextStyle);
  },
  [SET_COMMAND_BUTTONS_OUTLINED]: (state, payload) => {
    state.buttonOutlined = payload;
    storeCommandButtonOutlined(state.buttonOutlined);
  },
  [SET_COMMAND_BUTTONS_ROUNDED]: (state, payload) => {
    state.buttonRounded = payload;
    storeCommandButtonRounded(state.buttonRounded);
  },
  [SET_COMMAND_BUTTONS_ELEVATION]: (state, payload) => {
    state.buttonElevation = payload;
    storeCommandButtonElevation(state.buttonElevation);
  },

  [SET_TOOLBAR_DETAILS_COLOR]: (state, payload) => {
    state.toolbarDetailsColor = payload;
    storeToolbarDetailsColor(state.toolbarDetailsColor);
  },

  [SET_RECORDS_FETCH_COUNT]: (state, payload) => {
    state.recordsFetchCount = payload;
    console.log(
      `SET_RECORDS_FETCH_COUNT.state.recordsFetchCount: `,
      state.recordsFetchCount
    );
    storeRecordsFetchCount(state.recordsFetchCount?.toString());
  }
};

export default mutations;

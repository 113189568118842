import { Color } from "@/design/colors/Color";

/**
 *  Material Design display Text Types
 * @type {Readonly<{subtitle2: string, display1: string, subtitle1: string, body1: string, body2: string, caption: string, display3: string, title: string, overline: string, display2: string, headline: string}>}
 */
const displayTypes = Object.freeze({
  display4: "h1",
  display3: "h2",
  display2: "h3",
  display1: "h4",
  headline: "h5",
  title: "h6",
  subtitle1: "subtitle-1",
  subtitle2: "subtitle-2",
  body1: "body-1",
  body2: "body-2",
  button: "button", // upper case
  caption: "caption",
  overline: "overline"
});

/**
 * Material Design font Emphasis
 * @type {Readonly<{light: string, black: string, bold: string, medium: string, italic: string, regular: string, thin: string}>}
 */
const fontEmphasis = Object.freeze({
  black: "font-weight-black",
  bold: "font-weight-bold",
  medium: "font-weight-medium",
  regular: "font-weight-regular",
  light: "font-weight-light",
  thin: "font-weight-thin",
  italic: "font-italic"
});

/**
 * Material Design Text alignments
 * @type {Readonly<{left: string, justify: string, center: string, right: string}>}
 */
const alignments = Object.freeze({
  justify: "justify",
  center: "center",
  left: "left",
  right: "right"
});

/**
 * Material Design Text opacities
 * @type {Readonly<{secondary: string, disabled: string, primary: string}>}
 */
const opacities = Object.freeze({
  primary: "primary",
  secondary: "secondary",
  disabled: "disabled"
});

/**
 * Material Design Text transforms
 * @type {Readonly<{uppercase: string, lowercase: string, capitalize: string}>}
 */
const transforms = Object.freeze({
  lowercase: "lowercase",
  uppercase: "uppercase",
  capitalize: "capitalize"
});

/**
 * Material Design Text decorations
 * @type {Readonly<{underline: string, lineThrough: string, none: string, overline: string}>}
 */
const decorations = Object.freeze({
  none: "decoration-none",
  overline: "decoration-overline",
  underline: "decoration-underline",
  lineThrough: "decoration-line-through"
});

/**
 * Material Design prevents Wrapping Text
 * @type {Readonly<string>}
 */
const preventWrappingText = Object.freeze("text-no-wrap");

/**
 * Follows Material design colorName spec.
 * https://vuetifyjs.com/en/styles/text-and-typography
 */
class Text {
  color = new Color();

  // Left aligned text on viewports sized MD (medium) or wider.
  // alignment = `text-${breakpoints[2]}-${alignments[2]}}`;
  // opacity = `text--${opacities[0]}`;
  // transform = `text--${transforms[2]}`;
  // decoration = `text--${decorations[0]}`;

  displayType = displayTypes.subtitle1;
  emphasize = fontEmphasis.regular;
  alignment = undefined;
  breakpoint = undefined;
  opacity = undefined;
  transform = undefined;
  decoration = undefined;

  /**
   * Text constructor
   * @param {Color|undefined} color
   * @param {string|undefined} displayType
   * @param {string|undefined} emphasize
   * @param {string|undefined} alignment
   * @param {string|undefined} transform
   * @param {string|undefined} opacity
   * @param {string|undefined} decoration
   */
  constructor(
    color = undefined,
    displayType = displayTypes.subtitle1,
    emphasize = fontEmphasis.regular,
    alignment = undefined,
    transform = undefined,
    opacity = undefined,
    decoration = undefined
  ) {
    this.color = color;
    this.displayType = displayType;
    this.emphasize = emphasize;
    this.alignment = alignment;
    this.transform = transform;
    this.opacity = opacity;
    this.decoration = decoration;
  }

  /**
   * get Material Design Class Text
   * @returns {string}
   */
  getClassText() {
    // .text-{breakpoint}-{value} for sm, md, lg and xl
    let text = `text-${this.displayType} ${this.emphasize}`;

    if (this.alignment) {
      text = this.breakpoint
        ? `${text} text-${this.breakpoint}-${this.alignment}`
        : `${text} text-${this.alignment}`;
    }

    if (this.opacity) {
      text = `${text} text--${this.opacity}`;
    }

    if (this.transform) {
      text = `${text} text-${this.transform}`;
    }

    if (this.decoration) {
      text = `${text} text-${this.decoration}`;
    }

    if (!this.color) return text;

    const textColorClass = this.getTextColorClass();

    return textColorClass ? `${text} ${textColorClass}` : text;
  }

  /**
   * get Material Design Class Text Color
   * @returns {string|undefined}
   */
  getTextColorClass() {
    if (!this.color || !this.color.name) {
      return undefined;
    }

    const colorText = `${this.color.name}--text`;

    if (
      !this.color.isValidVariantType(this.color.variant) ||
      !this.color.isValidVariantNumber(this.color.variantNumber)
    )
      return colorText;

    return `${colorText} text--${this.color.variant}-${this.color.variantNumber}`;
  }
}

export {
  Text,
  fontEmphasis,
  alignments,
  opacities,
  transforms,
  decorations,
  preventWrappingText,
  displayTypes
};

import { ruleNames } from "@/model/rules/ruleModel";
import { toLowerCaseSafe } from "@/utils";
import {
  colorMD,
  colorTheme,
  variantNumber,
  variantType
} from "@/design/colors/mdColors";
import { Color } from "@/design/colors/Color";

/**
 * Category field Name
 * @type {Readonly<{categoryType: string, formId: string, formName: string, name: string, id: string}>}
 */
const fieldName = Object.freeze({
  id: "id",
  name: "name",
  categoryType: "categoryType",
  formId: "formId",
  formName: "formName"
});

/**
 * Category field label
 * @type {Readonly<{categoryType: string, formId: string, formName: string, name: string, id: string}>}
 */
const fieldLabel = Object.freeze({
  id: "Category Id",
  name: "Category Name",
  categoryType: "Category Type",
  formId: "Form Id",
  formName: "Form Name"
});

/**
 * Category All
 * @type {{formId: number, name: string, id: number, fields: *[]}}
 */
const categoryAll = {
  id: -1,
  name: "All Categories",
  formId: 0,
  fields: []
};

/**
 * record Name Rules
 * @param {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, autoFiling:boolean, requiredRecordName:boolean, requiredRecordNameForAutoFiling:boolean}} category Record category
 * @return {*[]} mixed array of types function, boolean and string
 */
const recordNameRules = category => {
  const rules = [];

  if (category?.requiredRecordName ?? false) {
    rules.push(value => !!value || ruleNames.required);
  }

  if (category?.requiredRecordNameForAutoFiling ?? false) {
    rules.push(value => !!value || ruleNames.requiredByAutoFiling);
  }

  return rules;
};

/**
 * record category Rules
 * @param {{id:number}} id Record category id
 * @return {*[]} mixed array of types function, boolean and string
 */
const recordCategoryRules = id => {
  const rules = [];

  if (id < 0) {
    rules.push(value => !!value || ruleNames.required);
  }

  return rules;
};

/**
 * category Type
 * @type {Readonly<{general: number, item: number, system: number, minutesSummary: number, otherInfoItemsDocument: number, agendaPacket: number, agendaSystemDocuments: number, section: number, backupMaterial: number, meeting: number}>}
 */
const categoryType = Object.freeze({
  general: 0,
  system: 1,
  meeting: 6,
  section: 7,
  item: 8,
  backupMaterial: 12,
  agendaSystemDocuments: 13,
  minutesSummary: 14,
  agendaPacket: 15,
  otherInfoItemsDocument: 16
});

/**
 * Category Types
 * @type {({name: string, id: number, label: string}|{name: string, id: number, label: string}|{name: string, id: number, label: string}|{name: string, id: number, label: string}|{name: string, id: number, label: string})[]}
 */
const categoryTypes = Object.freeze([
  {
    id: categoryType.general,
    name: "General",
    label: "General",
    color: {
      name: colorMD.indigo,
      variantType: variantType.darken,
      variantNumber: variantNumber.n2
    }
  },
  {
    id: categoryType.system,
    name: "System",
    label: "System",
    color: {
      name: colorTheme.secondary,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n4
    }
  },
  {
    id: categoryType.meeting,
    name: "Meeting",
    label: "Agenda Meeting",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: categoryType.section,
    name: "Section",
    label: "Agenda Section",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: categoryType.item,
    name: "Item",
    label: "Agenda Item",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: 12,
    name: categoryType.backupMaterial,
    label: "Agenda Backup Material",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: 13,
    name: categoryType.agendaSystemDocuments,
    label: "Agenda System Documents",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: 14,
    name: categoryType.minutesSummary,
    label: "Agenda Minutes Summary",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: 15,
    name: categoryType.agendaPacket,
    label: "Agenda Packet",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  },
  {
    id: 16,
    name: categoryType.otherInfoItemsDocument,
    label: "Agenda Other Info Items Document",
    color: {
      name: colorMD.green,
      variantType: variantType.lighten,
      variantNumber: variantNumber.n1
    }
  }
]);

/**
 * find Category Type
 * @param typeId Category Type id
 * @return {{name: string, id: number, label: string}} Category Type
 */
const findCategoryType = typeId => {
  return categoryTypes.find(ct => ct.id === typeId);
};

/**
 * find Category Type Color Class
 * @param id Category Type id
 * @return {String|string|undefined} Category Color Class
 */
const findCategoryTypeColorClass = id => {
  const color = findCategoryTypeColor(id);

  return color
    ? new Color(
        color.name,
        color.variantType,
        color.variantNumber
      ).getClassColor()
    : undefined;
};

/**
 * find Category Type Color
 * @param {Number|number} id Category Type id
 * @return {{name: string, variantType: string, variantNumber: number}|undefined}
 */
const findCategoryTypeColor = id => {
  return categoryTypes?.find(el => el?.id === id)?.color;
};

/**
 * is Allowed Record Category Operation
 * @param {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, operations: {name:string, allowed:boolean, valid:boolean}[]}} category Record Category
 * @param {string} operation Record Operation name
 * @return {boolean} returns true if Record Category Operation is allowed
 */
const isAllowedOperation = (category, operation) =>
  category?.operations?.find(
    op => toLowerCaseSafe(op.name) === toLowerCaseSafe(operation)
  )?.allowed ?? false;

export {
  fieldName,
  fieldLabel,
  categoryTypes,
  categoryAll,
  categoryType,
  recordNameRules,
  recordCategoryRules,
  isAllowedOperation,
  findCategoryType,
  findCategoryTypeColorClass,
  findCategoryTypeColor
};

import { fieldType, isNumericFieldType } from "@/model/field/fieldModel";
import { recordType } from "@/model/record/recordModel";
import { itemCount } from "@/utils";

/**
 * Create Query Model
 * @param {{Id:number, Name:string, Owner:string, Creator:string, CreatedDateFrom:string, CreatedDateTo:string, ModifiedDateFrom:string, ModifiedDateTo:string, CategoryId:number, TypeId:number, StateId:number, Extension:string, Version:number, Pages:number, Fields: {Id:number, Include:boolean, Operator:string, Value:string}[]}} payload
 * @return {{CategoryId: number, TypeId: number, Owner: string, CreatedDateTo: string, StateId: number, Creator: string, Name: string, Extension: string, Pages: number, Fields: {any}[], Version: number, CreatedDateFrom: string, ModifiedDateTo: string, ModifiedDateFrom: string}}
 */
const createQueryModel = payload => {
  return {
    Id: payload?.Id,
    Name: payload?.Name,
    Owner: payload?.Owner,
    Creator: payload?.Creator,
    CreatedDateFrom: payload?.CreatedDateFrom,
    CreatedDateTo: payload?.CreatedDateTo,
    ModifiedDateFrom: payload?.ModifiedDateFrom,
    ModifiedDateTo: payload?.ModifiedDateTo,
    CategoryId: payload?.CategoryId,
    TypeId: payload.TypeId,
    StateId: payload.StateId,
    Extension: payload.Extension,
    Version: payload?.Version,
    Pages: payload?.Pages,
    Fields: payload?.Fields
  };
  // or
  // return {
  //   [fieldName.Id]: payload?.Id,
  //   [fieldName.Name]: payload?.Name,
  //   [fieldName.Owner]: payload?.Owner,
  //   [fieldName.Creator]: payload?.Creator,
  //   [fieldName.CreatedDateFrom]: payload?.CreatedDateFrom,
  //   [fieldName.CreatedDateTo]: payload?.CreatedDateTo,
  //   [fieldName.ModifiedDateFrom]: payload?.ModifiedDateFrom,
  //   [fieldName.ModifiedDateTo]: payload?.ModifiedDateTo,
  //   [fieldName.CategoryId]: payload?.CategoryId,
  //   [fieldName.TypeId]: payload.TypeId,
  //   [fieldName.StateId]: payload.StateId,
  //   [fieldName.Extension]: payload.Extension,
  //   [fieldName.Version]: payload?.Version,
  //   [fieldName.Pages]: payload?.Pages,
  //   [fieldName.Fields]: payload?.Fields
  // };
};

/**
 * filter Include Fields
 * @param {{Id:number, Include:boolean, Operator:string, Value:string}[]} fields
 * @return {{Id:number, Include:boolean, Operator:string, Value:string}[]}
 */
const filterIncludeFields = fields => {
  const includeFields = [];

  if ((fields?.length ?? 0) === 0) {
    return includeFields;
  }

  fields
    .filter(el => el.Include)
    .forEach(el => {
      if (!includeFields.find(fld => fld === el.Id)) {
        includeFields.push(el.Id);
      }
    });

  return includeFields;
};

/**
 * Create Query Field Model
 * @param {number} id
 * @param {boolean} include
 * @param {string} operator
 * @param {any} value
 * @return {{Id: number, Include: boolean, Operator: string, Value: any}}
 */
const createQueryFieldModel = (id, include, operator, value) => {
  return {
    Id: id,
    Include: include ?? false,
    Operator: operator ?? "",
    Value: value
  };
  // or
  // * @return {{[p: string]: number|*}}
  // return {
  //   [fieldModelName.Id]: id,
  //   [fieldModelName.Include]: include ?? false,
  //   [fieldModelName.Operator]: operator ?? "",
  //   [fieldModelName.Value]: value
  // };
};

/**
 * Determines whether field has a valid searchable value
 * @param {{id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string, include:boolean}}  field
 * @return {boolean}
 */
const hasSearchableValue = field => {
  if (!field) {
    return false;
  }

  const type = field?.fieldDataType ?? fieldType.LITERAL;

  if (isNumericFieldType(type)) {
    return searchableNumericFieldValue(field?.value ?? "") >= 0;
  }

  return !!(field?.value?.toString() ?? undefined);
};

/**
 * Get Valid Search Field Value
 * @param {{id:number, name:string, label:string, fieldDataType:number, operators: {description: string, operator: string}[], value:any, operator:string}} field
 */
const searchableFieldValue = field => {
  const type = field?.fieldDataType ?? fieldType.LITERAL;

  if (isNumericFieldType(type)) {
    return searchableNumericFieldValue(field?.value);
  }

  return field?.value ?? "";
};

/**
 * get searchable Numeric Field Value
 * @param {any} value
 * @return {number|number}
 */
const searchableNumericFieldValue = value => {
  const parseVal = parseInt(value ?? "");

  return isNaN(parseVal) ? -1 : parseVal;
};

/**
 * can Search within a Record
 * @param {number} type
 * @return {boolean}
 */
const canSearchWithinRecord = type => {
  return !(type === recordType.FILE || type === recordType.RECORD);
};

/**
 * has a valid Search Criteria
 * @param criteria
 * @param {boolean} excludeWithInId
 * @return {boolean}
 */
const hasSearchCriteria = (criteria, excludeWithInId = true) => {
  if (!criteria) {
    return false;
  }

  if ((criteria?.Id?.length ?? 0) > 0) {
    if (searchableNumericFieldValue(criteria?.Id[0]?.Value ?? -1) >= 0) {
      return true;
    }
  }

  if (criteria?.Name?.Value) return true;
  if (criteria?.Owner?.Value) return true;
  if (criteria?.Creator?.Value) return true;

  if (criteria?.CreatedDateFrom?.Value) return true;
  if (criteria?.ModifiedDateFrom?.Value) return true;

  if (searchableNumericFieldValue(criteria?.TypeId?.Value) > 0) {
    return true;
  }

  if (searchableNumericFieldValue(criteria?.StateId?.Value) > 0) {
    return true;
  }

  if (criteria?.Extension?.Value) return true;

  if (searchableNumericFieldValue(criteria?.Version?.Value) >= 0) {
    return true;
  }
  if (searchableNumericFieldValue(criteria?.Pages?.Value) > 0) {
    return true;
  }

  if (searchableNumericFieldValue(criteria?.CategoryId?.Value) >= 0) {
    return true;
  }

  if (!excludeWithInId) {
    if (searchableNumericFieldValue(criteria.WithInId) > 0) return true;
  }

  return (
    itemCount(criteria.Fields) > 0 &&
    (criteria.Fields.filter(el => el?.Value?.toString() ?? "" !== "-1")
      ?.length ?? 0) > 0
  );
};

const formatSearchCriteria = (name, operator, value) =>
  `${name ?? ""} ${operator ?? ""} ${value ?? ""}`;

/**
 * field Criteria To String
 * @param {{id:number, name:string, label:string, operator:string, value:any}} field
 * @return {string} user friendly formatted field Criteria string
 */
const fieldCriteriaToString = field => {
  const name = field?.label ?? field.name ?? "";

  return formatSearchCriteria(name, field?.operator, field?.value);
};

export {
  createQueryModel,
  createQueryFieldModel,
  filterIncludeFields,
  searchableNumericFieldValue,
  searchableFieldValue,
  hasSearchableValue,
  canSearchWithinRecord,
  hasSearchCriteria,
  formatSearchCriteria,
  fieldCriteriaToString
};

const SET_BREADCRUMBS = "SET_BREADCRUMBS";

//
// workflow project
//
const SET_RECORD_PROJECTS = "SET_RECORD_PROJECTS";
const SET_RECORD_OPEN_PROJECTS = "SET_RECORD_OPEN_PROJECTS";

// set new record's workflow project
const SET_RECORD_NEW_PROJECT = "SET_RECORD_NEW_PROJECT";

//
// new workflow project
//
const SET_PROJECT_NEW = "SET_PROJECT_NEW";
const SET_PROJECT_NEW_TEMPLATE = "SET_PROJECT_NEW_TEMPLATE";

export {
  SET_BREADCRUMBS,
  SET_PROJECT_NEW,
  SET_PROJECT_NEW_TEMPLATE,
  SET_RECORD_PROJECTS,
  SET_RECORD_OPEN_PROJECTS,
  SET_RECORD_NEW_PROJECT
};

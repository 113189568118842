import Vue from "vue";
import App from "@/App";
import "@/registerServiceWorker";
import router from "@/router/index";
import store from "@/store/index";
import vuetify from "@/plugins/vuetify";
import { FontAwesomeIcon } from "@/plugins/fontawesome";

// services
import {
  setProductionSettings,
  setPublicAccessSettings,
  isDevelopment
} from "@/services/config/configService";

// store
import { INITIALIZE_STATES } from "@/store/solution/mutation-types";

Vue.config.productionTip = false;

// Create Two Broadcast Channels, one for the viewer, one for the main app, so if they collide, both can receive the updated record
Vue.prototype.$viewerChannel = new BroadcastChannel("Viewer");
Vue.prototype.$mainAppChannel = new BroadcastChannel("MainApp");

// Expose FontAwesomeIcon as a global component
Vue.component("font-awesome-icon", FontAwesomeIcon);

/**
 * URL of Runtime/Production Access Settings
 * @type {string}
 */
const urlRuntimeSettings = `/runtimeSettings.json`;

/**
 * URL of Public Access Settings
 * @type {string}
 */
const urlPublicAccessSettings = `/publicAccessSettings.json`;

//
// is Development, then .env will be used
// else, system will try to set Production Settings using
// external settings file: runtimeSettings.json located in the root of dist folder
// if the runtimeSettings.json is not found, then .env settings will be used
// Note: runtimeSettings.json can be expanded as we go forward
//
if (isDevelopment) {
  fetchSettings(urlPublicAccessSettings)
    .then(setting => {
      setPublicAccessSettings(setting);
    })
    .catch(e => {
      console.error(
        `Couldn't set Public Access Settings using url: ${urlPublicAccessSettings}`,
        e.toString()
      );
    })
    .finally(() => {
      runApp();
    });
} else {
  fetchAllSettings(urlRuntimeSettings, urlPublicAccessSettings)
    .then(([runtimeSettings, publicAccessSettings]) => {
      setProductionSettings(runtimeSettings);
      setPublicAccessSettings(publicAccessSettings);
    })
    .catch(e => {
      console.error(
        `Couldn't set Production and Public Access Settings using urlRuntimeSettings: ${urlRuntimeSettings} and urlPublicAccessSettings: ${urlPublicAccessSettings}`,
        e.toString()
      );
    })
    .finally(() => {
      runApp();
    });
}

/**
 * run Questys Access app
 */
function runApp() {
  new Vue({
    router,
    store,
    beforeCreate() {
      this.$store.commit(`solution/${INITIALIZE_STATES}`);
    },
    vuetify,
    render: h => h(App)
  }).$mount("#app");
}

/**
 * fetch JSON settings data
 * @param {string} url URL of a JSON file
 * @return {Promise<*>}
 */
async function fetchSettings(url) {
  try {
    // starts a fetch request and returns a promise
    const response = await fetch(url);

    // extract a JSON object from the response
    return response.json();
  } catch (e) {
    console.warn(`Couldn't fetch settings from ${url}`, e.toString());
  }
}

/**
 * fetch All JSON Settings
 * @param urlRuntimeSettings URL of Runtime Settings JSON file
 * @param urlPublicAccessSettings URL of Public Access Settings JSON file
 * @return {Promise<any[]>}
 */
async function fetchAllSettings(urlRuntimeSettings, urlPublicAccessSettings) {
  try {
    // perform parallel fetch requests
    const [runtimeSettings, publicAccessSettings] = await Promise.all([
      fetch(urlRuntimeSettings),
      fetch(urlPublicAccessSettings)
    ]);

    // extract a JSON objects from the responses
    const runtime = await runtimeSettings.json();
    const publicAccess = await publicAccessSettings.json();

    return [runtime, publicAccess];
  } catch (e) {
    console.warn(
      `Couldn't fetch Runtime & Public Access settings from urlRuntimeSettings: ${urlRuntimeSettings} and urlPublicAccessSettings: ${urlPublicAccessSettings}`,
      e.toString()
    );
  }
}
